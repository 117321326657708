import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'
import axios from 'axios'
import React from 'react'
import pedidoActions from '../../../actions/pedidoActions'
import { alerts } from '../../../services/alerts'

const instance = axios.create({
  baseURL: 'https://api.paypal.com',
})

const BotonCancelar = ({ pedido, getDetalle, carga }) => {
  const [open, setOpen] = React.useState(false)
  const [access_token, setAccessToken] = React.useState('Cancelar Pedido')
  const [loading, setLoading] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div style={{ marginLeft: '30px', float: 'right' }}>
      {pedido.status !== 'Devolucion Completa' && (
        <Button
          size='small'
          style={{ float: 'right', backgroundColor: 'red', color: 'white' }}
          variant='outlined'
          color='primary'
          onClick={handleClickOpen}
        >
          Cancelar pedido
        </Button>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        {/* <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle> */}
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            ¿Deseas cancelar el pedido?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose()
              setLoading(true)
              var token_ // variable will store the token
              var userName = window.localStorage.getItem('Client_ID')
              // app clientID
              var passWord = window.localStorage.getItem('Secret')
              // app clientSecret
              var caspioTokenUrl =
                'https://api-m.sandbox.paypal.com/v1/oauth2/token' // Your application token endpoint
              var request = new XMLHttpRequest()

              function getToken(url, clientID, clientSecret) {
                var decodedStringBtoA = clientID + ':' + clientSecret

                var myHeaders = new Headers()
                myHeaders.append(
                  'Authorization',
                  'Basic ' + btoa(decodedStringBtoA),
                )
                myHeaders.append(
                  'Content-Type',
                  'application/x-www-form-urlencoded',
                )

                var urlencoded = new URLSearchParams()
                urlencoded.append('grant_type', 'client_credentials')

                var requestOptions = {
                  method: 'POST',
                  headers: myHeaders,
                  body: urlencoded,
                  redirect: 'follow',
                }

                fetch(url, requestOptions)
                  .then(response => response.text())
                  .then(result => {
                    var json = JSON.parse(result)
                    console.log('éxito data cfdi:' + result)
                  })
                  .catch(error => {
                    console.error('error data:' + error)
                  })
              }
              // Get the token
              // getToken(caspioTokenUrl, userName, passWord);

              if (pedido.tipoPago === 1) {
                setLoading(true)
                carga(true)
                var decodedStringBtoA = userName + ':' + passWord

                var myHeaders = new Headers()
                myHeaders.append(
                  'Authorization',
                  'Basic ' + btoa(decodedStringBtoA),
                )
                myHeaders.append(
                  'Content-Type',
                  'application/x-www-form-urlencoded',
                )

                var urlencoded = new URLSearchParams()
                urlencoded.append('grant_type', 'client_credentials')

                var requestOptions = {
                  method: 'POST',
                  headers: myHeaders,
                  body: urlencoded,
                  redirect: 'follow',
                }

                fetch(caspioTokenUrl, requestOptions)
                  .then(response => response.text())
                  .then(result => {
                    var json = JSON.parse(result)
                    console.log('éxito data cfdi:' + result)
                    setAccessToken(json.access_token)

                    instance
                      .post(
                        `https://api-m.sandbox.paypal.com/v2/payments/captures/${pedido?.payPalCaptureId}/refund`,
                        {},
                        {
                          headers: {
                            Authorization: `Bearer ${json.access_token}`,
                            'Content-Type': 'application/json',
                          },
                        },
                      )
                      .then(response => {
                        const { id, status } = response.data
                        pedidoActions
                          .cancelPayPal({
                            folio: pedido.referencia,
                            status: status,
                            payPalId: id,
                          })
                          .then(() => {
                            handleClose()
                            getDetalle().then(() => {
                              setLoading(false)
                              carga(false)
                            })
                            alerts.success('Su pedido ha sido cancelado')
                          })
                      })
                      .catch(error => {
                        console.log(error)
                        setLoading(false)

                        carga(false)
                        alerts.success(
                          'Hubo un problema con la cancelacion del pedido',
                        )
                      })
                  })
                  .catch(error => {
                    setLoading(false)
                    carga(false)
                    alerts.success(
                      'Hubo un problema con la cancelacion del pedido',
                    )
                    console.error('error data:' + error)
                  })
              } else {
                setLoading(true)
                carga(true)

                var tokn = window.localStorage.getItem('access_token')

                instance
                  .post(
                    `https://api.mercadopago.com/v1/payments/${pedido?.payPalCaptureId}/refunds`,
                    {},
                    {
                      headers: {
                        Authorization: `Bearer ` + tokn,
                        'Content-Type': 'application/json',
                      },
                    },
                  )
                  .then(response => {
                    const { id, status } = response.data
                    pedidoActions
                      .cancelMP({
                        folio: pedido.referencia,
                        status: 'COMPLETED',
                        MPId: id.toString(),
                      })
                      .then(() => {
                        handleClose()

                        setLoading(false)
                        carga(false)

                        alerts.success('Su pedido ha sido cancelado')
                      })
                  })
                  .catch(error => {
                    console.log(error)
                    setLoading(false)

                    carga(false)
                    alerts.error(
                      'Hubo un problema con la cancelacion del pedido',
                    )
                  })
              }
            }}
            size='small'
            style={{ float: 'right', backgroundColor: 'red', color: 'white' }}
          >
            Cancelar pedido
          </Button>
          <Button onClick={handleClose} color='primary' autoFocus>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default BotonCancelar
