import React, { useContext, useEffect } from 'react'
import {
  Select,
  InputLabel,
  FormControl,
  FormControlLabel,
  Switch,
  Box,
} from '@material-ui/core'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

import ApiGeneral from '../../services/ApiGeneral'
import UserContext from '../../contexts/user/UserContext'
import errores from '../../services/errores'

const useStyles = makeStyles(() => ({
  btncancelar: {
    background: '#eeeeee',
    color: '#003462',
    backgroundColor: '#eeeeee!important',
  },
  btnsubnmit: {
    background: '#003462',
    color: '#ffff',
    backgroundColor: '#003462!important',
  },
  DiAction: {
    paddingRight: '2rem',
  },
  error: {
    color: '#ff0500',
  },
}))

const DireccionFormulario = ({
  getDirecciones,
  handleCloseDirecciones,
  setType,
  setMessage,
  setSBOpen,
  haveDirecctions,
}) => {
  const { UserState } = useContext(UserContext)
  const classes = useStyles()
  const [open, setOpen] = React.useState(true)
  const [Pais, setPais] = React.useState([])
  const [estado, setEstado] = React.useState([])
  const [municipio, setMunicipio] = React.useState([])
  const [colonia, setColonia] = React.useState([])
  const [CP, setCP] = React.useState('')
  const [selecteds, setSelecteds] = React.useState({
    Pais: '',
    Estado: '',
    Municipio: '',
    Colonia: '',
  })

  const validationSchema = yup.object({
    Colonia: yup.string().required('El campo colonia es requerido.'),
    NoExt: yup.string().required('El campo número exterior es requerido.'),
    Calle: yup.string().required('El campo calle es requerido.'),
    CP: yup
      .string()
      .min(5, 'El C.P. debe tener un mínimo de 5 caracteres.')
      .max(5, 'El C.P. debe tener un máximo de 5 caracteres.'),
  })

  const [direccion, setDireccion] = React.useState({
    ClienteId: null,
    ColoniaId: 0,
    Calle: '',
    NoExt: '',
    NoInt: '',
    CP: '',
    Colonia: '',
    Municipio: '',
    activo: true,
    Principal: true,
    UsuarioCreoId: null,
  })

  const validate = values => {
    const errors = {}
    var count = CP.length
    if (count < 5) {
      errors.CP = 'El C.P. debe tener un mínimo de 5 caracteres.'
    }
    if (count > 5) {
      errors.CP = 'El C.P. debe tener un máximo de 5 caracteres.'
    }
    // if (!values.rfc) {
    //   errors.rfc = 'El campo RFC es requerido.';
    // } else if (/^[a-zA-Z]{3,4}(\d{6})((\D|\d){2,3})?$/.test(values.rfc)) {
    //   errors.email = 'Invalid email address';
    // }
    return errors
  }

  // let usuario = user.userData

  const formik = useFormik({
    initialValues: {
      ClienteId: UserState.idCliente,
      //ClienteId:"64be9460-b84b-4ee7-a7aa-2d2eece6a315",
      ColoniaId: 0,
      Calle: '',
      NoExt: '',
      NoInt: '',
      CP: '',
      Colonia: '',
      activo: true,
      Principal: !haveDirecctions,
      //UsuarioCreoId:"64be9460-b84b-4ee7-a7aa-2d2eece6a315"
      UsuarioCreoId: UserState.idCliente,
    },
    validate,

    validationSchema: validationSchema,

    onSubmit: values => {
      var createDireccion = {
        ClienteId: UserState.idCliente,
        //ClienteId:"64be9460-b84b-4ee7-a7aa-2d2eece6a315",
        ColoniaId: selecteds.Colonia,
        Calle: values.Calle,
        NoExt: values.NoExt,
        NoInt: values.NoInt,
        CP: CP,
        activo: values.activo,
        Principal: values.Principal,
        //UsuarioCreoId:"64be9460-b84b-4ee7-a7aa-2d2eece6a315"
        UsuarioCreoId: UserState.idCliente,
      }

      ApiGeneral.createDir(createDireccion)
        .then(r => {
          handleClose()
          handleCloseDirecciones()
          setSBOpen(true)
          setMessage('Los datos se han actualizado con éxito.')
          setType('success')
          getDirecciones()
          console.info('La dirección se ha agregado con éxito', r)
        })
        .catch(e => {
          handleClose()
          handleCloseDirecciones()

          setSBOpen(true)
          setMessage(errores.handleError('error', e))
          setType('error')
        })
    },
  })

  const getDataByCP = event => {
    formik.values.Colonia = ''

    setCP(event.currentTarget.value)

    ApiGeneral.getCP(event.currentTarget.value)
      .then(response => {
        setPais(response.data.paises)
        setEstado(response.data.estados)
        setMunicipio(response.data.municipios)
        setColonia(response.data.colonias)
        setSelecteds({
          Pais: response.data.idPais,
          Estado: response.data.idEstado,
          Municipio: response.data.idMunicipio,
        })
        // setDatosFacturacion({ ...props.datosFacturacion, 'coloniaId': response.idColonia });
      })
      .catch(error => {})
  }

  useEffect(() => {
    getPais()
  }, [])

  const getPais = () => {
    ApiGeneral.getPais()
      .then(response => {
        console.info('data pais:' + response.data.paises)
        setPais(response.data.paises)
      })
      .catch(error => {
        console.error('error data pais:' + error)
      })
  }

  const handlePais = event => {
    selecteds.Pais = event.currentTarget.value
    setSelecteds({ ...selecteds, Pais: event.target.value })
    ApiGeneral.getEstadoPais(selecteds.Pais)
      .then(response => {
        setEstado(response.data.estados)
      })
      .catch(error => {
        console.error('error data estado:' + error)
      })
  }

  const handleEstado = event => {
    selecteds.Estado = event.currentTarget.value
    setSelecteds({ ...selecteds, Estado: event.target.value })
    ApiGeneral.getMunicipoEstado(event.currentTarget.value)
      .then(response => {
        setMunicipio(response.data.municipios)
      })
      .catch(error => {
        console.error('error data  municipio:' + error)
      })
  }

  const handleMunicipio = event => {
    selecteds.Municipio = event.currentTarget.value
    setSelecteds({ ...selecteds, Municipio: event.target.value })
    ApiGeneral.getColoniasByMunicipios(selecteds.Municipio)
      .then(response => {
        setColonia(response.data.colonias)
      })
      .catch(error => {
        //console.log("error data colonia"+ error);
      })
  }

  const handleColonia = event => {
    selecteds.Colonia = event.currentTarget.value
    formik.values.Colonia = event.currentTarget.value
    setSelecteds({ ...selecteds, Colonia: event.target.value })
    ApiGeneral.getColonias(event.currentTarget.value)
      .then(response => {
        setCP(response.data.cp)
        // selecteds.CP = response.data.cp
      })
      .catch(error => {
        console.error('error data colonias')
      })
  }

  const handleClose = () => {
    AsignarDatos(null)
    setSelecteds([])
    setCP('')
    formik.resetForm()
    setOpen(false)
    getDirecciones()
  }

  const handleChangeActive = event => {
    formik.values.Principal = event.target.checked
    setDireccion({ ...direccion, [event.target.name]: event.target.checked })
  }

  const handleChangeActiveDir = event => {
    formik.values.activo = event.target.checked
    setDireccion({ ...direccion, [event.target.name]: event.target.checked })
  }

  const AsignarDatos = objeto => {
    setDireccion({
      ...direccion,
      Calle: objeto === null ? '' : objeto.Calle,
      NoExt: objeto === null ? '' : objeto.NoExt,
      NoInt: objeto === null ? '' : objeto.NoInt,
      CP: objeto === null ? '' : objeto.CP,
      Colonia: objeto === null ? '' : objeto.Colonia,
      ColoniaId: objeto === null ? '' : objeto.ColoniaId,
      Municipio: objeto === null ? '' : objeto.Municipio,
      activo: objeto === null ? '' : objeto.activo,

      Principal: objeto === null ? '' : objeto.Principal,
      UsuarioCreoId: objeto === null ? '' : objeto.UsuarioCreoId,
      ClienteId: objeto === null ? '' : objeto.ClienteId,
    })
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box mb={1}>
        <TextField
          label='Calle*'
          margin='dense'
          id='Calle'
          name='Calle'
          type='text'
          fullWidth
          onChange={formik.handleChange}
          value={formik.values.Calle}
          onBlur={formik.handleBlur}
          error={formik.touched.Calle && Boolean(formik.errors.Calle)}
          helperText={formik.touched.Calle && formik.errors.Calle}
        />
      </Box>

      <Box mb={1}>
        <TextField
          margin='dense'
          id='NoExt'
          name='NoExt'
          label='No. Ext*'
          type='text'
          fullWidth
          onChange={formik.handleChange}
          value={formik.values.NoExt}
          onBlur={formik.handleBlur}
          error={formik.touched.NoExt && Boolean(formik.errors.NoExt)}
          helperText={formik.touched.NoExt && formik.errors.NoExt}
        />
      </Box>

      <Box mb={1}>
        <TextField
          margin='dense'
          id='NoInt'
          name='NoInt'
          label='No. Int'
          type='text'
          fullWidth
          onChange={formik.handleChange}
          value={formik.values.NoInt}
          onBlur={formik.handleBlur}
          error={formik.touched.NoInt && Boolean(formik.errors.NoInt)}
          helperText={formik.touched.NoInt && formik.errors.NoInt}
        />
      </Box>

      <Box mb={1}>
        <TextField
          label='C.P.*'
          fullWidth
          name='CP'
          id='CP'
          type='number'
          value={CP}
          onChange={getDataByCP}
          onBlur={formik.handleBlur}
          error={formik.touched.CP && Boolean(formik.errors.CP)}
          helperText={formik.touched.CP && formik.errors.CP}
        />
      </Box>

      <Box mb={1}>
        <FormControl fullWidth margin='dense'>
          <InputLabel htmlFor='cmbPais'>País*</InputLabel>
          <Select
            native
            inputProps={{
              name: 'Pais',
              id: 'Pais',
            }}
            onChange={handlePais}
            value={selecteds.Pais}
            onBlur={formik.handleBlur}
            error={formik.touched.Pais && Boolean(formik.errors.Pais)}
          >
            <option aria-label='None' value='' />
            {Pais.map((object, index) => (
              <option key={index} value={object.idPais}>
                {object.nombre} - {object.clavePais}
              </option>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box mb={1}>
        <FormControl fullWidth margin='dense'>
          <InputLabel htmlFor='cmbEstado'>Estado*</InputLabel>
          <Select
            native
            inputProps={{
              name: 'Estado',
              id: 'Estado',
            }}
            value={selecteds.Estado}
            onChange={handleEstado}
            onBlur={formik.handleBlur}
            error={formik.touched.Estado && Boolean(formik.errors.Estado)}
          >
            <option aria-label='None' value='' />
            {estado.map((object, index) => (
              <option key={index} value={object.idEstado}>
                {object.nombre} - {object.claveEstado}
              </option>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box mb={1}>
        <FormControl fullWidth margin='dense'>
          <InputLabel htmlFor='cmbMpio'>Municipio*</InputLabel>
          <Select
            native
            inputProps={{
              name: 'Municipio',
              id: 'Municipio',
            }}
            value={selecteds.Municipio}
            onChange={handleMunicipio}
            onBlur={formik.handleBlur}
            error={formik.touched.Municipio && Boolean(formik.errors.Municipio)}
          >
            <option aria-label='None' value='' />
            {municipio.map((object, index) => (
              <option key={index} value={object.idMunicipio}>
                {object.nombre}
              </option>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box mb={1}>
        <FormControl fullWidth margin='dense'>
          <InputLabel htmlFor='cmbColonia'>Colonia*</InputLabel>
          <Select
            native
            inputProps={{
              name: 'Colonia',
              id: 'Colonia',
            }}
            value={selecteds.Colonia}
            onChange={handleColonia}
            onBlur={formik.handleBlur}
            error={formik.touched.Colonia && Boolean(formik.errors.Colonia)}
          >
            <option aria-label='None' value='' />
            {colonia.map((object, index) => (
              <option key={index} value={object.idColonia}>
                {object.nombre}
              </option>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box display='flex' justifyContent='flex-start' mb={2}>
        <FormControlLabel
          control={
            <Switch
              color='primary'
              checked={formik.values.activo}
              onChange={handleChangeActiveDir}
              onBlur={formik.handleBlur}
              name='activo'
            />
          }
          label='Activo'
        />
        <FormControlLabel
          control={
            <Switch
              color='primary'
              checked={formik.values.Principal}
              onChange={handleChangeActive}
              onBlur={formik.handleBlur}
              name='Principal'
            />
          }
          label='Principal'
        />
      </Box>

      <Box textAlign='right' mt={2}>
        <Button
          onClick={handleCloseDirecciones}
          variant='outlined'
          color='default'
        >
          Cancelar
        </Button>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          style={{ marginLeft: '10px' }}
        >
          Guardar
        </Button>
      </Box>
    </form>
  )
}

export default DireccionFormulario
