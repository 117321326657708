import { SET_USER, CLEAR_USER } from './UserTypes'

// Cambiar la función para retornar un objeto de acción
export const setUser = userData => ({
  type: SET_USER,
  payload: userData,
})

// Cambiar la función para retornar un objeto de acción
export const clearUser = () => ({
  type: CLEAR_USER,
  payload: null,
})
