import React, { useEffect, useState, Suspense } from 'react'
import useWindowSize from '../../utils/useWindowSize'
//import BannerDesk from './Desk/BannerDesk';
//import BannerMobile from './Mobile/BannerMobile';
import { getBanner } from '../../actions/bannerActions'
import { getUrlForProductImg } from '../../utils/ima'
import {
  getBannerDesc,
  getExist,
  BannerSuscribe,
} from '../../actions/resultActions'
import { Link } from 'react-router-dom'
import { motion, useAnimation } from 'framer-motion'
import {
  FormControl,
  Select,
  InputLabel,
  InputAdornment,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  GridList,
  GridListTile,
  TextField,
  IconButton,
  Switch,
  FormControlLabel,
} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email'
import LoadingScreen from '../../components/RegistroCliente/LoadingScreen'
import Swal from 'sweetalert2'

import { useInView } from 'react-intersection-observer'
// import { COLORS } from '../../Views/Colores'
import LazyLoad from 'react-lazy-load'
import BannerDesk from '../../components/Banner/Desk/BannerDesk'
import BannerMobile from '../../components/Banner/Mobile/BannerMobile'
import Img from 'react-cool-img'
import { Carousel } from 'react-responsive-carousel'
import { Image } from 'antd'

import { useHistory, useLocation } from 'react-router'

import { LazyLoadImage } from 'react-lazy-load-image-component'
//const Banners = React.lazy(() => import("./Desk/BannerDesk"));
//const BannersMobile = React.lazy(() => import("./Mobile/BannerMobile"));
function BannerResponsive({ tipo, elementos, cl }) {
  const { responsiveSize } = useWindowSize()

  const [isLoading, setIsLoading] = useState(false)

  const save = async () => {
    setIsLoading(true)
    suscribe.correo = window.localStorage.getItem('correos')

    if (suscribe.correo !== '') {
      const informacion = await BannerSuscribe(suscribe)
      console.log(informacion)
      setIsLoading(false)

      if (informacion.status === 200) {
        setIsLoading(false)
        window.localStorage.setItem('correos', '')

        Swal.fire(
          'El registro se ha realizado con éxito, usted esta suscrito ahora',
          '',
          'success',
        )
      } else {
        setIsLoading(false)
        window.localStorage.setItem('correos', '')
        // handleClose();
        Swal.fire('Algo salio mal, intentalo de nuevo.', '', 'error')
      }
    } else {
      setIsLoading(false)
      window.localStorage.setItem('correos', '')
      // handleClose();
      Swal.fire('Tienes que llenar el campo de correo.', '', 'error')
    }
  }
  const [items, setItems] = useState([])
  const [responses, setResponse] = useState([])

  const [classe, setClase] = useState('')
  const [itemsMob, setItemsMob] = useState([])
  const history = useHistory()
  const [suscribe, setSsuscribe] = React.useState({
    id: '00000000-0000-0000-0000-000000000000',
    banner: tipo,
    correo: '',
  })
  // document.documentElement.style.setProperty('--color-principal', COLORS.black)
  useEffect(() => {
    //  getPromos();
    BannersGet()
  }, [])
  const sliderConfig = {
    autoPlay: true,
    infiniteLoop: true,
    animation: 'slide',
    indicators: true,
    timeout: 300,
    navButtonsAlwaysVisible: true,
    showStatus: false,
    showThumbs: false,
    showIndicators: true,
    interval: 8000,
  }
  const sliderConfigMob = {
    autoPlay: true,
    animation: 'slide',
    indicators: true,
    timeout: 300,
    navButtonsAlwaysVisible: true,
    navButtonsAlwaysInvisible: false,
    cycleNavigation: true,
    interval: 8000,
  }

  const handleChange = event => {
    setSsuscribe({ ...suscribe, [event.target.name]: event.target.value })
    setClase(event.target.value)
    //  var palabra =window.localStorage.getItem("correos")+event.target.value

    window.localStorage.setItem('correos', event.target.value)
    // almacen.tipo=event.target.value;
  }
  const BannersGet = async () => {
    const response = await getBanner(tipo)
    setResponse(response.data)
    var banner = response.data
    let items3 = []
    let items2 = []
    let ima = getUrlForProductImg(banner.imagenHorizontal)
    let ima2 = getUrlForProductImg(banner.imagenVertical)
    items3.push(
      <div className='contenedorInf'>
        <div key={banner.id}>
          <img src={ima} />
        </div>
        <div className='centradoInf'>
          <TextField
            margin='dense'
            id={'correo'}
            name={'correo'}
            style={{ backgroundColor: '#fff' }}
            placeholder={
              'Envíanos tu correo para regístrate al boletín informativo'
            }
            type='email'
            fullWidth
            required
            onChange={handleChange}
            variant='outlined'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            type='submit'
            variant='contained'
            value='Registrar'
            color='primary'
            style={{ marginLeft: '1%' }}
            onClick={save}
          >
            Enviar
          </Button>
        </div>
      </div>,
    )
    setItems(items3)
    items2.push(
      <div className='contenedorInf'>
        <div key={banner.id}>
          <img src={ima2} />
        </div>
        <div className='centradoInf'>
          <TextField
            margin='dense'
            id={'correo'}
            name={'correo'}
            placeholder={
              'Envíanos tu correo para regístrate al boletín informativo'
            }
            type='email'
            style={{ backgroundColor: '#fff' }}
            fullWidth
            required
            onChange={handleChange}
            variant='outlined'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            type='submit'
            variant='contained'
            value='Registrar'
            color='primary'
            style={{ marginLeft: '1%' }}
            onClick={save}
          >
            Enviar
          </Button>
        </div>
      </div>,
    )
    setItemsMob(items2)
  }

  // setItems(bannerItems);

  return (
    <div>
      <LoadingScreen open={isLoading} />
      {items.length > 0 && responsiveSize === 'sm' ? (
        <Carousel
          className='mobile-carousel'
          autoPlay={sliderConfigMob.autoPlay}
          animation={sliderConfigMob.animation}
          indicators={sliderConfigMob.indicators}
          timeout={sliderConfigMob.timeout}
          cycleNavigation={sliderConfigMob.cycleNavigation}
          navButtonsAlwaysVisible={sliderConfigMob.navButtonsAlwaysVisible}
          interval={sliderConfigMob.interval}
          navButtonsAlwaysInvisible={sliderConfigMob.navButtonsAlwaysInvisible}
        >
          {itemsMob}
        </Carousel>
      ) : (
        <Carousel
          className={cl}
          autoPlay={sliderConfig.autoPlay}
          infiniteLoop={sliderConfig.infiniteLoop}
          showThumbs={sliderConfig.showThumbs}
          showStatus={sliderConfig.showStatus}
          showIndicators={sliderConfig.showIndicators}
          interval={sliderConfig.interval}
        >
          {items}
        </Carousel>
      )}
      <br></br> <br></br>
    </div>
  )
}

export default BannerResponsive
