import React, { useEffect, useState, useRef, useContext } from 'react'
import { useParams } from 'react-router-dom'

// import { COLORS } from '../../Views/Colores'
import StylesContext from '../../contexts/styles/stylesContext'

import {
  // getProductDetail,
  // getProductDetailName,
  // postComentario,
  // getComentarios,
  // getBlogs,
  // getProductPrice,
  // getRespuesta,
  // getPreguntas,
  // getPromo,
  // getProductVariant,
  // postPregunta,
  // getVariant,
  // getWishList,
  // searchgetWishList,
  // getWishExist,
  // editWishList,
  // getElement,
  // getLogo,
  getBlogId,
} from '../../actions/resultActions'
import { Image } from 'antd'
import { Link, NavLink } from 'react-router-dom'

import { Input } from 'antd'

import { makeStyles } from '@material-ui/core/styles'

// import Header from '../Header/HeaderResponsive'
import { useHistory, useLocation } from 'react-router'
import Blogs from './Blogs'
// const Footer = React.lazy(() => import('../../Views/Footer/FooterMenu'))

const useStyles2 = makeStyles(Theme => ({
  botom: {
    padding: 5,
    fontSize: '15px',
    borderColor: '#191919',
    backgroundColor: 'white',
  },
  icon: {
    backgroundColor: 'white',
    borderColor: 'white',
    marginLeft: '-20px',
    marginTop: '5px',
  },
  filas: {
    display: 'flex',
  },
  image: {
    borderRadius: 150 / 2,
    overflow: 'hidden',
    borderWidth: 3,
    borderColor: 'red',
  },
  preguntas: {
    width: '63%',
    marginLeft: '26%',
  },
  botonp: {
    marginLeft: '91%',
    marginTop: '-37px',
  },
  botonp2: {
    marginLeft: '845px',
    marginTop: '-37px',
  },
  all: {
    marginLeft: '50px',
  },
  comentarios: {
    width: '63%',
    marginLeft: '25%',
  },
  allcom: {
    marginLeft: '-50px',
  },
  preguntaList: {
    marginLeft: '0%',
    margin: '20px',
  },
  comentList: {
    marginLeft: '0%',
  },
  precios: {
    marginTop: '-27px',
    marginLeft: '59px',
  },
}))

const { TextArea } = Input

const Details = props => {
  const stylesContext = useContext(StylesContext)
  const { idm } = useParams()
  //const direct = Replaces(resultResponse.nombre);

  const [logop, setLogop] = useState(() => stylesContext.logo)
  const [response, setResponse] = useState(null)
  const [all, setAll] = useState([])

  const getL = async () => {
    // const response2 = await getLogo()
    // setLogop(response2.data.url)
    const responses = await getBlogId(idm)
    setResponse(responses.data)
  }
  useEffect(() => {
    //  getPromos();
    getL()
  }, [idm])
  const formatDate = d => {
    const f = new Date(d)

    return f.getDate() + '/' + (f.getMonth() + 1) + '/' + f.getFullYear()
  }
  const history = useHistory()

  function getRandomInt(max) {
    return Math.floor(Math.random() * max)
  }
  return (
    <div>
      {response !== null && (
        <div>
          {/* <Header
            logop={logop}
            colors={stylesContext.black}
            nav={true}
          ></Header> */}
          <br></br>

          <div className='parrafosFecha'>{formatDate(response.fecha)}</div>
          <br></br>
          <div className='parrafosTitle'>{response.titulo}</div>
          <div className='parrafoImagen'>
            <Image
              preview={false}
              src={response.imagen}
              fallback={'/img/IMAGEN_NO_DISPONIBLE.png'}
            />
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: response.parrafo }}
            className='parrafos'
          />
          <div className='back'>
            <Link to={`/`}>Volver al inicio</Link>
          </div>
          <Blogs tittle={'Sigue explorando blogs'} />
          <br></br>
          {/* <Footer AligmentText='left' /> */}
        </div>
      )}
    </div>
  )
}

export default Details
