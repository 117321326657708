import React, { useEffect, useState } from 'react'

import { getByTable } from '../../actions/catalogoActions'

import './WhatsAppButton.css'
import iconWhatsApp from '../../static/whatsapp.png'

const WhatsAppButton = () => {
  const [dataWhatsApp, setDataWhatsApp] = useState({
    whatsapp: '',
    call_to_action: '', //"Envíanos un mensaje",
  })

  const handleClick = () => {
    window.open(`https://wa.me/${dataWhatsApp.number}`, '_blank')
  }

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const signal = controller.signal

    getByTable(signal, 15)
      .then(data => {
        if (isMounted) {
          if (data[0].activo) {
            console.log('getByTable', data)
            setDataWhatsApp({
              number: data[0].descripcionLarga.replace(/[+()\s]/g, ''),
              call_to_action: '',
            })
          }
        }
      })
      .catch(error => {
        if (isMounted) {
          console.error('Error fetching catGeneral', error)
        }
      })

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])

  return (
    <div className='whatsapp-button' onClick={handleClick}>
      <img src={iconWhatsApp} alt='Icon WhatsApp' />
    </div>
  )
}

export default WhatsAppButton
