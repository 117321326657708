import React, { useContext, useState, useEffect } from 'react'
import { Button } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import ApiGeneral from '../../services/ApiGeneral'
import UserContext from '../../contexts/user/UserContext'
import { getDirecciones } from '../../actions/catalogoActions'

import DireccionCard from './DireccionCard'
import DireccionFormulario from './DireccionFormulario'
import MySnackBar from './../MySnackBar/SnackBar'
import PixelR from '../GoogleAnalitycs/PixelR'

const Direccion = ({
  setCurrentDireccion,
  currentDireccion,
  showTitle = true,
  fromOrden = false,
  handleSelectDireccion,
}) => {
  const { UserState } = useContext(UserContext)

  const [abrir, setOpen] = useState(false)
  const [direcciones, setDirecciones] = useState([])
  const [message, setMessage] = useState('')
  const [type, setType] = useState('')
  const [SBopen, setSBOpen] = useState(false)

  const getdirecciones = () => {
    // const usuario = user.userData
    ApiGeneral.getDirecciones(UserState.idCliente)
      .then(response => {
        setDirecciones(response.data)
        console.log(response)
      })
      .catch(e => {
        console.error('error data:' + e)
      })
  }

  const handleCloseDirecciones = () => {
    setOpen(false)
  }

  const handleCloseSB = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSBOpen(false)
  }

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const signal = controller.signal
    // const usuario = user.userData

    getDirecciones(signal, UserState.idCliente)
      .then(data => {
        if (isMounted) {
          setDirecciones(data)
        }
      })
      .catch(error => {
        if (isMounted) {
          console.warn('Error fecth direcciones', error)
        }
      })

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [UserState.authenticated])

  return (
    <div style={{ width: '100%' }}>
      <PixelR nombres={'Mis direcciones'} />

      <MySnackBar
        Open={SBopen}
        Type={type}
        Message={message}
        HandleClose={handleCloseSB}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 5,
        }}
      >
        {showTitle ? <label>Mis direcciones de envío</label> : <label></label>}

        <>
          <div>
            <Button
              onClick={() => {
                setOpen(true)
              }}
              variant='outlined'
              color='primary'
            >
              Agregar dirección
            </Button>
            <Dialog
              fullWidth
              open={abrir}
              onClose={handleCloseDirecciones}
              aria-labelledby='simple-dialog-title'
            >
              <DialogTitle id='draggable-dialog-title'>
                <div
                  style={{
                    marginTop: '1rem',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <span>Agregar dirección</span>
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={handleCloseDirecciones}
                  >
                    ✕
                  </span>
                </div>
              </DialogTitle>

              <DialogContent dividers>
                <DireccionFormulario
                  getDirecciones={getdirecciones}
                  handleCloseDirecciones={handleCloseDirecciones}
                  setSBOpen={setSBOpen}
                  setMessage={setMessage}
                  setType={setType}
                  haveDirecctions={direcciones.helperData?.length > 0}
                />
              </DialogContent>
            </Dialog>
          </div>
        </>
      </div>

      {!direcciones.helperData && <div>Cargando...</div>}

      {direcciones.helperData &&
        direcciones.helperData.map(x => {
          if (x.activo) {
            return (
              <DireccionCard
                key={x.idClienteDireccion}
                direccion={x}
                fromOrden={fromOrden}
                setCurrentDireccion={setCurrentDireccion}
                currentDireccion={currentDireccion}
                getDirecciones={getdirecciones}
                setSBOpen={setSBOpen}
                setMessage={setMessage}
                setType={setType}
                handleSelectDireccion={handleSelectDireccion}
              />
            )
          }
          return null
        })}
    </div>
  )
}

export default Direccion
