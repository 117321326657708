import axios from 'axios'
import Swal from 'sweetalert2'

import Base from '../services/Base'
import { nombre } from '../services/Url'

const axiosInstance = axios.create({
  baseURL: Base.baseUrls.identity,
  headers: {
    'Content-Type': 'application/json',
    username: 'cliente',
    nombres: nombre.tienda,
  },
  //timeout: 1000,
})

// Interceptor para agregar el token a la cabecera de autorización
// axiosInstance.interceptors.request.use(
//   async config => {
//     if (token) {
//       config.headers = {
//         ...config.headers,
//         Authorization: `Bearer ${token}`,
//       }
//     }

//     return config
//   },
//   error => {
//     // Manejar errores antes de enviar la solicitud
//     return Promise.reject(error)
//   }
// )

// Función para manejar peticiones con cancelación y manejo centralizado de errores
const fetchWithCancel = async ({
  url,
  method = 'GET',
  data = null,
  params = null,
  signal,
}) => {
  try {
    const config = {
      method,
      url,
      data,
      params,
      signal,
    }

    const response = await axiosInstance(config)
    return response.data
  } catch (e) {
    if (axios.isCancel(e)) {
      console.warn('Request canceled', e.message)
    } else {
      const errorMessage =
        e.response?.data?.message || 'Ha ocurrido un error desconocido'
      // Swal.fire('Error en la solicitud', errorMessage, 'error')
      throw e
    }
  }
}

export const login = async (signal, loginRequest) => {
  return fetchWithCancel({
    url: 'Auth/do-login',
    method: 'POST',
    data: loginRequest,
    signal,
  })
}
