import Base from '../services/Base'
import { getProductDetail } from '../../src/actions/resultActions'

export const getFicha = ficha => {
  if (ficha === undefined) {
    return '<p/>'
  } else if (ficha === null) {
    return '<p/>'
  }
  return ficha
}

export const getFilesDownload = files => {
  if (files === undefined) {
    return '<p/>'
  }

  const hfile = files.reduce(
    (h, file) =>
      h +
      "<ul><a  class='linkfile'  target='_blank' href=" +
      (Base.baseFiles.imgProduct +
        '/' +
        file.rutaUbicacion +
        '/' +
        file.id +
        file.extension) +
      '>' +
      file.nombre +
      '</a></ul>',
    '',
  )

  return "<li class='filecontainerlink'>" + hfile + '</li>'
}

export const getUrlForProductImg = imgPerView => {
  const url =
    Base.baseFiles.imgProduct +
    '/' +
    imgPerView?.rutaUbicacion +
    '/' +
    imgPerView?.id +
    imgPerView?.extension

  return url
}
export const getImgVar = async imgPerView => {
  const response = await getProductDetail(imgPerView)

  var url = ' '
  response.data.productoImagen.map(i => {
    url =
      Base.baseFiles.imgProduct +
      '/' +
      i.rutaUbicacion +
      '/' +
      i.id +
      i.extension
  })
  console.log(url.toString())
  return url.toString()
}
export const getImg = async imgPerView => {
  const response = await getProductDetail(imgPerView)

  var url = ' '
  response.data.productoImagen.map(i => {
    url =
      Base.baseFiles.imgProduct +
      '/' +
      i.rutaUbicacion +
      '/' +
      i.id +
      i.extension
  })

  return url
}
export const getImgVarS = imgsPerView => {
  if (imgsPerView.resultResponseImg === '') {
    return ['']
  }

  const urlImg = Base.baseFiles.imgProduct
  console.log(imgsPerView.resultResponseImg)
  const urls = []
  imgsPerView.resultResponseImg.productoImagens.map(i => {
    const val = urlImg + '/' + i.rutaUbicacion + '/' + i.id + i.extension

    urls.push(val)
  })

  return urls
}

export const getUrlsForProductImg = imgsPerView => {
  if (imgsPerView.resultResponse === '') {
    return ['']
  }

  const urlImg = Base.baseFiles.imgProduct

  const urls = []
  imgsPerView.resultResponse.productoImagen.map(i => {
    const val = urlImg + '/' + i.rutaUbicacion + '/' + i.id + i.extension

    urls.push(val)
  })

  return urls
}
export const getUrlsForProductImgWish = (imgsPerView, index) => {
  if (imgsPerView.resultResponse === '') {
    return ['']
  }

  const urlImg = Base.baseFiles.imgProduct

  const urls = []
  imgsPerView.map((i, ind) => {
    if (index === ind) {
      const val = urlImg + '/' + i.rutaUbicacion + '/' + i.id + i.extension
      urls.push(val)
    }
  })

  return urls
}
//obtiene imagen de contactos
export const getUrlForContacto = path => {
  const url = Base.baseFiles.imgProduct + '/' + path

  return url
}
//obtiene url pdf y xml de pedidos
export const getUrlForPDF = path => {
  const url = Base.baseFiles.imgProduct + '/' + path
  return url
}
export const getFilesXML = files => {
  if (files === undefined) {
    return '<p/>'
  }

  const hfile =
    "<ul><a  class='linkfile'  target='_blank' href=" +
    (Base.baseFiles.imgProduct + '/' + files) +
    '</a></ul>'

  return "<li class='filecontainerlink'>" + hfile + '</li>'
}
