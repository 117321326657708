import {
  SET_DETALLE_STATE,
  SET_SECTIONS_STATE,
  SET_GLOBAL_STATE,
  SET_NOTIFICACIONES_STATE,
  SET_MONEDA_STATE,
  SET_PAYMENT_METHODS_STATE,
  SET_ALERT,
  HIDE_ALERT,
} from './GlobalTypes'

const globalReducer = (state, action) => {
  switch (action.type) {
    case SET_DETALLE_STATE:
      return {
        ...state,
        detalleState: action.payload,
      }
    case SET_SECTIONS_STATE:
      return {
        ...state,
        sectionsState: action.payload,
      }
    case SET_GLOBAL_STATE:
      return {
        ...state,
        globalState: {
          ...state.globalState,
          ...action.payload,
        },
      }
    case SET_NOTIFICACIONES_STATE:
      return {
        ...state,
        notificacionesState: action.payload,
      }
    case SET_MONEDA_STATE:
      return {
        ...state,
        monedaState: action.payload,
      }
    case SET_PAYMENT_METHODS_STATE:
      return {
        ...state,
        paymentMethods: action.payload,
      }
    case SET_ALERT:
      return {
        ...state,
        messageAlert: {
          message: action.payload.message,
          type: action.payload.type,
          active: true, //action.payload.active
        },
      }
    case HIDE_ALERT:
      return {
        ...state,
        messageAlert: {
          ...state.messageAlert,
          active: false,
        },
      }
    default:
      return state
  }
}

export default globalReducer
