import axios from 'axios'
import Swal from 'sweetalert2'

import Base from '../services/Base'
import { nombre } from '../services/Url'

const axiosInstance = axios.create({
  baseURL: Base.baseUrls.catalogo,
  headers: {
    'Content-Type': 'application/json',
    username: 'cliente',
    nombres: nombre.tienda,
  },
  //timeout: 1000,
})

// Función para manejar peticiones con cancelación y manejo centralizado de errores
const fetchWithCancel = async ({
  url,
  method = 'GET',
  data = null,
  params = null,
  signal,
}) => {
  try {
    const config = {
      method,
      url,
      data,
      params,
      signal,
    }

    const response = await axiosInstance(config)
    return response.data
  } catch (e) {
    if (axios.isCancel(e)) {
      console.warn('Request canceled', e.message)
    } else {
      Swal.fire(
        e.response?.data || 'Catalogo service',
        'Ha ocurrido un error desconocido',
        'error'
      )
      throw e
    }
  }
}

export const getItemCatGeneralById = async (signal, id) => {
  return fetchWithCancel({
    url: 'CatGeneral/getById/' + id,
    method: 'GET',
    params: null,
    signal,
  })
}

// Obtiene el menú habilitado por ID
export const getItemConfiguracionById = async (signal, id) => {
  return fetchWithCancel({
    url: 'Configuracion/GetById/' + id,
    method: 'GET',
    signal,
  })
}

export const getPaymentMethodKeysById = async (signal, id) => {
  return fetchWithCancel({
    url: 'TipoPagoConfig/GetByIdTipo/' + id,
    method: 'GET',
    signal,
  })
}

// ACCOUNT
export const registerUser = async userData => {
  try {
    const response = await axios.post(
      Base.baseUrls.catalogo + 'account/register-customer',
      userData,
      {
        headers: {
          'Content-Type': 'application/json',
          username: 'cliente',
          nombres: nombre.tienda,
        },
      }
    )
    return response.data
  } catch (error) {
    if (error.response) {
      // Si la respuesta está disponible, manejar el error basado en error.response.data
      throw error.response.data
    } else if (error.data) {
      // Si solo error.data está disponible (caso raro), manejarlo directamente
      throw error
    } else {
      // Lanza otros tipos de errores
      throw error.message || 'Error al registrar.'
    }
  }
}

export const verifyCode = async ({ email, codigoVerificacion }) => {
  try {
    const response = await axios.post(
      Base.baseUrls.catalogo + 'account/verify-code',
      { email, codigoVerificacion },
      {
        headers: {
          'Content-Type': 'application/json',
          username: 'cliente',
          nombres: nombre.tienda,
        },
      }
    )
    return response.data
  } catch (error) {
    // console.log('Error completo verifyCode:', error)
    if (error.data) {
      throw error
    } else {
      throw error.message || 'Error al verificar el código.'
    }
  }
}

export const resendVerificationCode = async email => {
  try {
    const response = await axios.post(
      Base.baseUrls.catalogo + 'account/resend-verification-code',
      { email },
      {
        headers: {
          'Content-Type': 'application/json',
          username: 'cliente',
          nombres: nombre.tienda,
        },
      }
    )
    return response.data
  } catch (error) {
    // console.log('Error completo verifyCode:', error)
    if (error.data) {
      throw error
    } else {
      throw error.message || 'Error al reenviar el código.'
    }
  }
}

export const RecoveryPassword = async (signal, recoveryPassword) => {
  return fetchWithCancel({
    url: 'account/recovery-password',
    method: 'POST',
    data: recoveryPassword,
    signal,
  })
}

export const RequestResetPassword = async (signal, resetPassword) => {
  return fetchWithCancel({
    url: 'account/reset-password',
    method: 'POST',
    data: resetPassword,
    signal,
  })
}

export const getLocationByCP = async (signal, cp) => {
  return fetchWithCancel({
    url: 'Location/getByCP/' + cp,
    method: 'GET',
    signal,
  })
}
