import React, { Component } from 'react'
import Mask from '../Result/productstyle.css'
import './index.css'
// import { COLORS } from '../../Views/Colores'

export default function CartCounter({
  quantity,
  id,
  decrementCounter,
  incrementCounter,
  maxQuantity,
}) {
  const state = {
    counter: 0,
  }
  // document.documentElement.style.setProperty('--color-principal', COLORS.black);
  return (
    <>
      <div>
        <button onClick={() => decrementCounter(id)} className='button-counter'>
          -
        </button>
        <input type='text' className='number' value={quantity}></input>
        <button
          disabled={maxQuantity <= quantity}
          onClick={() => incrementCounter(id)}
          className='button-counter'
        >
          +
        </button>
      </div>
    </>
  )
}
