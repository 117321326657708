import React, { useEffect, useState, Suspense } from 'react'
import useWindowSize from '../../utils/useWindowSize'
//import BannerDesk from './Desk/BannerDesk';
//import BannerMobile from './Mobile/BannerMobile';
import { getBanner } from '../../actions/bannerActions'
import { getUrlForProductImg } from '../../utils/ima'
import { getBannerDesc, getExist } from '../../actions/resultActions'
import { Link } from 'react-router-dom'
import { motion, useAnimation } from 'framer-motion'

import { useInView } from 'react-intersection-observer'
// import { COLORS } from '../../Views/Colores'
import LazyLoad from 'react-lazy-load'
import BannerDesk from '../../components/Banner/Desk/BannerDesk'
import BannerMobile from '../../components/Banner/Mobile/BannerMobile'
import Img from 'react-cool-img'
import { Carousel } from 'react-responsive-carousel'
import { Image } from 'antd'

import { useHistory, useLocation } from 'react-router'
import './index.css'
import { LazyLoadImage } from 'react-lazy-load-image-component'
//const Banners = React.lazy(() => import("./Desk/BannerDesk"));
//const BannersMobile = React.lazy(() => import("./Mobile/BannerMobile"));
function BannerResponsive({ tipo, elementos, cl }) {
  const control = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      control.start('visible')
    } else {
      control.start('hidden')
    }
  }, [control, inView])
  const { responsiveSize } = useWindowSize()
  const bannerItems = []
  const boxVariant = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.1 } },
    hidden: { opacity: 0.8, scale: 0.8 },
  }

  const [items, setItems] = useState([])
  const [classe, setClase] = useState('hidden')
  const [itemsMob, setItemsMob] = useState([])
  const history = useHistory()

  useEffect(() => {
    //  getPromos();
    BannersGet()
  }, [])
  const sliderConfig = {
    autoPlay: true,
    infiniteLoop: true,
    animation: 'slide',
    indicators: true,
    timeout: 300,
    navButtonsAlwaysVisible: true,
    showStatus: false,
    showThumbs: false,
    showIndicators: true,
    interval: 8000,
  }
  const sliderConfigMob = {
    autoPlay: true,
    animation: 'slide',
    indicators: true,
    timeout: 300,
    navButtonsAlwaysVisible: true,
    navButtonsAlwaysInvisible: false,
    cycleNavigation: true,
    interval: 8000,
  }

  const BannersGet = async () => {
    // const responses = await getColor()

    // document.documentElement.style.setProperty(
    //   '--color-principal',
    //   responses.data.nombre,
    // )
    const response = await getBanner(tipo)
    var banner = response.data
    const valide = await getExist(banner.idBanner, elementos)
    let items3 = []
    let items2 = []
    var descripcion
    let ima = getUrlForProductImg(banner.imagenHorizontal)
    let ima2 = getUrlForProductImg(banner.imagenVertical)
    if (valide.data === true) {
      descripcion = await getBannerDesc(banner.idBanner, elementos)
    }

    // setItems(bannerItems);

    // console.log(descripcion)
    if (valide.data === true) {
      if (descripcion.data.activoH !== true) {
        items3.push(
          <div key={banner.id}>
            <Link to={descripcion.data.link}>
              <motion.div
                ref={ref}
                variants={boxVariant}
                initial='hidden'
                animate={control}
              >
                <div className='contenedor'>
                  <img src={ima} />
                  {descripcion.data.activo === true && (
                    <div className='centrado'>
                      <b>
                        {' '}
                        <h1 style={{ color: '#fff' }}>
                          {descripcion.data.text}
                        </h1>
                      </b>
                    </div>
                  )}
                </div>
              </motion.div>
            </Link>
          </div>
        )
      } else {
        const descripcion2 = await getBanner(descripcion.data.iBannerH)
        var banner2 = descripcion2.data
        let imav = getUrlForProductImg(banner2.imagenVertical)
        items3.push(
          <div key={banner.id} style={{ display: 'flex', width: '100%' }}>
            <Link to={descripcion.data.link}>
              <motion.div
                ref={ref}
                variants={boxVariant}
                initial='hidden'
                animate={control}
              >
                <div className='contenedor3'>
                  <img src={ima2} />
                  {descripcion.data.activo === true && (
                    <div className='centrado2'>
                      <b>
                        {' '}
                        <h1 style={{ color: '#fff' }}>
                          {descripcion.data.text}
                        </h1>
                      </b>
                    </div>
                  )}
                </div>
              </motion.div>
            </Link>

            <Link to={descripcion.data.link2}>
              <motion.div
                ref={ref}
                variants={boxVariant}
                initial='hidden'
                animate={control}
              >
                <div className='contenedor2'>
                  <img src={imav} />
                  {descripcion.data.activo === true && (
                    <div className='centrado3'>
                      <b>
                        {' '}
                        <h1 style={{ color: '#fff' }}>
                          {descripcion.data.text2}
                        </h1>
                      </b>
                    </div>
                  )}
                </div>
              </motion.div>
            </Link>
          </div>
        )
      }

      setItems(items3)
    } else {
      items3.push(
        <div key={banner.id}>
          <motion.div
            ref={ref}
            variants={boxVariant}
            initial='hidden'
            animate={control}
          >
            <img src={ima} />
          </motion.div>
        </div>
      )
      setItems(items3)
    }
    const mob = (descripcion = await getBannerDesc(banner.idBanner, elementos))
    items2.push(
      <div style={{ marginTop: '80px' }} key={banner.id}>
        <Link to={mob.data.link}>
          <div className='contenedor'>
            <img src={ima2} />
            {mob.data.activo === true && (
              <div className='centrado'>
                <b>
                  {' '}
                  <h1 style={{ color: '#fff' }}>{mob.data.text}</h1>
                </b>
              </div>
            )}
          </div>
        </Link>
      </div>
    )
    setItemsMob(items2)
  }

  return (
    <div>
      {items.length > 0 && responsiveSize === 'sm' ? (
        <Carousel
          className='mobile-carousel'
          autoPlay={sliderConfigMob.autoPlay}
          animation={sliderConfigMob.animation}
          indicators={sliderConfigMob.indicators}
          timeout={sliderConfigMob.timeout}
          cycleNavigation={sliderConfigMob.cycleNavigation}
          navButtonsAlwaysVisible={sliderConfigMob.navButtonsAlwaysVisible}
          interval={sliderConfigMob.interval}
          navButtonsAlwaysInvisible={sliderConfigMob.navButtonsAlwaysInvisible}
        >
          {itemsMob}
        </Carousel>
      ) : (
        <Carousel
          className={cl}
          autoPlay={sliderConfig.autoPlay}
          infiniteLoop={sliderConfig.infiniteLoop}
          showThumbs={sliderConfig.showThumbs}
          showStatus={sliderConfig.showStatus}
          showIndicators={sliderConfig.showIndicators}
          interval={sliderConfig.interval}
        >
          {items}
        </Carousel>
      )}
    </div>
  )
}

export default BannerResponsive
