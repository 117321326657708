import React, { useContext } from 'react'

import StylesContext from '../../contexts/styles/stylesContext'
import GlobalContext from '../../contexts/GlobalContext'

import Ver from './Ver'
//import Ofertas from "../../components/Ofertas/Ofertas";
import Cvendido from '../../Views/Vendido/Vvendido'
//import General from '../../Views/General/General';
import BannerExample from '../Banner/BannerResponsive'
// import { COLORS } from '../../Views/Colores'
// import NotificacionBar from '../NotificacionBar/NotificacionBar'
import Blogs from './Blogs'
// import {
// getKeyPay,
// getEle,
// getMercadoPK,
// getLogo,
// getColor,
// getDetalle,
// getBanerT,
// getNotB,
// getBannerDesc,
// } from '../../actions/resultActions'
import BannerSuscribe from './BannerSuscribe'
// import Favicon from 'react-favicon'

import BannerIn from './Banner'
// import KeycloakService from '../../components/UserService'
// import { useHistory } from 'react-router-dom'
import BannerCarrusel from '../Banner/BannerCarrusel'
import Ofertas from './Ofertas'
import Rank from './Rank'
import PixelR from '../GoogleAnalitycs/PixelR'
import BannerCompra from './BannerCompra'
import Categorias from './Categorias'
import CarruselProd from './CarruselProducto'
import BannerColeccion from './BannerColeccion'

// const Header = React.lazy(() =>
//   import('../../components/Header/HeaderResponsive')
// )
// const Footer = React.lazy(() => import('../../Views/Footer/FooterMenu'))

const HomeE = () => {
  // const history = useHistory()

  const { sectionsState } = useContext(GlobalContext)
  const stylesContext = useContext(StylesContext)

  // const [response, setResponse] = useState([])

  function Componente(nombre, id, index, elemento) {
    switch (nombre) {
      case 'Banner':
        return (
          <div>
            <br></br>

            <BannerExample
              cl={'desk-banner'}
              tipo={id}
              elementos={elemento}
              style={{ paddingTop: '23%' }}
            />
          </div>
        )
      case 'BannerSuscripcion':
        return (
          <div>
            <br></br>

            <BannerSuscribe
              cl={'desk-banner'}
              tipo={id}
              style={{ paddingTop: '23%' }}
            />
          </div>
        )
      case 'VerMás':
        return (
          <div>
            <br></br>

            <Ver colors={stylesContext.black} id={id} ind={index} />
            <br></br>
          </div>
        )
      case 'Coleccion':
        return <Cvendido id={id} />
      // case 'Header':
      //   return (
      //     <Header
      //       logop={stylesContext.logo}
      //       colors={stylesContext.black}
      //       nav={false}
      //     ></Header>
      //   )
      case 'Rank':
        return <Rank num={2} style={{ marginTop: '-50%' }} />
      case 'BannerInformativo':
        return (
          <BannerIn colors={stylesContext.black} id={id} ind={index}></BannerIn>
        )

      // case 'NotificacionBar':
      //   return (
      //     <div
      //       style={{
      //         backgroundColor: stylesContext.black,
      //         fontWeight: 'bold',
      //         textAlign: 'center',
      //         color: '#fff',
      //       }}
      //     >
      //       <NotificacionBar tipo={id} style={{ paddingTop: '23%' }} />
      //     </div>
      //   )
      case 'Blog':
        return <Blogs tittle={'Blogs'} explore={false} />
      case 'BannerCarrusel':
        return (
          <BannerCarrusel
            cl={'desk-banner'}
            tipo={id}
            idElementos={elemento}
            style={{ paddingTop: '23%' }}
          />
        )
      case 'Oferta':
        return <Ofertas id={id} ind={index} />
      case 'Banner Producto':
        return <BannerCompra colors={stylesContext.black} id={id} ind={index} />
      case 'Categorias':
        return (
          <Categorias colors={stylesContext.black} id={elemento} ind={index} />
        )
      case 'Carrusel Productos':
        return (
          <CarruselProd
            colors={stylesContext.black}
            id={elemento}
            ind={index}
          />
        )
      case 'Banner Colecciones':
        return (
          <BannerColeccion colors={stylesContext.black} id={id} ind={index} />
        )
      default:
        return null
    }
  }

  // const getMonedas = async () => {
  //var id_client = await getKeyPay()

  //   window.localStorage.setItem('IdClien', id_client.data.clave)
  // }
  // const [logop, setLogop] = useState('')

  // const getPlantilla = async () => {
  // const responses = await getColor()
  // document.documentElement.style.setProperty(
  // 	'--color-principal',
  // 	responses.data.nombre
  // )
  // stylesContext.black = responses.data.nombre

  // const detalle = await getDetalle()
  // console.log('plantilla detalle ', detalle.data)

  // var response = await getEle(global?.idPlantilla)

  // setResponse(response.data)
  // console.log('plantilla response ', response.data)

  // const logo = await getLogo()
  // setLogop(logo.data.url)
  // window.localStorage.setItem('logo', logo.data.url)
  // }

  // useEffect(() => {
  //  getPromos();
  // getPlantilla()
  // getMonedas()
  // }, [])

  return (
    <>
      <PixelR nombres={'Home'} />

      {/* <div> */}
      {StylesContext &&
        sectionsState.map((object, index) => (
          <div
            key={object.idElementos}
            className={`orden-${object.orden}-${object.nombreElemento}`}
          >
            {Componente(
              object.nombreElemento,
              object.identificador,
              index,
              object.idElementos
            )}
          </div>
        ))}
      {/* <Footer AligmentText='left' /> */}
      {/* </div> */}
    </>
  )
}

export default HomeE
