import Base from '../services/Base'
import axios from 'axios'
import React, { useEffect, useState, useRef, useContext } from 'react'
import Context from '../store/Context'
import { nombre } from '../services/Url'

// export const getTipoCambio = () => {
//   return new Promise((resolve, reject) => {
//     const instance = axios.create({
//       baseURL: Base.baseUrls.almacen,
//       headers: {
//         'Content-Type': 'application/json',
//         nombres: nombre.tienda,
//       },
//     })
//     instance
//       .get('Producto/GetTipoCambio')
//       .then(response => {
//         resolve(response)
//       })
//       .catch(e => {
//         reject(e)
//       })
//   })
// }

export const getTipoCambio = async () => {
  try {
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',
        nombres: nombre.tienda,
      },
    });

    const response = await instance.get('Producto/GetTipoCambio');
    return response; // Puedes devolver solo response.data si no necesitas la respuesta completa
  } catch (error) {
    throw error; // Maneja el error o lo propaga al llamador
  }
};

export const deleteCarrito = id => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.carrito,
      headers: {
        'Content-Type': 'application/json',
        nombres: nombre.tienda,
      },
    })
    instance
      .get('Carrito/deleteCarrito/' + id)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        reject(e)
      })
  })
}

export const getCarrito = id => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.carrito,
      headers: {
        'Content-Type': 'application/json',
        nombres: nombre.tienda,
      },
    })
    instance
      .get('Carrito/getCarrito/' + id)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        reject(e)
      })
  })
}
export const overrideCarrito = id => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.carrito,
      headers: {
        'Content-Type': 'application/json',
        nombres: nombre.tienda,
      },
    })
    instance
      .get('Carrito/OverrideCarrito/' + id)
      .then(response => {
        resolve(response)
        console.log(id)
      })
      .catch(e => {
        reject(e)
      })
  })
}

const setCarrito = cart => {
  if (cart.length == 0) {
    deleteCarrito(window.localStorage.getItem('carritoID'))
  } else {
    return new Promise((resolve, reject) => {
      const instance = axios.create({
        baseURL: Base.baseUrls.carrito,
        headers: {
          'Content-Type': 'application/json',
          nombres: nombre.tienda,
        },
      })
      instance
        .post('Carrito/SetCarrito', getRedisFormat(cart))
        .then(response => {
          resolve(response)
        })
        .catch(e => {
          reject(e)
        })
    })
  }
}

const getRedisFormat = cart => {
  const payload = cart.reduce(
    (data, i) =>
      data +
      `{ "Id":"${i.product._id}",
      "Precio":${i.product.price},
      "Cantidad":${i.quantity},
      "Imagen":"${i.product.images}",
      "Peso":"${i.product.peso * i.quantity}",
      "FamiliaId":"${i.product.familiaId}",
      "InfoAdicional":"${i.product.infoAdicional}",
      "Producto":"${encodeURIComponent(i.product.title)}"},`,
    '',
  )
  return `{"Id":"${window.localStorage.getItem(
    'carritoID',
  )}","items":[${payload.slice(0, -1)}]}`
}

const carritoActions = {
  setCarrito,
}

export default carritoActions
