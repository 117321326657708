import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import {COLORS} from "../../Views/Colores";
//export const cols = COLORS.black;
export const Wrapper = styled.div`
  display: inline;
  margin: 0 5px;
  cursor: pointer;
  position: relative;
`

export const Icon = styled(FontAwesomeIcon)`
  padding: 5px;
  font-size: 24px;
  color: #ffffff;
`

export const CartCount = styled.span`
  position: absolute;
  bottom: -5px;
  right: -5px;
  padding: 3px 7px;
  border-radius: 50px;
  background: var(--color-principal);
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
`

export const CartSideBar = styled.div`
  z-index: 1000;
  width: 40%;
  background: #ffffff;
  top: 0;
  right: 0;
  padding: 25px;
  position: fixed;
  overflow: auto;
  height: 100%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  transition: all ease-in-out 0.3s;

  &.expand {
    transition: all ease-in-out 0.3s;
    right: 0;
  }

  &.shrink {
    transition: all ease-in-out 0.3s;
    right: -40%;
  }
  @media (max-width: 900px) {
    width: 100%;
    &.shrink {
      right: -100%;
    }
  }
`

export const EmptyCart = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: black;
`

export const SideBarHeader = styled.div`
  font-weight: 300;
  font-size: 24px;
  text-transform: none;
  position: relative;
  padding: 15px 0;
  color: black;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background: #f2f2f2;
  }
`

export const Card = styled.div`
  display: flex;
  justifyContent: center,
  position: relative;
  margin: 15px 0;
  border: 2px #f2f2f2 solid;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;

  &:hover {
    background-color: #e2e2e2;
    border-color: #cccccc;
  }
`

export const Card2 = styled.div`
  display: block;
  position: relative;
  margin: 15px 0 15px 0;
  border: 2px #f2f2f2 solid;
  padding: 10px;
  border-radius: 5px;
`

export const CardImage = styled.img`
  flex: 1;
  height: auto;
  width: auto;
  max-width: 100px;
  max-height: 100px;
`

export const CardBody = styled.div`
  flex: 2;
  margin-left: 25px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

export const CardTitle = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: var(--color-principal);
  font-weight: bold;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
`

export const CardSubtitle = styled.div`
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: black;
  font-weight: bold;
  margin-top: 5px;
  width: 50%;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
`

export const CardSubtitle2 = styled.span`
  text-align: center;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: black;
  font-weight: bold;
  margin-top: 5px;
  width: 50%;
`

export const CardRemove = styled(FontAwesomeIcon)`
    font-size: 16px:
    color: var(--color-principal);
    cursor: pointer;
     
`

export const CardRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px !important;
`

export const ClearButton = styled.button`
  width: 100%;
  padding: 18px 24px;
  background:var(--color-principal);
  color: #ffffff !important;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  font-weight:bold;,
  text-transform:none;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
 
`
