import React, { useContext, useEffect, useState } from 'react'
import Carousel from 'react-multi-carousel'
import { Link } from 'react-router-dom'

import './card.css'
import 'react-multi-carousel/lib/styles.css'

import Card from '../../components/Result/Mask/CardOriginal'

import { getUrlForProductImg } from '../../utils/ima'
import Context from '../../store/Context'
import StylesContext from '../../contexts/styles/stylesContext'
import GlobalContext from '../../contexts/GlobalContext'

import { getProductosById } from '../../actions/almacenActions'

const Vvendido = props => {
  const { id } = props

  const { monedaState } = useContext(GlobalContext)
  const context = useContext(Context)
  const stylesContext = useContext(StylesContext)

  const [productos, setProductos] = useState([])
  const [nombreColeccion, setNombreColeccion] = useState('')

  const moneda = monedaState
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1336 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  }

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const signal = controller.signal

    getProductosById(signal, id)
      .then(data => {
        if (isMounted) {
          setNombreColeccion(data.nombreColecion)
          setProductos(data.productos)
        }
      })
      .catch(e => {
        if (isMounted) {
          console.error('Error fetching banners ', e)
        }
      })

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])

  return (
    <div style={{ marginTop: '20px' }}>
      <div className='titleColeccion1'>
        <span>{nombreColeccion}</span>
      </div>
      <div className='titleColeccion2'>
        <Link to={'/products/ran/' + id + '/ls'}>
          <span style={{ color: stylesContext.primaryColor }}>Ver Todo</span>
        </Link>
      </div>

      <Carousel responsive={responsive}>
        {productos.map(item => (
          <div
            key={item.idProducto}
            style={{ margin: '0.2rem 0.5rem', height: '100%' }}
          >
            {moneda.abreviatura === 'USD' && (
              <Card
                key={item.idProducto}
                idProducto={item.idProducto}
                price={item.precio}
                imagenPereview={
                  item.imagenPereview === null
                    ? '/img/IMAGEN_NO_DISPONIBLE.png'
                    : getUrlForProductImg(item.imagenPereview)
                }
                sku={item.sku}
                nombre={item.nombre}
                sprice={item.precio}
                abrevia={moneda.abreviatura}
                calificacion={item.calificacion}
                activoDescuento={item.activoDescuento}
                descuentoPrecio={item.descuentoPrecio}
                rebaja={item.rebaja}
                descuento={item.descuento}
                icon={item.wish}
                peso={item.peso}
                inventario={item.inventario}
                categoriaId={item.categoriaId}
                lists={item.lists}
                addProductToCart={context.addProductToCart}
              />
            )}
            {moneda.abreviatura === 'MXN' && (
              <Card
                key={item.idProducto}
                idProducto={item.idProducto}
                price={item.precio * context.getTipoCambio}
                imagenPereview={
                  item.imagenPereview === null
                    ? '/img/IMAGEN_NO_DISPONIBLE.png'
                    : getUrlForProductImg(item.imagenPereview)
                }
                sku={item.sku}
                nombre={item.nombre}
                sprice={item.precio * context.getTipoCambio}
                abrevia={moneda.abreviatura}
                calificacion={item.calificacion}
                activoDescuento={item.activoDescuento}
                descuentoPrecio={item.descuentoPrecio * context.getTipoCambio}
                rebaja={item.rebaja}
                descuento={item.descuento}
                icon={item.wish}
                peso={item.peso}
                inventario={item.inventario}
                categoriaId={item.categoriaId}
                lists={item.lists}
                addProductToCart={context.addProductToCart}
              />
            )}
          </div>
        ))}
      </Carousel>
    </div>
  )
}

export default Vvendido
