import { createContext } from 'react'

export default createContext({
  carts: [],
  filters: [],
  subtotal: [],
  iva: [],
  total: [],
  loadingInitial: true,
  addProductToCart: product => {},
  removeProductFromCart: productID => {},
  removeAllProductsFromCart: () => {},
  clearCart: () => {},
  decrementCounter: () => {},
  incrementCounter: () => {},
  addCartFromRedis: product => {},
  addPrice: product => {},
  addProductFilter: filter => {},
  addTipoCambio: tipoCambio => {},
  getTipoCambio: 0,
  clearProductFilter: () => {},
  filterv2: {
    categoriaId: '1',
    precio: [0, 22000],
    pathCategory: ['18', '19'],
    espesorId: [],
    medidaId: [],
    tipoAceroId: [],
  },
  // setFilterv2: (filter) => { }
})
