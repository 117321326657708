import React from 'react'
import { useState, useEffect } from 'react'
import './ima.css'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'

export default function Countdown(props) {
  const [hrs, setHrs] = useState(props.hrs)
  const [dias, setDias] = useState(props.dias)

  const [mins, setMinutes] = useState(props.mins)
  const [secs, setSeconds] = useState(props.secs)
  useEffect(() => {
    let sampleInterval = setInterval(() => {
      if (secs > 0) {
        setSeconds(secs - 1)
      }
      if (secs === 0) {
        if (mins === 0) {
          if (hrs > 0) {
            setHrs(hrs - 1)
          }
          setMinutes(59)
          setSeconds(59)
          if (hrs === 0) {
            if (dias === 0) {
              setHrs(0)
              setMinutes(0)
              setSeconds(0)
              setDias(0)
            } else {
              setDias(dias - 1)
              setMinutes(59)
              setSeconds(59)
            }
          } else {
            setHrs(0)
            setMinutes(59)
            setSeconds(59)
          }
        } else {
          setMinutes(mins - 1)
          setSeconds(59)
        }
      }
    }, 1000)
    return () => {
      clearInterval(sampleInterval)
    }
  })

  return (
    <div>
      <span>
        {' '}
        <LocalOfferIcon /> Dias: {dias} Horas: {hrs} Minutos: {mins} Segundos:{' '}
        {secs}{' '}
      </span>
    </div>
  )
}
