import React from 'react'

export default function DetailsThumb({ images, setIndex }) {
  return (
    <div className='thumb'>
      {images.map((img, index) => (
        <img
          onError={e => {
            e.target.onerror = null
            e.target.src = '/img/IMAGEN_NO_DISPONIBLE.png'
          }}
          src={img}
          alt=''
          key={index}
          onClick={() => setIndex(index)}
        />
      ))}
    </div>
  )
}
