import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Swal from 'sweetalert2'
import Reaptcha from 'reaptcha'

import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import LoadingScreen from '../../components/RegistroCliente/LoadingScreen'

import UserContext from '../../contexts/user/UserContext'
import StylesContext from '../../contexts/styles/stylesContext'

import ApiGeneral from '../../services/ApiGeneral'
import { registerUser } from '../../services/catalogoService'

const Container = styled.div`
  width: 100%;
  margin: 0 auto;

  /* Pantallas grandes (Desktop) */
  @media (min-width: 1024px) {
    max-width: 480px;
  }

  /* Tablets */
  @media (min-width: 768px) and (max-width: 1023px) {
    max-width: 50vw;
  }

  /* Tamaño intermedio entre móvil y tablet */
  @media (min-width: 600px) and (max-width: 767px) {
    max-width: 70vw;
  }

  /* Móviles */
  @media (max-width: 599px) {
    max-width: 100%;
  }
`

const Form = styled.form`
  width: 100%;
  padding: 20px;
  border-radius: 0.6rem;
  background-color: #f5f5f5;

  /* Pantallas grandes (Desktop) */
  @media (min-width: 1024px) {
    border: 2px solid #f2f2f2;
  }

  /* Tablets */
  @media (min-width: 768px) and (max-width: 1023px) {
    border: 2px solid #f2f2f2;
  }

  /* Tamaño intermedio entre móvil y tablet */
  @media (min-width: 600px) and (max-width: 767px) {
    border: 1px solid #e0e0e0;
  }

  /* Móviles */
  @media (max-width: 599px) {
    border: none;
    background-color: #ffffff;
  }
`

const Title = styled.div`
  text-align: center;
  font-size: 32px;
  font-weight: 600;
`

const SubTitle = styled.div`
  text-align: center;
  font-size: 16px;
  font-weight: 400;
`

const TextLink = styled.div`
  display: inline-block;
  font-weight: 600;
  cursor: pointer;
`

const StyledDialogActions = styled(DialogActions)`
  padding-right: 2rem;
`

const Signup = () => {
  const history = useHistory()
  const referer = history.location.state?.from

  const { UserState } = useContext(UserContext)
  const stylesContext = useContext(StylesContext)

  const [isVerifiedCaptcha, setIsVerifiedCaptcha] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [idTipoCliente, setIdTipoCliente] = useState(null)
  const [isAcceptTerms, setIsAcceptTerms] = useState(() => false)
  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const onVerify = () => {
    setIsVerifiedCaptcha(true)
  }

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Introduzca un correo electrónico válido')
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        'Introduzca un correo electrónico válido'
      )
      .required('El campo correo electrónico es requerido'),
    nombre: Yup.string()
      .max(150, 'El nombre debe tener un máximo de 150 caracteres.')
      .required('El campo nombre es requerido'),
    telefono: Yup.string()
      .min(10, 'El teléfono debe tener un mínimo de 10 caracteres.')
      // .max(10, 'El teléfono debe tener un máximo de 10 caracteres.')
      .matches(
        /^(\+?\d{1,3})?\d{10}$/,
        'El teléfono solo debe contener números'
      )
      .required('El campo teléfono es requerido.'),
    password: Yup.string()
      .min(8, 'La contraseña debe tener al menos 8 caracteres.')
      .matches(
        /[A-Z]/,
        'La contraseña debe tener al menos una letra mayúscula.'
      )
      .matches(
        /[a-z]/,
        'La contraseña debe tener al menos una letra minúscula.'
      )
      .matches(/\d/, 'La contraseña debe tener al menos un número.')
      .matches(
        /[\W_]/,
        'La contraseña debe tener al menos un carácter especial.'
      )
      .required('El campo contraseña es requerido'),
    // aceptoTerminos: Yup.boolean().oneOf(
    //   [false],
    //   'Debes aceptar los términos y condiciones'
    // ),
  })

  const formik = useFormik({
    initialValues: {
      idTipoCliente: null,
      nombre: '',
      email: '',
      telefono: '',
      password: '',
      aceptoTerminos: true,
    },
    validationSchema,
    onSubmit: async values => {
      setIsLoading(true)

      if (!isAcceptTerms) {
        Swal.fire({
          title: 'Acción requerida',
          text: 'Debes aceptar los términos y condiciones para proceder con la solicitud.',
          icon: 'warning',
          showConfirmButton: true,
          confirmButtonText: 'Aceptar',
          timer: 5000,
          timerProgressBar: true,
        })
        setIsLoading(false)
        return
      }

      if (!isVerifiedCaptcha) {
        Swal.fire({
          title: 'Verificación fallida',
          text: 'Por favor, asegúrate de haber completado la verificación del captcha correctamente.',
          icon: 'error',
          showConfirmButton: true,
          confirmButtonText: 'Verificar de nuevo',
          timer: 5000,
          timerProgressBar: true,
        })
        setIsLoading(false)
        return
      }

      try {
        const response = await registerUser(values)

        if (response.success) {
          Swal.fire({
            icon: 'success',
            title: '¡Registro Exitoso! 🎉',
            text: '¡Tu cuenta ha sido creada! Revisa tu correo electrónico para encontrar el código de verificación.',
            confirmButtonText: 'Ir a Verificar',
            allowOutsideClick: false, // Evita que cierren el modal haciendo clic fuera
          }).then(() => {
            history.push(`/verify-code?email=${values.email}`)
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: '¡Oh no! Algo salió mal',
            text: response.message,
            confirmButtonText: 'Aceptar',
            allowOutsideClick: false,
          })
        }
      } catch (error) {
        // console.log('error', error)
        if (error.data) {
          const { success, message, statusCode, errorCode, additionalInfo } =
            error?.data

          console.error('Error en el registro:', message)
          if (statusCode === 'ClienteExistente') {
            Swal.fire({
              icon: 'warning',
              title: 'Correo electrónico ya registrado',
              text: 'El correo electrónico ya está registrado. Por favor, inicie sesión o recupere su contraseña.',
              confirmButtonText: 'Aceptar',
            })
          } else if (statusCode === 'ClientePendiente') {
            Swal.fire({
              icon: 'info',
              title: 'Verificación pendiente',
              text: 'Su cuenta está pendiente de verificación. Revise su correo electrónico para completar el proceso.',
              confirmButtonText: 'Aceptar',
            })
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error en el registro',
              text: 'Ocurrió un error durante el registro. Inténtelo nuevamente.',
              confirmButtonText: 'Aceptar',
            })
          }
        } else if (error.request) {
          // La solicitud fue hecha, pero no hubo respuesta del servidor
          console.error('No se recibió respuesta del servidor:', error.request)
          Swal.fire({
            icon: 'error',
            title: 'Error de conexión',
            text: 'No se recibió respuesta del servidor. Por favor, inténtelo de nuevo más tarde.',
            confirmButtonText: 'Aceptar',
          })
        } else {
          // Algo más ocurrió al configurar la solicitud que provocó un error
          console.error('Error al configurar la solicitud:', error.message)
          Swal.fire({
            icon: 'error',
            title: 'Error inesperado',
            text: 'Ocurrió un error inesperado. Intente nuevamente más tarde.',
            confirmButtonText: 'Aceptar',
          })
        }
      } finally {
        setIsLoading(false)
      }
    },
  })

  useEffect(() => {
    let isMounted = true

    const getTipoUsuario = async () => {
      try {
        const response = await ApiGeneral.getCFDI(4)
        if (isMounted) {
          console.log('getCFDI', response)
          const tipoClienteId = response.find(obj =>
            obj.descripcionCorta.includes('física')
          ).idGeneral
          setIdTipoCliente(tipoClienteId)
        }
      } catch (error) {
        console.error('Error obteniendo tipo de usuario:', error)
      }
    }

    getTipoUsuario()
    formik.handleReset()

    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    if (idTipoCliente) {
      formik.setFieldValue('idTipoCliente', idTipoCliente)
    }
  }, [idTipoCliente])

  useEffect(() => {
    if (UserState.authenticated) {
      const isCheckoutProcess = JSON.parse(
        localStorage.getItem('isCheckoutProcess')
      )
      const redirectTo = isCheckoutProcess
        ? '/orden/tienda/pedido/compra/pago'
        : referer || '/'
      localStorage.setItem('isCheckoutProcess', JSON.stringify(false))
      history.push(redirectTo)
    }
  }, [UserState.authenticated])

  return (
    <Container>
      <LoadingScreen open={isLoading} />
      <Form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Title>Registra tu cuenta</Title>
          <SubTitle>
            <span>¿Ya tienes una cuenta?&nbsp;</span>
          </SubTitle>
          <SubTitle>
            <TextLink
              style={{ color: stylesContext.primaryColor }}
              onClick={() => history.push('/signin')}
            >
              Inicia sesión
            </TextLink>
            <span>&nbsp;ó&nbsp;</span>
            <TextLink
              style={{ color: stylesContext.primaryColor }}
              onClick={() =>
                Swal.fire({
                  title: 'Recuperación de contraseña',
                  text: 'Se enviará un enlace de recuperación de contraseña al correo electrónico con el que intentas iniciar sesión. Este correo electrónico contiene un enlace con el que podrás restablecer tu contraseña.',
                  icon: 'info',
                  showCancelButton: true,
                  confirmButtonText: 'Aceptar',
                  cancelButtonText: 'Cancelar',
                }).then(result => {
                  if (result.isConfirmed) {
                    history.push(
                      `/request-recovery-password?email=${formik.values.email}`
                    )
                  }
                })
              }
            >
              recuperar contraseña
            </TextLink>
          </SubTitle>
        </DialogContent>

        <DialogContent>
          <DialogContentText>
            <TextField
              fullWidth
              margin='dense'
              type='email'
              id='email'
              name='email'
              label='Correo electrónico*'
              placeholder='Correo electrónico'
              onChange={formik.handleChange}
              value={formik.values.email}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />

            <TextField
              fullWidth
              margin='dense'
              type={showPassword ? 'text' : 'password'}
              id='password'
              name='password'
              label='Contraseña*'
              placeholder='Contraseña'
              onChange={formik.handleChange}
              value={formik.values.password}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={togglePasswordVisibility} edge='end'>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              fullWidth
              margin='dense'
              type='text'
              id='nombre'
              name='nombre'
              label='Nombre completo*'
              placeholder='Nombre completo'
              onChange={formik.handleChange}
              value={formik.values.nombre}
              onBlur={formik.handleBlur}
              error={formik.touched.nombre && Boolean(formik.errors.nombre)}
              helperText={formik.touched.nombre && formik.errors.nombre}
            />

            <TextField
              margin='dense'
              id='telefono'
              name='telefono'
              label='Teléfono*'
              placeholder='Teléfono'
              type='tel'
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.telefono}
              onBlur={formik.handleBlur}
              error={formik.touched.telefono && Boolean(formik.errors.telefono)}
              helperText={formik.touched.telefono && formik.errors.telefono}
            />

            <label>
              <input
                type='checkbox'
                id='aceptoTerminos'
                name='aceptoTerminos'
                checked={isAcceptTerms}
                onChange={() => setIsAcceptTerms(!isAcceptTerms)}
              />
              &nbsp;Aceptar términos y condiciones
            </label>
          </DialogContentText>
          <Reaptcha
            sitekey={
              process.env.NODE_ENV === 'production'
                ? '6LfXVq8cAAAAANirUaFiAIfwU43isKIro4cTEZH8'
                : process.env.REACT_APP_BASE_URL === 'prd'
                ? '6LfXVq8cAAAAANirUaFiAIfwU43isKIro4cTEZH8'
                : '6LcPtoMaAAAAAGsptRHqVLqSbVFucHWBBd9oOMeE'
            }
            onVerify={onVerify}
          />
        </DialogContent>

        <StyledDialogActions>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            disabled={!isVerifiedCaptcha}
          >
            Registrar cuenta
          </Button>
        </StyledDialogActions>
      </Form>
    </Container>
  )
}
export default Signup

// try {
//   const response = await registerUser(values)

//   if (response.success) {
//     // setAlert(response.success, 'success')

//     setTimeout(() => {
//       history.push(`/verify-code?email=${values.email}`)
//     }, 2000)
//   } else {
//     // setAlert(response.message, 'error')
//     // Swal(response)
//     // console.warn('el se de fetch response')
//     alert(response.message) // Puedes mostrar mensajes personalizados
//   }
// } catch (error) {

//   // // setAlert('Error al verificar el código.', 'error')
//   // console.error('registerUser(values)', error?.response?.data?.message)
//   // // Swal.fire('error catch', error?.response?.data?.message, 'error')
//   // if (error.response && error.response.status === 400) {
//   //   console.log('catch error if', error.response)
//   // }

//   // v2
//   // if (error.response && error.response.status === 400) {
//   //   // Acceder a los datos de la respuesta cuando hay un error 400
//   //   const { success, message, statusCode, errorCode, additionalInfo } =
//   //     error.response.data

//   //   console.error('Error en el registro:', message)
//   //   // Usar los datos para mostrar un mensaje personalizado en el frontend
//   //   if (statusCode === 'ClienteExistente') {
//   //     alert(
//   //       'El correo ya está registrado. Por favor, inicie sesión o recupere su contraseña.'
//   //     )
//   //   } else if (statusCode === 'ClientePendiente') {
//   //     alert(
//   //       'Su cuenta está pendiente de verificación. Revise su correo para completar el proceso.'
//   //     )
//   //   } else {
//   //     alert('Ocurrió un error durante el registro. Inténtelo nuevamente.')
//   //   }
//   // } else {
//   //   // Manejar otros errores que no sean 400
//   //   console.error('Error inesperado:', error)
//   //   alert('Ocurrió un error inesperado. Intente nuevamente más tarde.')
//   // }

//   // // v3
//   // if (error.response) {
//   //   // Confirmar que el error tiene una respuesta del servidor
//   //   const { success, message, statusCode, errorCode, additionalInfo } =
//   //     error.response.data

//   //   console.error('Error en el registro:', message)
//   //   if (statusCode === 'ClienteExistente') {
//   //     alert(
//   //       'El correo ya está registrado. Por favor, inicie sesión o recupere su contraseña.'
//   //     )
//   //   } else if (statusCode === 'ClientePendiente') {
//   //     alert(
//   //       'Su cuenta está pendiente de verificación. Revise su correo para completar el proceso.'
//   //     )
//   //   } else {
//   //     alert('Ocurrió un error durante el registro. Inténtelo nuevamente.')
//   //   }
//   // } else if (error.request) {
//   //   // La solicitud fue hecha, pero no hubo respuesta del servidor
//   //   console.error('No se recibió respuesta del servidor:', error.request)
//   //   alert(
//   //     'No se recibió respuesta del servidor. Por favor, inténtelo de nuevo más tarde.'
//   //   )
//   // } else {
//   //   // Algo más ocurrió al configurar la solicitud que provocó un error
//   //   console.error('Error al configurar la solicitud:', error.message)
//   //   alert('Ocurrió un error inesperado. Intente nuevamente más tarde.')
//   // }

//   // v4
//   // Manejo de errores HTTP
//   if (error.response) {
//     // Confirmar que el error tiene una respuesta del servidor
//     const { success, message, statusCode, errorCode, additionalInfo } =
//       error.response.data

//     console.error('Error en el registro:', message)
//     if (statusCode === 'ClienteExistente') {
//       alert(
//         'El correo ya está registrado. Por favor, inicie sesión o recupere su contraseña.'
//       )
//     } else if (statusCode === 'ClientePendiente') {
//       alert(
//         'Su cuenta está pendiente de verificación. Revise su correo para completar el proceso.'
//       )
//     } else {
//       alert('Ocurrió un error durante el registro. Inténtelo nuevamente.')
//     }
//   } else if (error.request) {
//     // La solicitud fue hecha, pero no hubo respuesta del servidor
//     console.error('No se recibió respuesta del servidor:', error.request)
//     alert(
//       'No se recibió respuesta del servidor. Por favor, inténtelo de nuevo más tarde.'
//     )
//   } else {
//     // Algo más ocurrió al configurar la solicitud que provocó un error
//     console.error('Error al configurar la solicitud:', error.message)
//     alert('Ocurrió un error inesperado. Intente nuevamente más tarde.')
//   }
// } finally {
//   setIsLoading(false)
// }
// }
