import React, { useEffect, useState, useContext } from 'react'

import { Box, Grid, Button, Typography, MenuItem } from '@material-ui/core'
import { Card } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { COLORS } from '../../Views/Colores'
import StylesContext from '../../contexts/styles/stylesContext'
import './ima.css'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { getBanerT, getVer } from '../../actions/resultActions'
import Container from '@material-ui/core/Container'
import { Image } from 'antd'
import Elementos from './Elemento'
import { height } from '@material-ui/system'
import './ima.css'
const useStyles2 = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '3%',
  },
  gridConten: {
    width: '100%',
  },
  titulo: {
    paddingRight: '-100%',
    width: '100%',
    color: '#ffff',
    fontWeight: 'bolder',
  },
  btnsubnmit: {
    background: COLORS.black,
    color: '#ffff!important',
    backgroundColor: COLORS.black,
    marginTop: '5%',
    marginLeft: '10%',
  },
  subtitulo: {
    width: '100%',
    color: '#ffff',
  },
  icon: {
    margin: theme.spacing(1),
    borderRadius: '5em',
  },
}))

function Elemento(props) {
  const stylesContext = useContext(StylesContext)
  const classes = useStyles2()

  useEffect(() => {
    //  getPromos();
    BannersGet()
  }, [])
  const [response, setResponse] = useState(null)
  const [tamano, settamano] = useState('')
  const BannersGet = async () => {
    const response = await getVer(props.id)
    setResponse(response.data)
  }
  const Cards = ({ img, link, titulo, subtitulo }) => {
    return (
      <Card className='tam'>
        <div style={{ display: 'flex' }}>
          <div style={{ display: 'block' }}>
            <span className='subt2' style={{ letterSpacing: '4px' }}>
              {titulo}
            </span>
            <br></br>
            <span className='subt'>{subtitulo}</span>
          </div>
          <LazyLoadImage
            preview={false}
            src={img}
            className='imagenes'
          ></LazyLoadImage>
        </div>
        <div className='alt'>
          <Button
            color={stylesContext.black}
            type='submit'
            variant='outlined'
            className={classes.btnsubnmit}
            onClick={() => (window.location.href = link)}
          >
            Ver Más
          </Button>
        </div>
        <br></br>
      </Card>
    )
  }
  return (
    <div>
      {response !== null && (
        <div>
          <div className='titleColeccion1'>
            <span>{response.titulo}</span>
          </div>
          <div className='ver'>
            <br></br>
            <Cards
              img={response.imagen1}
              link={response.link1}
              titulo={response.tituloImagen1}
              subtitulo={response.subtituloImagen1}
            />
            <Cards
              img={response.imagen2}
              link={response.link2}
              titulo={response.tituloImagen2}
              subtitulo={response.subtituloImagen2}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default Elemento
