import React, { useState, useContext, useEffect } from 'react'
// import Mask from '../Mask/integrinoxoriginal.css'
import { Link, NavLink } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import { Image } from 'antd'
import { Typography, Tooltip, Zoom } from '@material-ui/core'
// import { Link as link } from '@material-ui/core/Link'
import { withStyles } from '@material-ui/core/styles'
// import InfoIcon from '@material-ui/icons/Info'
// import { useHistory } from 'react-router'
// import { makeStyles } from '@material-ui/core/styles'
import { COLORS } from '../../../Views/Colores'
import { useAnalyticsEventTracker } from '../../GoogleAnalitycs/useAnalyticsEventTracker'
// import { Route, Switch, BrowserRouter } from 'react-router-dom'
// import { evento } from '../../FacebookPixel/Pixel'
import PagarTarjeta from '../../Tarjeta/PagarTarjeta'
// import { getKeyPay, getMercadoPK } from '../../../actions/resultActions'
import GlobalContext from '../../../contexts/GlobalContext'
const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: COLORS.black,
    color: COLORS.black,
    maxWidth: 100,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip)

export default function CardOriginal({ addProductToCart, ...props }) {
  const { globalState } = useContext(GlobalContext)
  // document.documentElement.style.setProperty('--color-principal', COLORS.black)
  const gaEventTracker = useAnalyticsEventTracker('Ver articulo')
  // const [idPay, setIdPay] = useState([])
  // const getKey = async () => {
  //   var id_client = await getKeyPay()

  //   setIdPay(id_client.data)
  // }
  // useEffect(() => {
  //  getPromos();

  //   getKey()
  // }, [])
  const go = () => {
    return <PagarTarjeta id={globalState.IdClien}></PagarTarjeta>
  }
  return (
    <div className='productargeta'>
      <div className='product-content'>
        <div className='product-img'>
          <br></br>
          <Link to={`/tarjetas/${props.id}/t`}>
            <Image
              className='product-image'
              preview={false}
              style={{ height: '100%' }}
              src={props.imagenPereview}
              fallback={'/img/IMAGEN_NO_DISPONIBLE.png'}
            />
          </Link>
        </div>
      </div>
      <div className='product-info'>
        <div className='product-info-content'>
          <div className='product-info-top'>
            <h2 className='product-sku'>{props.sku}</h2>
          </div>
          <div className='product-name'>
            <Tooltip title={props.nombre} placement='top'>
              <span>{props.nombre}</span>
            </Tooltip>
          </div>
          <p className='product-price' style={{ height: '30px' }}>
            {props.sprice > 0 ? (
              <div>
                <Tooltip
                  title={'Dolar américano. Fuente: www.banxico.org.mx/'}
                  placement='bottom'
                >
                  <Typography>
                    <NumberFormat
                      decimalSeparator={'.'}
                      value={parseFloat(props.sprice).toFixed(2)}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />{' '}
                    {props.moned}(Pieza)
                  </Typography>
                </Tooltip>
              </div>
            ) : (
              <Typography variant={'body2'}>
                <a
                  href={`https://wa.me/+528180287617?text=Hola! Quiero informes sobre el producto ${props.nombre}`}
                  target='_blank'
                >
                  Si deseas saber más de este producto, contáctanos.
                </a>
              </Typography>
            )}
          </p>

          <div>
            <Link className='product-btns' to={`/tarjetas/${props.id}/t`}>
              <button
                type='button'
                className='btn-cart'
                onClick={() => gaEventTracker(props.nombre)}
              >
                {' '}
                Comprar
                <span>
                  <i className='fas fa-plus'></i>
                </span>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
