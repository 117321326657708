import Base from '../services/Base'
import axios from 'axios'
import { nombre } from '../services/Url'
export const getBanner = id => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        nombres: nombre.tienda,
        username: 'a',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('Banner/getById/' + id)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        reject(e)
      })
  })
}
