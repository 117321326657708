import React, { useState } from 'react'

const IconUmbrella = () => {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsxlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      width='16px'
      height='20px'
      viewBox='0 0 16 16'
      xmlspace='preserve'
    >
      <g fill='#8F83B8'>
        <rect x='10' y='3' class='st0' width='6' height='2' />
        <polygon class='st0' points='3,7 9,7 9,1 3,1 3,3 0,3 0,5 3,5 	' />
        <rect y='11' class='st0' width='6' height='2' />
        <polygon
          class='st0'
          points='13,9 7,9 7,15 13,15 13,13 16,13 16,11 13,11 	'
        />
      </g>
    </svg>
  )
}

export default IconUmbrella
