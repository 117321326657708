import React from 'react'

const NotFound = () => {
  return (
    <div style={{ marginTop: '200px', textAlign: 'center' }}>
      <h2 className='title-NotFound'>No existe la página que buscas</h2>
      <img
        src='/img/DEFAUL2.png'
        alt=''
        style={{ objectFit: 'scale-down', marginBottom: '10%' }}
      />
    </div>
  )
}

export default NotFound
