import React, { useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import {
  GridList,
  GridListTile,
  Select,
  InputLabel,
  FormControl,
  InputAdornment,
  Tooltip,
  IconButton,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import { Button } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Colaboradores from './Colaboradores'
import AgregarColaborador from './AgregarColaborador'
import ColaboradoresList from './ColaboradoresList'
import ApiGeneral from '../../services/ApiGeneral'

import { useFormik } from 'formik'

//MIS DATOS
const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'relative',
    marginTop: 10,
  },
  principal: {
    width: '100%',
    textAlign: 'end',
    padding: 10,
    position: 'absolute',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  star: {
    fill: 'gold !important',
  },
})

const DatosCard = ({
  misDatos,
  getMisDatos,
  setType,
  setMessage,
  setSBOpen,
}) => {
  const classes = useStyles()

  const {
    idCliente,
    tipoClienteId,
    clienteColaboradores,
    nombre,
    razonSocial,
    nombreRazonSocial,
    aPaterno,
    aMaterno,
    email,
    telefono,
    celular,
    datosFacturacion,
  } = misDatos

  const [open, setOpen] = React.useState(false)
  const [openAdd, setOpenAdd] = React.useState(false)

  const [active, setActive] = React.useState(false)

  const formik = useFormik({
    initialValues: {
      activo: true,
    },

    onSubmit: values => {
      var editDir = {
        activo: values.activo,
      }
    },
  })
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleCloseDirecciones = () => {
    setOpen(false)
  }
  const handleCloseAdd = () => {
    setOpenAdd(false)
  }
  const handleChangeActive = event => {
    formik.values.activo = event.target.checked
  }

  return (
    <Card
      className={classes.root}
      variant='outlined'
      onClick={() => {
        // if (!!setCurrentDireccion) setCurrentDireccion(direccion);
      }}
    >
      <CardContent style={{ paddingBottom: 0 }}>
        <Card
          className={classes.root}
          variant='outlined'
          onClick={() => {
            // if (!!setCurrentDireccion) setCurrentDireccion(direccion);
          }}
        >
          <CardContent style={{ padding: '10px' }}>
            <Typography variant='body' component='p'>
              {nombre} {aPaterno} {aMaterno}
            </Typography>
            <Typography variant='body' component='p'>
              {razonSocial}
            </Typography>
            <Typography variant='body' component='p'>
              {datosFacturacion == null ? '' : datosFacturacion.rfc}
            </Typography>
            <Typography variant='body' component='p'>
              {datosFacturacion &&
                `${datosFacturacion?.calle} ${datosFacturacion?.noExt ? `#${datosFacturacion?.noExt}` : ''}${datosFacturacion?.noInt ? ` Int: ${datosFacturacion.noInt}` : ''}`}
            </Typography>
            <Typography variant='body' component='p'>
              {email}
            </Typography>
            <Typography variant='body' component='p'>
              {telefono}
            </Typography>
            <Typography variant='body' component='p'>
              {celular}
            </Typography>
          </CardContent>
        </Card>
        {misDatos?.tipoCliente?.includes('moral') ? (
          <div style={{ padding: '10px', display: 'inline!important' }}>
            <label
              style={{ marginTop: '20px', marginRight: '20px', width: '10px' }}
            >
              Colaboradores
            </label>
            <Button
              onClick={() => {
                setOpenAdd(true)
              }}
              variant='outlined'
              color='primary'
            >
              Agregar colaborador
            </Button>
            <Dialog
              onClose={handleCloseAdd}
              aria-labelledby='simple-dialog-title'
              open={openAdd}
            >
              <DialogTitle
                style={{ cursor: 'move' }}
                id='draggable-dialog-title'
              >
                <li
                  className='close-icon'
                  style={{ textAlign: 'right' }}
                  onClick={handleCloseAdd}
                >
                  ✕
                </li>
                Agregar colaborador
              </DialogTitle>
              <DialogContent dividers style={{ padding: 8 }}>
                <Grid
                  container
                  direction='column'
                  justify='center'
                  alignItems='center'
                >
                  <Box display='flex' p={1}>
                    <AgregarColaborador
                      misDatos={misDatos}
                      getMisDatos={getMisDatos}
                      handleCloseDirecciones={handleCloseAdd}
                      setSBOpen={setSBOpen}
                      setMessage={setMessage}
                      setType={setType}
                    />
                  </Box>
                </Grid>
              </DialogContent>
            </Dialog>
          </div>
        ) : (
          <div />
        )}

        {misDatos?.tipoCliente?.includes('moral') &&
          clienteColaboradores &&
          clienteColaboradores.map(x => (
            <ColaboradoresList
              misDatos={misDatos}
              getMisDatos={getMisDatos}
              c={x}
              setSBOpen={setSBOpen}
              setMessage={setMessage}
              setType={setType}
            />
          ))}
      </CardContent>
      <CardActions
        style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 0 }}
      ></CardActions>
    </Card>
  )
}

export default DatosCard
