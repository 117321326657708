import React, { useEffect, useState } from 'react'

import { Box, Grid, Typography, MenuItem } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
// import { COLORS } from '../../Views/Colores'
import './ima.css'
import {
  // getKeyPay,
  // getEle,
  // getMercadoPK,
  // getLogo,
  // getDetalle,
  getBanerT,
  getBanerR,
} from '../../actions/resultActions'
import Container from '@material-ui/core/Container'
// import { Image } from 'antd'
import Elementos from './Elemento'
const Header = React.lazy(
  () => import('../../components/Header/HeaderResponsive'),
)
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '3%',
  },
  gridConten: {
    width: '100%',
  },
  titulo: {
    paddingRight: '-100%',
    width: '100%',
    color: '#ffff',
    fontWeight: 'bolder',
  },

  subtitulo: {
    width: '100%',
    color: '#ffff',
  },
  icon: {
    margin: theme.spacing(1),
    borderRadius: '5em',
  },
}))

function Elemento(props) {
  const classes = useStyles()

  useEffect(() => {
    //  getPromos();
    BannersGet()
  }, [])
  const [response, setResponse] = useState(null)
  const [response2, setResponse2] = useState(null)
  const [tamano, settamano] = useState('')
  const BannersGet = async () => {
    const response = await getBanerT(props.id)
    setResponse(response.data)
    var tam = response.data.length * 2 + '%'
    settamano(tam)

    const response2 = await getBanerR(props.id)
    setResponse2(response2.data)
  }
  return (
    <div>
      {response2 !== null && (
        <Box
          className={classes.root}
          style={{ background: response2.colorFondo, textAlign: 'center' }}
        >
          <Container>
            <Grid container spacing={2} style={{ textAlign: 'center' }}>
              {response.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} className={classes.gridConten}>
                  <Box>
                    <div style={{ marginRight: '23%' }}>
                      <div style={{ width: '100%', paddingLeft: '20%' }}>
                        <Elementos
                          colorImage={item.colorImage}
                          icono={item.icono}
                          ind={index}
                          orden={props.ind}
                        ></Elementos>
                      </div>
                      <div
                        style={{
                          textAling: 'center',
                          margin: '1%',
                          width: '100%',
                        }}
                        className='letra'
                      >
                        <b>
                          {' '}
                          <Typography
                            variant='h6'
                            style={{
                              paddingLeft: '16%',
                              fontWeight: 'bolder',
                              color: item.colorTitulo,
                            }}
                          >
                            {item.titulo}
                          </Typography>
                        </b>
                        <Typography
                          style={{
                            paddingLeft: '18%',
                            color: item.colorDescripcion,
                          }}
                        >
                          {item.descripcion}
                        </Typography>
                      </div>
                      <div></div>
                    </div>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
      )}
    </div>
  )
}

export default Elemento
