import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useHistory, useParams, useLocation } from 'react-router-dom'

import styled from 'styled-components'
import ProductoMedida from './ProductoMedida'
import Contactanos from './Contactanos'
import ApiGeneral from '../../services/ApiGeneral'
import {
  // getMenuEnabled,
  // getProductoMedida,
  // getLogo,
  // getColor,
  // getCatG,
  // getForm,
  getPlantillas,
} from '../../actions/resultActions'

const SidebarLink = styled(Link)`
  display: flex;
  color: #333;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 18px;
  &:hover {
    background: #ffffff;
    border-left: 4px solid #ffffff;
    cursor: pointer;
  }
`

const SidebarLabel = styled.span`
  margin-left: 16px;
`

const DropdownLink = styled(Link)`
  background: #ffffff;
  margin-bottom: 10px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 18px;
  &:hover {
    background: #ffffff;
    cursor: revert;
  }
`

const SubMenu = ({ item, handleDrawerClose }) => {
  const [subnav, setSubnav] = useState(false)
  const [subnav2, setSubnav2] = useState(true)
  const showSubnav = () => setSubnav(!subnav)
  const showSubnav2 = () => setSubnav2(true)
  const [isToggle, setToggle] = useState(0)

  const [url, setUrl] = useState('')

  const [Plantillas, setPlantillas] = useState([])
  const history = useHistory()

  const getPlanti = async () => {
    const plant = await getPlantillas()
    setPlantillas(plant.data)
  }
  const handleClick = async (e, name) => {
    if (parseInt(e) === -2) {
      setToggle(-2)
    }
    if (parseInt(e) === -3) {
      history.push('/tienda/tarjetas')
    }
    if (parseInt(e) === -200) {
      const plant = await getPlantillas()
      plant.data.forEach(element => {
        if (element.nombre === name) {
          history.push('/' + element.nombre)
          showSubnav()
          handleDrawerClose()
        }
      })
    }
    if (parseInt(e) === -4) {
      setToggle(-4)
    }
  }
  const getIFrame = () => {
    ApiGeneral.getLeadForm()
      .then(response => {
        const data = response.data

        setUrl(data.src)
      })
      .catch(e => {
        console.error('error data:' + e)
      })
  }
  const link = '/products/' + item.idCategoria + '/cat/ls'
  useEffect(() => {
    getIFrame()
    //  getPlanti();
  }, [])
  return (
    <>
      {item.idCategoria === -1 ? (
        <SidebarLink
          to='/products/search/all/ls'
          onClick={() => {
            showSubnav()
            handleDrawerClose()
          }}
        >
          <div>
            <SidebarLabel>{item.nombre}</SidebarLabel>
          </div>
        </SidebarLink>
      ) : (
        <div>
          {item.idCategoria !== -200 &&
          item.idCategoria !== -2 &&
          item.idCategoria !== -4 &&
          item.idCategoria !== -3 ? (
            <SidebarLink
              to={link}
              onClick={
                item.categoriasHijas.length == 0
                  ? handleDrawerClose
                  : showSubnav
              }
            >
              <div>
                <SidebarLabel key={item.nombre}>
                  {item.categoriasHijas.length == 0
                    ? item.nombre
                    : !subnav
                      ? item.nombre + ' ' + '▼'
                      : item.nombre + ' ' + '▲'}
                </SidebarLabel>
              </div>
            </SidebarLink>
          ) : (
            <div>
              <SidebarLink
                onClick={() => handleClick(item.idCategoria, item.nombre)}
              >
                <div>
                  <SidebarLabel key={item.nombre}>
                    {item.categoriasHijas.length == 0
                      ? item.nombre
                      : !subnav
                        ? item.nombre + ' ' + '▼'
                        : item.nombre + ' ' + '▲'}
                  </SidebarLabel>
                </div>
              </SidebarLink>
              {isToggle === -2 && (
                <ProductoMedida fun={showSubnav} close={handleDrawerClose} />
              )}
              {isToggle === -4 && (
                <Contactanos
                  fun={showSubnav}
                  close={handleDrawerClose}
                  urls={url}
                />
              )}
            </div>
          )}
        </div>
      )}

      {subnav &&
        item.categoriasHijas.map((x, index) => {
          if (x.activo) {
            const linkCat = '/products/' + x.idCategoria + '/cat/ls'

            return (
              <DropdownLink to={linkCat} key={index}>
                {x.categoriasHijas.length > 0 &&
                x.categoriasHijas.filter(y => y.activo).length > 0 ? (
                  <div style={{ position: 'inherit' }}>
                    <SidebarLink
                      to={link}
                      onClick={
                        x.categoriasHijas.length == 0
                          ? handleDrawerClose
                          : showSubnav2
                      }
                    >
                      <SidebarLabel key={x.nombre}>
                        {x.categoriasHijas.length == 0 ||
                        x.categoriasHijas.filter(y => y.activo).length === 0
                          ? x.nombre
                          : !subnav2
                            ? x.nombre + ' ' + '▼'
                            : x.nombre + ' ' + '▲'}
                      </SidebarLabel>
                    </SidebarLink>
                    {x.categoriasHijas.map((k, idx) => (
                      <div>
                        {k.idCategoria !== -200 &&
                        k.idCategoria !== -2 &&
                        k.idCategoria !== -4 ? (
                          <div>
                            <DropdownLink
                              to={`/products/${k.idCategoria}/cat/ls`}
                              key={idx}
                            >
                              <SidebarLabel onClick={handleDrawerClose}>
                                {k.nombre}
                              </SidebarLabel>
                            </DropdownLink>
                          </div>
                        ) : (
                          <div>
                            {isToggle === -2 && <ProductoMedida />}
                            {isToggle === -4 && <Contactanos urls={url} />}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <SidebarLabel onClick={handleDrawerClose}>
                    {x.nombre}
                  </SidebarLabel>
                )}
              </DropdownLink>
            )
          }
        })}
    </>
  )
}

export default SubMenu
