// import { COLORS } from '../../Views/Colores'
import StylesContext from '../../contexts/styles/stylesContext'
import {
  getForm,
  // getEle,
  getCampos,
  getOpciones,
  saveClientes,
  // getBanerT,
  // getNotB,
} from '../../actions/resultActions'
import React, { useContext, useEffect, useState, Suspense } from 'react'
import {
  FormControl,
  Select,
  InputLabel,
  InputAdornment,
  Button,
  // Dialog,
  // DialogActions,
  // DialogContent,
  // DialogTitle,
  // GridList,
  // GridListTile,
  TextField,
  // IconButton,
  // Switch,
  // FormControlLabel,
} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email'
import Reaptcha from 'reaptcha'
// import * as yup from 'yup'
// import AuthCliente from '../../services/AuthCliente'
import { useKeycloak } from '@react-keycloak/web'
import UserContext from '../../contexts/user/UserContext'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
// import background from './images.jpg'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import './index.css'
import { useFormik } from 'formik'
import Swal from 'sweetalert2'
import PhoneIcon from '@material-ui/icons/Phone'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import NotesIcon from '@material-ui/icons/Notes'
import LoadingScreen from '../../components/RegistroCliente/LoadingScreen'
import ApiGeneral from '../../services/ApiGeneral'

import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
// const Footer = React.lazy(() => import('../../Views/Footer/FooterMenu'))

const useStyles2 = makeStyles(theme => ({
  selectstyle: {
    transform: `translate(14px, -1px) scale(0.75)`,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '15%',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  closeButton: {
    float: 'right',
    marginTop: '-10px',
  },
  formControl: {
    marginTop: theme.spacing(1),
  },
  container: {
    boxShadow: '1px 2px 9px #000',
    margin: '4em',
    padding: '1em',
  },
  container2: {
    boxShadow: '1px 2px 9px #0d34a0',
    margin: '4em',
    padding: '1em',
  },
}))

export default function HomeE() {
  const { doLogin } = useContext(UserContext)
  const stylesContext = useContext(StylesContext)
  const history = useHistory()
  const classes = useStyles2()
  const controlStyles = {
    border: '1px solid black',
    padding: '5px',

    color: 'white',
  }
  const [response, setResponse] = useState([])
  const [mensaje, setMensaje] = useState('')
  const { keycloak, initialized } = useKeycloak()
  const [isToggle, setToggle] = useState(false)

  const click = () => {
    var estado = keycloak.authenticated

    console.log(estado)
    setToggle(estado)
    if (estado === true) {
      history.push('/')
    } else {
      history.push('/')
      doLogin()
    }
  }
  const [campos, setCampos] = useState([])
  const [opciones, setOpciones] = useState([])
  const [info, setInfo] = React.useState([])
  const [idcampos, setIdCampos] = useState('')
  const handleChange = event => {
    setInfo({ ...info, [event.target.name]: event.target.value })

    // almacen.tipo=event.target.value;
  }
  const [selecciona, setSelecciona] = useState('')
  const [email, setEmail] = useState('')

  const [value, setValue] = React.useState(true)
  const [llenar, setLlenar] = React.useState(true)
  const [verified, setVerified] = React.useState(false)
  const [data, setData] = React.useState({
    idForm: idcampos,
    idCampo: '40B82F70-321B-4CB9-B4F0-C0A8800061AF',
    info: '',
    tipoClienteId: null,
    activo: true,
    elementos: [],
  })
  const [emailVerified, setEmailVerified] = React.useState(false)

  const [datos, setDatos] = React.useState({
    idCampo: '40B82F70-321B-4CB9-B4F0-C0A8800061AF',
    info: '',
  })
  const onVerify = () => {
    setVerified(true)
  }
  function Opciones(id) {
    var arr = []
    arr.push(
      <option title={''} value={''}>
        {''}
      </option>
    )

    opciones.forEach(element => {
      if (element.idCampo === id) {
        arr.push(
          <option title={element.nombre} value={element.nombre}>
            {element.nombre}
          </option>
        )
      }
    })
    return arr
  }
  const [tipoCliente, setTipoCliente] = React.useState([])
  const getTipoUsuario = () => {
    ApiGeneral.getCFDI(4) //tipoUsuario
      .then(response => {
        setTipoCliente(response)

        console.info('éxito data tipousuario:' + response)
      })
      .catch(error => {
        console.error('error data:' + error)
      })
  }
  const [isLoading, setIsLoading] = useState(false)

  const handleChangeCombo = event => {
    setInfo({ ...info, [event.target.name]: event.target.value })
    setSelecciona(event.target.value)
    // almacen.tipo=event.target.value;
    if (event.target.value.includes('@')) {
      setEmail(event.target.value)
    }
  }
  const save = async () => {
    setIsLoading(true)
    let arregloDeClavesYValores = Object.entries(info)

    data.elementos = arregloDeClavesYValores
    data.idForm = idcampos
    data.tipoClienteId = tipoCliente.find(obj => {
      return obj.descripcionCorta.includes('física')
    }).idGeneral
    console.log(data)
    /*AuthCliente.existsEmail(email)
.then(r => {
  setEmailVerified(r.data.helperData);
});*/

    if (verified === false) {
      setMensaje('*Debe verificar que no es un robot.')
      setIsLoading(false)
    }
    if (value === false) {
      setMensaje('*Debe aceptar los terminos y condiciones.')
      setIsLoading(false)
    }

    if (arregloDeClavesYValores.length !== campos.length) {
      setMensaje('*Debes llenar todos los campos')
      setLlenar(false)
      setIsLoading(false)
    }
    if (verified === true && value === true && llenar === true) {
      setMensaje('')
      const informacion = await saveClientes(data)
      console.log(informacion)
      setIsLoading(false)

      if (informacion.status === 200) {
        Swal.fire(
          'El registro se ha realizado con éxito, verifique su correo.',
          '',
          'success'
        )
        history.push('/')
        window.setTimeout(doLogin(), 2000)
      } else {
        setIsLoading(false)

        // handleClose();
        Swal.fire('Algo salio mal, intentalo de nuevo.', '', 'error')
      }
    }
  }
  function Componente(nombre, id) {
    switch (nombre) {
      case 'Email':
        return (
          <div>
            <TextField
              margin='dense'
              id={id}
              name={id}
              label={nombre}
              placeholder={nombre}
              type='email'
              fullWidth
              required
              onChange={handleChange}
              variant='outlined'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        )
      case 'Nombre':
        return (
          <div>
            <TextField
              margin='dense'
              id={id}
              name={id}
              label={nombre}
              placeholder={nombre}
              type='text'
              fullWidth
              onChange={handleChange}
              required
              variant='outlined'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <AssignmentIndIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        )
      case 'Nombres':
        return (
          <div>
            <TextField
              margin='dense'
              id={id}
              name={id}
              label={nombre}
              placeholder={nombre}
              type='text'
              fullWidth
              onChange={handleChange}
              required
              variant='outlined'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <AssignmentIndIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        )
      case 'nombre':
        return (
          <div>
            <TextField
              margin='dense'
              id={id}
              name={id}
              label={nombre}
              placeholder={nombre}
              type='text'
              onChange={handleChange}
              value={info.infor}
              fullWidth
              required
              variant='outlined'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <AssignmentIndIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        )
      case 'apellido':
        return (
          <div>
            <TextField
              margin='dense'
              id={id}
              name={id}
              label={nombre}
              placeholder={nombre}
              onChange={handleChange}
              type='text'
              fullWidth
              required
              variant='outlined'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <NotesIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        )
      case 'Apellido':
        return (
          <div>
            <TextField
              margin='dense'
              id={id}
              name={id}
              label={nombre}
              placeholder={nombre}
              onChange={handleChange}
              type='text'
              fullWidth
              required
              variant='outlined'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <NotesIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        )
      case 'telefono':
        return (
          <div>
            <TextField
              margin='dense'
              id={id}
              name={id}
              label={nombre}
              onChange={handleChange}
              placeholder={nombre}
              type='text'
              fullWidth
              required
              variant='outlined'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <PhoneIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        )
      case 'Telefono':
        return (
          <div>
            {' '}
            <TextField
              margin='dense'
              id={id}
              name={id}
              label={nombre}
              placeholder={nombre}
              type='text'
              fullWidth
              required
              onChange={handleChange}
              variant='outlined'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <PhoneIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        )
      case 'Apellido Materno':
        return (
          <div>
            <TextField
              margin='dense'
              id={id}
              name={id}
              label={nombre}
              placeholder={nombre}
              type='text'
              fullWidth
              onChange={handleChange}
              required
              variant='outlined'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <PeopleOutlineIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        )

      case 'Apellido Paterno':
        return (
          <div>
            <TextField
              margin='dense'
              id={id}
              name={id}
              label={nombre}
              onChange={handleChange}
              placeholder={nombre}
              type='text'
              fullWidth
              required
              variant='outlined'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <PeopleOutlineIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        )

      default:
        return (
          <div>
            <div>
              <TextField
                margin='dense'
                id={id}
                name={id}
                label={nombre}
                placeholder={nombre}
                type='text'
                fullWidth
                onChange={handleChange}
                required
                variant='outlined'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SupervisorAccountIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
        )
    }
  }
  const styles2 = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? 'bold' : 'normal',
      color: 'white',
      backgroundColor: state.data.color,
      fontSize: state.selectProps.myFontSize,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.color,
      fontSize: state.selectProps.myFontSize,
    }),
  }
  const getPlantilla = async () => {
    var response = await getForm()
    setResponse(response.data)
    var campos = await getCampos(response.data.idForm)
    setIdCampos(response.data.idForm)

    setCampos(campos.data)
    var opciones = await getOpciones()
    setOpciones(opciones.data)
  }
  useEffect(() => {
    //  getPromos();
    getPlantilla()
    getTipoUsuario()
  }, [])

  return (
    <div>
      <LoadingScreen open={isLoading} />

      <div className='banner'>
        <img src={response.banner} style={{ width: '100%' }}></img>
      </div>

      <div style={{ backgroundColor: '#f9f9f9' }}>
        <div className='infor'>
          <br></br>

          <img src={response.imagenInformativa}></img>
          <br></br>
        </div>
        <div
          style={{ backgroundColor: stylesContext.black, width: '92%' }}
          className='caja'
        >
          <div className='formulario'>
            <div>
              <img
                style={{ width: '60%', marginLeft: '10%' }}
                src={window.localStorage.getItem('logo')}
              ></img>
            </div>
            <h4 style={{ marginLeft: '13%' }}>
              ¿Ya eres Usuario? <a onClick={click}> &nbsp;Inicia sesión</a>
            </h4>
            {campos.map((object, index) => (
              <div style={{ width: '70%', marginLeft: '13%' }}>
                {object.tipo === 'Abierto' ? (
                  <div>{Componente(object.nombre, object.idCampo)}</div>
                ) : (
                  <div>
                    <FormControl
                      required
                      fullWidth
                      className={classes.formControl}
                      variant='outlined'
                      tyle={{ padding: '1%' }}
                    >
                      <InputLabel style={{ padding: '0%' }}>
                        {object.nombre}{' '}
                      </InputLabel>

                      <Select
                        native
                        value={selecciona}
                        defaultValue={'tipo'}
                        onChange={handleChangeCombo}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused ? 'grey' : 'grey',
                          }),
                        }}
                        label='Selecciona '
                        inputProps={{
                          name: object.idCampo,
                          id: 'cmbtipo',
                        }}
                      >
                        {Opciones(object.idCampo)}
                      </Select>
                    </FormControl>
                  </div>
                )}
              </div>
            ))}
            <div className='captcha'>
              <br></br>
              <label>
                <input
                  type='checkbox'
                  id='tandc'
                  name='tandc'
                  checked={value}
                  value={value}
                  onClick={() => setValue(!value)}
                />{' '}
                Aceptas términos y condiciones
              </label>

              {/*qa*/
              /*6LcPtoMaAAAAAGsptRHqVLqSbVFucHWBBd9oOMeE*/
              /*pp*/
              /*6LcYOdIaAAAAAG09grfCXalmQny9-od32Bs1Opd-*/}
              <br></br>
              <Reaptcha
                sitekey={
                  process.env.NODE_ENV === 'production'
                    ? '6LfXVq8cAAAAANirUaFiAIfwU43isKIro4cTEZH8'
                    : process.env.REACT_APP_BASE_URL === 'prd'
                    ? '6LfXVq8cAAAAANirUaFiAIfwU43isKIro4cTEZH8'
                    : '6LcPtoMaAAAAAGsptRHqVLqSbVFucHWBBd9oOMeE'
                }
                onVerify={onVerify}
              />
              <br></br>
              <h4 style={{ color: 'red' }}>{mensaje}</h4>
            </div>
            <Button
              type='submit'
              variant='contained'
              value='Registrar'
              color='primary'
              style={{ marginLeft: '13%' }}
              onClick={save}
            >
              Guardar
            </Button>

            <br></br>
            <br></br>
            <br></br>
          </div>
        </div>
      </div>

      <div>{/* <Footer AligmentText='left' /> */}</div>
    </div>
  )
}
