import React, { useContext, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import AuthCliente from '../../services/AuthCliente'
import Swal from 'sweetalert2'
import LoadingScreen from '../RegistroCliente/LoadingScreen'
import errores from '../../services/errores'
import TextField from '@material-ui/core/TextField'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Reaptcha from 'reaptcha'
import ApiGeneral from '../../services/ApiGeneral'
import UserContext from '../../contexts/user/UserContext'

const useStyles = makeStyles(() => ({
  btncancelar: {
    background: '#eeeeee',
    color: '#003462',
    backgroundColor: '#eeeeee!important',
  },
  btnsubnmit: {
    background: '#003462',
    color: '#ffffff',
    backgroundColor: '#003462!important',
  },
  DiAction: {
    paddingRight: '2rem',
  },
  error: {
    color: '#ff0500',
  },
}))

const Cliente = ({ openModal, onClose }) => {
  const { UserState, doLogin, doLogout } = useContext(UserContext)
  const classes = useStyles()
  const [isLoading, setIsLoading] = React.useState(false)

  const [open, setOpen] = React.useState(() => openModal)
  const [verified, setVerified] = React.useState(false)
  const [tipoCliente, setTipoCliente] = React.useState([])
  const [usuario, setUser] = React.useState({
    tipoClienteId: null,
    nombre: '',
    aPaterno: '',
    aMaterno: '',
    email: '',
    telefono: '',
    formaPagoId: null,
    metodoPagoId: null,
  })
  const validationSchema = yup.object({
    nombre: yup
      .string()
      .max(50, 'El nombre debe tener un máximo de 50 caracteres.')
      .required('El campo nombre es requerido'),
    aPaterno: yup
      .string()
      .max(50, 'El primer apellido debe tener un máximo de 50 caracteres.')
      .required('El campo primer apellido es requerido.'),
    aMaterno: yup
      .string()
      .max(50, 'El segundo apellido debe tener un máximo de 50 caracteres.')
      .required('El campo segundo apellido es requerido.'),
    // email: yup
    // .string()
    // .email('Introduzca un correo electrónico válido')
    // .required('El campo correo electrónico es requerido'),
    telefono: yup
      .string()
      .min(10, 'El telefono debe tener un mínimo de 10 caracteres.')
      .max(10, 'El telefono debe tener un máximo de 10 caracteres.')
      .required('El campo teléfono es requerido.'),
    tandc: yup
      .boolean()
      .oneOf([true], 'You must accept the terms and conditions'),
  })
  const [existEmail, setExistEmail] = React.useState({
    emailexist: 'hola@gmail.com',
  })
  const validate = values => {
    const errors = {}
    if (!values.email) {
      errors.email = 'El campo correo electrónico es requerido'
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = 'Introduzca un correo electrónico válido'
    }
    //else if(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)){
    //   var success=true;
    //   if(existEmail.emailexist !== values.email){
    //
    //      existEmail.emailexist=values.email;

    //      AuthCliente.existsEmail(values.email)
    //      .then(response => {
    //
    //        if(response.data.success){
    //         alert(response.data.success)
    //        }
    //    }).catch(error => {
    //        console.error('error data estado:'+ error);
    //    });
    //  }

    // }
    return errors
  }
  const formik = useFormik({
    initialValues: {
      tipoClienteId: null,
      nombre: '',
      aPaterno: '',
      aMaterno: '',
      email: '',
      telefono: '',
      activo: true,
      formaPagoId: null,
      metodoPagoId: null,
      tandc: true,
    },
    validationSchema: validationSchema,
    validate,
    onSubmit: values => {
      setIsLoading(true)
      if (verified) {
        values.tipoClienteId = tipoCliente.find(obj => {
          return obj.descripcionCorta.includes('física')
        })?.idGeneral
        AuthCliente.createUser(values)
          .then(r => {
            // handleClose();
            setIsLoading(false)
            handleClose()
            Swal.fire(
              'El registro se ha realizado con éxito, verifique su correo.',
              '',
              'success'
            )
            doLogin()

            console.info('El registro se ha realizado con éxito', r)
          })
          .catch(e => {
            // handleClose();
            setIsLoading(false)

            Swal.fire(errores.handleError(e.response.data.message), '', 'error')
          })
        //alert(JSON.stringify(values.email, null, 2));
      } else {
        return Swal.fire('verifique no es un robot.', '', 'error')
      }
    },
  })
  const getTipoUsuario = () => {
    ApiGeneral.getCFDI(4) //tipoUsuario
      .then(response => {
        setTipoCliente(response)

        console.info('éxito data tipousuario:' + response)
      })
      .catch(error => {
        console.error('error data:' + error)
      })
  }
  const validateExistEmail = () => {
    AuthCliente.existsEmail(formik.values.email)
      .then(r => {
        formik.values.email = ''

        formik.errors.email =
          'El correo ingresado ya existe, favor de ingresar otro'

        console.info(
          'El correo ingresado ya existe, favor de ingresar otro.',
          r
        )
      })
      .catch(e => {
        formik.errors.email = e.response.data.message

        console.info('error', e)
      })
  }

  const onVerify = () => {
    setVerified(true)
  }

  const handleClickOpen = () => {
    formik.handleReset()
    setOpen(true)
  }

  const handleClose = () => {
    AsignarDatos(null)
    setOpen(false)
    onClose()
  }

  const AsignarDatos = objeto => {
    setUser({
      ...usuario,
      nombre: objeto === null ? '' : objeto.nombre,
      aPaterno: objeto === null ? '' : objeto.aPaterno,
      aMaterno: objeto === null ? '' : objeto.aMaterno,
      email: objeto === null ? '' : objeto.email,
      telefono: objeto === null ? '' : objeto.telefono,
    })
  }

  useEffect(() => {
    if (!open) return
    getTipoUsuario()
  }, [open])

  return (
    <>
      <Button
        style={{ visibility: 'hidden', background: 'transparent' }}
        variant='contained'
        color='primary'
        onClick={handleClickOpen}
      >
        Registrar Cliente
      </Button>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby='draggable-dialog-title'
      >
        <LoadingScreen open={isLoading} />

        <DialogTitle style={{ cursor: 'move' }} id='draggable-dialog-title'>
          <li
            className='close-icon'
            style={{ marginBottom: '20px', textAlign: 'right' }}
            onClick={handleClose}
          >
            ✕
          </li>
          Registrar Cliente
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <DialogContentText>
              <TextField
                label='Correo Electrónico*'
                margin='dense'
                id='email'
                name='email'
                type='email'
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.email}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              {/* <span style={{color: "red"}}>{errores.error["email"]}</span> */}
              <TextField
                margin='dense'
                id='nombre'
                name='nombre'
                label='Nombre*'
                placeholder='Nombre'
                type='text'
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.nombre}
                onBlur={formik.handleBlur}
                error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                helperText={formik.touched.nombre && formik.errors.nombre}
              />
              {/* <span style={{color: "red"}}>{errores.error["nombre"]}</span> */}

              <TextField
                margin='dense'
                id='aPaterno'
                name='aPaterno'
                label='Primer Apellido*'
                placeholder='Primer Apellido'
                type='text'
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.aPaterno}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.aPaterno && Boolean(formik.errors.aPaterno)
                }
                helperText={formik.touched.aPaterno && formik.errors.aPaterno}
              />

              <TextField
                margin='dense'
                id='aMaterno'
                name='aMaterno'
                label='Segundo Apellido*'
                placeholder='Segundo Apellido'
                type='text'
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.aMaterno}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.aMaterno && Boolean(formik.errors.aMaterno)
                }
                helperText={formik.touched.aMaterno && formik.errors.aMaterno}
              />

              <TextField
                margin='dense'
                id='telefono'
                name='telefono'
                label='Teléfono*'
                placeholder='Teléfono'
                type='number'
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.telefono}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.telefono && Boolean(formik.errors.telefono)
                }
                helperText={formik.touched.telefono && formik.errors.telefono}
              />
              <label>
                <input
                  type='checkbox'
                  id='tandc'
                  name='tandc'
                  checked={true}
                  value={formik.values.tandc}
                  error={formik.touched.tandc && Boolean(formik.errors.tandc)}
                  helperText={formik.touched.tandc && formik.errors.tandc}
                />{' '}
                Aceptas términos y condiciones
              </label>
            </DialogContentText>
            {/*qa*/
            /*6LcPtoMaAAAAAGsptRHqVLqSbVFucHWBBd9oOMeE*/
            /*pp*/
            /*6LcYOdIaAAAAAG09grfCXalmQny9-od32Bs1Opd-*/}
            <Reaptcha
              sitekey={
                process.env.NODE_ENV === 'production'
                  ? '6LfXVq8cAAAAANirUaFiAIfwU43isKIro4cTEZH8'
                  : process.env.REACT_APP_BASE_URL === 'prd'
                  ? '6LfXVq8cAAAAANirUaFiAIfwU43isKIro4cTEZH8'
                  : '6LcPtoMaAAAAAGsptRHqVLqSbVFucHWBBd9oOMeE'
              }
              onVerify={onVerify}
            />
          </DialogContent>
          <DialogActions className={classes.DiAction}>
            <Button
              autoFocus
              onClick={handleClose}
              variant='outlined'
              className={classes.btncancelar}
            >
              Cancelar
            </Button>
            <Button
              type='submit'
              variant='contained'
              disabled={!verified}
              className={classes.btnsubnmit}
              value='Registrar'
              color='primary'
            >
              Guardar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default Cliente
