import React, { useEffect, useState, useRef, useContext } from 'react'
import { useParams } from 'react-router-dom'
import DetailsThumb from './DetailsThumb'
import StylesContext from '../../contexts/styles/stylesContext'
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import Context from '../../store/Context'
import {
  getCompare,
  searchgetCompareList,
  getProductDetail,
  postComentario,
  getComentarios,
  getRespuesta,
  getPreguntas,
  getPromo,
  getProductVariant,
  postPregunta,
  getWishList,
  searchgetWishList,
  getElement,
} from '../../actions/resultActions'
import {
  getUrlsForProductImg,
  getFilesDownload,
  getFicha,
} from '../../utils/ima'
import Carousel from 'react-multi-carousel'
import { getUrlForProductImg } from '../../utils/ima'
import UserContext from '../../contexts/user/UserContext'
import { useHistory } from 'react-router'
import Button from '@material-ui/core/Button'
import { Image } from 'antd'
import parse from 'html-react-parser'
import Swal from 'sweetalert2'
import NumberFormat from 'react-number-format'
import { Typography, Tooltip, Zoom } from '@material-ui/core'
import DetalleCatalogo from './CatalogoDetalle/DetalleCatalogo'
import { getCarrito } from '../../actions/carritoActions'
import { Input } from 'antd'
import InfoIcon from '@material-ui/icons/Info'
import { Helmet } from 'react-helmet'
import Base from './../../services/Base'
import Replaces from '../Result/Mask/funcion'
import { makeStyles } from '@material-ui/core/styles'
import Card from './Mask/CardOriginal'
import Estrellas from '../../Views/Calificacion/Estrellas'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { FaRegHeart } from 'react-icons/fa'
import { BsPlusSquare } from 'react-icons/bs'
import { BsPlusSquareFill } from 'react-icons/bs'
import PixelR from '../GoogleAnalitycs/PixelR'
import GlobalContext from '../../contexts/GlobalContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './productstyle.css'

const useStyles2 = makeStyles(Theme => ({
  botom: {
    padding: 5,
    fontSize: '15px',
    borderColor: '#191919',
    backgroundColor: 'white',
  },
  icon: {
    backgroundColor: 'white',
    borderColor: 'white',
    marginLeft: '-20px',
    marginTop: '5px',
  },
  filas: {
    display: 'flex',
  },
  image: {
    borderRadius: 150 / 2,
    overflow: 'hidden',
    borderWidth: 3,
    borderColor: 'red',
  },
  preguntas: {
    width: '63%',
    marginLeft: '26%',
  },
  botonp: {
    marginLeft: '91%',
    marginTop: '-37px',
  },
  botonp2: {
    marginLeft: '845px',
    marginTop: '-37px',
  },
  all: {
    marginLeft: '50px',
  },
  comentarios: {
    width: '63%',
    marginLeft: '25%',
  },
  allcom: {
    marginLeft: '-50px',
  },
  preguntaList: {
    marginLeft: '0%',
    margin: '20px',
  },
  comentList: {
    marginLeft: '0%',
  },
  precios: {
    marginTop: '-27px',
    marginLeft: '59px',
  },
}))

const { TextArea } = Input

const Details = props => {
  const images = []
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1336 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  }
  const { UserState } = useContext(UserContext)

  const { monedaState } = useContext(GlobalContext)
  const stylesContext = useContext(StylesContext)
  const img = []
  const { idm } = useParams()
  const classes = useStyles2()
  const [resultResponse, setResultResponse] = useState('')
  const [resultResponseImg, setResultResponseImg] = useState(null)
  const [infoAdicional, setInfoAdicional] = useState('')
  const [pregunta, setPregunta] = useState('')
  const [comentario, setComentario] = useState('')
  const [icon, setIcon] = useState(false)
  const [dataUser, setDataUser] = useState('')
  const [iconCompare, setIconCompare] = useState(false)
  const [resultPrecio, setResultPrecio] = useState(0)
  const [ahorros, setAhorro] = useState(0)
  const [precioNuevo, setPrecioNuevo] = useState(0)
  const [precio, setPrecio] = useState(resultPrecio)
  const [resultVariantes, setResultVariantes] = useState([])
  const [allProd, setAllProd] = useState(null)
  const [allComent, setAllComent] = useState(null)
  const [allResp, setAllResp] = useState(null)

  const context = useContext(Context)
  const imgVariante = []
  const history = useHistory()
  const [promos, setPromos] = useState([])
  const [moneda, setMoneda] = useState(() => monedaState)
  const [element, setElement] = useState([])

  useEffect(() => {
    getElementos()
    cargaInicial()
  }, [dataUser])

  useEffect(() => {
    if (!UserState.authenticated) return
    Promise.all([actualizaElCarrito(), cargaInicial()]).finally(() => {})
  }, idm)

  const handleText = event => {
    setPreguntaPost({
      ...preguntaPost,
      [event.target.name]: event.target.value,
    })
  }

  const handleComentario = event => {
    setComentarioPost({
      ...comentarioPost,
      [event.target.name]: event.target.value,
    })
  }

  const [preguntaPost, setPreguntaPost] = React.useState({
    productoId: '',
    usuarioID: '00000000-0000-0000-0000-000000000000',
    activo: true,
    pregunta: pregunta,
  })

  const [comentarioPost, setComentarioPost] = React.useState({
    comentarios: comentario,
    activo: true,
    usuario: '00000000-0000-0000-0000-000000000000',
    productoId: '',
  })

  const getProducts = async () => {
    let pedidos = await getPreguntas(window.localStorage.getItem('idProducto'))

    setAllProd(pedidos)
    let resp = await getRespuesta()
    setAllResp(resp)
  }

  const getComents = async () => {
    let pedidos = await getComentarios(
      window.localStorage.getItem('idProducto')
    )
    setAllComent(pedidos)
  }

  const getElementos = async () => {
    const response = await getElement(window.localStorage.getItem('idProducto'))
    setElement(response.data)
  }

  const getPromos = async () => {
    const promociones = await getPromo(
      window.localStorage.getItem('idProducto')
    )
    setPromos(promociones.data)
    if (promociones.data !== '') {
      let ahorro = promociones.data.descuento / 100
      let aux = ahorro * precio
      let precio_nuevo = precio - aux
      setAhorro(aux)
      setPrecioNuevo(precio_nuevo)
    } else {
      setAhorro(0)
      setPrecioNuevo(0)
    }
  }

  const cargaInicial = async () => {
    window.scrollTo({
      top: 10,
      left: 10,
      behavior: 'smooth',
    })

    if (window.performance.navigation.type === 1) {
      var URLdomain = window.location.host
    }
    const response = await getProductDetail(idm)

    setResultResponse(response.data)

    window.localStorage.setItem('idProducto', response.data.idProducto)
    var data = response.data.nombre.split(' ')
    window.localStorage.setItem('nombreProduct', data[0])

    setDataUser(UserState.idCliente)
    const datos = await searchgetWishList(
      UserState.idCliente,
      response.data.idProducto
    )
    const datosCompare = await searchgetCompareList(
      UserState.idCliente,
      response.data.idProducto
    )
    setIcon(datos.data.activo)
    setIconCompare(datosCompare.data.activo)
    window.localStorage.setItem('state', datos.data.activo)
    window.localStorage.setItem('icon', datos.data.activo)
    window.localStorage.setItem('stateCompare', datosCompare.data.activo)
    window.localStorage.setItem('iconcompare', datosCompare.data.activo)
    const res = await getProductVariant(
      window.localStorage.getItem('idProducto')
    )
    setResultVariantes(res.data)
    setResultPrecio(response.data.precio)
    setPrecio(response.data.precio)
    getComents()
    getProducts()
    cargaVariante()
  }

  const calculaPromo = (precio, descuento) => {
    let ahorro = descuento / 100
    let precio_nuevo = precio - ahorro
    setAhorro(ahorro)
    setPrecioNuevo(precio_nuevo)
  }

  const Pregunta = async () => {
    preguntaPost.usuarioID = UserState.idCliente
    //const response = await getProductDetail(idm);

    preguntaPost.productoId = window.localStorage.getItem('idProducto')
    const pregunta = await postPregunta(preguntaPost)
    getProducts()

    getComents()

    if (pregunta.status === 200) {
      Swal.fire('La pregunta fue enviada correctamente', '', 'success')
    }
  }

  const wishEliminar = async id => {
    if (icon === true) {
      const MyObject = {
        idProducto: window.localStorage.getItem('idProducto'),
        idusuario: UserState.idCliente,
        activo: true,
      }
      const JsonArray2 = JSON.stringify(MyObject)
      const result = await getWishList(JsonArray2)
      setIcon(result.data.activo)
    }
  }

  const Comentario = async () => {
    setDataUser(UserState.idCliente)
    comentarioPost.usuario = UserState.idCliente

    comentarioPost.productoId = window.localStorage.getItem('idProducto')
    const pregunta = await postComentario(comentarioPost)
    getProducts()

    getComents()

    if (pregunta.status === 200) {
      Swal.fire('El comentario fue enviado correctamente', '', 'success')
    }
  }

  const wishList = async () => {
    const MyObject = {
      idProducto: window.localStorage.getItem('idProducto'),
      idusuario: UserState.idCliente,
      activo: true,
    }
    const JsonArray2 = JSON.stringify(MyObject)
    const result = await getWishList(JsonArray2)
    setIcon(result.data.activo)
  }

  const compareList = async () => {
    const MyObject = {
      idProducto: window.localStorage.getItem('idProducto'),
      idusuario: UserState.idCliente,
      activo: true,
    }
    const JsonArray2 = JSON.stringify(MyObject)

    const result = await getCompare(JsonArray2)
    setIconCompare(result.data.activo)
  }

  const cargaVariante = async () => {
    const response = await getProductVariant(
      window.localStorage.getItem('idProducto')
    )

    imgVariante.push(response.data)

    imgVariante[0].forEach(element => {
      setResultResponseImg(element.productoImagens)
      // setUrl(element.productoIdVariante)
    })

    imgVariante[0].forEach(element => {
      img.push(element.productoIdVariante)
    })
    if (response.data.length === 0) {
      setResultResponseImg(null)
    }
  }

  const getImgVarS = (rutaUbicacion, id, extension) => {
    const urlImg = Base.baseFiles.imgProduct
    const val = urlImg + '/' + rutaUbicacion + '/' + id + extension
    return val
  }

  const getLink = ind => {
    resultVariantes.map((i, index) => {
      if (ind === index) {
        history.push('/products/' + i.productoIdVariante + '/p')
      }
    })
  }

  const actualizaElCarrito = () => {
    var response = getCarrito(window.localStorage.getItem('carritoID')).then(
      response => {
        var carrito = response.data
        context.removeAllProductsFromCart()
        carrito?.items?.map(i => {
          context.addCartFromRedis({
            quantity: i.cantidad,
            maxQuantity: i.maxCantidad,
            _id: i.id,
            price: i.precio,
            images: i.imagen,
            title: i.producto,
            peso: i.peso,
            familiaId: i.familiaId,
            infoAdicional: i.infoAdicional,
            tipoCambio: context.getTipoCambio,
            moned: moneda.abreviatura,
            wishList: icon,
            pedidos: true,
          })
        })
        if (
          carrito?.items?.filter(
            k => k.id === window.localStorage.getItem('idProducto')
          ).length > 0
        ) {
          var itemCart = carrito?.items?.filter(
            k => k.id === window.localStorage.getItem('idProducto')
          )[0]
          setInfoAdicional(itemCart?.infoAdicional)
        } else setInfoAdicional('')
      }
    )
  }

  const getExistencias = () => {
    if (resultResponse === '') return 0
    var itemCart = context.carts?.filter(
      k => k.product._id === window.localStorage.getItem('idProducto')
    )
    if (itemCart.length > 0) {
      var disponibles = resultResponse.inventario - itemCart[0].quantity
      return disponibles < 0 ? 0 : disponibles
    } else {
      return resultResponse.inventario
    }
  }

  const [index, setIndex] = useState(0)
  const imgDiv = useRef()

  const handleMouseMove = e => {
    const { left, top, width, height } = e.target.getBoundingClientRect()
    const x = ((e.pageX - left) / width) * 100
    const y = ((e.pageY - top) / height) * 100
    imgDiv.current.style.backgroundPosition = `${x}% ${y}%`
  }

  if (resultResponse.nombre !== undefined) {
    window.setTimeout(Replaces, 1000, resultResponse.nombre)
  }

  return (
    <div className='aquiii'>
      <PixelR nombres={'producto/' + resultResponse.nombre} />
      <Helmet>
        <title>{element.titulo}</title>
        <meta name='description' content={element.descripcion} />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <style type='text/css'>{`
     .makeStyles-root-38 {
      width: 330px;
      display: flex;
      align-items: center;
      margin-left: 0;
  }
  .makeStyles-root-135 {
    width: 330px;
    display: flex;
    align-items: center;
    margin-left: 0;
}
.makeStyles-root-120 {
  width: 330px;
  display: flex;
  align-items: center;
  margin-left: 0;
}
.makeStyles-root-256 {
  width: 330px;
  display: flex;
  align-items: center;
  margin-left: 0;
}
.makeStyles-root-439 {
  width: 330px;
  display: flex;
  align-items: center;
  margin-left: 0;
}
    `}</style>
        <meta name='keywords' content={resultResponse.categoria} />
        <meta name='pageId' content={resultResponse.sku} />
        <meta name='pageGroup' content='Product' />
      </Helmet>
      <div style={{ display: 'none' }}>
        {images.push(getUrlsForProductImg({ resultResponse }))}
      </div>
      {
        <div className='details' key={resultResponse.idProducto}>
          <div
            className='img-container'
            onMouseMove={handleMouseMove}
            style={{
              backgroundImage:
                resultResponse == ''
                  ? `url("")`
                  : resultResponse.productoImagen.length == 0
                  ? `url("/img/IMAGEN_NO_DISPONIBLE.png")`
                  : `url(${getUrlsForProductImg({ resultResponse })[index]})`,
            }}
            ref={imgDiv}
            onMouseLeave={() =>
              (imgDiv.current.style.backgroundPosition = `center`)
            }
          />

          <div className='box-details'>
            <Typography variant='h2' className='title' component='h2'>
              {resultResponse.nombre}
            </Typography>
            {dataUser != null && (
              <div className={classes.icon}>
                <Tooltip title={'Mis deseos'} placement='bottom'>
                  <Button
                    onClick={() => {
                      wishList()
                    }}
                  >
                    {icon === true && (
                      <div>
                        <FontAwesomeIcon
                          style={{
                            width: '20px',
                            height: '20px',
                            color: '#b11313',
                          }}
                          icon={faHeart}
                        />
                      </div>
                    )}
                    {icon === false && (
                      <div>
                        <FaRegHeart style={{ width: '20px', height: '20px' }} />
                      </div>
                    )}
                  </Button>
                </Tooltip>
              </div>
            )}
            {dataUser == null && (
              <div className={classes.icon}>
                <Tooltip title={'Debes iniciar sesión'} placement='bottom'>
                  <Button onClick={() => {}}>
                    {icon === false && (
                      <div>
                        <FaRegHeart style={{ width: '20px', height: '20px' }} />
                      </div>
                    )}
                  </Button>
                </Tooltip>
              </div>
            )}
            {dataUser != null && (
              <div className={classes.icon}>
                <Tooltip title={'Mi lista'} placement='bottom'>
                  <Button
                    onClick={() => {
                      compareList()
                    }}
                  >
                    {iconCompare === true && (
                      <div>
                        <BsPlusSquareFill
                          style={{
                            width: '20px',
                            height: '20px',
                            color: '#069435',
                          }}
                        />
                      </div>
                    )}
                    {iconCompare === false && (
                      <div>
                        <BsPlusSquare
                          style={{ width: '20px', height: '20px' }}
                        />
                      </div>
                    )}
                  </Button>
                </Tooltip>
              </div>
            )}
            {dataUser == null && (
              <div className={classes.icon}>
                <Tooltip title={'Debes iniciar sesión'} placement='bottom'>
                  <Button onClick={() => {}}>
                    {iconCompare === false && (
                      <div>
                        <BsPlusSquare
                          style={{ width: '20px', height: '20px' }}
                        />
                      </div>
                    )}
                  </Button>
                </Tooltip>
              </div>
            )}
            <span className='price'>
              {resultResponse.precio > 0 ? (
                <div>
                  {moneda.abreviatura === 'USD' && (
                    <div>
                      <div>
                        {(resultResponse.activoDescuento === true ||
                          resultResponse.rebaja === true) && (
                          <div>
                            <del>
                              <span>
                                ${parseFloat(resultResponse.precio).toFixed(2)}
                              </span>
                              <br></br>
                            </del>
                          </div>
                        )}
                        {resultResponse.activoDescuento === true ||
                        resultResponse.rebaja === true ? (
                          <div>
                            <Tooltip
                              title={
                                'Dolar américano. Fuente: www.banxico.org.mx/'
                              }
                              placement='bottom'
                            >
                              <Typography>
                                <NumberFormat
                                  decimalSeparator={'.'}
                                  value={parseFloat(
                                    resultResponse.descuentoPrecio
                                  ).toFixed(2)}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={'$'}
                                />{' '}
                                {moneda.abreviatura} (Pieza)
                                <InfoIcon
                                  style={{
                                    marginLeft: '5px',
                                    verticalAlign: 'top',
                                    marginTop: '2px',
                                  }}
                                  fontSize={'small'}
                                />
                              </Typography>
                            </Tooltip>
                          </div>
                        ) : (
                          <div>
                            <Tooltip
                              title={
                                'Dolar américano. Fuente: www.banxico.org.mx/'
                              }
                              placement='bottom'
                            >
                              <Typography>
                                <NumberFormat
                                  decimalSeparator={'.'}
                                  value={parseFloat(
                                    resultResponse.precio
                                  ).toFixed(2)}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={'$'}
                                />{' '}
                                {moneda.abreviatura} (Pieza)
                                <InfoIcon
                                  style={{
                                    marginLeft: '5px',
                                    verticalAlign: 'top',
                                    marginTop: '2px',
                                  }}
                                  fontSize={'small'}
                                />
                              </Typography>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {moneda.abreviatura === 'MXN' && (
                    <div>
                      {(resultResponse.activoDescuento === true ||
                        resultResponse.rebaja === true) && (
                        <div>
                          <Typography>
                            <del>
                              <span>
                                $
                                {parseFloat(
                                  resultResponse.precio * context.getTipoCambio
                                ).toFixed(2)}{' '}
                              </span>
                            </del>{' '}
                            (Precio Anterior)<br></br>
                          </Typography>
                        </div>
                      )}
                      {resultResponse.activoDescuento === true ||
                      resultResponse.rebaja === true ? (
                        <div>
                          <Tooltip
                            title={'Peso Mexicano Fuente: www.banxico.org.mx/'}
                            placement='bottom'
                          >
                            <Typography>
                              <NumberFormat
                                decimalSeparator={'.'}
                                value={parseFloat(
                                  resultResponse.descuentoPrecio *
                                    context.getTipoCambio
                                ).toFixed(2)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                              />{' '}
                              {moneda.abreviatura} (Pieza)
                              <InfoIcon
                                style={{
                                  marginLeft: '5px',
                                  verticalAlign: 'top',
                                  marginTop: '2px',
                                }}
                                fontSize={'small'}
                              />
                            </Typography>
                          </Tooltip>
                        </div>
                      ) : (
                        <div>
                          <Tooltip
                            title={'Peso Mexicano Fuente: www.banxico.org.mx/'}
                            placement='bottom'
                          >
                            <Typography>
                              <NumberFormat
                                decimalSeparator={'.'}
                                value={parseFloat(
                                  resultResponse.precio * context.getTipoCambio
                                ).toFixed(2)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                              />{' '}
                              {moneda.abreviatura} (Pieza)
                              <InfoIcon
                                style={{
                                  marginLeft: '5px',
                                  verticalAlign: 'top',
                                  marginTop: '2px',
                                }}
                                fontSize={'small'}
                              />
                            </Typography>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <Typography variant={'body2'}>
                  <a
                    href={`https://wa.me/+528180287617?text=Hola! Quiero informes sobre el producto ${resultResponse.nombre}`}
                    target='_blank'
                  >
                    Si deseas saber más de este producto, contáctanos.
                  </a>
                </Typography>
              )}
            </span>
            {resultResponse && resultResponse?.calificacion > 0 && (
              <div>
                <Estrellas
                  idProducto={resultResponse.idProducto}
                  calificacion={resultResponse.calificacion}
                  pd={'#000'}
                />
              </div>
            )}
            <Typography variant='h2' className='sku'>
              {resultResponse.sku}
            </Typography>

            {resultResponse.tipoAceroId && (
              <DetalleCatalogo
                id={9}
                name={'Tipo Acero'}
                idCat={resultResponse.tipoAceroId}
              />
            )}
            {resultResponse.ancho && (
              <div className='key-val'>
                <Typography variant='h2' className='keytext'>
                  Ancho:
                </Typography>
                <Typography variant='h2' className='valtext'>
                  {resultResponse.ancho}
                </Typography>
                <Typography variant='h2' className='valtext'>
                  &nbsp;CM
                </Typography>
              </div>
            )}
            {resultResponse.largo && (
              <div className='key-val'>
                <Typography variant='h2' className='keytext'>
                  Largo:
                </Typography>
                <Typography variant='h2' className='valtext'>
                  {resultResponse.largo}
                </Typography>
                <Typography variant='h2' className='valtext'>
                  &nbsp;CM
                </Typography>
              </div>
            )}
            {resultResponse.espesorId && (
              <DetalleCatalogo
                id={7}
                name={'Calibre/Espesor'}
                idCat={resultResponse.espesorId}
              />
            )}
            {resultResponse.medidaId && (
              <DetalleCatalogo
                id={0}
                name={'Medida'}
                idCat={resultResponse.medidaId}
              />
            )}
            <div className='key-val'>
              <Typography variant='h2' className='keytext'>
                Linea:
              </Typography>
              <Typography variant='h2' className='valtext'>
                {resultResponse.modelo}
              </Typography>
            </div>
            {resultResponse.unidadMedidaId && (
              <DetalleCatalogo
                id={0}
                name={'Unidad de Medida'}
                idCat={resultResponse.unidadMedidaId}
              />
            )}
            {resultResponse.cantidadPresentacion ? (
              <div className='key-val'>
                <Typography variant='h2' className='keytext'>
                  Numero de piezas:
                </Typography>
                <Typography variant='h2' className='valtext'>
                  {resultResponse.cantidadPresentacion}
                </Typography>
              </div>
            ) : null}

            {resultResponse.cA2 ? (
              <div className='key-val'>
                <Typography variant='h2' className='keytext'>
                  {resultResponse.cA2}:
                </Typography>
                <Typography variant='h2' className='valtext'>
                  {resultResponse.vA2}
                </Typography>
              </div>
            ) : null}
            {resultResponse.cA1 ? (
              <div className='key-val'>
                <Typography variant='h2' className='keytext'>
                  {resultResponse.cA1}:
                </Typography>
                <Typography variant='h2' className='valtext'>
                  {resultResponse.vA1}
                </Typography>
              </div>
            ) : null}
            {resultResponse.acabadoId && (
              <DetalleCatalogo
                id={13}
                name={'Acabado'}
                idCat={resultResponse.acabadoId}
              />
            )}
            <div style={{ marginTop: '10px', marginBottom: '10px' }}>
              <DetailsThumb
                images={getUrlsForProductImg({ resultResponse })}
                setIndex={setIndex}
              />
            </div>

            {resultResponse.categoriaId && (
              <div className='key-val'>
                <Typography variant='h2' className='keytext'>
                  Familia:
                </Typography>
                <Typography variant='h2' className='valtext'>
                  {resultResponse.categoria}
                </Typography>
              </div>
            )}

            <div>
              <br></br>
              {resultResponseImg != null && (
                <Typography variant='h2' className='keytext'>
                  Variantes:
                </Typography>
              )}
              {resultResponseImg != null && (
                <div className={classes.filas}>
                  {resultResponseImg.map((item, index) => (
                    <div style={{ height: '18%', width: '18%', margin: '2%' }}>
                      <Link onClick={() => getLink(index)}>
                        <div key={item}>
                          <br></br>
                          <Image
                            preview={false}
                            src={getImgVarS(
                              item.rutaUbicacion,
                              item.id,
                              item.extension
                            )}
                            fallback={'/img/IMAGEN_NO_DISPONIBLE.png'}
                          />
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {resultResponse.peso ? (
              <div className='key-val'>
                <Typography variant='h2' className='keytext'>
                  Peso:
                </Typography>
                <Typography variant='h2' className='valtext'>
                  {resultResponse.peso}
                </Typography>
              </div>
            ) : null}

            <br></br>
            <div>
              <Typography variant='h2' className='etiqueta-text'>
                Especificación adicional:
              </Typography>
              <TextArea
                showCount
                maxLength={280}
                placeholder='En caso de algún requerimiento adicional en su compra, favor de llenarlo en esta área.'
                allowClear
                rows={2}
                value={infoAdicional}
                enterButton
                onChange={e => {
                  setInfoAdicional(e.target.value)
                }}
              />
            </div>
            <div className='counter-co'>
              <div className='key-val' style={{ visibility: 'hidden' }}>
                <Typography variant='h2' className='keytext'>
                  Piezas disponibles:
                </Typography>
                <Typography variant='h2' className='valtext'>
                  {getExistencias()}
                </Typography>
              </div>
              {moneda.abreviatura === 'USD' && (
                <div>
                  <div className='btn-cont'>
                    <Link to='#' className='product-btns'>
                      <button
                        disabled={
                          getExistencias() <= 0 || resultResponse.precio == 0
                        }
                        onClick={() => {
                          context.addProductToCart({
                            _id: resultResponse.idProducto,
                            price:
                              resultResponse.rebaja === true ||
                              resultResponse.activoDescuento === true
                                ? resultResponse.descuentoPrecio
                                : resultResponse.precio,
                            images:
                              getUrlsForProductImg({ resultResponse })[0] ==
                              undefined
                                ? '/img/IMAGEN_NO_DISPONIBLE.png'
                                : getUrlsForProductImg({ resultResponse })[0],
                            title: resultResponse.nombre.replaceAll(`"`, `''`),
                            peso: resultResponse.peso,
                            familiaId: resultResponse.categoriaId,
                            infoAdicional: infoAdicional,
                            maxQuantity: resultResponse.inventario,
                            tipoCambio: context.getTipoCambio,
                            moned: moneda.abreviatura,
                            wishList: icon,
                            pedidos: true,
                          })
                          wishEliminar(resultResponse.idProducto)
                        }}
                        type='button'
                        className='btn-cart'
                      >
                        <Typography className='botton-detail'>
                          {resultResponse.precio === 0
                            ? 'Precio no disponible'
                            : resultResponse.inventario > 0
                            ? 'Agregar al carrito'
                            : 'No disponible'}
                        </Typography>
                      </button>
                    </Link>
                  </div>
                </div>
              )}
              {moneda.abreviatura === 'MXN' && (
                <div>
                  <div className='btn-cont'>
                    <Link to='#' className='product-btns'>
                      <button
                        disabled={
                          getExistencias() <= 0 || resultResponse.precio == 0
                        }
                        onClick={() => {
                          context.addProductToCart({
                            _id: resultResponse.idProducto,

                            price:
                              resultResponse.rebaja === true ||
                              resultResponse.activoDescuento === true
                                ? resultResponse.descuentoPrecio *
                                  context.getTipoCambio
                                : resultResponse.precio * context.getTipoCambio,
                            images:
                              getUrlsForProductImg({ resultResponse })[0] ==
                              undefined
                                ? '/img/IMAGEN_NO_DISPONIBLE.png'
                                : getUrlsForProductImg({ resultResponse })[0],
                            title: resultResponse.nombre.replaceAll(`"`, `''`),
                            peso: resultResponse.peso,
                            familiaId: resultResponse.categoriaId,
                            infoAdicional: infoAdicional,
                            maxQuantity: resultResponse.inventario,
                            tipoCambio: context.getTipoCambio,
                            moned: moneda.abreviatura,
                            wishList: icon,
                            pedidos: true,
                          })
                          wishEliminar(resultResponse.idProducto)
                        }}
                        type='button'
                        className='btn-cart'
                      >
                        <Typography className='botton-detail'>
                          {resultResponse.precio === 0
                            ? 'Precio no disponible'
                            : resultResponse.inventario > 0
                            ? 'Agregar al carrito'
                            : 'No disponible'}
                        </Typography>
                      </button>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      }
      {resultResponseImg === null && (
        <div>
          <br></br> <br></br> <br></br> <br></br> <br></br> <br></br> <br></br>{' '}
          <br></br>
        </div>
      )}
      {resultResponse.fichaTecnica == null ? (
        <div></div>
      ) : resultResponse.fichaTecnica == '<p></p>\n' ? (
        <div></div>
      ) : (
        <div className='tabs1'>
          <Tabs>
            <TabList className='tab-header'>
              {resultResponse.fichaTecnica === undefined ? (
                <div></div>
              ) : resultResponse.fichaTecnica === null ? (
                <div></div>
              ) : resultResponse.fichaTecnica == '<p></p>\n' ? (
                <div></div>
              ) : (
                <Tab className='tab-chl'>
                  <Typography variant='h2' className='etiqueta'>
                    Ficha técnica
                  </Typography>
                </Tab>
              )}
              {resultResponse.productoArchivo === undefined ||
              resultResponse.productoArchivo === null ? (
                <div></div>
              ) : resultResponse.productoArchivo.length === 0 ? (
                <div></div>
              ) : (
                <Tab className='tab-chl'>
                  <Typography variant='h2' className='etiqueta'>
                    Descargables
                  </Typography>
                </Tab>
              )}
            </TabList>

            {resultResponse.fichaTecnica === undefined ? (
              <div></div>
            ) : resultResponse.fichaTecnica === null ? (
              <div></div>
            ) : resultResponse.fichaTecnica == '<p></p>\n' ? (
              <div></div>
            ) : (
              <div className='t'>
                <TabPanel>
                  <Typography variant='h2' className='etiqueta'>
                    {' '}
                    {parse(getFicha(resultResponse.fichaTecnica))}
                  </Typography>
                </TabPanel>
              </div>
            )}
            {resultResponse.productoArchivo === undefined ||
            resultResponse.productoArchivo === null ? (
              <div></div>
            ) : resultResponse.productoArchivo.length === 0 ? (
              <div></div>
            ) : (
              <TabPanel>
                <Typography variant='h2' className='etiqueta-text'>
                  {parse(getFilesDownload(resultResponse.productoArchivo))}
                </Typography>
              </TabPanel>
            )}
          </Tabs>
        </div>
      )}
      <div className='question'>
        <Typography variant='h2' className='titulo'>
          Haz una pregunta:
        </Typography>
        <TextArea
          showCount
          placeholder='Escribe tu pregunta..'
          maxLength={200}
          allowClear
          rows={1}
          name='pregunta'
          value={preguntaPost.pregunta}
          enterButton
          onChange={handleText}
        />
        <br></br>
        <Button variant='contained' color='primary' onClick={Pregunta}>
          Preguntar
        </Button>
        <br></br> <br></br>
        <div>
          <Typography variant='h1' className='titulo'>
            Ultimas preguntas realizadas:<br></br>
            <br></br>
          </Typography>
          {allProd !== null && (
            <div className={classes.preguntaList}>
              {allProd.data.map((item, index) => (
                <div>
                  {item.activo === true && (
                    <Typography variant='h2' className='etiqueta'>
                      {item.pregunta}
                    </Typography>
                  )}

                  {allResp !== null && (
                    <div>
                      {allResp.data.map((item2, index) => (
                        <div>
                          {item2.preguntaId === item.preguntaId &&
                            item.activo === true && <h6>{item2.respuestaP}</h6>}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
          <Typography variant='h2' className='titulo'>
            Deja un comentario:
          </Typography>
          <TextArea
            showCount
            placeholder='Escribe tu comentario..'
            maxLength={300}
            allowClear
            rows={1}
            name='comentarios'
            value={comentarioPost.comentarios}
            enterButton
            onChange={handleComentario}
          />
          <br></br>
          <Button variant='contained' color='primary' onClick={Comentario}>
            Comentar
          </Button>
          <br></br> <br></br>
          <Typography variant='h1' className='titulo'>
            Comentarios:
          </Typography>
          <div className='clist'>
            {allComent !== null && (
              <div className={classes.comentList}>
                {allComent.data.map((item, index) => (
                  <div className='clist'>
                    <Typography variant='h2' className='etiqueta'>
                      {item.comentarios}
                    </Typography>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <br></br>
      <br></br> <br></br>
      <br></br> <br></br>
      <br></br> <br></br>
      <h2 style={{ color: stylesContext.black }} className='colec'>
        Te podria interesar:
      </h2>
      {resultResponse !== '' && (
        <div>
          <Carousel responsive={responsive}>
            {resultResponse.productoColeccion.map(p => (
              <div>
                {moneda.abreviatura === 'USD' && (
                  <Card
                    key={p.idProducto}
                    idProducto={p.idProducto}
                    price={p.precio}
                    imagenPereview={
                      p.imagenPereview === null
                        ? '/img/IMAGEN_NO_DISPONIBLE.png'
                        : getUrlForProductImg(p.imagenPereview)
                    }
                    sku={p.sku}
                    nombre={p.nombre}
                    sprice={p.precio}
                    abrevia={moneda.abreviatura}
                    calificacion={p.calificacion}
                    activoDescuento={p.activoDescuento}
                    descuentoPrecio={p.descuentoPrecio * context.getTipoCambio}
                    rebaja={p.rebaja}
                    descuento={p.descuento}
                    icon={p.wish}
                    peso={p.peso}
                    inventario={p.inventario}
                    categoriaId={p.categoriaId}
                    lists={p.lists}
                    addProductToCart={context.addProductToCart}
                  />
                )}
                {moneda.abreviatura === 'MXN' && (
                  <Card
                    key={p.idProducto}
                    idProducto={p.idProducto}
                    price={p.precio * context.getTipoCambio}
                    imagenPereview={
                      p.imagenPereview === null
                        ? '/img/IMAGEN_NO_DISPONIBLE.png'
                        : getUrlForProductImg(p.imagenPereview)
                    }
                    sku={p.sku}
                    nombre={p.nombre}
                    sprice={p.precio * context.getTipoCambio}
                    abrevia={moneda.abreviatura}
                    calificacion={p.calificacion}
                    activoDescuento={p.activoDescuento}
                    descuentoPrecio={p.descuentoPrecio * context.getTipoCambio}
                    rebaja={p.rebaja}
                    descuento={p.descuento}
                    icon={p.wish}
                    peso={p.peso}
                    inventario={p.inventario}
                    categoriaId={p.categoriaId}
                    lists={p.lists}
                    addProductToCart={context.addProductToCart}
                  />
                )}
              </div>
            ))}
          </Carousel>
        </div>
      )}
    </div>
  )
}

export default Details
