import Base from '../services/Base'
import actions from './actions'

const pedidoActions = {
  getDireccionPrincial: () =>
    actions.get('catalogo', 'ClienteDireccion/getMainAddress'),
  getSucursales: () => actions.get('almacen', 'almacen/getAll'),
  getPedidos: () => actions.get('pedido', 'pedido/client'),
  getCotizacion: (data, id) =>
    actions.post('skydropx', `envio/cotizacion/` + id, data),
  getCotizacion99M: (data, id) =>
    actions.post('minutos', `envio/Cotizacion99M/` + id, data),
  getEnvio: data => actions.post('skydropx', `envio/Envio/`, data),

  getCarritoResumen: carritoId =>
    actions.get('almacen2', `producto/summary/${carritoId}`),
  getCarritototales: (
    carritoId,
    idFleteConfiguracion,
    idCupon,
    deliveryMethod,
  ) =>
    actions.post('almacen2', `producto/totals`, {
      carritoId: carritoId,
      idFleteConfiguracion: idFleteConfiguracion,
      idCupones: idCupon,
      deliveryMethod: deliveryMethod,
    }),
  getPedidoResumen: folio => actions.get('pedido', `pedido/summary/${folio}`),
  getPeso: carritoId => actions.get('almacen2', `producto/weight/${carritoId}`),
  getFletesByPesoAndCP: (peso, cp) =>
    actions.get('logistica', `fleteConfiguracion/getByPesoAndCP/${peso}/${cp}`),
  getFletesByPesoAndCP: (peso, cp, carritoId) =>
    actions.get(
      'logistica',
      `fleteConfiguracion/getByPesoAndCP/${peso}/${cp}/${carritoId}`,
    ),
  getCuponValidacion: codigo =>
    actions.get('promociones', `cupones/validate/${codigo}`),
  createPayPal: data => actions.post('pedido', `pedido/paypal`, data),
  createPayPal99M: data => actions.post('pedido99m', `pedido/paypal`, data),

  cancelPayPal: data => actions.post('pedido', `pedido/paypal/cancel`, data),
  cancelMP: data => actions.post('pedido', `pedido/MP/cancel`, data),
  cancelBBVA: data => actions.post('pedido', `pedido/bbva/cancel`, data),
  updateBBVA: folio => actions.post('pedido', `pedido/bbva/${folio}`, {}),
  updateInfo: info => actions.post('pedido', `pedido/info`, info),
  buyAgain: data => actions.post('pedido', `pedido/buy/again`, data),
}

export default pedidoActions
