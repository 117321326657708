import { Helmet } from 'react-helmet'
import Base from './../services/Base'
import { useParams } from 'react-router-dom'
import { getProductDetail } from './../actions/resultActions'
import React, { useEffect, useState } from 'react'
import { GetDetalle } from '../components/Result/Mask/catalogorequest'

function HemmetWrapping() {
  const { idm } = useParams()
  const [resultResponse, setResultResponse] = React.useState('')
  const [detalle, setDetalle] = useState([])
  const getDetalles = async () => {
    const responses = await GetDetalle()
    setDetalle(responses.data)
  }
  useEffect(() => {
    console.log(idm)
    getDetalles()
  }, [])

  const HelmetProduct = () => {
    const response = getProductDetail(idm).then(response => {
      setResultResponse(response.data)
      return (
        <Helmet>
          <title>{resultResponse.nombre}</title>
          <meta name='description' content={resultResponse.nombre} />
          <link
            rel='canonical'
            // href={`${Base.baseUrls.baseUrl}products/${idm}/p`}
            href='https://productosnu.com/'
          />
        </Helmet>
      )
    })
  }
  const HelmetGeneric = () => (
    <Helmet>
      <title>{detalle.titulo}</title>

      <link
        rel='canonical'
        // href={`${Base.baseUrls.baseUrl}`}
        href='https://productosnu.com/'
      />
      <meta property='og:url' content={detalle.url} />
      <meta property='og:image' content={detalle.image} />
    </Helmet>
  )
  return idm === null || idm === undefined ? HelmetGeneric() : HelmetProduct()
}
export default HemmetWrapping
