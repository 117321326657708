import React, { useReducer, useEffect } from 'react'

import GlobalContext from './GlobalContext'
import GlobalReducer from './GlobalReducer'
import {
  setDetalleState,
  setSectionsState,
  setGlobalState,
  setNotificacionesState,
  setMonedaState,
  setPaymentMethods,
  setAlert,
} from './GlobalActions'

import {
  getDetalleById,
  getSectionsHomeById,
  getKeyNot,
  getTipoPagoById,
  getAllPaymentMethods,
  getPaymentMethodKeysById,
  getMoneda,
} from '../actions/catalogoActions'

const initialState = {
  globalState: {
    IdClien: null,
    idPlantilla: null,
    moneda: null,
  },
  detalleState: {},
  sectionsState: [],
  notificacionesState: {},
  monedaState: [],
  paymentMethods: [],
  messageAlert: {
    message: 'message',
    type: 'info',
    active: false,
  },
}

const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(GlobalReducer, initialState)

  // precargar
  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const signal = controller.signal

    // fix
    const idDetalle = 'b8ddb7d8-66f5-401c-b3e8-95f087b19d48'

    getDetalleById(signal, idDetalle)
      .then(data => {
        if (isMounted) {
          console.info('getDetalleById', data)
          setDetalleState(dispatch, data)
        }
      })
      .catch(e => {
        if (isMounted) {
          console.error('Error fetching detalle ', e)
        }
      })

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [dispatch])

  // precargar esta peticion
  useEffect(() => {
    if (!state.detalleState.idPlantilla) return

    let isMounted = true
    const controller = new AbortController()
    const signal = controller.signal

    getSectionsHomeById(signal, state.detalleState.idPlantilla)
      .then(data => {
        if (isMounted) {
          console.info('getSectionsHomeById ', data)
          setSectionsState(dispatch, data)
        }
      })
      .catch(e => {
        if (isMounted) {
          console.error('Error fetching sections ', e)
        }
      })

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [dispatch, state.detalleState.idPlantilla])

  useEffect(() => {
    if (!state.sectionsState) return

    let isMounted = true
    const controller = new AbortController()
    const signal = controller.signal

    // id ? paypal / mercado pago
    const id = 'B6DBD78F-B062-4F84-B7EE-0A173785F0A8'

    getTipoPagoById(signal, id)
      .then(data => {
        if (isMounted) {
          console.info('getKeyPay ', data.clave)
          window.localStorage.setItem('IdClien', data.clave)
          setGlobalState(dispatch, { IdClien: data.clave })
        }
      })
      .catch(e => {
        if (isMounted) {
          console.error('Error fetching key pay:', e)
        }
      })

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [dispatch, state.sectionsState])

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const signal = controller.signal

    getMoneda(signal)
      .then(data => {
        if (isMounted) {
          console.info('getMoneda ', data)
          window.localStorage.setItem('monedaF', data.abreviatura)
          window.localStorage.setItem('monedasup', data.abreviatura)
          window.localStorage.setItem('removemoneda', data.abreviatura)
          window.localStorage.setItem('monedas', data.abreviatura)
          setMonedaState(dispatch, data)
        }
      })
      .catch(e => {
        if (isMounted) {
          console.error('Error fetching moneda ', e)
        }
      })

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [dispatch, state.sectionsState])

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const signal = controller.signal

    getAllPaymentMethods(signal)
      .then(data => {
        if (isMounted) {
          if (data) {
            const activePaymentMethods = data.filter(element => element.activo)

            // FIX revisar donde se usan, cambiar por state
            activePaymentMethods.forEach(element => {
              if (element.nombreTipo === 'Mercado Pago') {
                window.localStorage.setItem('idTipo', element.idTipo)
                window.localStorage.setItem('idMercadoPago', element.idTipo)
              }
              if (element.nombreTipo === 'Paypal') {
                window.localStorage.setItem('idTipoPaypal', element.idTipo)
                window.localStorage.setItem('idPaypal', element.idTipo)
              }
            })

            setPaymentMethods(dispatch, activePaymentMethods)
          }
        }
      })
      .catch(e => {
        if (isMounted) {
          console.error('Error fetching KeysPago:', e)
        }
      })

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [dispatch, state.sectionsState])

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const signal = controller.signal

    getKeyNot(signal)
      .then(data => {
        if (isMounted) {
          console.info('notificaciones', data)
          setNotificacionesState(dispatch, data)
        }
      })
      .catch(e => {
        if (isMounted) {
          console.error('Error fetching notificaciones:', e)
        }
      })

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [dispatch, state.sectionsState])

  const idMercado = window.localStorage.getItem('idTipo')

  useEffect(() => {
    if (!idMercado) return

    let isMounted = true
    const controller = new AbortController()
    const signal = controller.signal

    getPaymentMethodKeysById(signal, idMercado)
      .then(data => {
        console.log('getPaymentMethodKeysById(idMercado) ', data)

        if (isMounted) {
          data.forEach(element => {
            if (
              element.nombreClave === 'Access_token' ||
              element.nombreClave === ' Access_token'
            ) {
              window.localStorage.setItem('access_token', element.clave)
            }
          })
        }
      })
      .catch(e => {
        if (isMounted) {
          console.log('Error fetching accessToken mercado:', e)
        }
      })

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [idMercado])

  const idPaypal = window.localStorage.getItem('idTipoPaypal')

  useEffect(() => {
    if (!idPaypal) return

    let isMounted = true
    const controller = new AbortController()
    const signal = controller.signal

    getPaymentMethodKeysById(signal, idPaypal)
      .then(data => {
        console.log('getPaymentMethodKeysById(idPaypal) ', data)

        if (isMounted) {
          data.forEach(element => {
            if (element.nombreClave === 'AccessToken') {
              window.localStorage.setItem('AccessToken', element.clave)
            }
            if (element.nombreClave === 'Client_ID') {
              window.localStorage.setItem('Client_ID', element.clave)
            }
            if (element.nombreClave === 'Secret') {
              window.localStorage.setItem('Secret', element.clave)
            }
          })
        }
      })
      .catch(e => {
        if (isMounted) {
          console.log('Error fetching accessToken paypal:', e)
        }
      })

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [idPaypal])

  const updateGlobalState = newState => {
    setGlobalState(dispatch, newState)
  }

  return (
    <GlobalContext.Provider
      value={{
        ...state,
        updateGlobalState,
        setAlert: (message, type) => setAlert(dispatch, message, type),
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalProvider
