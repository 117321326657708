import React, { useContext, useEffect, useState } from 'react'
import './styles.css'
// import {
//   getUrlForProductImg,
//   getFilesDownload,
//   getUrlsForImgPlantilla,
// } from '../../utils/ima'
// import { Link, useParams } from 'react-router-dom'
// import ApiGeneral from '../../services/ApiGeneral'
import { useHistory } from 'react-router-dom'
import {
  makeStyles,
  Button,
  // Dialog,
  // DialogActions,
  // DialogContent,
  // DialogTitle,
  // Divider,
  // FormControl,
  GridList,
  GridListTile,
  // IconButton,
  // InputLabel,
  // Select,
  TextField,
  // Tooltip,
  Typography,
} from '@material-ui/core'
import { getTarjetasId, PostTarjetas } from '../Result/Mask/catalogorequest'
// import CardTarjeta from '../Result/Mask/CardTarjeta'
// import { COLORS } from '../../Views/Colores'
import StylesContext from '../../contexts/styles/stylesContext'
import { getMercadoPK } from '../../actions/resultActions'
import { Input } from 'antd'
import Swal from 'sweetalert2'
import { Image } from 'antd'
// import { loadScript } from '@paypal/paypal-js'
import LoadingScreen from '../RegistroCliente/LoadingScreen'
import {
  // getKeysPago,
  getPrefer,
  // getKeyTipoName,
} from '../../actions/resultActions'
import { dominio } from '../../services/Url'
// import Mercado from './Mercado'
import Paypal from './Pay'
import { Segment, Icon as SemanticIcon } from 'semantic-ui-react'
import { useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
// import Moment from 'moment'

import 'moment/locale/es'
// import { getMoneda } from '../../components/Result/Mask/catalogorequest'
import Context from '../../store/Context'
import GlobalContext from '../../contexts/GlobalContext'
// const Header = React.lazy(() =>
//   import('../../components/Header/HeaderResponsive')
// )
// const Footer = React.lazy(() => import('../../Views/Footer/FooterMenu'))

const useStyles2 = makeStyles(() => ({
  header: {
    backgroundColor: '#000',
    paddingLeft: '70px',
    zIndex: 999,
    position: 'inherit',
    '@media (max-width: 900px)': {
      paddingLeft: 1,
    },
  },
  logo: {
    width: '20%',

    display: 'flex',
  },
  menuButton: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 250,
    size: '14px',
    marginLeft: '5px',
    color: '#003462',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  drawerContainer: {
    padding: '20px 30px',
  },
  button: {},
  textfildMargin: {
    width: '17rem',
    height: '9px !important',
    paddingbottom: '2.5px',
  },
  btnRegis: {
    width: '19rem',
  },
  gridAlings: {
    textAlign: 'center',
    padding: '1rem',
  },
  btnSocial: {
    left: '-500px',
  },
}))
const { TextArea } = Input
const Plantillas = ({ id }) => {
  const { globalState, monedaState } = useContext(GlobalContext)
  const context = useContext(Context)
  const stylesContext = useContext(StylesContext)
  const classes = useStyles2()
  const history = useHistory()
  const [tarjetas, setTarjetas] = useState([])
  const [idTarje, setIdTarje] = useState('')
  const [idClint, setIdC] = useState(() => globalState.idClient)
  const [paypalID, setPayPayID] = useState(() => globalState.idClient)
  const [isLoading, setIsLoading] = useState(false)
  const [moneda, setMoneda] = useState(() => monedaState)

  const TarjetaGet = async () => {
    var pathname = window.location.pathname
    var cadenas = pathname.split('/')
    // var id_client = await getKeyPay()
    // setIdC(id_client.data.clave)

    // console.log(id_client)
    // window.localStorage.setItem('IdClien', id_client.data.clave)
    // setPayPayID(id_client.data.clave)
    const responses = await getTarjetasId(cadenas[2])
    setTarjetas(responses.data)
    _Producto.id = cadenas[2]
  }

  // const getMonedas = async () => {
  //   const moned = await getMoneda()
  //   setMoneda(moned.data)
  //   window.localStorage.setItem('monedasup', moned.data.abreviatura)

  // var id_client = await getKeyPay()
  // setIdC(id_client.data.clave)

  // setPayPayID(id_client.data.clave)
  // window.localStorage.setItem('monedas', moned.data.abreviatura)

  // console.log(moned)
  // }

  // useEffect(() => {
  //  getPromos();
  //   getMonedas()
  // }, [])

  const methods = useForm()
  const [loading, setLoading] = React.useState(false)
  const [urlPago, setLink] = React.useState('')
  const { handleSubmit } = methods
  const MercadoPago = async () => {
    _Producto.activo = false
    setIsLoading(true)
    if (_Producto.cantidad >= 1) {
      handleSubmit(TarjetaPost)
      if (_Producto.cantidad >= 1) {
        _Producto.montoActual = tarjetas.monto
        _Producto.nombre = tarjetas.nombre
        _Producto.urls = tarjetas.urls

        const response = await PostTarjetas(_Producto)
        console.log(response)

        var jsonMercado
        if (moneda.abreviatura === 'MXN') {
          jsonMercado = {
            items: [
              {
                title: 'Tarjeta de regalo',
                quantity: 1,
                unit_price: tarjetas.monto * _Producto.cantidad,
              },
            ],
            taxes: [{ type: 'IVA', value: 16 }],
            back_urls: {
              success:
                dominio.tienda +
                `enviado/${_Producto.id}/${response.data.idCompra}`,
              failure:
                dominio.tienda +
                `enviado/${_Producto.id}/${response.data.idCompra}`,
              pending:
                dominio.tienda +
                `enviado/${_Producto.id}/${response.data.idCompra}`,
            },
            auto_return: 'approved',
            payment_methods: {
              excluded_payment_methods: [
                {
                  id: 'paypal',
                },
              ],
              excluded_payment_types: [
                {
                  id: 'ticket',
                },
              ],
            },
          }
        } else {
          jsonMercado = {
            items: [
              {
                title: 'Tarjeta de regalo',
                quantity: 1,
                unit_price: Number(
                  (
                    (tarjetas.monto / context.getTipoCambio) *
                    _Producto.cantidad
                  ).toFixed(2)
                ),
                currency_id: 'USD',
              },
            ],
            taxes: [{ type: 'IVA', value: 16 }],
            back_urls: {
              success:
                dominio.tienda +
                `enviado/${_Producto.id}/${response.data.idCompra}`,
              failure:
                dominio.tienda +
                `enviado/${_Producto.id}/${response.data.idCompra}`,
              pending:
                dominio.tienda +
                `enviado/${_Producto.id}/${response.data.idCompra}`,
            },
            auto_return: 'approved',
            payment_methods: {
              excluded_payment_methods: [
                {
                  id: 'paypal',
                },
              ],
              excluded_payment_types: [
                {
                  id: 'ticket',
                },
              ],
            },
          }
        }

        console.log(urlPago)

        const JsonArray2 = JSON.stringify(jsonMercado)

        var access_token = await getMercadoPK()
        const peticion = await getPrefer(JsonArray2, access_token.data.clave)

        window.location.href = peticion.data.sandbox_init_point
        ///tarjetas/enviado
      } else {
        window.alert('Necesitas seleccionar 1 o más tarjetas')
      }
    } else {
      window.alert('Necesitas seleccionar 1 o más tarjetas')
    }

    //handleSubmit(TarjetaPost)
  }
  const links = () => {
    return urlPago
  }

  const TarjetaPost = async () => {
    setIsLoading(true)
    if (_Producto.cantidad >= 1) {
      _Producto.montoActual = tarjetas.monto
      if (_Producto.fecha === '' || _Producto.nombre === '') {
        var fecha = new Date()
        _Producto.fecha = fecha.toDateString()
        _Producto.nombre = 'Anonimo'
      }
      console.log(_Producto)
      const response = await PostTarjetas(_Producto)
      console.log(response)

      window.localStorage.setItem('IdCompra', response.data.idCompra)
      setLink(response.data.idCompra)

      setIsLoading(false)
      if (response.status === 200) {
        history.push(`/enviado/${_Producto.id}/${response.data.idCompra}`)
        setLoading(false)
      }

      ///tarjetas/enviado
      else {
        Swal.fire('Hubo un error al enviar el correo', '', 'success')
        setLoading(false)
      }
    }
  }
  const TarjetaPostMercado = async () => {
    if (_Producto.cantidad >= 1) {
      const response = await PostTarjetas(_Producto)
      console.log(response)

      window.localStorage.setItem('IdCompra', response.data.idCompra)
      setLink(response.data.idCompra)

      ///tarjetas/enviado
    } else {
      window.alert('Necesitas seleccionar 1 o más tarjetas')
    }
  }
  useEffect(() => {
    TarjetaGet()
    let newDate = new Date()
    let date = newDate.getDate()
    let month = newDate.getMonth()
    let year = newDate.getFullYear()
    _Producto.fecha = date.toString() + month.toString() + year.toString()
  }, [])

  const [_Producto, setProducto] = React.useState({
    id: '',
    activo: true,
    destinatario: '',
    remitente: '',
    mensaje: '',
    fecha: new Date().toDateString(),
    cantidad: 1,
    montoActual: 0,
    urls: '',
    nombre: '',
  })
  const handleText = event => {
    setProducto({ ..._Producto, [event.target.name]: event.target.value })
  }
  // document.documentElement.style.setProperty('--color-principal', COLORS.black)
  const [state, setState] = React.useState([
    {
      startDate: new Date(),
    },
  ])
  const [logop, setLogop] = useState(() => stylesContext.logo)
  // const getPlantilla = async () => {
  //   const response2 = await getLogo()
  //   setLogop(response2.data.url)
  // }
  // useEffect(() => {
  //   //  getPromos();
  //   getPlantilla()
  // }, [])
  return (
    <div>
      {/* <Header logop={logop} colors={stylesContext.black} nav={true}></Header> */}
      <br></br> <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br> <br></br>
      <div style={{ marginLeft: '0%' }}>
        <br></br>
        <Typography
          style={{
            marginLeft: '41%',
            fontWeight: 'bold',
            fontSize: '30px',
            color: stylesContext.black,
          }}
        >
          ¡Envia tu tarjeta!
        </Typography>
        <br></br>
        <br></br>
        <div style={{ marginLeft: '41%' }}>
          <Image
            preview={false}
            style={{ height: '20%', width: '20%' }}
            src={tarjetas.urls}
            fallback={'/img/IMAGEN_NO_DISPONIBLE.png'}
          />
          <br></br>
          <Typography style={{ display: 'flex', fontSize: '20px' }}>
            <Typography
              style={{
                fontWeight: 'bold',
                fontSize: '20px',
                color: stylesContext.black,
              }}
            >
              Tarjeta:
            </Typography>
            <Typography style={{ fontWeight: 'bold', fontSize: '20px' }}>
              {tarjetas.nombre}
            </Typography>
          </Typography>

          <Typography
            style={{ display: 'flex', fontWeight: 'bold', fontSize: '20px' }}
          >
            <Typography
              style={{
                fontSize: '20px',
                color: stylesContext.black,
                fontWeight: 'bold',
              }}
            >
              Monto:
            </Typography>
            {moneda.abreviatura === 'MXN' && (
              <div>
                <NumberFormat
                  decimalSeparator={'.'}
                  value={parseFloat(tarjetas.monto).toFixed(2)}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                  style={{ fontWeight: 'bold', fontSize: '20px' }}
                />{' '}
                &nbsp;MXN (Pieza)
              </div>
            )}
            {moneda.abreviatura === 'USD' && (
              <div>
                <NumberFormat
                  decimalSeparator={'.'}
                  value={parseFloat(
                    tarjetas.monto / context.getTipoCambio
                  ).toFixed(2)}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                  style={{ fontWeight: 'bold', fontSize: '20px' }}
                />{' '}
                &nbsp; USD (Pieza)
              </div>
            )}
          </Typography>
        </div>
      </div>
      <br></br>
      <form
        onSubmit={handleSubmit(TarjetaPost)}
        autoComplete='off'
        style={{ marginLeft: '500px' }}
      >
        <div>
          <GridList cols={2} cellHeight={80}>
            <GridListTile xs={8}>
              <TextField
                label='De (Tu nombre)'
                fullWidth
                required
                name='remitente'
                type='text'
                value={_Producto.remitente}
                onChange={handleText}
              />
            </GridListTile>
          </GridList>
          <GridList cols={2} cellHeight={80}>
            <GridListTile xs={8}>
              <TextField
                label='Para (email del destinatario)'
                fullWidth
                required
                name='destinatario'
                type='email'
                value={_Producto.destinatario}
                onChange={handleText}
              />
            </GridListTile>
          </GridList>
          <GridList cols={2} cellHeight={80}>
            <GridListTile xs={8}>
              <TextField
                label='Cantidad'
                fullWidth
                required
                name='cantidad'
                type='number'
                value={_Producto.cantidad}
                onChange={handleText}
              />
            </GridListTile>
          </GridList>

          <TextArea
            showCount
            placeholder='Mensaje'
            maxLength={400}
            allowClear
            rows={3}
            name='mensaje'
            enterButton
            value={_Producto.mensaje}
            onChange={handleText}
            style={{ width: '50%' }}
          />
        </div>

        <br></br>
        <br></br>
        <Segment
          basic
          style={{ padding: 0, marginLeft: '-5%', width: '100%' }}
          loading={loading}
        >
          <Typography
            style={{
              marginLeft: '15%',
              fontWeight: 'bold',
              fontSize: '20px',
              color: stylesContext.black,
            }}
          >
            Elige un método de pago
          </Typography>
          {moneda.abreviatura === 'MXN' && (
            <div>
              <Paypal
                setLoading={setLoading}
                id={_Producto.id}
                activo={_Producto.activo}
                destinatario={_Producto.destinatario}
                fecha={_Producto.fecha}
                remitente={_Producto.remitente}
                mensaje={_Producto.mensaje}
                cantidad={_Producto.cantidad}
                monto={tarjetas.monto}
                nombre={tarjetas.nombre}
                urls={tarjetas.urls}
                idClient={idClint}
                enviar={handleSubmit(TarjetaPost)}
                paypalID={id}
              ></Paypal>
            </div>
          )}
          {moneda.abreviatura === 'USD' && (
            <div>
              <Paypal
                setLoading={setLoading}
                id={_Producto.id}
                activo={_Producto.activo}
                destinatario={_Producto.destinatario}
                fecha={_Producto.fecha}
                remitente={_Producto.remitente}
                mensaje={_Producto.mensaje}
                cantidad={_Producto.cantidad}
                monto={Number(
                  (tarjetas.monto / context.getTipoCambio).toFixed(2)
                )}
                nombre={tarjetas.nombre}
                urls={tarjetas.urls}
                idClient={idClint}
                mone={moneda.abreviatura}
                enviar={handleSubmit(TarjetaPost)}
                paypalID={id}
              ></Paypal>
            </div>
          )}
        </Segment>
        <LoadingScreen open={isLoading} />
        <Button
          className={classes.imgs}
          onClick={() => {
            MercadoPago()
          }}
        >
          <img
            style={{ height: '70%', width: '70%', marginLeft: '29%' }}
            src='/img/MercadoPago.png'
          />
        </Button>
      </form>
      {/* <Footer AligmentText='left' /> */}
    </div>
  )
}

export default Plantillas
