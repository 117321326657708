import {
  SET_DETALLE_STATE,
  SET_SECTIONS_STATE,
  SET_GLOBAL_STATE,
  SET_NOTIFICACIONES_STATE,
  SET_MONEDA_STATE,
  SET_PAYMENT_METHODS_STATE,
  SET_ALERT,
  HIDE_ALERT,
} from './GlobalTypes'

// Acción para establecer el detalle
export const setDetalleState = (dispatch, data) => {
  dispatch({ type: SET_DETALLE_STATE, payload: data })
}

// Acción para establecer las secciones
export const setSectionsState = (dispatch, data) => {
  dispatch({ type: SET_SECTIONS_STATE, payload: data })
}

// Acción para establecer el estado global
export const setGlobalState = (dispatch, data) => {
  dispatch({ type: SET_GLOBAL_STATE, payload: data })
}

// Acción para establecer las notificaciones
export const setNotificacionesState = (dispatch, data) => {
  dispatch({ type: SET_NOTIFICACIONES_STATE, payload: data })
}

// Acción para establecer la moneda
export const setMonedaState = (dispatch, data) => {
  dispatch({ type: SET_MONEDA_STATE, payload: data })
}

// Acción para establecer el id del metodo de pago
export const setPaymentMethods = (dispatch, data) => {
  dispatch({ type: SET_PAYMENT_METHODS_STATE, payload: data })
}

// Acción para actualizar el mensaje de alerta
export const setAlert = (dispatch, message, type, active) => {
  dispatch({
    type: SET_ALERT,
    payload: {
      message,
      type,
      active,
    },
  })

  // Ocultar la alerta automáticamente
  setTimeout(() => {
    dispatch({ type: HIDE_ALERT })
  }, 3000) // Alerta visible por 3 segundos
}
