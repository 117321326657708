import React, { useContext, useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import Cards from '../Result/Mask/CardMobile'
import {
  getBySource,
  getFilteredUnidad,
  getFilteredMarca,
  getFilteredFicha,
} from '../../actions/resultActions'
import Context from '../../../src/store/Context'
import Filterbar from '../Navbar/Navbar'
import './Search_ResultStyle.css'
import GlobalContext from '../../contexts/GlobalContext'

export default function Products(props) {
  const { monedaState } = useContext(GlobalContext)
  const context = useContext(Context)
  const { search } = useParams()
  const { by } = useParams()

  const [fill, setFill] = useState(<h1 className='buscando'>Buscando...</h1>)

  const [moneda, setMoneda] = useState(() => monedaState)

  const [mainData, setMainData] = useState([])

  const childRef = useRef()

  const [nombre, setNombre] = useState('')
  const filterFicha = async seleccionado => {
    const response = await getFilteredFicha(seleccionado, mainData, search)
    setFill(<Cards items={response.data.productos} />)
    setMainData(response.data.productos)
  }
  const filterMarca = async seleccionado => {
    const response = await getFilteredMarca(mainData, seleccionado)
    setFill(<Cards items={response.data.productos} />)
    setMainData(response.data.productos)
  }
  const filterUnidad = async seleccionado => {
    const response = await getFilteredUnidad(seleccionado, mainData)
    setFill(<Cards items={response.data.productos} />)
    setMainData(response.data.productos)
  }
  const filterPrice = () => {
    let monedaC = moneda.abreviatura
    var aux = []

    var withFilter = mainData.filter(function (item) {
      if (item.rebaja === true) {
        item.preciod = item.precio
        item.precio = item.descuentoPrecio
      }
      if (item.activoDescuento === true) {
        item.preciod = item.precio
        item.precio = item.precio - (item.descuento / 100) * item.precio
      }
      if (monedaC === 'MXN') {
        item.precio = item.precio * context.getTipoCambio
      }

      if (
        item.precio >= context.filterv2.precio[0] &&
        item.precio <= context.filterv2.precio[1]
      ) {
        if (monedaC === 'MXN') {
          item.precio = item.precio / context.getTipoCambio
        }

        aux.push(item)
      } else {
        if (monedaC === 'MXN') {
          item.precio = item.precio / context.getTipoCambio
        }
      }

      return aux
    })
    withFilter = aux
    console.log(withFilter)
    setFill(<Cards items={withFilter} />)
    console.log(monedaC)
  }
  useEffect(() => {
    setMainData([])
    context.clearProductFilter()
    setFill(<h1 className='buscando'>Buscando...</h1>)
    const buscarProductos = async () => {
      const response = await getBySource({ source: by, filtro: search })
      var withFilter
      if (search !== 'ran') {
        response.data.filtros.map(i => {
          context.addProductFilter({
            _id: i.idCategoria,
            name: i.nombreCategoria,
          })
        })
        context.filterv2.pathCategory =
          by === 'cat' ? response.data.treeCategories : []
        context.filterv2.categoriaId = by === 'cat' ? search : 1
        context.addTipoCambio(response.data.tipoCambio)
        if (by === 'cat') {
          setNombre(response.data.productos[0].categoria)
        }
        if (by === 'all') {
          setNombre('Todos')
        }
        setMainData(response.data.productos)
        withFilter = response.data.productos.filter(function (item) {
          return (
            (context.filterv2.precio[1] > 20000
              ? item.precio >= context.filterv2.precio[0]
              : item.precio >= context.filterv2.precio[0] &&
                item.precio <= context.filterv2.precio[1]) &&
            (context.filterv2.medidaId.length === 0 &&
            context.filterv2.tipoAceroId.length === 0 &&
            context.filterv2.espesorId.length === 0
              ? item === item
              : context.filterv2.medidaId
                  .map(k => k.idGeneral)
                  .includes(item.medidaId) ||
                context.filterv2.tipoAceroId
                  .map(k => k.idGeneral)
                  .includes(item.tipoAceroId) ||
                context.filterv2.espesorId
                  .map(k => k.idGeneral)
                  .includes(item.espesorId))
          )
        })
      } else {
        const response = await getBySource({ source: 'ran', filtro: by })
        withFilter = response.data.productos
        setNombre(response.data.nombreColecion)
        setMainData(response.data.productos)
      }

      setFill(<Cards items={withFilter} nombres={nombre} />)
    }
    buscarProductos()
  }, [props.location.pathname])

  const [Filters, setFilters] = useState({
    continents: [],
    price: 1,
  })

  const handleFilters = (filters, category) => {
    const newFilters = { ...Filters }

    newFilters[category] = filters
    setFilters(newFilters)

    const nf = newFilters.continents
    const price = newFilters.continents.price
    console.log(price)

    const stringData = nf.continents.reduce((result, item) => {
      return `${result}${item}.`
    }, '')

    if (stringData == '') {
      if (price === 1) {
        setFill(<Cards items={mainData} />)
      } else if (price === 2) {
        var withFilter = mainData.filter(function (item) {
          return item.precio <= 10000
        })
        setFill(<Cards items={withFilter} />)
      } else if (price === 3) {
        var withFilter = mainData.filter(function (item) {
          return item.precio <= 15000 && item.precio > 10000
        })
        setFill(<Cards items={withFilter} />)
      } else if (price === 4) {
        var withFilter = mainData.filter(function (item) {
          return item.precio >= 20000
        })
        setFill(<Cards items={withFilter} />)
      }
    } else {
      var withFilter = mainData.filter(function (item) {
        return stringData.includes(item.categoriaId)
      })

      if (price === 1) {
        setFill(<Cards items={withFilter} />)
      } else if (price === 2) {
        var withFilterS = withFilter.filter(function (item) {
          return item.precio <= 10000
        })
        setFill(<Cards items={withFilterS} />)
      } else if (price === 3) {
        var withFilterS = withFilter.filter(function (item) {
          return item.precio <= 15000 && item.precio > 10000
        })
        setFill(<Cards items={withFilterS} />)
      } else if (price === 4) {
        var withFilterS = withFilter.filter(function (item) {
          return item.precio >= 20000
        })
        setFill(<Cards items={withFilterS} />)
      }
    }

    childRef.current.getAlert()
  }

  return (
    <div className='desk-banner'>
      <div className='products'>
        <Filterbar
          nombres={nombre}
          ref={childRef}
          handleFilters={filters => handleFilters(filters, 'continents')}
          filterPrice={filterPrice}
          filterFicha={filterFicha}
          filterMarca={filterMarca}
          filterUnidad={filterUnidad}
          categoria={by}
          prods={mainData}
          setMain={setMainData}
          set={setFill}
        />
        {fill}
      </div>
    </div>
  )
}
