import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'
import axios from 'axios'
import React from 'react'
import pedidoActions from '../../../actions/pedidoActions'
import { alerts } from '../../../services/alerts'
import Typography from '@material-ui/core/Typography'

const BotonConsultarFactura = ({ pedido, getDetalle }) => {
  let varequis = pedido

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div
      style={{ display: 'inline-block', marginLeft: '10px', float: 'center' }}
    >
      <Button
        size='small'
        style={{ float: 'center', backgroundColor: 'blue', color: 'white' }}
        variant='outlined'
        color='primary'
        onClick={handleClickOpen}
      >
        Factura Solicitada
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        {/* <DialogTitle id="alert-dialog-title">
            {"Use Google's location service?"}
          </DialogTitle> */}
        <DialogContent>
          <li
            className='close-icon'
            style={{ marginBottom: '10px', textAlign: 'right' }}
            onClick={handleClose}
          >
            ✕
          </li>
          <DialogContentText id='alert-dialog-description'>
            <Typography variant='body' component='p'>
              Razón social: {pedido.pedidoDatosFacturacion.razonSocial}
            </Typography>
            <Typography variant='body' component='p'>
              RFC: {pedido.pedidoDatosFacturacion.rfc}
            </Typography>
            <Typography variant='body' component='p'>
              CP: {pedido.pedidoDatosFacturacion.codigoPostal}
            </Typography>
            <Typography variant='body' component='p'>
              Domicilio fiscal: {pedido.pedidoDatosFacturacion.domicilioFiscal}
            </Typography>
            <Typography variant='body' component='p'>
              Uso del CFDI: {pedido.pedidoDatosFacturacion.usoCFDI}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary' autoFocus>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default BotonConsultarFactura
