import React, { useContext, useEffect, useState } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import Direccion from '../Direccion/Direccion'
import DireccionCard from '../Direccion/DireccionCard'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import AuthCliente from '../../services/AuthCliente'
import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  FormControlLabel,
  Switch,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import Swal from 'sweetalert2'
import errores from '../../services/errores'
import TextField from '@material-ui/core/TextField'
import { useFormik } from 'formik'
import ApiGeneral from '../../services/ApiGeneral'
import UserContext from '../../contexts/user/UserContext'
import * as yup from 'yup'
import pedidoActions from '../../actions/pedidoActions'

import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(() => ({
  btncancelar: {
    background: '#eeeeee',
    color: '#003462',
    backgroundColor: '#eeeeee!important',
  },
  formContro: {
    minWidth: '531px',
  },
  btnsubnmit: {
    background: '#003462',
    color: '#ffff',
    backgroundColor: '#003462!important',
  },
  DiAction: {
    paddingRight: '2rem',
  },
  error: {
    color: '#ff0500',
  },
}))

const DesactivarColaborador = ({
  handleCloseActiveCancel,
  getMisDatos,
  activoC,
  colaborador,
  handleCloseActivo,
}) => {
  const { UserState } = useContext(UserContext)

  const colab = colaborador

  const [selecteds] = React.useState({
    Pais: '',
    Estado: '',
    //Municipio:direccion.municipio,
    //Colonia: direccion.colonia,
  })
  const classes = useStyles()

  const [open, setOpen] = React.useState(true)
  const [tipoCfdi, setTipoCfdi] = React.useState([])
  const [FormaPago, setFormaPago] = React.useState([])
  const [MetodoPago, setMetodoPago] = React.useState([])
  const [rfcVerified, setRfcVerified] = React.useState(false)
  const [direcciones, getClienteDirecciones] = useState([])
  // const [colaborador, getColaboradorId] = useState([]);
  const [currentDireccion, setCurrentDireccion] = React.useState(null)
  const [openDirecciones, setOpenDirecciones] = React.useState(false)

  const formik = useFormik({
    initialValues: {
      idClienteColaborador: colaborador.idClienteColaborador,
      clienteId: colaborador.clienteId,
      clienteDireccionId: colaborador.clienteDireccionId,
      nombre: colaborador.nombre,
      aPaterno: colaborador.aPaterno,
      aMaterno: colaborador.aMaterno,
      email: colaborador.email,
      telefono: colaborador.telefono,
      activo: activoC,
      clienteDireccion: null,
      usuarioCreoId: colaborador.clienteId,
      usuarioModId: colaborador.clienteId,
    },
    onSubmit: values => {
      ApiGeneral.editeColaborador(values)
        .then(r => {
          getMisDatos()
          handleClose()
          //  handleCloseActiveCancel();
          handleCloseActivo()
          Swal.fire('Los datos se han actualizado con éxito.', '', 'success')
          console.info('Los datos se han actualizado con éxito', r)
        })
        .catch(e => {
          handleClose()
          // handleCloseActiveCancel();
          handleCloseActivo()
          Swal.fire(errores.handleError('error'), '', 'error')
        })
    },
  })

  useEffect(() => {}, [])

  const handleClose = () => {
    //setCP("");

    //  AsignarDatos(null);
    setOpen(false)
    handleCloseActiveCancel()
    getMisDatos()

    // setColonia([])
  }
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {activoC ? (
          <Typography variant='body' component='p'>
            ¿Estás seguro que deseas activar al cliente {colaborador.nombre}{' '}
            {colaborador.aPaterno}?
          </Typography>
        ) : (
          <Typography variant='body' component='p'>
            ¿Estás seguro que deseas desactivar al cliente {colaborador.nombre}{' '}
            {colaborador.aPaterno}?
          </Typography>
        )}

        <div style={{ textAlign: 'right', marginTop: '20px' }}>
          <Button
            autoFocus
            onClick={handleCloseActiveCancel}
            variant='outlined'
            className={classes.btncancelar}
          >
            NO
          </Button>
          <Button
            style={{ marginLeft: '10px' }}
            type='submit'
            variant='contained'
            className={classes.btnsubnmit}
            value='Registrar'
            color='primary'
          >
            SI
          </Button>
        </div>
      </form>
    </>
  )
}

export default DesactivarColaborador
