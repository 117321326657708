import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import Swal from 'sweetalert2'
import DeleteIcon from '@material-ui/icons/Delete'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import Base from './../../services/Base'
import { makeStyles } from '@material-ui/core/styles'
import { Beenhere, LocalShipping } from '@material-ui/icons'
import { Image } from 'antd'
import Check from '@material-ui/icons/Check'
import SettingsIcon from '@material-ui/icons/Settings'
import clsx from 'clsx'
import { getUrlsForProductImgWish } from '../../utils/ima'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { searchgetWishList, editWishList } from '../../actions/resultActions'
import Context from '../../store/Context'
import useWindowSize from '../../utils/useWindowSize'
import UserContext from '../../contexts/user/UserContext'
import '../Pedidos/Styles.css'
import PixelR from '../GoogleAnalitycs/PixelR'
import GlobalContext from '../../contexts/GlobalContext'

import { getProductsWish } from '../../services/almacenService'

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
})

const getImgVarS = (rutaUbicacion, id, extension) => {
  const urlImg = Base.baseFiles.imgProduct
  const val = urlImg + '/' + rutaUbicacion + '/' + id + extension
  return val
}

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles()
  const { active, completed } = props

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  )
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
}

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: '#1c476d',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundColor: '#1c476d',
  },
})

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles()
  const { active, completed } = props

  const icons = {
    1: <SettingsIcon className='pedido-detalle-icon' />,
    2: <LocalShipping className='pedido-detalle-icon' />,
    3: <Beenhere className='pedido-detalle-icon' />,
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  )
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  list: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  table: {
    minWidth: 900,
  },
  card: {
    height: '85%',
    marginTop: '10px',
    textAlign: 'center',
    borderRadius: '10px',
  },
  titulo: {
    color: '#000',
  },
}))

export default function Pedidos() {
  const { UserState } = useContext(UserContext)
  const context = useContext(Context)
  const { monedaState } = useContext(GlobalContext)
  const { responsiveSize } = useWindowSize()
  const history = useHistory()

  const classes = useStyles()
  const [pedidos, setPedidos] = useState(null)
  const [moneda, setMoneda] = useState(() => monedaState)

  const wishEliminar = async (id, eliminar) => {
    if (eliminar === 'carrito')
      Swal.fire('El producto fue agregado al carrito', '', 'success')
    else {
      Swal.fire('El producto fue eliminado ', '', 'success')
    }
    const response = await searchgetWishList(UserState.idCliente, id)
    var MyObject = {
      idWishList: response.data.idWishList,
      activo: true,
    }

    const editar = await editWishList(MyObject)
    console.log(editar.data)
  }

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const signal = controller.signal

    getProductsWish(signal, UserState.idCliente)
      .then(data => {
        if (isMounted) {
          setPedidos(data)
        }
      })
      .catch(error => {
        if (isMounted) {
          console.error('error fetching wishlist', error)
        }
      })

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])

  if (!pedidos) return <div>Cargando...</div>

  return (
    <div className={classes.root}>
      <label>Mis deseos</label>
      <PixelR nombres={'Mis deseos'} />
      <TableContainer
        style={{ boxShadow: 'none', overflowX: 'unset' }}
        component={Paper}
      >
        <Table className={classes.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell align='right' colSpan={8}></TableCell>
            </TableRow>
            <TableRow
              style={{
                borderLeft: '1px solid #e0e0e0',
                borderRight: '1px solid #e0e0e0',
              }}
            >
              <TableCell
                align='left'
                style={{ pagging: '8px 16px', width: '25%' }}
              >
                Producto
              </TableCell>
              <TableCell
                align='center'
                style={{ pagging: '8px 16px', width: '15%' }}
              >
                Familia
              </TableCell>
              <TableCell
                align='center'
                style={{ pagging: '8px 16px', width: '10%' }}
              >
                Piezas disponibles
              </TableCell>
              <TableCell
                align='center'
                style={{ pagging: '8px 16px', width: '10%' }}
              >
                Precio
              </TableCell>

              <TableCell
                align='center'
                style={{ pagging: '8px 16px', width: '10%' }}
              >
                Ver
              </TableCell>
              <TableCell
                align='center'
                style={{ pagging: '8px 16px', width: '10%' }}
              >
                Agregar al carrito
              </TableCell>
              <TableCell
                align='center'
                style={{ pagging: '8px 16px', width: '10%' }}
              >
                Eliminar
              </TableCell>
              <TableCell
                align='center'
                style={{ pagging: '8px 16px', width: '10%' }}
              >
                {''}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pedidos &&
              pedidos.map((p, ind) => (
                <TableRow
                  key={ind}
                  style={{
                    borderLeft: '1px solid #e0e0e0',
                    borderRight: '1px solid #e0e0e0',
                  }}
                >
                  <TableCell align='left'>
                    <Typography className={classes.titulo} gutterBottom>
                      {p.nombre}
                    </Typography>
                  </TableCell>
                  <TableCell align='center'>
                    <Typography className={classes.titulo} gutterBottom>
                      {p.categoria}
                    </Typography>
                  </TableCell>
                  <TableCell align='center'>
                    <Typography className={classes.titulo} gutterBottom>
                      {p.inventario}
                    </Typography>
                  </TableCell>
                  <TableCell align='center'>
                    {p.productoPrecio.map((precio, index) => (
                      <div>
                        {moneda.abreviatura === 'USD' && index === 0 && (
                          <Typography className={classes.titulo} gutterBottom>
                            {precio.precio}
                          </Typography>
                        )}
                        {moneda.abreviatura === 'MXN' && index === 0 && (
                          <Typography className={classes.titulo} gutterBottom>
                            {(precio.precio * context.getTipoCambio).toFixed(2)}
                          </Typography>
                        )}
                      </div>
                    ))}
                  </TableCell>
                  <TableCell align='center'>
                    <Button
                      size='small'
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        history.push('/products/' + p.idProducto + '/p')
                      }}
                    >
                      Ir
                    </Button>
                  </TableCell>
                  <TableCell align='center'>
                    {p.productoPrecio.map((precio, index) => (
                      <div>
                        {index === 0 && (
                          <div>
                            <Button
                              size='small'
                              variant='contained'
                              color='primary'
                              onClick={() => {
                                context.addProductToCart({
                                  _id: p.idProducto,

                                  price: precio.precio * context.getTipoCambio,
                                  images:
                                    getUrlsForProductImgWish(
                                      p.responseProducto,
                                      ind
                                    )[0] == undefined
                                      ? '/img/IMAGEN_NO_DISPONIBLE.png'
                                      : getUrlsForProductImgWish(
                                          p.responseProducto,
                                          ind
                                        )[0],
                                  title: p.nombre.replaceAll(`"`, `''`),
                                  peso: p.peso,
                                  familiaId: p.categoriaId,
                                  infoAdicional: null,
                                  maxQuantity: p.inventario,
                                  tipoCambio: context.getTipoCambio,
                                  moned: moneda.abreviatura,
                                  wishList: false,
                                  pedidos: true,
                                })
                              }}
                            >
                              <ShoppingCartIcon className='pedido-detalle-icon' />
                            </Button>
                          </div>
                        )}
                      </div>
                    ))}
                  </TableCell>
                  <TableCell align='center'>
                    <Button
                      size='small'
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        wishEliminar(p.idProducto, 'eliminar')
                      }}
                    >
                      <DeleteIcon className='pedido-detalle-icon' />
                    </Button>
                  </TableCell>
                  <TableCell align='center'>
                    {p.responseProducto.map((producto, index) => (
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        {ind === index && (
                          <Image
                            preview={false}
                            style={{
                              height: '50px',
                              width: 'auto',
                            }}
                            src={getImgVarS(
                              producto.rutaUbicacion,
                              producto.id,
                              producto.extension
                            )}
                            fallback={'/img/IMAGEN_NO_DISPONIBLE.png'}
                          />
                        )}
                      </div>
                    ))}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
