// import { COLORS } from '../../Views/Colores'
// import useWindowSize from './../../utils/useWindowSize'

import {
  // getKeyPay,
  // getEle,
  // getMercadoPK,
  // getLogo,
  // getDetalle,
  // getBanerT,
  getCarrusel,
} from '../../actions/resultActions'
import React, { useContext, useEffect, useState, Suspense } from 'react'
import './plantillas.css'
import { Link } from 'react-router-dom'
import Carousel from 'react-multi-carousel'
// import Card from 'react-bootstrap/Card'
export default function HomeTest(props) {
  const [banners, setBanners] = useState([])

  useEffect(() => {
    //  getPromos();
    BannersGet()
  }, [])
  const BannersGet = async () => {
    const carrusels = await getCarrusel(props.id)
    setBanners(carrusels.data)
  }
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1336 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }
  return (
    <div>
      <section className='bestSellers'>
        <div className='container'>
          <div className='row justify-content-center'>
            {banners.length > 0 && (
              <h2 className='text-center'>{banners[0].titulo}</h2>
            )}
          </div>

          <Carousel responsive={responsive}>
            {banners.map((object, index) => (
              <div className='imgs'>
                <Link to={`/products/${object.producto}/p`}>
                  <img src={object.imagen} />
                </Link>
              </div>
            ))}
          </Carousel>
        </div>
      </section>
    </div>
  )
}
