import React, { useContext, useEffect, useState } from 'react'
// import Mask from '../Result/Mask/integrinoxoriginal.css'
// import {
//   getUrlForProductImg,
//   getFilesDownload,
//   getUrlsForImgPlantilla,
// } from '../../utils/ima'
// import { Link, useParams } from 'react-router-dom'
// import ApiGeneral from '../../services/ApiGeneral'
// import { useHistory } from 'react-router-dom'
import {
  makeStyles,
  // Button,
  // Dialog,
  // DialogActions,
  // DialogContent,
  // DialogTitle,
  // Divider,
  // FormControl,
  // GridList,
  // GridListTile,
  // IconButton,
  // InputLabel,
  // Select,
  // TextField,
  // Tooltip,
  Typography,
} from '@material-ui/core'
import {
  getTarjetasId,
  PostTarjetas,
  getTarjetasRegaloId,
  UpdateTarjetas,
} from '../Result/Mask/catalogorequest'
// import CardTarjeta from '../Result/Mask/CardTarjeta'
// import { COLORS } from '../../Views/Colores'
import StylesContext from '../../contexts/styles/stylesContext'
// import { getKeyPay, getMercadoPK, getLogo } from '../../actions/resultActions'

import { Input } from 'antd'
import { Image } from 'antd'
import Context from '../../store/Context'
// import { getMoneda } from '../../components/Result/Mask/catalogorequest'
import { useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
// import Moment from 'moment'
import 'moment/locale/es'
import GlobalContext from '../../contexts/GlobalContext'

// const Header = React.lazy(() =>
//   import('../../components/Header/HeaderResponsive')
// )
// const Footer = React.lazy(() => import('../../Views/Footer/FooterMenu'))

const useStyles2 = makeStyles(() => ({
  header: {
    backgroundColor: '#000',
    paddingLeft: '70px',
    zIndex: 999,
    position: 'inherit',
    '@media (max-width: 900px)': {
      paddingLeft: 1,
    },
  },
  logo: {
    width: '20%',

    display: 'flex',
  },
  menuButton: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 250,
    size: '14px',
    marginLeft: '5px',
    color: '#003462',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  drawerContainer: {
    padding: '20px 30px',
  },
  button: {},
  textfildMargin: {
    width: '17rem',
    height: '9px !important',
    paddingbottom: '2.5px',
  },
  btnRegis: {
    width: '19rem',
  },
  gridAlings: {
    textAlign: 'center',
    padding: '1rem',
  },
  btnSocial: {
    left: '-500px',
  },
}))
const { TextArea } = Input
const Plantillas = ({}) => {
  const { monedaState } = useContext(GlobalContext)
  const stylesContext = useContext(StylesContext)

  const classes = useStyles2()
  const context = useContext(Context)
  const [tarjetas, setTarjetas] = useState([])
  const [tarjetasEnviada, setTarjetasEnviada] = useState([])
  const TarjetaGet = async () => {
    var pathname = window.location.pathname
    var cadenas = pathname.split('/')
    //getTarjetasRegaloId

    const responses = await getTarjetasId(cadenas[2])
    setTarjetas(responses.data)

    const responses2 = await getTarjetasRegaloId(cadenas[3])
    setTarjetasEnviada(responses2.data)

    _Producto.id = cadenas[2]
    const valores = window.location.search
    const urlParams = new URLSearchParams(valores)
    var producto = urlParams.get('status')
    console.log(producto)
    if (producto === 'approved' || producto === 'pending') {
      _Producto.id = cadenas[2]
      _Producto.idCompra = cadenas[3]
      _Producto.activo = true
      _Producto.cantidad = responses2.data.cantidad
      _Producto.destinatario = responses2.data.destinatario
      _Producto.fecha = responses2.data.fecha
      _Producto.mensaje = responses2.data.mensaje
      _Producto.remitente = responses2.data.remitente
      _Producto.montoActual = responses.data.montoActual
      _Producto.nombre = responses.data.nombre
      _Producto.urls = responses.data.urls
      const resp = await UpdateTarjetas(_Producto)
      console.log(resp)
    }
  }
  const methods = useForm()

  const { handleSubmit } = methods
  const TarjetaPost = async () => {
    const response = await PostTarjetas(_Producto)
    console.log(response)
  }
  const [moneda, setMoneda] = useState(() => monedaState)

  // const getMonedas = async () => {
  //   const moned = await getMoneda()
  //   window.localStorage.setItem('monedasup', moned.data.abreviatura)

  //   setMoneda(moned.data)
  //   window.localStorage.setItem('monedas', moned.data.abreviatura)

  //   console.log(moned)
  // }

  // useEffect(() => {
  //   //  getPromos();
  //   getMonedas()
  // }, [])

  useEffect(() => {
    TarjetaGet()
  }, [])

  const [logop, setLogop] = useState(() => stylesContext.logo)
  // const getPlantilla = async () => {
  //   const response2 = await getLogo()
  //   setLogop(response2.data.url)
  // }
  // useEffect(() => {
  //   //  getPromos();
  //   getPlantilla()
  // }, [])
  const [_Producto, setProducto] = React.useState({
    id: '',
    activo: true,
    destinatario: '',
    remitente: '',
    mensaje: '',
    fecha: '',
    cantidad: 1,
    montoActual: 0,
    urls: '',
    nombre: '',
  })
  const handleText = event => {
    setProducto({ ..._Producto, [event.target.name]: event.target.value })
  }
  // document.documentElement.style.setProperty('--color-principal', COLORS.black)
  const [state, setState] = React.useState([
    {
      startDate: new Date(),
    },
  ])

  return (
    <div>
      {/* <Header logop={logop} colors={stylesContext.black} nav={true}></Header> */}
      <br></br> <br></br> <br></br> <br></br> <br></br> <br></br> <br></br>
      <Typography
        style={{
          marginLeft: '630px',
          fontWeight: 'bold',
          fontSize: '30px',
          color: stylesContext.black,
        }}
      >
        ¡Tarjeta Enviada!
      </Typography>
      <br></br>
      <br></br>
      <div>
        <Image
          preview={false}
          style={{ height: '20%', width: '20%', marginLeft: '600px' }}
          src={tarjetas.urls}
          fallback={'/img/IMAGEN_NO_DISPONIBLE.png'}
        />
        <br></br>
        <Typography
          style={{ marginLeft: '600px', display: 'flex', fontSize: '20px' }}
        >
          <Typography
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
              color: stylesContext.black,
            }}
          >
            Tarjeta:
          </Typography>
          <Typography
            style={{ marginLeft: '5px', fontWeight: 'bold', fontSize: '20px' }}
          >
            {tarjetas.nombre}
          </Typography>
        </Typography>
        <Typography
          style={{ marginLeft: '600px', display: 'flex', fontSize: '20px' }}
        >
          <Typography
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
              color: stylesContext.black,
            }}
          >
            Enviado a:
          </Typography>
          <Typography
            style={{ marginLeft: '5px', fontWeight: 'bold', fontSize: '20px' }}
          >
            {tarjetasEnviada.destinatario}
          </Typography>
        </Typography>
        <Typography
          style={{ marginLeft: '600px', display: 'flex', fontSize: '20px' }}
        >
          <Typography
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
              color: stylesContext.black,
            }}
          >
            Enviado por:
          </Typography>
          <Typography
            style={{ marginLeft: '5px', fontWeight: 'bold', fontSize: '20px' }}
          >
            {tarjetasEnviada.remitente}
          </Typography>
        </Typography>
        <Typography
          style={{ marginLeft: '600px', display: 'flex', fontSize: '20px' }}
        >
          <Typography
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
              color: stylesContext.black,
            }}
          >
            Mensaje:
          </Typography>
          <Typography
            style={{ marginLeft: '5px', fontWeight: 'bold', fontSize: '20px' }}
          >
            {tarjetasEnviada.mensaje}
          </Typography>
        </Typography>
        <Typography
          style={{ marginLeft: '600px', display: 'flex', fontSize: '20px' }}
        >
          <Typography
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
              color: stylesContext.black,
            }}
          >
            Fecha:
          </Typography>
          <Typography
            style={{ marginLeft: '5px', fontWeight: 'bold', fontSize: '20px' }}
          >
            {tarjetasEnviada.fecha}
          </Typography>
        </Typography>
        <Typography
          style={{
            marginLeft: '500px',
            display: 'flex',
            fontWeight: 'bold',
            fontSize: '20px',
          }}
        >
          <Typography
            style={{
              marginLeft: '100px',
              fontSize: '20px',
              color: stylesContext.black,
              fontWeight: 'bold',
            }}
          >
            Monto:
          </Typography>
          {moneda.abreviatura === 'USD' && (
            <div>
              <NumberFormat
                decimalSeparator={'.'}
                value={parseFloat(
                  tarjetas.monto / context.getTipoCambio
                ).toFixed(2)}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                style={{
                  marginLeft: '8px',
                  fontWeight: 'bold',
                  fontSize: '20px',
                }}
              />{' '}
              &nbsp; USD (Pieza)
            </div>
          )}
          {moneda.abreviatura === 'MXN' && (
            <div>
              <NumberFormat
                decimalSeparator={'.'}
                value={parseFloat(tarjetas.monto).toFixed(2)}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                style={{
                  marginLeft: '8px',
                  fontWeight: 'bold',
                  fontSize: '20px',
                }}
              />{' '}
              &nbsp; MXN (Pieza)
            </div>
          )}
        </Typography>
      </div>
      <br></br>
      {/* <Footer AligmentText='left' /> */}
    </div>
  )
}

export default Plantillas
