import axios from 'axios'
import { alerts } from '../services/alerts'
import Base from '../services/Base'
import { nombre } from '../services/Url'

axios.interceptors.request.use(
  config => {
    const tokenCustom = localStorage.getItem('tokenCustom')
    if (tokenCustom)
      config.headers = {
        Authorization: `Bearer ${tokenCustom}`,
        nombres: nombre.tienda,
      }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(undefined, async error => {
  if (error.message === 'Network Error' && !error.response) {
    alerts.error('Error de conexión')
    throw error.response
  }

  const { status, headers } = error?.response

  if (
    error.response.request.responseType === 'blob' &&
    error.response.status !== 403
  ) {
    const text = await new Response(error.response.data).text()
    const json = JSON.parse(text)
    error.response.data = json
  }

  // if (status === 500) {
  //   alerts.error("Ha ocurrido un error en el servidor");
  //   console.log(error.response);
  // }

  if (!headers['www-authenticate']?.includes('The token expired'))
    throw error.response
})

const responseBody = response => response?.data

const actions = {
  get: (base, url) =>
    axios.get(url, { baseURL: Base.baseUrls[base] }).then(responseBody),

  getBody: (base, url, body) =>
    axios.post(url, body, { baseURL: Base.baseUrls[base] }).then(responseBody),
  post: (base, url, body) =>
    axios
      .post(url, body ?? {}, { baseURL: Base.baseUrls[base] })

      .then(responseBody),
  postForm: (base, url, formData) =>
    axios
      .post(url, formData, {
        baseURL: Base.baseUrls[base],
        headers: {
          'Content-type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then(responseBody),
  put: (base, url, body) =>
    axios
      .put(url, body ?? {}, { baseURL: Base.baseUrls[base] })
      .then(responseBody),
  putForm: (base, url, formData) =>
    axios
      .put(url, formData, {
        baseURL: Base.baseUrls[base],
        headers: { 'Content-type': 'multipart/form-data' },
      })
      .then(responseBody),
  delete: (base, url) =>
    axios.delete(url, { baseURL: Base.baseUrls[base] }).then(responseBody),
  download: (base, url, name, body) =>
    axios
      .post(url, body, {
        baseURL: Base.baseUrls[base],
        responseType: 'blob',
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
      }),
}

export default actions
