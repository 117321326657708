import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import useWindowSize from '../../utils/useWindowSize'
import Box from '@material-ui/core/Box'
import './estrellas.css'

const labels = {
  0: '0 Estrellas',
  0.5: 'Malo',
  1: 'Malo',
  1.5: 'Regular',
  2: 'Regular',
  2.5: 'Bueno',
  3: 'Bueno',
  3.5: 'Muy Bueno',
  4: 'Muy Bueno',
  4.5: 'Excelente',
  5: 'Excelente',
  6: 'Inicia Sesión',
}

const useStyles = makeStyles({
  root: {
    width: 400,
    display: 'flex',
    alignItems: 'center',
    marginLeft: 0,
  },
  tooltip: {
    maxWidth: 350,
    fontSize: 16,
    border: '1px solid #dadde9',
  },
})

export default function HoverRating(props) {
  const { responsiveSize } = useWindowSize()
  const [value, setValue] = React.useState(props.calificacion)
  const [hover, setHover] = React.useState(props.calificacion)
  const classes = useStyles()

  return (
    <div>
      {responsiveSize === 'sm' ? (
        <div>
          <div className={classes.root} style={{ marginLeft: '5%' }}></div>
          {value !== null && (
            <Box ml={2}>
              {hover === 6 && (
                <span
                  style={{
                    color: props.pd,
                    fontWeight: 'bold',
                    marginTop: '5%',
                    marginRight: '30%',
                  }}
                >
                  {labels[hover !== -1 ? hover : value]}
                </span>
              )}
              {hover !== 6 && (
                <span
                  style={{
                    fontSize: '12px',
                    marginTop: '5%',
                    marginRight: '30%',
                  }}
                >
                  {labels[hover !== -1 ? hover : value]}
                </span>
              )}
            </Box>
          )}
        </div>
      ) : (
        <div className={classes.root} style={{ marginLeft: props.ml }}>
          {value !== null && (
            <Box ml={2}>
              {hover === 6 && (
                <span
                  className='puntuacion'
                  style={{ color: props.pd, fontWeight: 'bold' }}
                >
                  {labels[hover !== -1 ? hover : value]}
                </span>
              )}
              {hover !== 6 && (
                <span className='puntuacion'>
                  {labels[hover !== -1 ? hover : value]}
                </span>
              )}
            </Box>
          )}
        </div>
      )}
    </div>
  )
}
