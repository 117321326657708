import React, { useContext, useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Swal from 'sweetalert2'
import {
  Button,
  TextField,
  DialogContent,
  DialogActions,
} from '@material-ui/core'

import {
  verifyCode,
  resendVerificationCode,
} from '../../services/catalogoService'

const Container = styled.div`
  width: 100%;
  min-heigth: 360px;
  margin: 0 auto;

  /* Pantallas grandes (Desktop) */
  @media (min-width: 1024px) {
    max-width: 480px;
  }

  /* Tablets */
  @media (min-width: 768px) and (max-width: 1023px) {
    max-width: 50vw;
  }

  /* Tamaño intermedio entre móvil y tablet */
  @media (min-width: 600px) and (max-width: 767px) {
    max-width: 70vw;
  }

  /* Móviles */
  @media (max-width: 599px) {
    max-width: 100%;
  }
`

const Form = styled.form`
  width: 100%;
  padding: 20px;
  border-radius: 0.6rem;
  background-color: #f5f5f5;

  /* Pantallas grandes (Desktop) */
  @media (min-width: 1024px) {
    border: 2px solid #f2f2f2;
  }

  /* Tablets */
  @media (min-width: 768px) and (max-width: 1023px) {
    border: 2px solid #f2f2f2;
  }

  /* Tamaño intermedio entre móvil y tablet */
  @media (min-width: 600px) and (max-width: 767px) {
    border: 1px solid #e0e0e0;
  }

  /* Móviles */
  @media (max-width: 599px) {
    border: none;
    background-color: #ffffff;
  }
`

const Title = styled.div`
  text-align: center;
  font-size: 32px;
  font-weight: 600;
`

const StyledDialogActions = styled(DialogActions)`
  padding-right: 2rem;
`

const VerifyCode = () => {
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(false)

  // Recuperar el correo electrónico desde los parámetros de la URL
  const query = new URLSearchParams(useLocation().search)
  const emailFromUrl = query.get('email')
  const codeFromUrl = query.get('code')

  const formik = useFormik({
    initialValues: {
      email: emailFromUrl || '',
      password: codeFromUrl || '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Correo inválido').required('Correo requerido'),
      verificationCode: Yup.string().required(
        'El código de verificación es requerido'
      ),
    }),
    onSubmit: async values => {
      setIsLoading(true)
      try {
        const response = await verifyCode({
          email: values.email,
          codigoVerificacion: values.verificationCode,
        })

        if (response.success) {
          // Mostrar mensaje de éxito con SweetAlert2 y redirigir al inicio de sesión
          Swal.fire({
            icon: 'success',
            title: '¡Verificación exitosa!',
            text: 'Tu cuenta ha sido activada. Ahora puedes iniciar sesión.',
            confirmButtonText: 'Ir a Iniciar Sesión',
            allowOutsideClick: false,
          }).then(() => {
            // Redirigir al usuario a la página de inicio de sesión
            // TODO: hacer login automaticamente
            history.push('/signin')
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: '¡Oh no! Algo salió mal',
            text: response.message,
            confirmButtonText: 'Aceptar',
            allowOutsideClick: false,
          })
        }
      } catch (error) {
        if (error.data) {
          const { success, message, statusCode, errorCode, additionalInfo } =
            error?.data

          switch (statusCode) {
            case 'ClienteNoExistente':
              Swal.fire({
                icon: 'error',
                title: 'Correo no encontrado',
                text: 'No se encontró un registro para el correo proporcionado. Por favor, verifica e intenta de nuevo.',
                confirmButtonText: 'Aceptar',
              })
              break

            case 'CodigoVerificacionInvalido':
              Swal.fire({
                icon: 'error',
                title: 'Código incorrecto',
                text: 'El código de verificación ingresado es incorrecto. Por favor, verifica e intenta nuevamente.',
                confirmButtonText: 'Aceptar',
              })
              break

            case 'CodigoVerificacionExpirado':
              Swal.fire({
                icon: 'warning',
                title: 'Código expirado',
                text: 'El código de verificación ha expirado. ¿Deseas solicitar uno nuevo?',
                confirmButtonText: 'Reenviar Código',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                allowOutsideClick: false,
              }).then(async result => {
                if (result.isConfirmed) {
                  // Llamar a la función para reenviar el código
                  setIsLoading(true)
                  try {
                    const resendResponse = await resendVerificationCode(
                      values.email
                    )
                    if (resendResponse.success) {
                      Swal.fire({
                        icon: 'success',
                        title: 'Código reenviado',
                        text: 'Se ha enviado un nuevo código de verificación a tu correo.',
                        confirmButtonText: 'Aceptar',
                      })
                    } else {
                      Swal.fire({
                        icon: 'error',
                        title: 'Error al reenviar',
                        text:
                          resendResponse.message ||
                          'Ocurrió un error al intentar reenviar el código.',
                        confirmButtonText: 'Aceptar',
                      })
                    }
                  } catch (error) {
                    Swal.fire({
                      icon: 'error',
                      title: 'Error inesperado',
                      text: 'No se pudo reenviar el código de verificación. Por favor, inténtelo más tarde.',
                      confirmButtonText: 'Aceptar',
                    })
                  } finally {
                    setIsLoading(false)
                  }
                }
              })
              break

            default:
              Swal.fire({
                icon: 'error',
                title: 'Error en la verificación',
                text:
                  error.request ||
                  'Ocurrió un error durante la verificación. Inténtelo nuevamente.',
                confirmButtonText: 'Aceptar',
              })
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error inesperado',
            text: 'Ocurrió un error al verificar el código. Inténtelo nuevamente más tarde.',
            confirmButtonText: 'Aceptar',
          })
        }
      } finally {
        setIsLoading(false)
      }
    },
  })

  // Si el código está en la URL, verificar automáticamente
  useEffect(() => {
    if (emailFromUrl && codeFromUrl) {
      formik.setFieldValue('email', emailFromUrl)
      formik.setFieldValue('verificationCode', codeFromUrl)
      formik.handleSubmit() // Intentar verificación automática
    }
  }, [emailFromUrl, codeFromUrl])

  return (
    <Container>
      <Form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Title>Verificar Código</Title>
        </DialogContent>
        <DialogContent>
          <TextField
            label='Correo Electrónico*'
            margin='dense'
            id='email'
            name='email'
            type='email'
            fullWidth
            onChange={formik.handleChange}
            value={formik.values.email}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            // disabled={!!emailFromUrl} // Deshabilitar si viene de la URL
          />

          <TextField
            label='Código de Verificación*'
            margin='dense'
            id='verificationCode'
            name='verificationCode'
            type='text'
            fullWidth
            onChange={formik.handleChange}
            value={formik.values.verificationCode}
            onBlur={formik.handleBlur}
            error={
              formik.touched.verificationCode &&
              Boolean(formik.errors.verificationCode)
            }
            helperText={
              formik.touched.verificationCode && formik.errors.verificationCode
            }
          />
        </DialogContent>

        <StyledDialogActions>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            disabled={isLoading}
          >
            {isLoading ? 'Verificando...' : 'Verificar'}
          </Button>
        </StyledDialogActions>
      </Form>
    </Container>
  )
}

export default VerifyCode
