import React, { useEffect, useState, useContext, lazy } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Alert } from '@material-ui/lab'

import GlobalContext from '../../contexts/GlobalContext'
import UserContext from '../../contexts/user/UserContext'

import WhatsAppButton from '../WhatsAppButton/WhatsAppButton'

import NotificacionBar from '../NotificacionBar/NotificacionBar'
import Header from '../../components/Header/HeaderResponsive'
import Footer from '../../Views/Footer/FooterMenu'
// const NotificacionBar = lazy(() => import('../NotificacionBar/NotificacionBar'))
// const Header = lazy(() => import('../../components/Header/HeaderResponsive'))
// const Footer = lazy(() => import('../../Views/Footer/FooterMenu'))

const Layout = ({ children }) => {
  const history = useHistory()
  const location = useLocation()

  const { UserState, loading } = useContext(UserContext)
  const { sectionsState, messageAlert } = useContext(GlobalContext)

  const notificacionBar = sectionsState.find(item => item.orden === 1)

  const currentPath = location.pathname

  const protectedRoutes = [
    '/MiCuenta',
    '/MisDirecciones',
    '/pago',
    '/orden',
    '/pedido',
  ]

  const authRestrictedRoutes = [
    '/signin',
    '/signup',
    // '/verify-code',
    // '/reset-password',
    // '/request-recovery-password',
  ]

  useEffect(() => {
    if (loading) return

    // Redirigir si la ruta es protegida y el usuario no está autenticado
    if (protectedRoutes.some(route => currentPath.startsWith(route))) {
      if (!UserState.authenticated) {
        history.replace('/signin', { from: currentPath })
      }
    }

    // Redirigir si el usuario autenticado intenta acceder a /signin o /signup
    if (UserState.authenticated && authRestrictedRoutes.includes(currentPath)) {
      history.replace('/')
    }
  }, [currentPath, UserState.authenticated, history, loading])

  return (
    <>
      {messageAlert.active && (
        <div
          className={`alert-container ${messageAlert.active ? 'active' : ''}`}
        >
          <Alert severity={messageAlert.type}>{messageAlert.message}</Alert>
        </div>
      )}

      {notificacionBar && notificacionBar.activo && (
        <NotificacionBar identificador={notificacionBar.identificador} />
      )}

      <Header />

      <div className='content' style={{ width: '100%', maxWidth: '100vw' }}>
        {children}
      </div>

      <Footer />

      <WhatsAppButton />
    </>
  )
}

export default Layout
