import { SET_PRIMARY_COLOR, SET_BLACK_COLOR, SET_LOGO } from './stylesTypes'

const stylesReducer = (state, action) => {
  switch (action.type) {
    case SET_PRIMARY_COLOR:
      return {
        ...state,
        primaryColor: action.payload,
      }
    case SET_BLACK_COLOR:
      return {
        ...state,
        black: action.payload,
      }
    case SET_LOGO:
      return {
        ...state,
        logo: action.payload,
      }
    default:
      return state
  }
}

export default stylesReducer
