import React, { useContext, useEffect, useState } from 'react'

import {
  getUrlForContacto,
  getFilesDownload,
  getUrlsForImgPlantilla,
} from '../../utils/ima'
import { Link, useParams } from 'react-router-dom'
import ApiGeneral from '../../services/ApiGeneral'
import { makeStyles } from '@material-ui/core/styles'
import Swal from 'sweetalert2'
import errores from '../../services/errores'
import TextField from '@material-ui/core/TextField'
import { useFormik } from 'formik'
import * as yup from 'yup'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextArea from 'antd/lib/input/TextArea'
import { Label } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  btncancelar: {
    background: '#eeeeee',
    color: '#003462',
    backgroundColor: '#eeeeee!important',
  },
  btnsubnmit: {
    background: '#003462',
    color: '#ffffff',
    backgroundColor: '#003462!important',
  },
  DiAction: {
    paddingRight: '2rem',
  },
  error: {
    color: '#ff0500',
  },
}))

const Contacto = ({}) => {
  const images = []
  const classes = useStyles()
  const history = useHistory()
  const { id } = useParams()
  const { nombre } = useParams()
  const [Contactos, setContactos] = useState([])
  const [Correos, setCorreos] = useState([])
  const [usuario, setUser] = React.useState({
    nombre: '',
    email: '',
    telefono: '',
    direccion: '',
    comentario: '',
  })
  const requiereNombre = Contactos.requiereNombre
    ? {
        nombre: yup
          .string()
          .max(50, 'El nombre debe tener un máximo de 50 caracteres.')
          .required('El campo nombre es requerido'),
      }
    : {}
  const requiereTelefono = Contactos.requiereTelefono
    ? {
        telefono: yup
          .string()
          .min(10, 'El teléfono debe tener un mínimo de 10 caracteres.')
          .max(10, 'El teléfono debe tener un máximo de 10 caracteres.')
          .required('El campo teléfono es requerido.'),
      }
    : {}
  const requiereDireccion = Contactos.requiereDireccion
    ? {
        direccion: yup.string().required('El campo dirección es requerido.'),
      }
    : {}
  const requiereComentario = Contactos.requiereComentario
    ? {
        comentario: yup.string().required('El campo comentario es requerido.'),
      }
    : {}

  const validationSchema = yup.object().shape({
    ...requiereNombre,
    ...requiereTelefono,
    ...requiereDireccion,
    ...requiereComentario,
  })
  const validate = values => {
    const errors = {}
    if (!values.email) {
      errors.email = 'El campo correo electrónico es requerido'
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = 'Introduzca un correo electrónico válido'
    }

    return errors
  }
  const formik = useFormik({
    initialValues: {
      nombre: '',
      email: '',
      telefono: '',
      direccion: '',
      comentario: '',
      correosD: Contactos.correosD,
    },
    validate,
    validationSchema: validationSchema,

    onSubmit: (values, { resetForm }) => {
      let valores = values
      values.correosD = Contactos.correosD
      ApiGeneral.enviarNotificacion(values)
        .then(r => {
          resetForm({ values: '' })
          Swal.fire('Los datos se han enviado con éxito.', '', 'success')
        })
        .catch(e => {
          Swal.fire(errores.handleError('error'), '', 'error')
        })
    },
  })
  useEffect(() => {
    // images.push(getUrlsForProductImg({ plantillas.imagen }))
    const getContacto = () => {
      ApiGeneral.getContactoId(id)
        .then(response => {
          const data = response.data
          if (!data.activo) history.push(`/woops`)
          else if (data.periodo != null) {
            var fNow = new Date()
            fNow.setHours(0, 0, 0, 0)
            if (Date.parse(data.periodo[1]) < fNow) history.push(`/woops`)
            else if (Date.parse(data.periodo[0]) > fNow) history.push(`/woops`)
            else setContactos(data)
          } else setContactos(data)
        })
        .catch(e => {
          console.error('error data:' + e)
        })
    }

    getContacto()
  }, [])

  return (
    <>
      <div
        style={{
          marginTop: '125px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={getUrlForContacto(Contactos.path)}
          style={{
            marginTop: '60px',
            height: '300px',
            objectFit: 'scale-down',
          }}
        />
      </div>
      <div style={{ marginTop: '10px' }}>
        <form onSubmit={formik.handleSubmit} style={{ marginTop: '30px' }}>
          <DialogContent>
            <DialogContentText>
              {Contactos.requiereNombre ? (
                <TextField
                  margin='dense'
                  id='nombre'
                  name='nombre'
                  label='Nombre'
                  placeholder='Nombre'
                  type='text'
                  fullWidth
                  required
                  onChange={formik.handleChange}
                  value={formik.values.nombre}
                  onBlur={formik.handleBlur}
                  error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                  helperText={formik.touched.nombre && formik.errors.nombre}
                />
              ) : (
                <div></div>
              )}
              {Contactos.requiereCorreo ? (
                <TextField
                  label='Correo Electrónico'
                  margin='dense'
                  id='email'
                  name='email'
                  type='email'
                  fullWidth
                  required
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              ) : (
                <div></div>
              )}
              {Contactos.requiereTelefono ? (
                <TextField
                  margin='dense'
                  id='telefono'
                  name='telefono'
                  label='Teléfono'
                  placeholder='Teléfono'
                  type='number'
                  fullWidth
                  required
                  onChange={formik.handleChange}
                  value={formik.values.telefono}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.telefono && Boolean(formik.errors.telefono)
                  }
                  helperText={formik.touched.telefono && formik.errors.telefono}
                />
              ) : (
                <div></div>
              )}
              {Contactos.requiereDireccion ? (
                <TextField
                  margin='dense'
                  id='direccion'
                  name='direccion'
                  label='Dirección'
                  placeholder='Dirección'
                  type='text'
                  fullWidth
                  multiline
                  rows={4}
                  required
                  onChange={formik.handleChange}
                  value={formik.values.direccion}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.direccion && Boolean(formik.errors.direccion)
                  }
                  helperText={
                    formik.touched.direccion && formik.errors.direccion
                  }
                />
              ) : (
                <div></div>
              )}

              {Contactos.requiereComentario ? (
                <TextField
                  margin='dense'
                  id='comentario'
                  name='comentario'
                  label='Comentario'
                  placeholder='Comentario'
                  type='text'
                  fullWidth
                  multiline
                  rows={4}
                  required
                  style={{ width: '100%', height: '150px', marginTop: '30px' }}
                  onChange={formik.handleChange}
                  value={formik.values.comentario}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.comentario &&
                    Boolean(formik.errors.comentario)
                  }
                  helperText={
                    formik.touched.comentario && formik.errors.comentario
                  }
                />
              ) : (
                <div></div>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.DiAction}>
            {Contactos.requiereNombre ||
            Contactos.requiereCorreo ||
            Contactos.requiereTelefono ||
            Contactos.requiereDireccion ||
            Contactos.requiereComentario ? (
              <Button
                type='submit'
                variant='contained'
                className={classes.btnsubnmit}
                value='Registrar'
                color='primary'
              >
                Enviar
              </Button>
            ) : (
              <div></div>
            )}
          </DialogActions>
        </form>
      </div>
    </>
  )
}
export default Contacto
