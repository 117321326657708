import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Tab, Tabs } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useParams } from 'react-router'

import './MiCuenta.css'
import { useAnalyticsEventTracker } from '../GoogleAnalitycs/useAnalyticsEventTracker'

import Direccion from '../Direccion/Direccion'
import MisDatos from '../DatosPersonales/MisDatosPersonales'
import Pedidos from '../Pedidos/Pedidos'
import useWindowSize from '../../utils/useWindowSize'
import WishList from '../WishList/WishList'
import CompareList from '../CompareList/CompareList'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    padding: '1rem',
  },
  paper: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8),
  },
  fondo: {
    background: 'green',
  },
  tabs: {},
  tabPanel: {
    width: '100%',
    overflow: 'hidden',
    overflowY: 'auto',
  },
  tab: {
    textTransform: 'capitalize',
    minHeight: 30,
    display: 'grid',
    textAlign: 'start',
    justifyContent: 'start',
  },
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      className='TabPanel'
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

const MiCuenta = () => {
  const { path } = useParams()
  const { responsiveSize } = useWindowSize()
  const classes = useStyles()

  useEffect(() => {
    if (path === 'milista') setValue(4)
    if (path === 'deseos') setValue(3)
    if (path === 'pedidos') setValue(2)
    if (path === 'direcciones') setValue(1)
    if (path === 'misdatos') setValue(0)
  }, [path])

  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const gaEventTracker = useAnalyticsEventTracker('Mi cuenta')

  return responsiveSize === 'sm' ? (
    <div>
      <div
        style={{
          marginBottom: 15,
          padding: '0px 10px',
          marginTop: 30,
        }}
        id='me-tab'
      >
        <CssBaseline />
        <Grid container direction='column'>
          <Grid item xs direction='row' style={{ padding: '.5rem 2rem' }}>
            <Typography variant='h5' color='initial'>
              Mi cuenta
            </Typography>
          </Grid>
          <Divider />
          <div>
            <Tabs
              orientation='horizontal'
              indicatorColor='primary'
              textColor='primary'
              value={value}
              onChange={handleChange}
              centered
              style={{ marginBottom: 12 }}
            >
              <Tab label='Mis Datos' {...a11yProps(0)} />
              <Tab label='Mis Direcciones' {...a11yProps(1)} />
              <Tab label='Mis pedidos' {...a11yProps(2)} />
              <Tab label='Mis deseos' {...a11yProps(3)} />
              <Tab label='Mi lista' {...a11yProps(4)} />
            </Tabs>
            <TabPanel value={value} index={0}>
              <Grid xs={12} sm={9} md={6}>
                <MisDatos />
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Grid xs={12} sm={9} md={6}>
                <Direccion />
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Pedidos />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <WishList />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <CompareList />
            </TabPanel>
          </div>
        </Grid>
      </div>
    </div>
  ) : (
    <div
      style={{
        height: 'auto',
        minHeight: '60vh',
      }}
    >
      <div
        style={{
          marginBottom: 0,
          paddingBottom: 0,
          paddingTop: 0,
          marginTop: 30,
        }}
        id='me-tab'
      >
        <CssBaseline />
        <Grid container direction='column'>
          <Grid item xs direction='row' style={{ padding: '.5rem 2rem' }}>
            <Typography variant='h5' color='initial'>
              Mi cuenta
            </Typography>
          </Grid>
          <Divider />
          <div className={classes.root}>
            <Tabs
              orientation='vertical'
              indicatorColor='primary'
              textColor='primary'
              variant='scrollable'
              value={value}
              onChange={handleChange}
              className={classes.tabs}
            >
              <Tab
                className={classes.tab}
                label='Mis Datos'
                {...a11yProps(0)}
                onClick={() => gaEventTracker('Mis datos')}
              />
              <Tab
                className={classes.tab}
                label='Mis Direcciones'
                onClick={() => gaEventTracker('Mis direcciones')}
                {...a11yProps(1)}
              />
              <Tab
                className={classes.tab}
                label='Mis pedidos'
                onClick={() => gaEventTracker('Mis pedidos')}
                {...a11yProps(2)}
              />
              <Tab
                className={classes.tab}
                label='Mis deseos'
                onClick={() => gaEventTracker('Mis deseos')}
                {...a11yProps(3)}
              />
              <Tab
                className={classes.tab}
                label='Mi Lista'
                onClick={() => gaEventTracker('Mi Lista')}
                {...a11yProps(4)}
              />
            </Tabs>

            <TabPanel className={classes.tabPanel} value={value} index={0}>
              <Grid xs={12} sm={9} md={6}>
                <MisDatos />
              </Grid>
            </TabPanel>
            <TabPanel className={classes.tabPanel} value={value} index={1}>
              <Grid xs={12} sm={9} md={6}>
                <Direccion />
              </Grid>
            </TabPanel>
            <TabPanel className={classes.tabPanel} value={value} index={2}>
              <Pedidos />
            </TabPanel>
            <TabPanel className={classes.tabPanel} value={value} index={3}>
              <WishList />
            </TabPanel>
            <TabPanel className={classes.tabPanel} value={value} index={4}>
              <CompareList />
            </TabPanel>
          </div>
        </Grid>
      </div>
    </div>
  )
}

export default MiCuenta
