import React, { useState, useEffect, memo, useContext } from 'react'
import { Link, Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import FacebookIcon from '@material-ui/icons/Facebook'
import SendIcon from '@material-ui/icons/Send'
import WhatsappIcon from '@material-ui/icons/WhatsApp'
import Phone from '@material-ui/icons/Phone'
import UbicacionIcon from '@material-ui/icons/Place'

import { getElementosfooter } from '../../actions/catalogoActions'
import StylesContext from '../../contexts/styles/stylesContext'

import { nombre } from '../../services/Url'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '3%',
  },
  gridConten: {
    width: '100%',
  },
  titulo: {
    paddingRight: '-100%',
    width: '100%',
    color: '#ffff',
    fontWeight: 'bolder',
  },
  subtitulo: {
    width: '100%',
    color: '#ffff',
  },
  icon: {
    margin: theme.spacing(1),
    borderRadius: '5em',
  },
}))

const Footer = memo(({ AligmentText }) => {
  const classes = useStyles()
  const stylesContext = useContext(StylesContext)

  const [elementosMenu, setElementosMenu] = useState(() => [])

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const signal = controller.signal

    getElementosfooter(signal)
      .then(data => {
        if (isMounted) {
          setElementosMenu(data.filter(item => item.menu.length > 0))
        }
      })
      .catch(error => {
        if (isMounted) {
          console.warn('Error fetching elemtnos footer', error)
        }
      })

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])

  return (
    <Box
      className={classes.root}
      style={{
        marginTop: 'auto',
        background: stylesContext.primaryColor,
        textAlign: 'center',
      }}
    >
      <Grid container spacing={2} style={{ textAlign: 'center' }}>
        {elementosMenu.map(item => (
          <Grid
            key={item.idFooter}
            xs={12}
            sm={6}
            md={elementosMenu?.length === 4 ? 3 : 4}
            className={classes.gridConten}
          >
            <Box>
              <Typography
                variant='h6'
                className={classes.titulo}
                style={{
                  textAlign: AligmentText,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {item.title === 'Ubicación' && (
                  <UbicacionIcon style={{ background: '#fff' }} />
                )}
                &nbsp;{item.title}
              </Typography>

              {item.title === '¡Síguenos!' && (
                <div
                  style={{
                    marginLeft: '5%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {item.menu.map((item2, index) => (
                    <div key={item2.id}>
                      {index === 0 && (
                        <Link
                          style={{ textTransform: 'capitalize' }}
                          href={item2.link}
                          variant='subtitle1'
                          className={classes.subtitulo}
                        >
                          <FacebookIcon style={{ background: '#fff' }} /> &nbsp;
                        </Link>
                      )}
                    </div>
                  ))}

                  {item.menu.map((item2, index) => (
                    <div key={item2.id}>
                      {index === 1 && (
                        <Link
                          style={{ textTransform: 'capitalize' }}
                          href={item2.link}
                          variant='subtitle1'
                          className={classes.subtitulo}
                        >
                          <WhatsappIcon style={{ background: '#fff' }} /> &nbsp;
                        </Link>
                      )}
                    </div>
                  ))}

                  {item.menu.map((item2, index) => (
                    <div key={item2.id}>
                      {index === 2 && (
                        <Link
                          style={{ textTransform: 'capitalize' }}
                          href={item2.link}
                          variant='subtitle1'
                          className={classes.subtitulo}
                        >
                          <SendIcon style={{ background: '#fff' }} /> &nbsp;
                        </Link>
                      )}
                    </div>
                  ))}
                </div>
              )}

              <div>
                {item.menu.map(item2 => (
                  <Typography
                    key={item2.id}
                    className={classes.subtitulo}
                    style={{ textAlign: AligmentText }}
                  >
                    <Link
                      style={{ textTransform: 'capitalize' }}
                      href={item2.link}
                      variant='subtitle1'
                      className={classes.subtitulo}
                    >
                      {item.title === '¿Necesitas Ayuda?' && (
                        <Phone style={{ background: '#fff' }} />
                      )}
                      {item.title === '¿Necesitas ayuda?' && (
                        <Phone style={{ background: '#fff' }} />
                      )}
                      &nbsp; {item2.text}
                    </Link>
                  </Typography>
                ))}
              </div>

              <div>
                {item.menu.map(items2 => {
                  if (
                    item.title === 'Ubicacion' ||
                    item.title === 'Ubicación'
                  ) {
                    return (
                      <div key={items2.id} style={{ marginTop: '20px' }}>
                        {nombre.tienda !== 'MediNu' && (
                          <iframe
                            src={items2.link}
                            title={`Mapa de ${item.title} ${items2.id}`} // Título único para cada iframe
                            style={{
                              width: '250px',
                              height: '120px',
                            }}
                            allowFullScreen
                            loading='lazy'
                          ></iframe>
                        )}
                      </div>
                    )
                  }
                  return null
                })}
              </div>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
})

export default Footer
