import React, { useState, useContext, useEffect } from 'react'
import Context from '../../../store/Context'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Image } from 'antd'
import { FaCartPlus } from 'react-icons/fa'
import { FaHeart } from 'react-icons/fa'
import { FaRegHeart } from 'react-icons/fa'
import { BsPlusSquare } from 'react-icons/bs'
import { BsPlusSquareFill } from 'react-icons/bs'
import { Typography, Tooltip } from '@material-ui/core'
// import { withStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router'
// import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

import {
  getCompare,
  getProductVariant,
  getWishList,
} from '../../../actions/resultActions'
import Base from '../../../services/Base'
import Estrellas from '../../../Views/Calificacion/Estrellas'
import UserContext from '../../../contexts/user/UserContext'

// import { COLORS } from '../../../Views/Colores'
import { useAnalyticsEventTracker } from '../../GoogleAnalitycs/useAnalyticsEventTracker'

// const HtmlTooltip = withStyles(theme => ({
//   tooltip: {
// backgroundColor: COLORS.black,
// color: COLORS.black,
//     maxWidth: 350,
//     fontSize: theme.typography.pxToRem(12),
//     border: '1px solid #dadde9',
//   },
// }))(Tooltip)

// const useStyles = makeStyles(Theme => ({
//   botom: {
//     padding: 5,
//     fontSize: '15px',
//     borderColor: '#191919',
//     backgroundColor: 'white',
//   },
//   icon: {
//     backgroundColor: 'white',
//     borderColor: 'white',
//     marginLeft: '-20px',
//     marginTop: '5px',
//   },
//   filas: {
//     display: 'flex',
//   },
//   image: {
//     borderRadius: 150 / 2,
//     overflow: 'hidden',
//     borderWidth: 3,
//     borderColor: 'red',
//   },
//   preguntas: {
//     width: '63%',
//     marginLeft: '26%',
//   },
//   botonp: {
//     marginLeft: '91%',
//     marginTop: '-37px',
//   },
//   botonp2: {
//     marginLeft: '845px',
//     marginTop: '-37px',
//   },
//   all: {
//     marginLeft: '50px',
//   },
//   comentarios: {
//     width: '63%',
//     marginLeft: '25%',
//   },
//   allcom: {
//     marginLeft: '-50px',
//   },
//   preguntaList: {
//     marginLeft: '0%',
//     margin: '20px',
//   },
//   comentList: {
//     marginLeft: '0%',
//   },
//   precios: {
//     marginTop: '-27px',
//     marginLeft: '59px',
//   },
// }))

const ProductCard = styled.div`
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-radius: 1rem;
  border-color: rgb(223, 220, 220);

  background-color: #ffffff;
  margin: 0.2rem 0.4rem;
  padding: 0.6rem 0.4rem;

  width: 100%;
  max-width: 280px;

  @media (max-width: 599px) {
    max-width: 200px;
  }
`
const CardActions = styled.div`
  // background-color: yellowgreen;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`

const Icon = styled.div``

const CardOriginal = ({ addProductToCart, ...props }) => {
  const { UserState } = useContext(UserContext)
  console.log('CardOriginal', props)

  const imgVariante = []
  const [resultResponseImg, setResultResponseImg] = useState(null)
  const img = []
  const history = useHistory()

  const getImgVarS = (rutaUbicacion, id, extension) => {
    const urlImg = Base.baseFiles.imgProduct
    const val = urlImg + '/' + rutaUbicacion + '/' + id + extension
    return val
  }

  const [resultVariantes, setResultVariantes] = useState([])

  const getLink = ind => {
    resultVariantes.map((i, index) => {
      if (ind === index) {
        history.push('/products/' + i.productoIdVariante + '/p')
      }
    })
  }

  useEffect(() => {
    cargaVariante()
  }, [])

  const cargaVariante = async () => {
    const res = await getProductVariant(props.idProducto)
    setResultVariantes(res.data)
    const response = await getProductVariant(props.idProducto)

    imgVariante.push(response.data)

    imgVariante[0].forEach(element => {
      setResultResponseImg(element.productoImagens)
      // setUrl(element.productoIdVariante)
    })

    imgVariante[0].forEach(element => {
      img.push(element.productoIdVariante)
    })
    if (response.data.length === 0) {
      setResultResponseImg(null)
    }
  }

  const [icon, setIcon] = useState(() => props.icon)
  const [iconCompare, setIconCompare] = useState(() => props.lists)

  const wishEliminar = async id => {
    if (icon === true) {
      const MyObject = {
        idProducto: id,
        idusuario: UserState.idCliente,
        activo: true,
      }
      const JsonArray2 = JSON.stringify(MyObject)
      const result = await getWishList(JsonArray2)

      if (!UserState.authenticated) return
      setIcon(result.data.activo)
    }
  }

  const wishList = async () => {
    if (!UserState.authenticated) return

    const MyObject = {
      idProducto: props.idProducto,
      idusuario: UserState.idCliente,
      activo: true,
    }
    const JsonArray2 = JSON.stringify(MyObject)
    const result = await getWishList(JsonArray2)
    setIcon(!icon)
  }

  const compareList = async () => {
    if (!UserState.authenticated) return
    const MyObject = {
      idProducto: props.idProducto,
      idusuario: UserState.idCliente,
      activo: true,
    }
    const JsonArray2 = JSON.stringify(MyObject)

    const result = await getCompare(JsonArray2)
    setIconCompare(!iconCompare)
  }

  // const classes = useStyles()
  const context = useContext(Context)
  const gaEventTracker = useAnalyticsEventTracker('Ver articulo')

  return (
    <ProductCard>
      {/* {props.activoDescuento === true || props.rebaja === true ? (
        <div>
          <div className='tag'>
            <h3 className='fou'>{props.descuento} %</h3>
            <div className='tagmob'></div>
          </div>{' '}
          <div style={{ marginTop: '-15%' }}></div>{' '}
        </div>
      ) : (
        <div style={{ background: 'transparent', width: '30%' }}>
          <h3 className='fou'>{props.descuento} %</h3>
        </div>
      )} */}

      {/* <div style={{ marginTop: '0' }}> */}
      <CardActions>
        <Icon>
          <Button
            onClick={() => {
              context.addProductToCart({
                _id: props.idProducto,
                price:
                  props.rebaja === true || props.activoDescuento === true
                    ? props.descuentoPrecio
                    : props.sprice,
                images: props.imagenPereview,
                title: props.nombre.replaceAll(`"`, `''`),
                peso: props.peso,
                familiaId: props.categoriaId,
                infoAdicional: '',
                maxQuantity: props.inventario,
                tipoCambio: context.getTipoCambio,
                moned: props.abrevia,
                wishList: icon,
                pedidos: true,
              })
              wishEliminar(props.idProducto)
            }}
          >
            <FaCartPlus />
          </Button>
        </Icon>

        <Icon>
          <Tooltip
            title={
              UserState.authenticated ? 'Mis deseos' : 'Debes iniciar sesión'
            }
            placement='bottom'
          >
            <Button
              onClick={() => {
                wishList()
              }}
            >
              {icon ? <FaHeart /> : <FaRegHeart />}
            </Button>
          </Tooltip>
        </Icon>

        <Icon>
          <Tooltip
            title={
              UserState.authenticated ? 'Mi lista' : 'Debes iniciar sesión'
            }
            placement='bottom'
          >
            <Button
              onClick={() => {
                compareList()
              }}
            >
              {iconCompare ? <BsPlusSquareFill /> : <BsPlusSquare />}
            </Button>
          </Tooltip>
        </Icon>
      </CardActions>

      <div style={{ margin: '0.5rem' }}>
        <Link to={`/products/${props.idProducto}/p`}>
          <Image
            preview={false}
            src={props.imagenPereview}
            fallback={'/img/IMAGEN_NO_DISPONIBLE.png'}
            style={{
              minHeight: '140px',
              objectFit: 'contain',
            }}
          />
        </Link>
      </div>

      {props && props?.calificacion > 0 && (
        <Estrellas
          idProducto={props.idProducto}
          calificacion={props.calificacion}
          pd={'red'}
          ml={15}
        />
      )}

      <div style={{ margin: '0.5rem' }}>
        {props.sprice2 > 0 ? (
          <div>
            {props.activoDescuento === false && props.rebaja === false && (
              <div
                className='mycart'
                style={{
                  textAlign: 'left',
                  padding: '0.4rem 0',
                  fontWeight: 600,
                }}
              >
                <Tooltip
                  title={'Dolar américano. Fuente: www.banxico.org.mx/'}
                  placement='bottom'
                >
                  <span className=''>
                    ${parseFloat(props.sprice2).toFixed(2)} {props.abrevia}{' '}
                  </span>
                </Tooltip>
              </div>
            )}

            {props.activoDescuento === true || props.rebaja === true ? (
              <div className='product3'>
                <div style={{ width: '100%' }}>
                  <Tooltip
                    title={'Dolar américano. Fuente: www.banxico.org.mx/'}
                    placement='bottom'
                  >
                    <span className='tamprecio'>
                      &nbsp;&nbsp;$
                      {parseFloat(props.descuentoPrecio).toFixed(2)}{' '}
                      {props.abrevia}{' '}
                    </span>
                  </Tooltip>
                </div>
                <div className='preciomobt'>
                  <del>
                    <span>
                      ${parseFloat(props.sprice).toFixed(2)} (Precio Anterior)
                    </span>
                  </del>
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <Typography variant={'body2'}>
            <a
              href={`https://wa.me/+528180287617?text=Hola! Quiero informes sobre el producto ${props.nombre}`}
              target='_blank'
            >
              Si deseas saber más de este producto, contáctanos.
            </a>
          </Typography>
        )}
      </div>

      <div style={{ margin: '0.5rem' }}>
        <Tooltip title={props.nombre} placement='bottom'>
          <p>{props.nombre}</p>
        </Tooltip>
      </div>

      {/* {resultVariantes.length === 0 && (
        <div style={{ marginTop: '24%' }}>
          <br></br>
        </div>
      )} */}

      {/* {props.nombre.length <= 30 && <div className='mycart4'></div>} */}

      {resultResponseImg !== null && (
        <div className='variant2'>
          {resultResponseImg.map((item, index) => (
            <div style={{ height: '18%', width: '18%', margin: '2%' }}>
              <Link onClick={() => getLink(index)}>
                <div key={item}>
                  <br></br>
                  <Image
                    preview={false}
                    src={getImgVarS(
                      item.rutaUbicacion,
                      item.id,
                      item.extension
                    )}
                    fallback={'/img/IMAGEN_NO_DISPONIBLE.png'}
                  />
                </div>
              </Link>
            </div>
          ))}
        </div>
      )}
    </ProductCard>
  )
}

export default CardOriginal
