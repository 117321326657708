import React, { useContext, useEffect, useState } from 'react'
import { getTendencia } from '../../actions/resultActions'
import Cards from '../../components/Result/Mask/CardIntegrinox'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import Card from '../../components/Result/Mask/CardOriginal'
import { FcIdea } from 'react-icons/fc'
import { FcPositiveDynamic } from 'react-icons/fc'
import { FcApproval } from 'react-icons/fc'

import Context from '../../store/Context'
import { getUrlForProductImg } from '../../utils/ima'
import '../../Views/Vendido/card.css'
import GlobalContext from '../../contexts/GlobalContext'

function Vvendido() {
  const { monedaState } = useContext(GlobalContext)
  const [fill, setFill] = useState(<h1 className='buscando'></h1>)
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1336 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  }
  const arr = []

  const context = useContext(Context)
  const [all, allset] = useState([])
  const [moneda, setMoneda] = useState(() => monedaState)
  const [responses, setReponse] = useState([])

  useEffect(() => {
    const buscarProductos = async () => {
      const response = await getTendencia()
      allset(response.data)
      setReponse(response.data.nuevos)
      setFill(<Cards items={response.data.nuevos} />)
    }
    buscarProductos()
  }, [])

  const buscarProductos2 = async data => {
    if (data === 'Tendencia') {
      setReponse(all.tendencias)
      setFill(<Cards items={all.tendencias} />)
    }

    if (data === 'Vendidos') {
      setReponse(all.vendidos)
      setFill(<Cards items={all.vendidos} />)
    }

    if (data === 'Nuevo') {
      setReponse(all.nuevos)
      setFill(<Cards items={all.nuevos} />)
    }
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          gap: '2.5rem',
          fontSize: '22px',
          fontWeight: '600',
          letterSpacing: '2px',
          margin: '1rem 0'
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
            cursor: 'pointer',
          }}
          onClick={() => {
            buscarProductos2('Nuevo')
          }}
        >
          <span>Nuevos</span>
          <FcIdea />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
            cursor: 'pointer',
          }}
          onClick={() => {
            buscarProductos2('Tendencia')
          }}
        >
          <span>Tendencia</span>
          <FcPositiveDynamic />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
            cursor: 'pointer',
          }}
          onClick={() => {
            buscarProductos2('Vendidos')
          }}
        >
          <span>Más vendidos</span>
          <FcApproval />
        </div>
      </div>

      <Carousel responsive={responsive}>
        {responses.map(p => (
          <div>
            {moneda.abreviatura === 'USD' && (
              <Card
                key={p.idProducto}
                idProducto={p.idProducto}
                price={p.precio}
                imagenPereview={
                  p.imagenPereview === null
                    ? '/img/IMAGEN_NO_DISPONIBLE.png'
                    : getUrlForProductImg(p.imagenPereview)
                }
                sku={p.sku}
                nombre={p.nombre}
                sprice={p.precio}
                abrevia={moneda.abreviatura}
                calificacion={p.calificacion}
                activoDescuento={p.activoDescuento}
                descuentoPrecio={p.descuentoPrecio}
                rebaja={p.rebaja}
                descuento={p.descuento}
                icon={p.wish}
                peso={p.peso}
                inventario={p.inventario}
                categoriaId={p.categoriaId}
                lists={p.lists}
                addProductToCart={context.addProductToCart}
              />
            )}
            {moneda.abreviatura === 'MXN' && (
              <Card
                key={p.idProducto}
                idProducto={p.idProducto}
                price={p.precio * context.getTipoCambio}
                imagenPereview={
                  p.imagenPereview === null
                    ? '/img/IMAGEN_NO_DISPONIBLE.png'
                    : getUrlForProductImg(p.imagenPereview)
                }
                sku={p.sku}
                nombre={p.nombre}
                sprice={p.precio * context.getTipoCambio}
                abrevia={moneda.abreviatura}
                calificacion={p.calificacion}
                activoDescuento={p.activoDescuento}
                descuentoPrecio={p.descuentoPrecio * context.getTipoCambio}
                rebaja={p.rebaja}
                descuento={p.descuento}
                icon={p.wish}
                peso={p.peso}
                inventario={p.inventario}
                categoriaId={p.categoriaId}
                lists={p.lists}
                addProductToCart={context.addProductToCart}
              />
            )}
          </div>
        ))}
      </Carousel>
    </div>
  )
}

export default Vvendido
