import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { getKey } from '../../actions/resultActions'

const { forwardRef, useRef, useImperativeHandle } = React

const Navbar = forwardRef((props, ref) => {
  const [pixelId, setPixelId] = useState('')
  const PixelId = async () => {
    const responses = await getKey('6fb0aa72-a96e-48a5-8717-2dc98f56d1c9')
    setPixelId(responses.data.valor)
  }
  useEffect(() => {
    PixelId()
  }, [])
  return (
    <div>
      <Helmet>
        <script id='facebook-pixel-script'>
          {`  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '` +
            pixelId +
            `');
  fbq('track', '` +
            props.nombres +
            `');
`}
        </script>
        <noscript id='facebook-pixel-image'>
          {`
       <img height="1" width="1" style="display:none"
       src="https://www.facebook.com/tr?id=` +
            pixelId +
            `&ev=PageView&noscript=1"
       />
       `}
        </noscript>
      </Helmet>
    </div>
  )
})

export default Navbar
