import React from 'react'

export default function useWindowSize() {
  const isSSR = typeof window !== 'undefined'
  const [windowSize, setWindowSize] = React.useState({
    width: isSSR ? 1200 : window.innerWidth,
    height: isSSR ? 800 : window.innerHeight,
    responsiveSize:
      window.innerWidth >= 900
        ? 'xl'
        : window.innerWidth < 900 && window.innerWidth >= 700
          ? 'm'
          : 'sm',
  })

  function changeWindowSize() {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
      responsiveSize:
        window.innerWidth >= 900
          ? 'xl'
          : window.innerWidth < 900 && window.innerWidth >= 700
            ? 'm'
            : 'sm',
    })
  }

  React.useEffect(() => {
    window.addEventListener('resize', changeWindowSize)

    return () => {
      window.removeEventListener('resize', changeWindowSize)
    }
  }, [])

  return windowSize
}
