import {
  SET_USER,
  CLEAR_USER,
  LOADING_START,
  LOADING_END,
  LOGIN_ERROR,
} from './UserTypes'

const UserReducer = (state, action) => {
  switch (action.type) {
    case SET_USER:
      console.log('UserReducer', action)
      return {
        ...state,
        authenticated: action.payload.authenticated,
        token: action.payload.token,
        tokenExp: action.payload.tokenExp,
        idCliente: action.payload.idCliente,
        email: action.payload.email,
        name: action.payload.name,
        preferredUsername: action.payload.preferredUsername,
        locale: action.payload.locale,
        loading: false,
        error: null,
      }
    case CLEAR_USER:
      return {
        authenticated: false,
        token: null,
        tokenExp: null,
        idCliente: null,
        email: null,
        name: null,
        preferredUsername: null,
        locale: null,
        loading: false,
        error: null,
      }
    case LOADING_START:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case LOADING_END:
      return {
        ...state,
        loading: false,
      }
    case LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default UserReducer
