import React, { useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import LoadingScreen from '../RegistroCliente/LoadingScreen'

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    maxHeight: '90vh',
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
  },
  dialogContent: {
    flexGrow: 1,
    overflowY: 'auto',
  },
  dialogTitle: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#fff',
    zIndex: 1000,
  },
  btnCancelar: {
    // Otros estilos que necesites
  },
}))

const Cliente = ({ setToggle, urls, fun, close }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    handleClickOpen()
  }, [])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setIsLoading(false)
    fun()
    close()
  }

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby='draggable-dialog-title'
      classes={{ paper: classes.dialogPaper }}
    >
      <LoadingScreen open={isLoading} />

      <DialogTitle className={classes.dialogTitle} id='draggable-dialog-title'>
        <div
          style={{
            marginTop: '1rem',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <span style={{ display: 'inline-block' }}>Contáctanos</span>
          <span
            style={{ display: 'inline-block', cursor: 'pointer' }}
            onClick={handleClose}
          >
            ✕
          </span>
        </div>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <iframe
          id='inlineFrameExample'
          title='Inline Frame Example'
          width='100%'
          height='500'
          src={urls}
        ></iframe>
      </DialogContent>

      <DialogActions>
        <Button
          autoFocus
          onClick={handleClose}
          variant='outlined'
          className={classes.btnCancelar}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Cliente
