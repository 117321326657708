const MisCompras = () => {
  const titlestyle = {
    textAlign: 'center',
    marginTop: '230px',
    width: '100%',
    background: 'white',
    height: '400px',
  }

  return (
    <>
      <h1 style={titlestyle} className='title'>
        Mis Compras
      </h1>
    </>
  )
}
export default MisCompras
