import axios from 'axios'
import Base from './Base'
import { nombre, realm } from './Url'
import { getToken } from '../components/SecuredUser'
const getCFDI = id => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        realm: realm.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('CatGeneral/getByTable/' + id)
      .then(r => {
        resolve(r.data)
      })
      .catch(e => {
        reject(e)
      })
  })
}
const getPais = () => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('Location/getPaises')
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        reject(e)
      })
  })
}
const getContactos = () => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',

        nombres: nombre.tienda,
        realm: realm.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('Contacto')
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        reject(e)
      })
  })
}
const getContactoId = id => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        realm: realm.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('Contacto/' + id)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        reject(e)
      })
  })
}
//getPlantillas
const getPlantillas = () => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('Plantillas/getAll/')
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        reject(e)
      })
  })
}
const getPlantillasActivas = () => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('Plantillas/getActives/')
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        reject(e)
      })
  })
}
const getPlantillaId = id => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('Plantillas/getById/' + id)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        reject(e)
      })
  })
}
//obtener categorias
const getCategorias = () => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('Categoria/GetRoot')
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        reject(e)
      })
  })
}

//obtener iframe
const getLeadForm = () => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('LeadForm/get')
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        reject(e)
      })
  })
}

const getMisDatos = IdCliente => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        realm: realm.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    //64be9460-b84b-4ee7-a7aa-2d2eece6a315

    instance
      .get('clientes/getById/' + IdCliente)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        reject(e)
      })
  })
}
const getMisDatosC = IdCliente => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        realm: realm.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    //64be9460-b84b-4ee7-a7aa-2d2eece6a315

    instance
      .get('clientes/contacto/get/' + IdCliente)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        reject(e)
      })
  })
}
const formaPago = formaPagoId => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })

    instance
      .get('CatGeneral/getByTable/' + formaPagoId)
      .then(r => {
        resolve(r.data)
      })
      .catch(e => {
        reject(e)
      })
  })
}
const metodoPago = metodoPagoId => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })

    instance
      .get('CatGeneral/getByTable/' + metodoPagoId)
      .then(r => {
        resolve(r.data)
      })
      .catch(e => {
        reject(e)
      })
  })
}
const editeMisDatos = cliente => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        realm: realm.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })

    instance
      .post('Clientes/edit', cliente)
      .then(r => {
        resolve(r.data)
      })
      .catch(e => {
        reject(e)
      })
  })
}

const editeColaborador = colaborador => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        realm: realm.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })

    instance
      .post('Clientes/contacto/edit', colaborador)
      .then(r => {
        resolve(r.data)
      })
      .catch(e => {
        reject(e)
      })
  })
}
const addColaborador = colaborador => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        realm: realm.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })

    instance
      .post('Clientes/contacto/save', colaborador)
      .then(r => {
        resolve(r.data)
      })
      .catch(e => {
        reject(e)
      })
  })
}
const getColaborador = IdCliente => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        realm: realm.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })

    instance
      .get('Clientes/contacto/get/' + IdCliente)
      .then(r => {
        resolve(r.data)
      })
      .catch(e => {
        reject(e)
      })
  })
}
//direcciones
const getDirecciones = IdCliente => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    // Base.baseUrls.catalogoClienteDireccion/get/64be9460-b84b-4ee7-a7aa-2d2eece6a315

    instance
      .get('clientes/direccion/get/' + IdCliente)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        reject(e)
      })
  })
}

//crear direccion
const createDir = direccion => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        realm: realm.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })

    instance
      .post('clientes/direccion/save', direccion)
      .then(r => {
        resolve(r.data)
      })
      .catch(e => {
        reject(e)
      })
  })
}
const editeDir = direccion => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })

    instance
      .post('clientes/direccion/edit', direccion)
      .then(r => {
        resolve(r.data)
      })
      .catch(e => {
        reject(e)
      })
  })
}

//'Authorization': `Bearer ${getToken}`
const getEstadoPais = IdPais => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        IdPais: 1,
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('Location/getEstadosByPais/' + IdPais)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        reject(e)
      })
  })
}
const getMunicipoEstado = IdEstado => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        IdEstado: IdEstado,
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('Location/getMunicipiosByEstado/' + IdEstado)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        reject(e)
      })
  })
}
const getLocation = cp => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('Location/getByCP/' + cp)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        reject(e)
      })
  })
}
const getColoniasByMunicipios = IdMunicipio => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        IdMunicipio: IdMunicipio,
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('Location/getColoniasByMunicipio/' + IdMunicipio)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        reject(e)
      })
  })
}
const getColonias = value => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('Location/getColonia/' + value)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        reject(e)
      })
  })
}

const getCP = cp => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('Location/getByCP/' + cp)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        reject(e)
      })
  })
}

//notificaciones
const enviarNotificacion = notificacion => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.notificacion,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })

    instance
      .post('Notification/email/contacto/notify', notificacion)
      .then(r => {
        resolve(r.data)
      })
      .catch(e => {
        reject(e)
      })
  })
}

const enviarFacturacion = facturacion => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.pedido,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })

    instance
      .post('Pedido/FacturaPedido', facturacion)
      .then(r => {
        resolve(r.data)
      })
      .catch(e => {
        reject(e)
      })
  })
}

const ApiGeneral = {
  getCFDI,
  getPais,
  getEstadoPais,
  getMunicipoEstado,
  getColoniasByMunicipios,
  enviarNotificacion,
  getColonias,
  getCategorias,
  getDirecciones,
  createDir,
  getLocation,
  editeDir,
  editeMisDatos,
  metodoPago,
  getPlantillas,
  getPlantillasActivas,
  getContactoId,
  getContactos,
  getPlantillaId,
  formaPago,
  getMisDatos,
  enviarFacturacion,
  getCP,
  editeColaborador,
  addColaborador,
  getColaborador,
  getMisDatosC,
  getLeadForm,
}

export default ApiGeneral
