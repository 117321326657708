import React, { useEffect, useState, useContext } from 'react'

// import { Box, Grid, Button, Typography, MenuItem } from '@material-ui/core'
// import { Card } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { COLORS } from '../../Views/Colores'
import './ima.css'
import {
  // getBanerT,
  getOferta,
  getOfertas,
  getTime,
} from '../../actions/resultActions'
import {
  getProductosConPromo,
  getMoneda,
} from '../../components/Result/Mask/catalogorequest'
import { Link, NavLink } from 'react-router-dom'

// import Container from '@material-ui/core/Container'
import { Image } from 'antd'
import Carousel from 'react-multi-carousel'
import Estrellas from '../../Views/Calificacion/Estrellas'
import Context from '../../store/Context'
import GlobalContext from '../../contexts/GlobalContext'
import Count from './Count'
// import Elementos from './Elemento'
// import { height } from '@material-ui/system'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '3%',
  },
  gridConten: {
    width: '100%',
  },
  titulo: {
    paddingRight: '-100%',
    width: '100%',
    color: '#ffff',
    fontWeight: 'bolder',
  },
  btnsubnmit: {
    background: COLORS.black,
    color: '#ffff!important',
    backgroundColor: COLORS.black,
    marginTop: '5%',
    marginLeft: '10%',
  },
  subtitulo: {
    width: '100%',
    color: '#ffff',
  },
  icon: {
    margin: theme.spacing(1),
    borderRadius: '5em',
  },
}))

function Elemento(props) {
  const { monedaState } = useContext(GlobalContext)
  const context = useContext(Context)
  const classes = useStyles()
  useEffect(() => {
    //  getPromos();
    BannersGet()
  }, [])
  const [moneda, setMoneda] = useState(() => monedaState)

  // const getMonedas = async () => {
  //   const moned = await getMoneda()
  //   window.localStorage.setItem('monedasup', moned.data.abreviatura)

  //   setMoneda(moned.data)
  //   console.log(moned)
  // }

  // useEffect(() => {
  //   //  getPromos();
  //   getMonedas()
  // }, [])

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1336 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }
  const [response, setResponse] = useState(null)
  const [responseOferta, setResponseOferta] = useState(null)
  const [fecha, setFecha] = useState(null)

  const [tamano, settamano] = useState('')
  const BannersGet = async () => {
    const fechas = await getTime(props.id)
    setFecha(fechas.data)
    const responseOferta = await getOfertas(props.id)
    setResponseOferta(responseOferta.data)
    const response = await getOferta(props.id)
    setResponse(response.data)
  }
  const Cards = ({
    img,
    nombre,
    descripcion,
    id,
    precio,
    activoDescuento,
    precioA,
  }) => {
    return (
      <div>
        <div className='caru'>
          <div className='ofertaimg'>
            <br></br>
            <Link to={`/products/${id}/p`}>
              <Image preview={false} src={img}></Image>
            </Link>
          </div>
          <div className='contenido'>
            <div>
              <span className='name'>{nombre}</span>
            </div>
            {nombre.length < 25 && (
              <div>
                <br></br>
              </div>
            )}
            <div className='stars'>
              <Estrellas idProducto={id} calificacion={5} pd={'red'} ml={0} />
            </div>
            <br></br>
            {activoDescuento === true && (
              <div>
                <del>
                  <span className='priceA'>${precio.toFixed(2)}</span>
                  <br></br>
                </del>
                <span className='price'>${precioA.toFixed(2)}</span>
                <br></br>
              </div>
            )}
            {activoDescuento === false && (
              <div>
                <br></br>
                <span className='price'>${precio.toFixed(2)}</span>
              </div>
            )}

            <div className='descripcion'>
              <span>{descripcion}</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div>
      {response !== null && (
        <div>
          <div className='ofer'>
            <div className='titleColeccion1'>
              <span>{responseOferta.titulo}</span>
            </div>
            <div className='mydat'>
              <Count
                hrs={fecha.horas}
                dias={fecha.dias}
                mins={fecha.minutos}
                secs={fecha.segundos}
              />
            </div>
          </div>
          <br></br>
          <Carousel responsive={responsive}>
            {response.map(p => (
              <div>
                {moneda.abreviatura === 'MXN' && (
                  <Cards
                    img={p.url}
                    nombre={p.nombre}
                    descripcion={p.descripcion}
                    id={p.productoId}
                    precio={p.precio * context.getTipoCambio}
                    activoDescuento={p.activoDescuento}
                    precioA={p.precioDescuento * context.getTipoCambio}
                  />
                )}
                {moneda.abreviatura === 'USD' && (
                  <Cards
                    img={p.url}
                    nombre={p.nombre}
                    descripcion={p.descripcion}
                    id={p.productoId}
                    precio={p.precio}
                    activoDescuento={p.activoDescuento}
                    precioA={p.precioDescuento}
                  />
                )}
                <br></br>
              </div>
            ))}
          </Carousel>
        </div>
      )}
      <br></br>
    </div>
  )
}

export default Elemento
