import {
  Button,
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import PixelR from '../GoogleAnalitycs/PixelR'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import { Beenhere, LocalShipping } from '@material-ui/icons'
import Check from '@material-ui/icons/Check'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import SettingsIcon from '@material-ui/icons/Settings'
import clsx from 'clsx'
import moment from 'moment'
import PropTypes from 'prop-types'
import LoadingScreen from '../RegistroCliente/LoadingScreen'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { getCarrito } from '../../actions/carritoActions'
import pedidoActions from '../../actions/pedidoActions'
import Context from '../../store/Context'
import useWindowSize from '../../utils/useWindowSize'
import PedidoDetalle from './Detalle/PedidoDetalle'
import ApiGeneral from '../../services/ApiGeneral'
import UserContext from '../../contexts/user/UserContext'
import TipoDevolucion from './Detalle/TipoDevolucion'
import { postEstatus, Devolver } from '../Result/Mask/catalogorequest'
import './Styles.css'
import GlobalContext from '../../contexts/GlobalContext'

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
})

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles()
  const { active, completed } = props

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  )
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
}

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: '#1c476d',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundColor: '#1c476d',
  },
})

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles()
  const { active, completed } = props

  const icons = {
    1: <SettingsIcon className='pedido-detalle-icon' />,
    2: <LocalShipping className='pedido-detalle-icon' />,
    3: <Beenhere className='pedido-detalle-icon' />,
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  )
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  list: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  table: {
    minWidth: 900,
  },
  center: {
    textAlign: 'center',
  },
  card: {
    height: '85%',
    marginTop: '10px',
    textAlign: 'center',
    borderRadius: '10px',
  },
  titulo: {
    color: 'white',
  },
}))

export default function Pedidos() {
  const { UserState } = useContext(UserContext)
  const { monedaState } = useContext(GlobalContext)
  const { responsiveSize } = useWindowSize()
  const classes = useStyles()
  const context = useContext(Context)
  const history = useHistory()

  const [currentDireccion, setCurrentDireccion] = useState(null)
  const [ped, setPed] = useState(null)
  const [misDatos, getClienteDatos] = useState([])
  const [misDatosId, getClienteDatosId] = useState('')
  const [moneda, setMoneda] = useState(() => monedaState)
  const [openModal, setopenModal] = useState(false)
  const [pedidos, setPedidos] = useState(null)
  const [pedido, setPedido] = useState(null)
  const [pedidoIn, setPedidoIn] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const [almacen, setAlmacen] = useState({
    id: '',
    pedidoId: '',
    correo: '',
    monto: '',
    tipoDevolucion: false,
    idCliente: '',
    idDevolucionEnvio: '',

    envio: { address_from: {}, carriers: [] },
  })

  const getDireccionPrincipal = async () => {
    const direccion = await pedidoActions.getDireccionPrincial()

    setCurrentDireccion(direccion)
  }

  const handleopenModal = () => {
    setopenModal(!openModal)
  }

  const getMisDatos = () => {
    getClienteDatosId(UserState.idCliente)

    ApiGeneral.getMisDatos(UserState.idCliente)
      .then(response => {
        getClienteDatos(response.data)
        console.log(response.data)
      })
      .catch(e => {
        console.error('error data:' + e)
      })
  }

  const postStatus = async (id, estado) => {
    const moned = await postEstatus(id, estado)
    getPedidos()
  }

  const getPedidos = async () => {
    let pedidos = await pedidoActions.getPedidos()
    setPedidos(pedidos)
  }

  const envioPost = async pedido => {
    almacen.id = '00000000-0000-0000-0000-000000000000'
    almacen.pedidoId = pedido.pedido
    almacen.monto = 0
    almacen.idCliente = misDatosId
    almacen.correo = ''
    almacen.tipoDevolucion = false

    almacen.idDevolucionEnvio = pedido.idEnvio
    almacen.envio.address_from.province = 'Nuevo Leon'
    almacen.envio.address_from.city = currentDireccion.municipio
    almacen.envio.address_from.name = misDatos.nombre
    almacen.envio.address_from.zip = currentDireccion.cp
    almacen.envio.address_from.country = 'MX'
    almacen.envio.address_from.address1 =
      currentDireccion.colonia + ' ' + currentDireccion.noExt
    almacen.envio.address_from.company = 'Cliente'
    almacen.envio.address_from.address2 =
      currentDireccion.colonia + ' ' + currentDireccion.noExt
    almacen.envio.address_from.phone = misDatos.telefono
    almacen.envio.address_from.email = misDatos.email
    let carriers = []
    let name = {
      name: 'DHL',
    }
    let name2 = {
      name: 'Fedex',
    }
    carriers.push(name, name2)
    almacen.envio.carriers = carriers

    setAlmacen(almacen)
    console.log(almacen)
  }

  const postDevolver = async pe => {
    setIsLoading(true)
    envioPost(pe)
    // const moned = await Devolver(almacen)
    setIsLoading(false)
  }

  useEffect(() => {
    getDireccionPrincipal()
    getMisDatos()
  }, [])

  useEffect(() => {
    console.log(responsiveSize)
  }, [responsiveSize])

  useEffect(() => {
    getPedidos()
  }, [])

  return pedido === null ? (
    responsiveSize === 'sm' ? (
      pedidos.map(p => (
        <Card
          style={{ marginBottom: 20 }}
          onClick={() => {
            window.scroll({ top: 0, behavior: 'smooth' })
            setPedido(p.pedido)
            setPedidoIn(p)
          }}
        >
          <CardContent>
            <PixelR nombres={'Mis pedidos'} />

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>
                <strong>Id</strong>: {p.pedido}
              </Typography>
              <Typography>
                {moment(p.fechaPedido).format('DD/MM/YYYY')}
              </Typography>
            </div>
            <Typography
              component='div'
              style={{ backgroundColor: p.estatus.color }}
              className={classes.card}
            >
              <Typography className={classes.titulo} gutterBottom>
                {p.estatus.nombre}
              </Typography>
            </Typography>
            <Typography>
              <Fragment>
                <strong>Entrega</strong>:{' '}
                {p.entregaDomicilio ? (
                  !!p.direccion ? (
                    // ? p.direccion.calle p.direccion.noInt<br/>Col. p.direccion.colonia<br/>CP. p.direccion.cp
                    <div>
                      {p.direccion.calle} {p.direccion.noInt}
                      <br />
                      CP. {p.direccion.cp} | Col. {p.direccion.colonia}
                    </div>
                  ) : (
                    ''
                  )
                ) : !!p.almacen ? (
                  p.almacen.nombre
                ) : (
                  ''
                )}
              </Fragment>
            </Typography>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              {moneda.abreviatura === 'USD' && (
                <div>
                  <Typography>
                    <strong>Tipo de cambio</strong>:{' '}
                    {p.tipoCambio.toLocaleString('es-MX', {
                      style: 'currency',
                      currency: 'MXN',
                      minimumFractionDigits: 4,
                    })}
                  </Typography>
                </div>
              )}

              <Typography>
                <strong>Total</strong>:{' '}
                {p.total.toLocaleString('es-MX', {
                  style: 'currency',
                  currency: 'MXN',
                })}{' '}
                MXN
              </Typography>
            </div>
          </CardContent>
        </Card>
      ))
    ) : (
      <div className={classes.root}>
        <label>Mis pedidos</label>
        <PixelR nombres={'Mis pedidos'} />
        <TableContainer
          style={{ boxShadow: 'none', overflowX: 'unset' }}
          component={Paper}
        >
          <LoadingScreen open={isLoading} />
          <Table
            className={classes.table}
            aria-label='simple table'
            style={{ width: '100%' }}
          >
            <TableHead>
              <TableRow>
                <TableCell align='right' colSpan={8}></TableCell>
              </TableRow>
              <TableRow
                style={{
                  borderLeft: '1px solid #e0e0e0',
                  borderRight: '1px solid #e0e0e0',
                }}
              >
                <TableCell
                  align='center'
                  style={{ pagging: '8px 16px', width: '10%' }}
                >
                  Núm. Pedido
                </TableCell>
                <TableCell
                  align='center'
                  style={{ pagging: '8px 16px', width: '10%' }}
                >
                  Estatus
                </TableCell>
                <TableCell
                  align='center'
                  style={{ pagging: '8px 16px', width: '10%' }}
                >
                  Fecha pedido
                </TableCell>
                {moneda.abreviatura === 'USD' && (
                  <TableCell
                    align='center'
                    style={{ pagging: '8px 16px', width: '10%' }}
                  >
                    Tipo de cambio
                  </TableCell>
                )}
                <TableCell
                  align='center'
                  style={{ pagging: '8px 16px', width: '10%' }}
                >
                  Total
                </TableCell>
                <TableCell
                  align='center'
                  style={{ pagging: '815x 16px', width: '15%' }}
                >
                  Dirección
                </TableCell>
                <TableCell
                  align='center'
                  style={{ pagging: '8px 16px', width: '10%' }}
                >
                  Acciones
                </TableCell>
                <TableCell
                  align='center'
                  style={{ pagging: '8px 16px', width: '15%' }}
                >
                  Ver Detalle
                </TableCell>
                <TableCell
                  align='center'
                  style={{ pagging: '8px 16px', width: '10%' }}
                >
                  {''}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pedidos &&
                pedidos.map(p => (
                  <TableRow
                    key={p.pedido}
                    style={{
                      borderLeft: '1px solid #e0e0e0',
                      borderRight: '1px solid #e0e0e0',
                    }}
                  >
                    <TableCell align='center'>{p.pedido}</TableCell>
                    <TableCell align='center'>
                      <Typography
                        component='div'
                        style={{ backgroundColor: p.estatus.color }}
                        className={classes.card}
                      >
                        <Typography className={classes.titulo} gutterBottom>
                          {p.estatus.nombre}
                        </Typography>
                      </Typography>
                    </TableCell>
                    <TableCell align='center'>
                      {moment(p.fechaPedido).format('DD/MM/YYYY')}
                    </TableCell>
                    {moneda.abreviatura === 'USD' && (
                      <TableCell align='center'>
                        {p.tipoCambio.toLocaleString('es-MX', {
                          style: 'currency',
                          currency: 'MXN',
                          minimumFractionDigits: 4,
                        })}
                      </TableCell>
                    )}
                    {moneda.abreviatura === 'USD' && (
                      <TableCell align='center'>
                        {(p.total / context.getTipoCambio).toLocaleString(
                          'es-MX',
                          {
                            style: 'currency',
                            currency: 'MXN',
                          }
                        )}
                      </TableCell>
                    )}
                    {moneda.abreviatura === 'MXN' && (
                      <TableCell align='center'>
                        {p.total.toLocaleString('es-MX', {
                          style: 'currency',
                          currency: 'MXN',
                        })}
                      </TableCell>
                    )}

                    <TableCell align='center'>
                      {p.entregaDomicilio ? (
                        !!p.direccion ? (
                          // ? p.direccion.calle p.direccion.noInt<br/>Col. p.direccion.colonia<br/>CP. p.direccion.cp
                          <div className={classes.center}>
                            {p.direccion.calle} {p.direccion.noInt}
                            <br />
                            Col. {p.direccion.colonia}
                            <br />
                            CP. {p.direccion.cp}
                          </div>
                        ) : (
                          ''
                        )
                      ) : !!p.almacen ? (
                        <div className={classes.center}>
                          {p.almacen.locacion}{' '}
                        </div>
                      ) : (
                        ''
                      )}
                    </TableCell>
                    <TableCell align='center'>
                      {p.estatus.id === 6 && (
                        <div>
                          <Button
                            onClick={() => {
                              postStatus(p.pedido, 7)
                            }}
                            size='small'
                            variant='contained'
                            color='primary'
                          >
                            Recibido
                          </Button>
                        </div>
                      )}
                      {p.estatus.id === 7 && (
                        <div>
                          <Button
                            onClick={() => {
                              if (p.entregaDomicilio === true) {
                                setPed(p)
                                handleopenModal()
                              } else {
                                postDevolver(p)
                              }
                            }}
                            size='small'
                            variant='contained'
                            color='primary'
                          >
                            Devolver
                          </Button>
                        </div>
                      )}
                    </TableCell>
                    <TableCell align='center'>
                      <Button
                        onClick={() => {
                          const carritoId =
                            window.localStorage.getItem('carritoID')
                          const data = {
                            carritoId,
                            folio: p.pedido,
                          }
                          pedidoActions
                            .buyAgain(data)
                            .then(() => {
                              const carritoId =
                                window.localStorage.getItem('carritoID')
                              return getCarrito(carritoId)
                            })
                            .then(response => {
                              var carrito = response.data
                              carrito.items.forEach(i => {
                                context.addCartFromRedis({
                                  quantity: i.cantidad,
                                  _id: i.id,
                                  price: i.precio,
                                  images: i.imagen,
                                  title: i.producto,
                                  peso: i.peso,
                                  familiaId: i.categoriaId,
                                })
                              })
                              history.push('/tienda/articulos/carrito/pago')
                            })
                            .catch(e => {
                              console.log(e)
                            })
                        }}
                        size='small'
                        variant='contained'
                        color='primary'
                      >
                        Volver a comprar
                      </Button>
                    </TableCell>

                    <TableCell align='center'>
                      <IconButton
                        color='primary'
                        aria-label='detalle'
                        component='span'
                        onClick={() => {
                          window.scroll({ top: 0, behavior: 'smooth' })
                          setPedido(p.pedido)
                          setPedidoIn(p)
                        }}
                      >
                        <LocalShippingIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TipoDevolucion
          pedido={ped}
          open={openModal}
          onClose={handleopenModal}
        />
      </div>
    )
  ) : (
    <PedidoDetalle
      pedido={pedido}
      pedidoInd={pedidoIn}
      setPedido={setPedido}
      getPedidos={getPedidos}
    />
  )
}
