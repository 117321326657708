import React, { useContext, useEffect, useState } from 'react'
// import Mask from '../Result/Mask/integrinoxoriginal.css'
// import {
//   getUrlForProductImg,
//   getFilesDownload,
//   getUrlsForImgPlantilla,
// } from '../../utils/ima'
// import { Link, useParams } from 'react-router-dom'
// import ApiGeneral from '../../services/ApiGeneral'
// import { useHistory } from 'react-router-dom'
import {
  // AppBar,
  // Box,
  // Button,
  // Drawer,
  // IconButton,
  makeStyles,
  // MenuItem,
  // Toolbar,
} from '@material-ui/core'
// import { getEle, getLogo } from '../../actions/resultActions'
import { getTarjetas } from '../Result/Mask/catalogorequest'
import CardTarjeta from '../Result/Mask/CardTarjeta'
import PixelR from '../GoogleAnalitycs/PixelR'

// import { COLORS } from '../../Views/Colores'
import StylesContext from '../../contexts/styles/stylesContext'
import GlobalContext from '../../contexts/GlobalContext'
import Context from '../../store/Context'

// const Header = React.lazy(() =>
//   import('../../components/Header/HeaderResponsive')
// )
// const Footer = React.lazy(() => import('../../Views/Footer/FooterMenu'))

const useStyles2 = makeStyles(() => ({
  header: {
    backgroundColor: '#000',
    paddingLeft: '70px',
    zIndex: 999,
    position: 'inherit',
    '@media (max-width: 900px)': {
      paddingLeft: 1,
    },
  },
  logo: {
    width: '20%',

    display: 'flex',
  },
  menuButton: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 250,
    size: '14px',
    marginLeft: '5px',
    color: '#003462',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  drawerContainer: {
    padding: '20px 30px',
  },
  button: {},
  textfildMargin: {
    width: '17rem',
    height: '9px !important',
    paddingbottom: '2.5px',
  },
  btnRegis: {
    width: '19rem',
  },
  gridAlings: {
    textAlign: 'center',
    padding: '1rem',
  },
  btnSocial: {
    textAlign: 'center',
    padding: '1rem',
  },
}))

const Plantillas = ({}) => {
  const context = useContext(Context)
  const { monedaState } = useContext(GlobalContext)
  const stylesContext = useContext(StylesContext)
  const classes = useStyles2()
  const [tarjetas, setTarjetas] = useState([])
  const TarjetaGet = async () => {
    const responses = await getTarjetas()
    setTarjetas(responses.data)
  }
  useEffect(() => {
    TarjetaGet()
  }, [])
  const [moneda, setMoneda] = useState(() => monedaState)

  // const getMonedas = async () => {
  //   const moned = await getMoneda()
  //   window.localStorage.setItem('monedasup', moned.data.abreviatura)

  //   setMoneda(moned.data)
  //   window.localStorage.setItem('monedas', moned.data.abreviatura)

  //   console.log(moned)
  // }

  const [logop, setLogop] = useState(() => stylesContext.logo)
  // const getPlantilla = async () => {
  //   const response2 = await getLogo()
  //   setLogop(response2.data.url)
  // }

  // useEffect(() => {
  //   //  getPromos();
  //   // getPlantilla()
  //   getMonedas()
  // }, [])

  // document.documentElement.style.setProperty('--color-principal', COLORS.black)

  const nodata = (
    <div style={{ textAlign: 'center' }}>
      <br></br>

      <div>
        <h2 className='title-NotFound'>No se encontraron resultados</h2>
      </div>

      <div>
        <img style={{ textAlign: 'center' }} src='/img/DEFAULT1.png' alt='' />
      </div>
    </div>
  )
  const datos = (
    <div>
      <PixelR nombres={'Tarjeta de regalo'} />

      {/* <Header logop={logop} colors={stylesContext.black} nav={true}></Header> */}
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div className='product-items'>
        {tarjetas.map(p => (
          <div>
            {moneda.abreviatura === 'MXN' && (
              <div>
                <CardTarjeta
                  key={p.id}
                  id={p.id}
                  price={p.monto}
                  imagenPereview={
                    p.urls === null ? '/img/IMAGEN_NO_DISPONIBLE.png' : p.urls
                  }
                  sku={'Tarjeta de regalo'}
                  nombre={p.nombre}
                  sprice={p.monto}
                  moned={'MXN'}
                />
              </div>
            )}
            {moneda.abreviatura === 'USD' && (
              <div>
                <CardTarjeta
                  key={p.id}
                  id={p.id}
                  price={p.monto / context.getTipoCambio}
                  imagenPereview={
                    p.urls === null ? '/img/IMAGEN_NO_DISPONIBLE.png' : p.urls
                  }
                  sku={'Tarjeta de regalo'}
                  nombre={p.nombre}
                  sprice={p.monto / context.getTipoCambio}
                  moned={'USD'}
                />
              </div>
            )}
          </div>
        ))}
      </div>
      {/* <Footer AligmentText='left' /> */}
    </div>
  )

  const result = tarjetas.length == 0 ? nodata : datos

  return result
}

export default Plantillas
