import React, { useEffect, useState } from 'react'
import { FormHelperText } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { Select, InputLabel, FormControl, Box } from '@material-ui/core'
import { useFormik } from 'formik'
import * as yup from 'yup'
import ApiGeneral from '../../services/ApiGeneral'
import AuthCliente from '../../services/AuthCliente'
import errores from '../../services/errores'

const EditarMisDatos = ({
  misDatos,
  getMisDatos,
  handleCloseDirecciones,
  setType,
  setMessage,
  setSBOpen,
}) => {
  let dir =
    misDatos.datosFacturacion == null ? '' : misDatos.datosFacturacion.rfc

  const [open, setOpen] = useState(true)
  const [tipoCfdi, setTipoCfdi] = useState([])
  const [FormaPago, setFormaPago] = useState([])
  const [MetodoPago, setMetodoPago] = useState([])
  const [RFCVal, setRFCVal] = useState(dir)
  const [rfcVerified, setRfcVerified] = useState(false)
  const [_datosFacturacion, setDatosFacturacion] = useState(
    misDatos.datosFacturacion == null
      ? null
      : {
          rfc: misDatos.datosFacturacion.rfc,
          calle: misDatos.datosFacturacion.calle,
          noExt: misDatos.datosFacturacion.noExt,
          noInt: misDatos.datosFacturacion.noInt,
          coloniaId: misDatos.datosFacturacion.coloniaId,
          usoCFDIId: misDatos.datosFacturacion.usoCFDIId,
          idFacturacionCliente: misDatos.datosFacturacion.idFacturacionCliente,
          clienteId: misDatos.datosFacturacion.clienteId,
          usuarioCreoId: misDatos.datosFacturacion.clienteId,
          usuarioModId: misDatos.datosFacturacion.clienteId,
        }
  )

  const validate = values => {
    const errors = {}
    if (rfcVerified) {
      errors.rfc = 'El RFC ingresado ya existe, favor de ingresar otro'
    }
    return errors
  }

  const validationSchema = yup.object(
    misDatos.tipoCliente.includes('moral')
      ? {
          razonSocial: yup
            .string()
            .required('El campo razón social es requerido'),
          telefono: yup
            .string()
            .min(10, 'El teléfono debe tener un mínimo de 10 caracteres.')
            .max(10, 'El teléfono debe tener un máximo de 10 caracteres.')
            .required('El campo teléfono es requerido.'),
          celular: yup
            .string()
            .min(
              10,
              'El teléfono adicional debe tener un mínimo de 10 caracteres.'
            )
            .max(
              10,
              'El teléfono adicional debe tener un máximo de 10 caracteres.'
            ),
          rfc: yup
            .string()
            .min(12, 'El RFC social debe tener un mínimo de 12 caracteres.')
            .max(13, 'El RFC social debe tener un máximo de 13 caracteres.')
            .required(
              RFCVal
                ? rfcVerified
                  ? 'Hola'
                  : ''
                : 'El campo RFC es requerido.'
            ),
          calle: yup.string().required('El campo calle es requerido.'),
          usoCFDIId: yup
            .number()
            .min(1, 'El campo CDFI es requerido.')
            .required(),
          formaPagoId: yup
            .number()
            .min(1, 'El campo forma de pago es requerido.')
            .required('El campo forma de pago es requerido.'),
          metodoPagoId: yup
            .number()
            .min(1, 'El campo método de pago es requerido.')
            .required('El campo método de pago es requerido.'),
          noExt: yup
            .string()
            .required('El campo número exterior es requerido.'),
        }
      : {
          nombre: yup.string().required('El nombre es requerido.'),
          aPaterno: yup.string().required('El apellido paterno es requerido.'),
          telefono: yup
            .string()
            .min(10, 'El teléfono debe tener un mínimo de 10 caracteres.')
            .max(10, 'El teléfono debe tener un máximo de 10 caracteres.')
            .required('El campo teléfono es requerido.'),
          celular: yup
            .string()
            .min(
              10,
              'El teléfono adicional debe tener un mínimo de 10 caracteres.'
            )
            .max(
              10,
              'El teléfono adicional debe tener un máximo de 10 caracteres.'
            ),
          formaPagoId: yup
            .number()
            .min(1, 'El campo forma de pago es requerido.')
            .required('El campo forma de pago es requerido.'),
          metodoPagoId: yup
            .number()
            .min(1, 'El campo método de pago es requerido.')
            .required('El campo método de pago es requerido.'),
        }
  )

  const formik = useFormik({
    initialValues: {
      idCliente: misDatos.idCliente,
      tipoClienteId: misDatos.tipoClienteId,
      nombre: misDatos.nombre,

      razonSocial: misDatos.razonSocial == null ? '' : misDatos.razonSocial,
      aPaterno: misDatos.aPaterno == null ? '' : misDatos.aPaterno,
      aMaterno: misDatos.aMaterno == null ? '' : misDatos.aMaterno,
      email: misDatos.email == null ? '' : misDatos.email,
      telefono: misDatos.telefono == null ? '' : misDatos.telefono,
      celular: misDatos.celular == null ? '' : misDatos.celular,
      activo: true,
      formaPagoId: misDatos.formaPagoId == null ? 0 : misDatos.formaPagoId,
      metodoPagoId: misDatos.metodoPagoId == null ? 0 : misDatos.metodoPagoId,
      usuarioCreoId: misDatos.idCliente,
      usuarioModId: misDatos.idCliente,
      datosFacturacion: _datosFacturacion == null ? '' : _datosFacturacion,
      rfc: _datosFacturacion == null ? '' : _datosFacturacion.rfc,
      noExt: _datosFacturacion == null ? '' : _datosFacturacion.noExt,
      noInt: _datosFacturacion == null ? '' : _datosFacturacion.noInt,

      calle: _datosFacturacion == null ? '' : _datosFacturacion.calle,
      usoCFDIId: _datosFacturacion == null ? 0 : _datosFacturacion.usoCFDIId,
    },
    validate,
    validationSchema: validationSchema,
    onSubmit: values => {
      var editDatos = null
      if (_datosFacturacion != null) {
        _datosFacturacion.rfc = RFCVal
        _datosFacturacion.noExt = values.noExt
        _datosFacturacion.noInt = values.noInt

        _datosFacturacion.calle = values.calle
        _datosFacturacion.usoCFDIId = values.usoCFDIId
      }

      if (misDatos.tipoCliente.includes('moral')) {
        editDatos = {
          idCliente: values.idCliente,
          tipoClienteId: values.tipoClienteId,
          razonSocial: values.razonSocial,
          email: values.email,
          telefono: values.telefono,
          celular: values.celular,
          activo: true,
          formaPagoId: values.formaPagoId,
          metodoPagoId: values.metodoPagoId,
          usuarioCreoId: values.idCliente,
          usuarioModId: values.idCliente,
          datosFacturacion: _datosFacturacion,
        }
      } else {
        editDatos = {
          idCliente: values.idCliente,
          tipoClienteId: values.tipoClienteId,
          nombre: values.nombre,

          razonSocial: values.razonSocial,
          aPaterno: values.aPaterno,
          aMaterno: values.aMaterno,
          email: values.email,
          telefono: values.telefono,
          celular: values.celular,
          activo: true,
          formaPagoId: values.formaPagoId,
          metodoPagoId: values.metodoPagoId,
          usuarioCreoId: values.idCliente,
          usuarioModId: values.idCliente,
          datosFacturacion: values.datosFacturacion,
        }
      }
      ApiGeneral.editeMisDatos(editDatos)
        .then(r => {
          handleClose()
          handleCloseDirecciones()
          setSBOpen(true)
          setMessage('Los datos se han actualizado con éxito.')
          setType('success')
          getMisDatos()
          console.info('Los datos se han actualizado con éxito', r)
        })
        .catch(e => {
          handleClose()
          handleCloseDirecciones()
          setSBOpen(true)
          setMessage(errores.handleError('error', e))
          setType('error')
        })
    },
  })

  useEffect(() => {
    const getCfdi = () => {
      ApiGeneral.getCFDI(3)
        .then(response => {
          setTipoCfdi(response)

          console.info('éxito data cfdi:' + response)
        })
        .catch(error => {
          console.error('error data:' + error)
        })
    }
    const getFormaPgo = () => {
      ApiGeneral.formaPago(1)
        .then(response => {
          setFormaPago(response)
        })
        .catch(error => {
          console.error('error data:' + error)
        })
    }
    const getMetodoPgo = () => {
      ApiGeneral.metodoPago(2)
        .then(response => {
          setMetodoPago(response)
        })
        .catch(error => {
          console.error('error data:' + error)
        })
    }

    getCfdi()
    getFormaPgo()
    getMetodoPgo()
  }, [])

  const handleRFC = event => {
    formik.values.rfc = event.currentTarget.value
    setRFCVal(event.currentTarget.value)
    AuthCliente.existsRFCv2(event.currentTarget.value)
      .then(r => {
        if (r.data.helperData === null) {
          setRfcVerified(false)
          // formik.errors.rfc = '';
        } else {
          setRfcVerified(true)
          // formik.errors.rfc = "El RFC ingresado ya existe, favor de ingresar otro.";
        }
        validate()
        console.info('El RFC ingresado ya existe, favor de ingresar otro.', r)
      })
      .catch(e => {
        setRfcVerified(false)
        validate()

        console.info('error catch rfc', e)
      })
  }

  const handleClose = () => {
    setOpen(false)
  }

  const renderSelectOptions = data =>
    data.map((object, index) =>
      object.activo ? (
        <option key={index} value={object.idGeneral}>
          {object.descripcionLarga}
        </option>
      ) : null
    )

  if (misDatos.tipoCliente.includes('moral')) {
    return (
      <form onSubmit={formik.handleSubmit}>
        <Box mb={1}>
          <TextField
            label='Razón Social*'
            margin='dense'
            id='razonSocial'
            name='razonSocial'
            type='text'
            fullWidth
            onChange={formik.handleChange}
            value={formik.values.razonSocial}
            onBlur={formik.handleBlur}
            error={
              formik.touched.razonSocial && Boolean(formik.errors.razonSocial)
            }
            helperText={formik.touched.razonSocial && formik.errors.razonSocial}
          />
        </Box>

        <Box mb={1}>
          <TextField
            label='RFC*'
            margin='dense'
            id='rfc'
            name='rfc'
            type='text'
            fullWidth
            onChange={handleRFC}
            value={RFCVal}
            onBlur={handleRFC && formik.handleBlur}
            error={formik.touched.rfc && Boolean(formik.errors.rfc)}
            helperText={formik.touched.rfc && formik.errors.rfc}
          />
        </Box>

        <Box mb={1}>
          <FormControl fullWidth margin='dense'>
            <InputLabel htmlFor='usoCFDIId'>CFDI*</InputLabel>
            <Select
              native
              label='CFDI*'
              inputProps={{
                name: 'usoCFDIId',
                id: 'usoCFDIId',
              }}
              value={formik.values.usoCFDIId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option aria-label='None' value={0}></option>
              {renderSelectOptions(tipoCfdi)}
            </Select>
            <FormHelperText error>
              {formik.touched.usoCFDIId && formik.errors.usoCFDIId}
            </FormHelperText>
          </FormControl>
        </Box>

        <Box mb={1}>
          <TextField
            label='Calle*'
            margin='dense'
            id='calle'
            name='calle'
            type='text'
            fullWidth
            onChange={formik.handleChange}
            value={formik.values.calle}
            onBlur={formik.handleBlur}
            error={formik.touched.calle && Boolean(formik.errors.calle)}
            helperText={formik.touched.calle && formik.errors.calle}
          />
        </Box>

        <Box mb={1}>
          <TextField
            label='No. Ext*'
            margin='dense'
            id='noExt'
            name='noExt'
            type='text'
            fullWidth
            onChange={formik.handleChange}
            value={formik.values.noExt}
            onBlur={formik.handleBlur}
            error={formik.touched.noExt && Boolean(formik.errors.noExt)}
            helperText={formik.touched.noExt && formik.errors.noExt}
          />
        </Box>

        <Box mb={1}>
          <TextField
            label='No. Int'
            margin='dense'
            id='noInt'
            name='noInt'
            type='text'
            fullWidth
            onChange={formik.handleChange}
            value={formik.values.noInt}
            onBlur={formik.handleBlur}
            error={formik.touched.noInt && Boolean(formik.errors.noInt)}
            helperText={formik.touched.noInt && formik.errors.noInt}
          />
        </Box>

        <Box mb={1}>
          <TextField
            margin='dense'
            id='telefono'
            name='telefono'
            label='Teléfono*'
            placeholder='Teléfono'
            type='number'
            fullWidth
            onChange={formik.handleChange}
            value={formik.values.telefono}
            onBlur={formik.handleBlur}
            error={formik.touched.telefono && Boolean(formik.errors.telefono)}
            helperText={formik.touched.telefono && formik.errors.telefono}
          />
        </Box>

        <Box mb={1}>
          <TextField
            margin='dense'
            id='celular'
            name='celular'
            label='Teléfono adicional'
            placeholder='Teléfono adicional'
            type='number'
            fullWidth
            onChange={formik.handleChange}
            value={formik.values.celular}
            onBlur={formik.handleBlur}
            error={formik.touched.celular && Boolean(formik.errors.celular)}
            helperText={formik.touched.celular && formik.errors.celular}
          />
        </Box>

        <Box mb={1}>
          <FormControl fullWidth margin='dense'>
            <InputLabel htmlFor='cmbFormaPago'>Forma de pago*</InputLabel>
            <Select
              native
              inputProps={{
                name: 'formaPagoId',
                id: 'cmbFormaPago',
              }}
              value={formik.values.formaPagoId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option aria-label='None' value={0}></option>
              {renderSelectOptions(FormaPago)}
            </Select>
            <FormHelperText error>
              {formik.touched.formaPagoId && formik.errors.formaPagoId}
            </FormHelperText>
          </FormControl>
        </Box>

        <Box mb={1}>
          <FormControl fullWidth margin='dense'>
            <InputLabel htmlFor='cmbMetodoPago'>Método de pago*</InputLabel>
            <Select
              native
              inputProps={{
                name: 'metodoPagoId',
                id: 'cmbMetodoPago',
              }}
              value={formik.values.metodoPagoId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option aria-label='None' value={0}></option>
              {renderSelectOptions(MetodoPago)}
            </Select>
            <FormHelperText error>
              {formik.touched.metodoPagoId && formik.errors.metodoPagoId}
            </FormHelperText>
          </FormControl>
        </Box>

        <Box textAlign='right' mt={2}>
          <Button
            onClick={handleCloseDirecciones}
            variant='outlined'
            color='default'
          >
            Cancelar
          </Button>
          <Button
            style={{ marginLeft: '10px' }}
            type='submit'
            variant='contained'
            color='primary'
          >
            Guardar
          </Button>
        </Box>
      </form>
    )
  } else {
    return (
      <form onSubmit={formik.handleSubmit}>
        <Box mb={1}>
          <TextField
            label='Nombre*'
            margin='dense'
            id='nombre'
            name='nombre'
            type='text'
            fullWidth
            onChange={formik.handleChange}
            value={formik.values.nombre}
            error={formik.touched.nombre && Boolean(formik.errors.nombre)}
            helperText={formik.touched.nombre && formik.errors.nombre}
          />
        </Box>

        <Box mb={1}>
          <TextField
            margin='dense'
            id='aPaterno'
            name='aPaterno'
            label='Apellido Paterno*'
            placeholder='Apellido Paterno'
            type='text'
            fullWidth
            onChange={formik.handleChange}
            value={formik.values.aPaterno}
            error={formik.touched.aPaterno && Boolean(formik.errors.aPaterno)}
            helperText={formik.touched.aPaterno && formik.errors.aPaterno}
          />
        </Box>

        <Box mb={1}>
          <TextField
            margin='dense'
            id='aMaterno'
            name='aMaterno'
            label='Apellido Materno'
            placeholder='Apellido Materno'
            type='text'
            fullWidth
            onChange={formik.handleChange}
            value={formik.values.aMaterno}
            error={formik.touched.aMaterno && Boolean(formik.errors.aMaterno)}
            helperText={formik.touched.aMaterno && formik.errors.aMaterno}
          />
        </Box>

        <Box mb={1}>
          <TextField
            margin='dense'
            id='telefono'
            name='telefono'
            label='Teléfono principal*'
            placeholder='Teléfono principal'
            type='number'
            fullWidth
            onChange={formik.handleChange}
            value={formik.values.telefono}
            error={formik.touched.telefono && Boolean(formik.errors.telefono)}
            helperText={formik.touched.telefono && formik.errors.telefono}
          />
        </Box>

        <Box mb={1}>
          <TextField
            margin='dense'
            id='celular'
            name='celular'
            label='Teléfono adicional'
            placeholder='Teléfono adicional'
            type='number'
            fullWidth
            onChange={formik.handleChange}
            value={formik.values.celular}
            error={formik.touched.celular && Boolean(formik.errors.celular)}
            helperText={formik.touched.celular && formik.errors.celular}
          />
        </Box>

        <Box mb={1}>
          <FormControl fullWidth margin='dense'>
            <InputLabel htmlFor='cmbFormaPago'>Forma de pago*</InputLabel>
            <Select
              native
              inputProps={{
                name: 'formaPagoId',
                id: 'cmbFormaPago',
              }}
              value={formik.values.formaPagoId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option aria-label='None' value={0}></option>
              {renderSelectOptions(FormaPago)}
            </Select>
            <FormHelperText error>
              {formik.touched.formaPagoId && formik.errors.formaPagoId}
            </FormHelperText>
          </FormControl>
        </Box>

        <Box mb={1}>
          <FormControl fullWidth margin='dense'>
            <InputLabel htmlFor='cmbMetodoPago'>Método de pago*</InputLabel>
            <Select
              native
              inputProps={{
                name: 'metodoPagoId',
                id: 'cmbMetodoPago',
              }}
              value={formik.values.metodoPagoId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option aria-label='None' value={0}></option>
              {renderSelectOptions(MetodoPago)}
            </Select>
            <FormHelperText error>
              {formik.touched.metodoPagoId && formik.errors.metodoPagoId}
            </FormHelperText>
          </FormControl>
        </Box>

        <Box textAlign='right' mt={2}>
          <Button
            onClick={handleCloseDirecciones}
            variant='outlined'
            color='default'
          >
            Cancelar
          </Button>
          <Button
            style={{ marginLeft: '10px' }}
            type='submit'
            variant='contained'
            color='primary'
          >
            Guardar
          </Button>
        </Box>
      </form>
    )
  }
}

export default EditarMisDatos
