import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { RecoveryPassword } from '../../services/catalogoService'
import Swal from 'sweetalert2'
import axios from 'axios'
import {
  Button,
  TextField,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@material-ui/core'
import LoadingScreen from '../../components/RegistroCliente/LoadingScreen'

const Container = styled.div`
  width: 100%;
  min-height: 360px;
  margin: 0 auto;

  /* Pantallas grandes (Desktop) */
  @media (min-width: 1024px) {
    max-width: 480px;
  }

  /* Tablets */
  @media (min-width: 768px) and (max-width: 1023px) {
    max-width: 50vw;
  }

  /* Tamaño intermedio entre móvil y tablet */
  @media (min-width: 600px) and (max-width: 767px) {
    max-width: 70vw;
  }

  /* Móviles */
  @media (max-width: 599px) {
    max-width: 100%;
  }
`

const Form = styled.form`
  width: 100%;
  padding: 20px;
  border-radius: 0.6rem;
  background-color: #f5f5f5;

  /* Pantallas grandes (Desktop) */
  @media (min-width: 1024px) {
    border: 2px solid #f2f2f2;
  }

  /* Tablets */
  @media (min-width: 768px) and (max-width: 1023px) {
    border: 2px solid #f2f2f2;
  }

  /* Tamaño intermedio entre móvil y tablet */
  @media (min-width: 600px) and (max-width: 767px) {
    border: 1px solid #e0e0e0;
  }

  /* Móviles */
  @media (max-width: 599px) {
    border: none;
    background-color: #ffffff;
  }
`

const Title = styled.div`
  text-align: center;
  font-size: 32px;
  font-weight: 600;
`

const RequestRecoveryPassword = () => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isEmailFromParams, setIsEmailFromParams] = useState(false)
  const controller = new AbortController()

  // Validación con Yup
  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Correo electrónico inválido')
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        'Introduzca un correo electrónico válido'
      )
      .required('Correo electrónico requerido'),
  })

  // Función para obtener la IP del usuario
  const getIPAddress = async () => {
    try {
      const res = await axios.get(
        'https://cors-anywhere.herokuapp.com/https://api.ipify.org?format=json'
      )
      return res.data.ip
    } catch {
      return '0.0.0.0'
    }
  }

  // Hook de Formik para manejar el formulario y validación
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: async values => {
      await sendRecoveryEmail(values.email)
    },
  })

  // Función para enviar el correo de recuperación
  const sendRecoveryEmail = async email => {
    setIsLoading(true)

    try {
      const response = await RecoveryPassword(controller.signal, {
        email,
        ipAddress: await getIPAddress(),
        userAgent: navigator.userAgent,
      })

      setIsLoading(false)

      console.log(response)

      if (response.success) {
        setIsSubmitted(true)
        Swal.fire({
          title: 'Correo enviado',
          text: response.message,
          icon: 'success',
          confirmButtonText: 'Aceptar',
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: '¡Oh no! Algo salió mal',
          text: response.message,
          confirmButtonText: 'Aceptar',
          allowOutsideClick: false,
        })
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        const { success, message, statusCode, errorCode, additionalInfo } =
          error?.response.data

        Swal.fire({
          title: 'Error',
          text: message,
          icon: 'error',
          confirmButtonText: 'Aceptar',
        })
      } else {
        Swal.fire({
          title: 'Error',
          text: 'Error al procesar la solicitud. Inténtelo de nuevo.',
          icon: 'error',
          confirmButtonText: 'Aceptar',
        })
      }
    }
  }

  // Obtener el email de los params al cargar el componente
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const emailFromParams = params.get('email')

    if (emailFromParams) {
      formik.setFieldValue('email', emailFromParams)
      setIsEmailFromParams(true)
      sendRecoveryEmail(emailFromParams)
    }
  }, [])

  return (
    <Container>
      <LoadingScreen open={isLoading} />
      {!isSubmitted ? (
        <Form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Title>Recuperar contraseña</Title>
          </DialogContent>
          <DialogContent>
            <DialogContentText>
              <TextField
                label='Correo electrónico*'
                margin='dense'
                id='email'
                name='email'
                type='email'
                fullWidth
                disabled={isEmailFromParams}
                onChange={formik.handleChange}
                value={formik.values.email}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button type='submit' variant='contained' color='primary'>
              {isEmailFromParams ? 'Intentar de nuevo' : 'Enviar'}
            </Button>
          </DialogActions>
        </Form>
      ) : (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <h2>Enlace de recuperación enviado</h2>
          <p>
            Hemos enviado un correo electrónico a la dirección proporcionada con
            un enlace para restablecer tu contraseña. Por favor, revisa tu
            bandeja de entrada y sigue las instrucciones.
          </p>
        </div>
      )}
    </Container>
  )
}

export default RequestRecoveryPassword
