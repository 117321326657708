import React, { useContext } from 'react'
import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'
import { getusername, removeUserSession } from '../../components/SecuredUser'
import { useHistory } from 'react-router-dom'
import ApiGeneral from '../../services/ApiGeneral'
import { useAnalyticsEventTracker } from '../GoogleAnalitycs/useAnalyticsEventTracker'
import PersonIcon from '@material-ui/icons/Person'
import Direccion from '../Direccion/Direccion'
import Grid from '@material-ui/core/Grid'

import UserContext from '../../contexts/user/UserContext'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}))
function MenuDetalle(props) {
  const { UserState, doLogout } = useContext(UserContext)
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [misDatos, getClienteDatos] = React.useState([])
  const history = useHistory()

  const anchorRef = React.useRef(null)
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    gaEventLogin('Mi cuenta')
    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    const getMisDatos = () => {
      ApiGeneral.getMisDatos(UserState.idCliente)
        .then(response => {
          getClienteDatos(response.data)
        })
        .catch(e => {})
    }
    // getMisDatos();
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open

    if (UserState.authenticated) getMisDatos()
  }, [open, UserState.authenticated])

  const handleLogout = () => {
    history.push(`/`)
  }
  const gaEventLogin = useAnalyticsEventTracker('Mi cuenta')
  let variable = misDatos
  return (
    <div className={classes.root}>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
        startIcon={<PersonIcon />}
        style={{ marginTop: '20%' }}
      >
        {misDatos.tipoCliente == 'Persona moral'
          ? misDatos.razonSocial
          : misDatos.nombre}
        {/* user.name */}
      </Button>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: '9999' }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id='menu-list-grow'
                  onKeyDown={handleListKeyDown}
                >
                  <Typography variant='h6' color='initial'>
                    Hola{' '}
                    {misDatos.tipoCliente == 'Persona moral'
                      ? misDatos.razonSocial
                      : misDatos.nombre}
                  </Typography>
                  <Divider />

                  <Link
                    to='/MiCuenta/misdatos'
                    onClick={() => gaEventLogin('Mis datos')}
                  >
                    <MenuItem>Mis Datos</MenuItem>
                  </Link>

                  <Link to='/MiCuenta/direcciones'>
                    <MenuItem onClick={handleClose}>Mis Direcciones</MenuItem>
                  </Link>
                  <Link to='/MiCuenta/pedidos'>
                    <MenuItem onClick={handleClose}>Mis Pedidos</MenuItem>
                  </Link>
                  <Link to='/MiCuenta/deseos'>
                    <MenuItem onClick={handleClose}>Mis deseos</MenuItem>
                  </Link>
                  <Link to='/MiCuenta/milista'>
                    <MenuItem onClick={handleClose}>Mi Lista</MenuItem>
                  </Link>
                  <Divider />
                  <Link to='/'>
                    <MenuItem onClick={() => doLogout()}>Salir</MenuItem>
                  </Link>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

export default MenuDetalle
