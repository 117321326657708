import React, { useContext, useEffect, useState, Suspense, lazy } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import ReactGA4 from 'react-ga4'
import ReactGA from 'react-ga'
import OneSignal from 'react-onesignal'
import Favicon from 'react-favicon'

import './App.css'
import { getKey } from './actions/resultActions'
import { getCarrito, getTipoCambio } from './actions/carritoActions'
import UserContext from './contexts/user/UserContext'

import Context from './store/Context'
import GlobalContext from './contexts/GlobalContext'
import StylesContext from './contexts/styles/stylesContext'

import Layout from './components/Layout/Layout'
import HemmetWrapping from './components/HemmetWrapping'

// const HomeT = lazy(() => import('./components/test/PlantillaElement'))
import HomeT from './components/test/PlantillaElement'

import Signin from './Views/Signin/Signin'
import Signup from './Views/Signup/Signup'
import VerifyCode from './Views/VerifyCode/VerifyCode'
import RequestRecoveryPassword from './Views/RequestRecoveryPassword/RequestRecoveryPassword'
import ResetPassword from './Views/ResetPassword/ResetPassword'

// import Secured from './components/Secured'
import Details from './components/Result/ProductDetail'
import Blogs from './components/test/Blog'
import MiPlantilla from './components/Plantillas/MiPlantilla'
import Products from './components/Result/Search_Result'
// TODO: revisar
import MisCompras from './components/Cuenta/MisCompras/Miscompras'
import Direccion from './components/Direccion/AgregarDireccion'
// TODO: revisar
import Tarjetas from './components/Tarjeta/TarjetaRegalo'
import PagarTarjeta from './components/Tarjeta/PagarTarjeta'
import TarjetaEnviada from './components/Tarjeta/TarjetaEnviada'
import Contacto from './components/Contacto/Contacto'
import ArticulosCarrito from './components/ProcederPago/ArticulosCarrito'
import HistorialPedidos from './components/Cuenta/Historial/HistorialPedidos'
import Registro from './Views/Registro/Registrar'
import MiCuenta from './components/DetalleCuenta/MiCuenta'
import MisDirecciones from './components/Direccion/Direccion'

import Orden from './components/Orden/Orden'
import PagoResult from './Views/Pago/PagoResult'
// import Plantilla  from './components/Plantillas/Plantillas'
import PedidoResumen from './Views/Pedido/PedidoResumen'

import NotFound from './Views/NotFound/NotFound'

const App = () => {
  const { UserState } = useContext(UserContext)
  const { notificacionesState } = useContext(GlobalContext)
  const context = useContext(Context)
  const stylesContext = useContext(StylesContext)
  const logo = stylesContext.logo

  let idCarrito = window.localStorage.getItem('carritoID')

  if (!idCarrito) {
    idCarrito = uuidv4()
    window.localStorage.setItem('carritoID', idCarrito)
  }

  useEffect(() => {
    if (!notificacionesState.valor) return

    OneSignal.init({
      appId: notificacionesState?.valor,
    })
  }, [notificacionesState])

  // Analytics
  useEffect(() => {
    const getMenuAdmin = async () => {
      const responses = await getKey('9d7e554f-32c5-40c6-bdcf-a558f7a28f9c')

      const TRACKING_ID = responses.data.valor
      ReactGA.initialize(TRACKING_ID)
      ReactGA4.initialize(TRACKING_ID)
    }

    getMenuAdmin()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        let TC = 1

        const r = await getTipoCambio()
        console.log('getTipoCambio', r.data.tipoCambio)
        context.addTipoCambio(r.data.tipoCambio)
        context.filterv2.espesorId = []
        context.filterv2.medidaId = []
        context.filterv2.tipoAceroId = []

        TC = r.data.tipoCambio

        const k = await getCarrito(idCarrito)
        const carrito = k.data

        carrito?.items?.forEach(i => {
          context.addCartFromRedis({
            quantity: i.cantidad,
            maxQuantity: i.maxCantidad,
            _id: i.id,
            price: i.precio,
            images: i.imagen,
            title: i.producto,
            peso: i.peso,
            familiaId: i.familiaId,
            infoAdicional: i.infoAdicional,
            tipoCambio: TC,
          })
        })
      } catch (error) {
        console.error('Error fetching data: ', error)
      }
    }

    console.log('context', context)
    console.log('idCarrito', idCarrito)
    fetchData()
  }, [idCarrito])

  const actualizaElCarrito = async () => {
    if (UserState.authenticated) {
      window.localStorage.setItem('carritoID', UserState.email)
    }

    var response = await getCarrito(window.localStorage.getItem('carritoID'))

    var carrito = response.data

    carrito?.items?.forEach(i => {
      context.addCartFromRedis({
        quantity: i.cantidad,
        maxQuantity: i.maxCantidad,
        _id: i.id,
        price: i.precio,
        images: i.imagen,
        title: i.producto,
        peso: i.peso,
        familiaId: i.familiaId,
        infoAdicional: i.infoAdicional,
        tipoCambio: context.getTipoCambio,
      })
    })
  }

  return (
    <BrowserRouter>
      <HemmetWrapping />
      {logo ? <Favicon url={logo} /> : null}
      <Layout>
        <Switch>
          <Route exact path='/' component={HomeT} />

          <Route exact path='/signin' component={Signin} />
          <Route exact path='/signup' component={Signup} />
          <Route exact path='/verify-code' component={VerifyCode} />
          <Route
            exact
            path='/request-recovery-password'
            component={RequestRecoveryPassword}
          />
          <Route exact path='/reset-password' component={ResetPassword} />

          <Route exact path='/products' component={Products} />
          <Route exact path='/products/:search/:by/ls' component={Products} />
          <Route
            exact
            path='/products/:idm/p'
            render={() => <Details actualizaElCarrito={actualizaElCarrito} />}
          />

          <Route exact path='/blog/:idm/p' render={() => <Blogs />} />
          <Route exact path='/:idm' render={() => <MiPlantilla />} />
          {/* <Route exact path='/Secured' component={Secured} /> */}
          <Route exact path='/compras' component={MisCompras} />
          <Route exact path='/AgregarDireccion' component={Direccion} />
          <Route path='/tienda/tarjetas' exact component={Tarjetas} />
          <Route path='/tarjetas/:id/t' exact component={PagarTarjeta} />
          <Route path='/enviado/:id/:idm' exact component={TarjetaEnviada} />
          <Route path='/Contacto/:id/:nombre' exact component={Contacto} />
          <Route
            exact
            path='/tienda/articulos/carrito/pago'
            component={ArticulosCarrito}
          />
          <Route exact path='/historial' component={HistorialPedidos} />
          <Route exact path='/registro/clikalo/tienda' component={Registro} />
          <Route exact path='/MiCuenta' component={MiCuenta} />

          <Route exact path='/MiCuenta/:path' component={MiCuenta} />
          <Route exact path='/MisDirecciones' component={MisDirecciones} />
          <Route exact path='/orden/:idPedido' component={Orden} />
          <Route exact path='/pedido/:idPedido' component={PedidoResumen} />
          <Route
            exact
            path='/orden/tienda/pedido/compra/pago'
            component={Orden}
          />
          <Route exact path='/orden/:bbva/process/:pedido' component={Orden} />
          <Route exact path='/pago/:status/:supplier' component={PagoResult} />

          <Route component={NotFound} />
        </Switch>
      </Layout>
    </BrowserRouter>
  )
}

export default App
