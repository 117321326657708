import axios from 'axios'
import Swal from 'sweetalert2'

import KeycloakService from '../services/KeycloakService'
import Base from '../services/Base'
import { nombre } from '../services/Url'

const axiosInstance = axios.create({
  baseURL: Base.baseUrls.almacen,
  headers: {
    'Content-Type': 'application/json',
    username: 'cliente',
    nombres: nombre.tienda,
    //id: id,
  },
  //timeout: 1000,
})

// Interceptor para agregar el token a la cabecera de autorización
axiosInstance.interceptors.request.use(
  async config => {
    const token = KeycloakService.getToken()

    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      }
    }

    return config
  },
  error => {
    // Manejar errores antes de enviar la solicitud
    return Promise.reject(error)
  }
)

// Función para manejar peticiones con cancelación y manejo centralizado de errores
const fetchWithCancel = async ({
  url,
  method = 'GET',
  data = null,
  params = null,
  signal,
}) => {
  try {
    const config = {
      method,
      url,
      data,
      params,
      signal,
    }

    const response = await axiosInstance(config)
    return response.data
  } catch (e) {
    if (axios.isCancel(e)) {
      console.warn('Request canceled', e.message)
    } else {
      Swal.fire(
        e.response?.data || 'Almacen service',
        'Ha ocurrido un error desconocido',
        'error'
      )
      throw e
    }
  }
}

export const getProductsWish = async (signal, idUsuario) => {
  return fetchWithCancel({
    url: 'WishList/GetListProducts/' + idUsuario,
    method: 'GET',
    signal,
  })
}

export const getProductsList = async (signal, idUsuario) => {
  return fetchWithCancel({
    url: 'CompareList/GetListProducts/' + idUsuario,
    method: 'GET',
    signal,
  })
}

export const getPedidoResumenByIdCarrito = async (signal, IdCarrito) => {
  return fetchWithCancel({
    url: 'producto/summary/' + IdCarrito,
    method: 'GET',
    signal,
  })
}
