import React, { useEffect, useState, useRef, useContext } from 'react'

import Card from './CardOriginal'
// import Mask from './integrinoxoriginal.css'
import Context from '../../../store/Context'
import { getUrlForProductImg } from '../../../utils/ima'
// import NumberFormat from 'react-number-format'
// import { COLORS } from '../../../Views/Colores'
import StylesContext from '../../../contexts/styles/stylesContext'
import GlobalContext from '../../../contexts/GlobalContext'

// import { getProductosConPromo, getMoneda } from './catalogorequest'
// import CardPromo from './CardPromo'
// import Header from '../../Header/HeaderResponsive'
// import {
//   getProductDetail,
//   getProductDetailName,
//   postComentario,
//   getComentarios,
//   getProductPrice,
//   getRespuesta,
//   getPreguntas,
//   getPromo,
//   getProductVariant,
//   postPregunta,
//   getVariant,
//   getWishList,
//   searchgetWishList,
//   getWishExist,
//   editWishList,
//   getElement,
//   getLogo,
// } from '../../../actions/resultActions'

// const Footer = React.lazy(() => import('../../../Views/Footer/FooterMenu'))

const ProductCard = ({ items }) => {
  const { monedaState } = useContext(GlobalContext)
  const context = useContext(Context)
  const stylesContext = useContext(StylesContext)
  // const [promos, setPromos] = useState([])
  const [moneda, setMoneda] = useState(() => monedaState)

  // const getPromos = async () => {
  //   const promociones = await getProductosConPromo()
  //   console.log(promociones.data)
  //   setPromos(promociones.data)
  // }

  // const getMonedas = async () => {
  //   const moned = await getMoneda()
  //   window.localStorage.setItem('monedasup', moned.data.abreviatura)
  //   setMoneda(moned.data)
  //   console.log(moned)
  // }

  // useEffect(() => {
  //  getPromos();
  // getMonedas()
  // getL()
  // }, [])

  // const getL = async () => {
  //   const response2 = await getLogo()
  //   setLogop(response2.data.url)
  // }

  const [logop, setLogop] = useState(() => stylesContext.logo)
  // document.documentElement.style.setProperty('--color-principal', COLORS.black)

  const nodata = (
    <div style={{ textAlign: 'center' }}>
      <br></br>

      <div>
        <h2 className='title-NotFound'>No se encontraron resultados</h2>
      </div>

      <div>
        <img style={{ textAlign: 'center' }} src='/img/DEFAULT1.png' alt='' />
      </div>

      {/* <Footer AligmentText='left' /> */}
    </div>
  )
  const datos = (
    <div className='product-items'>
      {items.map(p => (
        <div>
          {moneda.abreviatura === 'USD' && (
            <Card
              key={p.idProducto}
              idProducto={p.idProducto}
              price={p.precio}
              imagenPereview={
                p.imagenPereview === null
                  ? '/img/IMAGEN_NO_DISPONIBLE.png'
                  : getUrlForProductImg(p.imagenPereview)
              }
              sku={p.sku}
              nombre={p.nombre}
              sprice={p.precio}
              abrevia={moneda.abreviatura}
              addProductToCart={context.addProductToCart}
            />
          )}
          {moneda.abreviatura === 'MXN' && (
            <Card
              key={p.idProducto}
              idProducto={p.idProducto}
              price={p.precio * context.getTipoCambio}
              imagenPereview={
                p.imagenPereview === null
                  ? '/img/IMAGEN_NO_DISPONIBLE.png'
                  : getUrlForProductImg(p.imagenPereview)
              }
              sku={p.sku}
              nombre={p.nombre}
              sprice={p.precio * context.getTipoCambio}
              abrevia={moneda.abreviatura}
              addProductToCart={context.addProductToCart}
            />
          )}
        </div>
      ))}
    </div>
  )

  const result = items.length == 0 ? nodata : datos

  return result
}

export default ProductCard
