import React, { useContext, useEffect, useState } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import Direccion from '../Direccion/Direccion'
import DireccionCard from '../Direccion/DireccionCard'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import AuthCliente from '../../services/AuthCliente'
import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  FormControlLabel,
  Switch,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import Swal from 'sweetalert2'
import errores from '../../services/errores'
import TextField from '@material-ui/core/TextField'
import { useFormik } from 'formik'
import ApiGeneral from '../../services/ApiGeneral'
import UserContext from '../../contexts/user/UserContext'
import * as yup from 'yup'
import pedidoActions from '../../actions/pedidoActions'

const useStyles = makeStyles(() => ({
  btncancelar: {
    background: '#eeeeee',
    color: '#003462',
    backgroundColor: '#eeeeee!important',
  },
  formContro: {
    minWidth: '531px',
  },
  btnsubnmit: {
    background: '#003462',
    color: '#ffff',
    backgroundColor: '#003462!important',
  },
  DiAction: {
    paddingRight: '2rem',
  },
  error: {
    color: '#ff0500',
  },
}))

const Colaboradores = ({
  getMisDatos,
  colaborador,
  setActive,
  handleCloseDirecciones,
  setType,
  setMessage,
  setSBOpen,
}) => {
  const { UserState } = useContext(UserContext)

  const colab = colaborador

  const [selecteds] = React.useState({
    Pais: '',
    Estado: '',
    //Municipio:direccion.municipio,
    //Colonia: direccion.colonia,
  })
  const classes = useStyles()

  const [open, setOpen] = React.useState(true)
  const [tipoCfdi, setTipoCfdi] = React.useState([])
  const [FormaPago, setFormaPago] = React.useState([])
  const [MetodoPago, setMetodoPago] = React.useState([])
  const [rfcVerified, setRfcVerified] = React.useState(false)
  const [direcciones, getClienteDirecciones] = useState([])
  // const [colaborador, getColaboradorId] = useState([]);
  const [currentDireccion, setCurrentDireccion] = React.useState(null)
  const [openDirecciones, setOpenDirecciones] = React.useState(false)

  const validate = values => {
    const errors = {}

    return errors
  }
  const validationSchema = yup.object({
    nombre: yup.string().required('El nombre es requerido.'),
    aPaterno: yup.string().required('El apellido paterno es requerido.'),
    telefono: yup
      .string()
      .min(10, 'El teléfono debe tener un mínimo de 10 caracteres.')
      .max(10, 'El teléfono debe tener un máximo de 10 caracteres.')
      .required('El campo teléfono es requerido.'),
    celular: yup
      .string()
      .min(10, 'El celular debe tener un mínimo de 10 caracteres.')
      .max(10, 'El celular debe tener un máximo de 10 caracteres.'),
  })

  const formik = useFormik({
    initialValues: {
      idClienteColaborador: colaborador.idClienteColaborador,
      clienteId: colaborador.clienteId,
      clienteDireccionId: colaborador.clienteDireccionId,
      nombre: colaborador.nombre,
      aPaterno: colaborador.aPaterno,
      aMaterno: colaborador.aMaterno,
      email: colaborador.email,
      telefono: colaborador.telefono,
      activo: colaborador.activo,
      clienteDireccion: null,
      usuarioCreoId: colaborador.clienteId,
      usuarioModId: colaborador.clienteId,
    },
    validate,
    validationSchema: validationSchema,
    onSubmit: values => {
      if (currentDireccion != null) {
        values.clienteDireccionId = currentDireccion.idClienteDireccion
      }
      ApiGeneral.editeColaborador(values)
        .then(r => {
          setActive(values.activo)
          handleClose()
          handleCloseDirecciones()

          setSBOpen(true)
          setMessage('Los datos se han actualizado con éxito.')
          setType('success')
          getMisDatos()

          console.info('Los datos se han actualizado con éxito', r)
        })
        .catch(e => {
          handleClose()
          handleCloseDirecciones()
          setSBOpen(true)
          setMessage(errores.handleError('error', e))
          setType('error')
        })
    },
  })
  const handleCloseDireccionesC = () => {
    setOpenDirecciones(false)
  }

  useEffect(() => {
    const getDireccionPrincipal = async () => {
      const direccion = await pedidoActions.getDireccionPrincial()
      setCurrentDireccion(direccion)
    }
    getDireccionPrincipal()

    const getdirecciones = () => {
      ApiGeneral.getDirecciones(colaborador.clienteId)
        .then(response => {
          console.info('data:' + response.data)

          getClienteDirecciones(response.data)
        })
        .catch(e => {
          console.error('error data:' + e)
        })
    }
    //getColaboradores();

    getdirecciones()
  }, [])

  const handleClose = () => {
    //setCP("");

    //  AsignarDatos(null);
    setOpen(false)

    // setColonia([])
  }
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          label='Nombre*'
          margin='dense'
          id='nombre'
          name='nombre'
          type='text'
          fullWidth
          onChange={formik.handleChange}
          value={formik.values.nombre}
          error={formik.touched.nombre && Boolean(formik.errors.nombre)}
          helperText={formik.touched.nombre && formik.errors.nombre}
        />
        {/*datos*/}
        <TextField
          margin='dense'
          id='aPaterno'
          name='aPaterno'
          label='Apellido Paterno*'
          placeholder='Apellido Paterno'
          type='text'
          fullWidth
          onChange={formik.handleChange}
          value={formik.values.aPaterno}
          error={formik.touched.aPaterno && Boolean(formik.errors.aPaterno)}
          helperText={formik.touched.aPaterno && formik.errors.aPaterno}
        />
        <TextField
          margin='dense'
          id='aMaterno'
          name='aMaterno'
          label='Apellido Materno'
          placeholder='Apellido Materno'
          type='text'
          fullWidth
          onChange={formik.handleChange}
          value={formik.values.aMaterno}
          error={formik.touched.aMaterno && Boolean(formik.errors.aMaterno)}
          helperText={formik.touched.aMaterno && formik.errors.aMaterno}
        />
        <TextField
          margin='dense'
          id='telefono'
          name='telefono'
          label='Teléfono*'
          placeholder='Teléfono'
          type='number'
          fullWidth
          onChange={formik.handleChange}
          value={formik.values.telefono}
          error={formik.touched.telefono && Boolean(formik.errors.telefono)}
          helperText={formik.touched.telefono && formik.errors.telefono}
        />
        <FormControlLabel
          control={
            <Switch
              color='primary'
              checked={formik.values.activo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name='activo'
            />
          }
          label='Activo'
          labelPlacement='top'
        />
        <div>
          <Button
            style={{ float: 'right' }}
            onClick={() => {
              setOpenDirecciones(true)
            }}
          >
            Seleccionar dirección
          </Button>
          <Dialog
            onClose={handleCloseDireccionesC}
            aria-labelledby='simple-dialog-title'
            open={openDirecciones}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <label style={{ paddingLeft: 16 }}>
                Mis direcciones de envío
              </label>
              <IconButton
                aria-label='close'
                className={classes.closeButton}
                onClick={handleCloseDireccionesC}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <DialogContent dividers style={{ padding: 8 }}>
              <Grid
                container
                direction='column'
                justify='center'
                alignItems='center'
              >
                <Box display='flex' p={1}>
                  <Direccion
                    currentDireccion={currentDireccion}
                    setCurrentDireccion={setCurrentDireccion}
                    showTitle={false}
                  />
                </Box>
              </Grid>
            </DialogContent>
          </Dialog>
          {!!currentDireccion && (
            <DireccionCard direccion={currentDireccion} editable={false} />
          )}
        </div>
        {/* <span style={{color: "red"}}>{errores.error["nombre"]}</span> */}

        <div style={{ textAlign: 'right', marginTop: '20px' }}>
          <Button
            autoFocus
            onClick={handleCloseDirecciones}
            variant='outlined'
            className={classes.btncancelar}
          >
            Cancelar
          </Button>
          <Button
            style={{ marginLeft: '10px' }}
            type='submit'
            variant='contained'
            className={classes.btnsubnmit}
            value='Registrar'
            color='primary'
          >
            Guardar
          </Button>
        </div>
      </form>
    </>
  )
}

export default Colaboradores
