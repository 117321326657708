import axios from 'axios'
import data from './url.json'
export const urls = {
  direccion: data.urlPage,
}
export const nombre = {
  tienda: 'MediNu',
}
export const realm = {
  tienda: 'MediNuPublic',
}
export const dominio = {
  tienda: 'https://MediNu.clikalo.com/',
}
export const getDominio = () => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: 'https://api.clikalo.com/services/Catalogo/api/', //aqui va la url.direccion
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
      },
    })
    instance
      .get('Dominio/28d97798-bacb-4361-954c-886c4fc9d2be')
      .then(response => {
        resolve(response)
        console.log('getDominio', response)
      })
      .catch(e => {
        console.log(e)
        reject(e)
      })
  })
}
