import React from 'react'
import ReactDOM from 'react-dom'

import 'semantic-ui-css/components/loader.min.css'
import 'semantic-ui-css/components/segment.min.css'
import 'semantic-ui-css/components/icon.min.css'

import App from './App'

import UserProvider from './contexts/user/UserProvider'
import GlobalState from '../src/store/GlobalState'
import StylesProvider from './contexts/styles/stylesProvider'
import GlobalProvider from './contexts/GlobalProvider'

console.log('Entorno api >> ' + process.env.REACT_APP_BASE_URL)
console.log('Entorno de ejecucion >> ' + process.env.NODE_ENV)

ReactDOM.render(
  // <React.StrictMode>
  <UserProvider>
    <GlobalState>
      <GlobalProvider>
        <StylesProvider>
          <App />
        </StylesProvider>
      </GlobalProvider>
    </GlobalState>
  </UserProvider>,
  // </React.StrictMode>,

  document.getElementById('root')
)
