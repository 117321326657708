import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import Countdown from 'react-countdown'

const OrderCountdown = ({
  isActive,
  // allowedPaths = [],
  timeLeft = 300000,
  completePath = '/tienda/articulos/carrito/pago',
}) => {
  const location = useLocation()
  const history = useHistory()

  // Mostrar el temporizador solo si `isActive` es true y la ruta actual está en `allowedPaths`
  // const shouldShowTimer = isActive && allowedPaths.includes(location.pathname)

  return isActive ? (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        color: 'red',
        fontWeight: 'bold',
      }}
    >
      <Typography>Completa tu compra en:</Typography>
      <Typography>
        <Countdown
          date={Date.now() + timeLeft}
          onComplete={() => {
            history.push(completePath)
          }}
          renderer={({ minutes, seconds }) => (
            <span>
              {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
            </span>
          )}
        />
      </Typography>
    </div>
  ) : null
}

export default OrderCountdown
