import React, { useState, useEffect, useRef } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import { CreateOrder } from '../../services/pedidoService'

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

const Loader = styled.div`
  margin: 20px auto;
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 1s linear infinite;
`

const PagoResult = () => {
  const location = useLocation()
  const history = useHistory()
  const { status, supplier } = useParams()
  const searchParams = new URLSearchParams(location.search)

  const [idPedido, setIdPedido] = useState(null)
  const [loading, setLoading] = useState(false)
  const [mensaje, setMensaje] = useState(
    'Estamos creando tu pedido. Por favor, no salgas de esta pantalla.'
  )
  const [orderData, setOrderData] = useState(
    JSON.parse(window.localStorage.getItem('dataOrder')) || null
  )

  const paymentId = searchParams.get('payment_id')
  const paymentStatus = searchParams.get('status')
  const merchantOrderId = searchParams.get('merchant_order_id')

  const pedidoRegistradoRef = useRef(false)

  const mensajes = {
    success: {
      titulo: '¡Pago realizado con éxito!',
      descripcion: `Tu pago ha sido aprobado. Gracias por tu compra.`,
    },
    failure: {
      titulo: 'Error en el pago',
      descripcion:
        'El pago no pudo ser procesado. Por favor, intenta nuevamente o usa otro método de pago.',
    },
    pending: {
      titulo: 'Pago pendiente',
      descripcion:
        'Tu pago está en proceso. Recibirás una notificación cuando se confirme.',
    },
  }

  const mensajeProveedor = {
    paypal: 'Tu pago ha sido gestionado con PayPal.',
    mercadopago: 'Tu pago ha sido gestionado con MercadoPago.',
    paymentondelivery:
      'Has seleccionado el pago a la entrega. Por favor, prepara el pago cuando recibas el pedido.',
  }

  const delay = ms => new Promise(resolve => setTimeout(resolve, ms))

  // Función para registrar el pedido
  const registrarPedido = async signal => {
    await delay(2000)

    let countCreateOrder =
      parseInt(localStorage.getItem('countCreateOrder'), 10) || 0

    if (pedidoRegistradoRef.current || countCreateOrder > 0) {
      console.log('El pedido ya fue registrado.')
      return
    }

    if (!orderData) {
      console.error('No se encontraron datos del pedido.')
      setMensaje('No se pudieron encontrar los datos del pedido.')
      return
    }

    try {
      setLoading(true)

      // Configuración del proveedor
      if (supplier === 'mercadopago') {
        orderData.paymentProviderId = paymentId
        orderData.captureId = merchantOrderId
        orderData.intent = 'CAPTURE'
        orderData.status =
          paymentStatus === 'approved' ? 'COMPLETED' : 'PENDING'
      } else if (supplier === 'paymentondelivery') {
        orderData.paymentProviderId = null
        orderData.captureId = null
        orderData.intent = 'CAPTURE'
        orderData.status = 'COMPLETED'
      }

      // Asegurar que no haya múltiples ejecuciones
      if (countCreateOrder > 0) return

      const response = await CreateOrder(signal, orderData)
      const orderId = response.orderId

      if (orderId) {
        setIdPedido(orderId)
        setLoading(false)
        setMensaje('Tu pedido fue registrado con éxito.')
        pedidoRegistradoRef.current = true

        // Almacenar estado de completado y actualizar contador
        localStorage.setItem('countCreateOrder', `${countCreateOrder + 1}`) // Convertir a string antes de guardar

        // Redireccionar
        history.push(`/pedido/${orderId}`)
      }
    } catch (error) {
      console.error('Error al registrar el pedido:', error)
      setLoading(false)
      setMensaje('No se pudo crear el pedido. Inténtalo más tarde.')
    }
  }

  useEffect(() => {
    const abortController = new AbortController()
    const signal = abortController.signal

    const checkAuthAndRegisterOrder = async () => {
      if (status !== 'success') return

      if (supplier === 'mercadopago' && paymentStatus === 'approved') {
        registrarPedido(signal)
      } else if (supplier === 'paypal' && paymentStatus === 'approved') {
        registrarPedido(signal)
      } else if (supplier === 'paymentondelivery') {
        registrarPedido(signal) // No necesita aprobación, solo se registra el pedido
      }
    }

    checkAuthAndRegisterOrder()

    return () => {
      abortController.abort()
    }
  }, [idPedido, status])

  useEffect(() => {
    const handleBeforeUnload = event => {
      if (loading) {
        event.preventDefault()
        event.returnValue = '' // Necesario para algunos navegadores
      }
    }

    const unblock = history.block((location, action) => {
      if (loading) {
        const confirmExit = window.confirm(
          'Tu pedido se está procesando. ¿Estás seguro de que quieres salir? Si sales, el pedido podría no registrarse correctamente.'
        )
        if (!confirmExit) {
          return false // Bloquea la navegación
        }
      }
    })

    // Agregar el evento de antes de descargar
    window.addEventListener('beforeunload', handleBeforeUnload)

    // Limpiar al desmontar
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      unblock() // Liberar el bloqueo de navegación
    }
  }, [loading, history])

  return (
    <div style={{ textAlign: 'center', padding: '10px 0px', margin: '40px 0' }}>
      <h1>{mensajes[status]?.titulo || 'Estado desconocido'}</h1>
      <p style={{ marginTop: '25px' }}>
        {mensajes[status]?.descripcion ||
          'No hay información disponible sobre el estado de tu pago.'}
      </p>
      <p style={{ marginTop: '8px' }}>
        {mensajeProveedor[supplier] || 'Proveedor de pago no reconocido.'}
      </p>
      {loading && (
        <div style={{ marginTop: '50px' }}>
          <p style={{ fontSize: '18px', fontWeight: 600 }}>{mensaje}</p>
          <Loader />
        </div>
      )}
    </div>
  )
}

export default PagoResult
