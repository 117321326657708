import { makeStyles, withStyles } from '@material-ui/core/styles'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MuiTreeItem from '@material-ui/lab/TreeItem'
import Cards from '../../Result/Mask/CardMobile'

import TreeView from '@material-ui/lab/TreeView'
import { Collapse } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import {
  Link,
  Slider,
  Button,
  TextField,
  Checkbox,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import '../Navbar.css'
import Colors from './Colors'
import { useHistory } from 'react-router'
import Context from '../../../store/Context'
import { Autocomplete } from '@material-ui/lab'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { COLORS } from '../../../Views/Colores'
import StylesContext from '../../../contexts/styles/stylesContext'
import {
  getCatG,
  getMarcas,
  getFilteredUnidad,
  getFilteredMarca,
  getFilteredFicha,
} from '../../../actions/resultActions'
const { Panel } = Collapse
const useStyles2 = makeStyles(theme => ({
  rootA: {
    flexGrow: 1,
    marginLeft: '25px',
  },
  root: {
    width: '80%',
    marginLeft: '25px',
    marginTop: '20px',
  },
  margin: {
    height: theme.spacing(3),
  },
  aplicar: {
    borderColor: '#3a8589',
    color: COLORS.black,
    marginTop: '25px',
    marginRight: '15px',
    float: 'right',
    '&:hover': {
      backgroundColor: '#3a8589',
      borderColor: '#0062cc',
      boxShadow: 'none',
      color: 'white',
    },
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#3a8589 ',
  },
  customTextField: {
    '& .Mui-focused': {
      color: '#3a8589',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#3a8589',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#3a8589',
        color: '#3a8589',
      },
    },
  },
}))

const TreeItem = withStyles({
  root: {
    color: COLORS.black,
    width: '100%',
    '&.Mui-selected > .MuiTreeItem-content': {
      color: 'white',
      backgroundColor: '#3a858969',
    },
  },
})(MuiTreeItem)
function valuetext(value) {
  return `${value}°C`
}

const AirbnbSlider = withStyles({
  root: {
    color: '#3a8589',
    height: 3,
    padding: '13px 0',
  },
  thumb: {
    height: 27,
    width: 27,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    marginTop: -12,
    marginLeft: -13,
    boxShadow: '#ebebeb 0 2px 2px',
    '&:focus, &:hover, &$active': {
      boxShadow: '#ccc 0 2px 3px 1px',
    },
    '& .bar': {
      // display: inline-block !important;
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 8px)',
  },
  mark: {
    height: 7,
    width: 7,
    backgroundColor: 'white',
    borderRadius: 10,
    marginTop: -1,
  },
  track: {
    height: 3,
  },
  rail: {
    color: '#d8d8d8',
    opacity: 1,
    height: 3,
  },
})(Slider)

export default function TreeViewFamilias(props) {
  const stylesContext = useContext(StylesContext)

  const context = useContext(Context)
  const history = useHistory()
  const classes = useStyles2()
  const [cat, setCat] = useState([])
  const [marcas, setMarcas] = useState([])
  const [cantidad, setCantidad] = useState(0)
  const [caracteristica, setCaracteristica] = useState('')
  const [valor, setValor] = useState('')
  const [medida, setMedida] = useState(0)

  const [check, setCheck] = React.useState({
    activo: false,
    position: 0,
  })
  const [checkUnidad, setCheckUnidad] = React.useState({
    activo: false,
    position: 0,
  })
  const getL = async () => {
    const response2 = await getCatG(6)
    setCat(response2.data)
    var arr2 = []
    response2.data.forEach((element, index) => {
      const aunx2 = {
        activo: false,
        position: 0,
      }

      aunx2.position = index

      aunx2.activo = false
      arr2.push(aunx2)
    })
    setCheckUnidad(arr2)
    const response3 = await getMarcas()
    setMarcas(response3.data)
    var arr = []
    response3.data.forEach((element, index) => {
      const aunx = {
        activo: false,
        position: 0,
      }

      aunx.position = index

      aunx.activo = false
      arr.push(aunx)
    })
    setCheck(arr)
  }
  useEffect(() => {
    //  getPromos();
    getL()
  }, [])
  const [selected, setSelected] = React.useState(context.filterv2.categoriaId)
  const [expanded, setExpanded] = React.useState(context.filterv2.pathCategory)
  const [value, setValue] = React.useState(context.filterv2.precio)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const handleStart = evt => {
    let newValue = [...value]
    newValue[0] = evt.target.valueAsNumber
    setValue(newValue)
  }
  const handleEnd = evt => {
    let newValue = [...value]
    newValue[1] = evt.target.valueAsNumber
    setValue(newValue)
  }
  const handleToggle = value => {
    setCantidad(cantidad + 1)

    console.log(cantidad)
    setMedida(value)

    filterUnidad(props.prods, value, props.categoria, true)
  }
  const handleToggle2 = (value, index) => {
    setCantidad(cantidad + 1)
    console.log(cantidad)
    check.forEach(element => {
      if (element.position === index) {
        element.activo = !element.activo
      }
    })
    setCheck(check)
    filterMarca(props.prods, value, props.categoria, check[index].activo)
  }
  const filterFicha = async (seleccionado1, seleccionado2) => {
    const response = await getFilteredFicha(
      props.prods,
      seleccionado1,
      seleccionado2,
      cantidad
    )
    props.set(<Cards items={response.data.productos} />)
    if (response.data.productos.length === 0) {
      setCantidad(0)
    }
    props.setMain(response.data.productos)
  }
  const filterMarca = async (mainData, seleccionado, search, checks) => {
    const response = await getFilteredMarca(
      mainData,
      seleccionado,
      cantidad,
      checks
    )
    props.set(<Cards items={response.data.productos} />)
    if (response.data.productos.length === 0) {
      setCantidad(0)
    }
    props.setMain(response.data.productos)
  }
  const filterUnidad = async (mainData, seleccionado, search, checks) => {
    const response = await getFilteredUnidad(
      mainData,
      seleccionado,
      cantidad,
      checks
    )
    props.set(<Cards items={response.data.productos} />)
    if (response.data.productos.length === 0) {
      setCantidad(0)
    }
  }

  const handleSelect = (event, nodeId) => {
    new Promise((resolve, reject) => {
      try {
        context.filterv2.categoriaId = nodeId
        context.filterv2.precio = value
        resolve()
      } catch (e) {
        reject(e)
      }
    })
      .then(() => {
        setSelected(nodeId)
        history.push(`/products/${nodeId}/cat/ls`)
      })
      .catch(e => {
        console.log(e)
      })
  }

  const genNode = objeto => (
    <TreeItem
      nodeId={objeto.idCategoria.toString()}
      key={objeto.idCategoria.toString()}
      label={objeto.nombre}
    >
      {objeto.categoriasHijas.filter(x => x.activo).length > 0 &&
        objeto.categoriasHijas
          .filter(x => x.activo)
          .map((hija, idx) => genNode(hija))}
    </TreeItem>
  )

  const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
  const checkedIcon = <CheckBoxIcon fontSize='small' />

  return (
    <div>
      <Collapse style={{ marginTop: '-20px' }} defaultActiveKey={['0', '1']}>
        <Typography
          style={{
            fontWeight: 'bolder',
            fontSize: '28px',
            color: stylesContext.black,
            marginTop: '-15%',
            marginLeft: '5%',
          }}
        >
          {props.nombre}
        </Typography>

        <Panel
          className='mar'
          header={'Precio'}
          key='2'
          style={{ marginTop: '15px' }}
        >
          <div className={classes.root}>
            <div className={classes.margin} />

            <AirbnbSlider
              value={value}
              onChange={handleChange}
              defaultExpanded={expanded}
              expanded={expanded}
              valueLabelDisplay='on'
              valueLabelFormat={x =>
                x <= 20000
                  ? new Intl.NumberFormat('es-MX', {
                      style: 'currency',
                      currency: 'MXN',
                    }).format(x)
                  : '∞'
              }
              aria-labelledby='range-slider'
              getAriaValueText={valuetext}
              min={0}
              max={25000}
              marks={[
                {
                  value: 20000,
                  label: '',
                },
              ]}
            />
            <div style={{ marginTop: '10px' }}>
              <TextField
                id='start'
                type='number'
                label={'Desde...'}
                size={'small'}
                onChange={handleStart}
                inputProps={{
                  name: 'inputStart',
                  id: 'inputStart',
                  style: { color: 'black', textAlign: 'center' },
                  min: 0,
                  max: 25000,
                }}
                style={{ maxWidth: '45%', color: 'white' }}
                value={value[0]}
                variant='outlined'
                className={classes.customTextField}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
              <TextField
                id='end'
                type='number'
                label={'...Hasta'}
                size={'small'}
                onChange={handleEnd}
                thousandSeparator
                isNumericString
                variant='outlined'
                inputProps={{
                  name: 'inputEnd',
                  id: 'inputEnd',
                  style: { color: 'black', textAlign: 'center' },
                  min: 0,
                  max: 25000,
                }}
                className={classes.customTextField}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                style={{ maxWidth: '45%', float: 'right' }}
                value={value[1]}
              />
              <Button
                className={classes.aplicar}
                variant='outlined'
                color='primary'
                onClick={() => {
                  new Promise((resolve, reject) => {
                    try {
                      context.filterv2.precio = value

                      resolve()
                    } catch (e) {
                      reject(e)
                    }
                  })
                    .then(() => {
                      props.filterPrice()
                    })
                    .catch(e => {
                      console.log(e)
                    })
                }}
              >
                Aplicar
              </Button>
              <br></br> <br></br> <br></br> <br></br>
            </div>
          </div>
        </Panel>
      </Collapse>
      <br></br>

      <Collapse style={{ marginTop: '-20px' }} defaultActiveKey={['0', '1']}>
        <Panel
          className='mar'
          header={'Marca'}
          key='2'
          style={{ marginTop: '15px' }}
        >
          <div className={classes.root}>
            {check.length > 0 && (
              <div>
                {marcas.map((object, index) => (
                  <div>
                    <Checkbox
                      onChange={() => handleToggle2(object, index)}
                      value={object}
                      checked={check[index].activo}
                    >
                      {' '}
                    </Checkbox>
                    <span>{object}</span>
                  </div>
                ))}
              </div>
            )}

            {/*qa*/
            /*6LcPtoMaAAAAAGsptRHqVLqSbVFucHWBBd9oOMeE*/
            /*pp*/
            /*6LcYOdIaAAAAAG09grfCXalmQny9-od32Bs1Opd-*/}
          </div>
        </Panel>
      </Collapse>
      <br></br>
      <Collapse style={{ marginTop: '-20px' }} defaultActiveKey={['0', '1']}>
        <Panel
          className='mar'
          header={'Unidad de medida'}
          key='2'
          style={{ marginTop: '15px' }}
        >
          <div className={classes.root}>
            <FormControl
              required
              size='small'
              fullWidth
              variant='outlined'
              className={classes.formControl}
            >
              <InputLabel htmlFor='cmbUnidadMedida'>
                Unidad de medida
              </InputLabel>
              <Select
                native
                label='Unidad de medida'
                inputProps={{
                  name: 'unidadMedidaId',
                  id: 'cmbUnidadMedida',
                }}
                value={medida}
                onChange={e => handleToggle(e.target.value)}
              >
                <option aria-label='None' value={null} />
                {cat.map((object, index) =>
                  object.activo ? (
                    <option value={object.idGeneral}>
                      {object.descripcionLarga}
                    </option>
                  ) : (
                    <div></div>
                  )
                )}
              </Select>
            </FormControl>
          </div>

          {/*qa*/
          /*6LcPtoMaAAAAAGsptRHqVLqSbVFucHWBBd9oOMeE*/
          /*pp*/
          /*6LcYOdIaAAAAAG09grfCXalmQny9-od32Bs1Opd-*/}
        </Panel>
      </Collapse>
      <br></br>

      <Collapse style={{ marginTop: '-20px' }} defaultActiveKey={['0', '1']}>
        <Panel
          className='mar'
          header={'Caracteristica adicional'}
          key='2'
          style={{ marginTop: '15px' }}
        >
          <div className={classes.root}>
            <TextField
              label='Caracteristica ejemplo *Color'
              size='small'
              fullWidth
              name='caracteristica'
              type='text'
              value={caracteristica}
              onChange={e => setCaracteristica(e.target.value)}
            />
            <TextField
              label='Valor ejemplo *rojo'
              size='small'
              fullWidth
              name='valor'
              type='text'
              value={valor}
              onChange={e => setValor(e.target.value)}
            />
            <Button
              className={classes.aplicar}
              variant='outlined'
              color='primary'
              onClick={() => {
                filterFicha(caracteristica, valor)
              }}
            >
              Aplicar
            </Button>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
        </Panel>
      </Collapse>
      <br></br>
    </div>
  )
}
