import {
  Button,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
  Zoom,
} from '@material-ui/core'
import Fab from '@material-ui/core/Fab'
import Step from '@material-ui/core/Step'
import StepConnector from '@material-ui/core/StepConnector'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Check from '@material-ui/icons/Check'
import { Segment, Icon as SemanticIcon } from 'semantic-ui-react'
import { Image } from 'antd'
import clsx from 'clsx'
import moment from 'moment'
import 'moment/locale/es'
import PropTypes from 'prop-types'
// import {
//   getProductosConPromo,
//   getMoneda,
// } from '../../Result/Mask/catalogorequest'

import React, { Fragment, useContext, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router'
import { Icon } from 'semantic-ui-react'
import pedidoActions from '../../../actions/pedidoActions'
import Base from '../../../services/Base'
import { ReactComponent as LogoPDF } from '../../../svg/_pdf.svg'
import { ReactComponent as LogoXML } from '../../../svg/_xml.svg'
import Context from '../../../store/Context'
import '../Styles.css'
import BotonCancelar from './BotonCancelar'
import BotonConsultarFactura from './BotonConsultarFactura'
import BotonFacturar from './BotonFacturar'
import { getCarrito } from '../../../actions/carritoActions'
import { getMercadoPK } from '../../../actions/resultActions'
import InfoIcon from '@material-ui/icons/Info'
// import { COLORS } from '../../../Views/Colores'
import StylesContext from '../../../contexts/styles/stylesContext'
import GlobalContext from '../../../contexts/GlobalContext'

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    marginBottom: '-20px',
  },
}))(Tooltip)

// import "antd/dist/antd.css";

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  icon: {
    borderRadius: '5em',
  },
})

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles()
  const { active, completed } = props

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  )
}

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein }
}

const rows = [createData('Frozen yoghurt', 159, 6.0, 24, 4.0)]

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
}

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 18,
  },
  active: {
    '& $line': {
      //   backgroundImage:
      //     "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      backgroundImage:
        'linear-gradient(95deg,rgb(164 163 175) 0%,rgb(112 94 148) 50%,rgb(28 71 109) 100%);',
    },
  },
  completed: {
    '& $line': {
      //   backgroundImage:
      //     "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      backgroundImage:
        'linear-gradient(95deg,rgb(164 163 175) 0%,rgb(112 94 148) 50%,rgb(28 71 109) 100%);',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector)

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    // backgroundImage:
    //   "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    backgroundColor: '#1c476d',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    // backgroundImage:
    //   "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    backgroundColor: '#1c476d',
  },
})

const useStyles2 = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  list: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  table: {
    minWidth: 650,
  },
}))

function ColorlibStepIcon(props, _icons) {
  const classes = useColorlibStepIconStyles()
  const { active, completed } = props

  let icons = {}
  for (let [i, value] of _icons.entries()) {
    const index = i + 1
    icons = { ...icons, [`${index}`]: <Icon fit className={value} /> }
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  )
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
}

const groupHistorialByDate = detalles => {
  const sorted = detalles.sort((a, b) => b.estatusId - a.estatusId)

  let acum = []
  sorted.forEach(currentValue => {
    const date = moment(new Date(currentValue.fecha)).locale('es').format('LL')
    const index = acum.findIndex(x => x.id === date)
    if (index >= 0) {
      const curr = acum[index]
      curr.detalle = [...curr.detalle, currentValue]
    } else {
      acum.push({ id: date, detalle: [currentValue] })
    }
  })
  return acum
}

export default function PedidoDetalle({
  pedido: noPedido,
  setPedido: setNoPedido,
  pedidoInd,
  getPedidos,
  fechaP,
}) {
  const { monedaState } = useContext(GlobalContext)
  const stylesContext = useContext(StylesContext)
  const classes = useStyles2()
  const [activeStep, setActiveStep] = useState()
  // const steps = getSteps();
  const [pedido, setPedido] = useState(null)
  const [historial, setHistorial] = useState(null)
  const [steps, setSteps] = useState([])
  const [icons, setIcons] = useState([])
  const history = useHistory()
  const context = useContext(Context)

  useEffect(() => {
    if (pedido?.historico && pedido?.historico?.length > 0) {
      const steps = pedido.historico
        .sort((a, b) => a.estatusId - b.estatusId)
        .map(x => x.nombre)
      const icons = pedido.historico
        .sort((a, b) => a.estatusId - b.estatusId)
        .map(x => x.icon)
      setSteps(steps)
      setIcons(icons)
      setActiveStep(pedido.estatusId)
    }
  }, [pedido])

  useEffect(() => {
    getDetalle()
  }, [])

  const getDetalle = async () => {
    const pedido = await pedidoActions.getPedidoResumen(noPedido)

    setPedido(pedido)

    const hist = groupHistorialByDate(pedido.historico)
    setHistorial(hist)
    var access_token = await getMercadoPK()
    window.localStorage.setItem('access_token', access_token.data.clave)
  }
  const fecha = new Date()
  const mesHoy = fecha.getMonth() + 1
  const fechaPedido = pedido !== null ? pedido.historico[0].fecha : ''
  const fechaFormato = new Date(fechaPedido == '' ? fecha : fechaPedido)
  const mesPedido = fechaFormato.getMonth() + 1
  const [loading, setLoading] = React.useState(false)
  const [moneda, setMoneda] = useState(() => monedaState.abreviatura)

  // const getMonedas = async () => {
  // const moned = await getMoneda()
  // window.localStorage.setItem('monedasup', moned.data.abreviatura)

  // setMoneda(moned.data.abreviatura)
  // console.log(moned)
  // }

  // useEffect(() => {
  //  getPromos();
  // getMonedas()
  // }, [])

  //  console.log(hoy);
  const getInfoAdicional = producto =>
    !producto.infoAdicional ? (
      <TableCell align='left'>{producto.producto}</TableCell>
    ) : (
      <HtmlTooltip
        placement='top-start'
        interactive
        TransitionComponent={Zoom}
        leaveDelay={200}
        title={
          <React.Fragment>
            <Typography style={{ display: 'flex' }}>
              <Typography variant='caption' gutterBottom align='center'>
                {producto.infoAdicional}
              </Typography>
            </Typography>
          </React.Fragment>
        }
      >
        <TableCell align='left'>
          <Typography>
            {producto.producto}
            <InfoIcon
              style={{ marginLeft: '5px', verticalAlign: 'top' }}
              fontSize={'small'}
            />
          </Typography>
        </TableCell>
      </HtmlTooltip>
    )

  return (
    <div className={classes.root}>
      <Segment
        basic
        style={{ padding: 0, margin: 0, width: '100%' }}
        loading={loading}
      >
        <div>
          <label
            style={{
              color: stylesContext.black,
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={() => {
              setNoPedido(null)
              getPedidos()
            }}
          >
            Mis pedidos
          </label>{' '}
          {'>'} Pedido #{noPedido}
          <Grid container style={{ marginTop: '30px' }}>
            <Grid item xs={2}>
              {pedido === null ||
              pedido?.estatusId === 8 ||
              pedido?.estatusId === 9 ? (
                <div />
              ) : pedido.facturacion ? (
                <BotonConsultarFactura
                  pedido={pedido}
                  getDetalle={getDetalle}
                />
              ) : (
                pedido?.estatusId === 3 && (
                  <BotonFacturar pedido={pedido} getDetalle={getDetalle} />
                )
              )}
            </Grid>
            <Grid item xs={8}>
              {pedido === null ||
              pedido?.estatusId === 8 ||
              pedido?.estatusId === 9 ? (
                <div />
              ) : pedido.facturacion ? (
                pedido.estatusId === 10 && (
                  <div style={{ marginLeft: '30px' }}>
                    <Grid container>
                      <Grid item xs={2} md={1}>
                        <Fab
                          size='small'
                          onClick={() => {
                            window.open(
                              `${Base.baseFiles.facturacion}${pedido.filePDF}`
                            )
                          }}
                          className={classes.icon}
                        >
                          <LogoPDF style={{ width: '70%' }} />
                        </Fab>
                      </Grid>
                      <Grid item xs={2} md={1}>
                        <Fab
                          size='small'
                          onClick={() => {
                            window.open(
                              `${Base.baseFiles.facturacion}${pedido.fileXML}`
                            )
                          }}
                          className={classes.icon}
                        >
                          <LogoXML style={{ width: '70%' }} />
                        </Fab>
                      </Grid>
                    </Grid>
                  </div>
                )
              ) : pedido?.estatusId >= 2 ? (
                '' /*<label style={{ textAlign: "center", padding: "10px", backgroundColor: "#fade98", display: "block", marginRight: "10px", marginLeft: "10px" }}>¡No olvides facturar tu recibo en el mismo mes que se realizó tu pedido!</label>*/
              ) : (
                <label
                  style={{
                    textAlign: 'center',
                    padding: '10px',
                    backgroundColor: '#fade98',
                    display: 'block',
                    marginRight: '10px',
                    marginLeft: '10px',
                  }}
                >
                  La factura podrá ser solicitada una vez que el pago de su
                  pedido se haya efectuado.
                </label>
              )}
            </Grid>
            <Grid item xs={2}>
              {pedido?.estatusId < 7 && (
                <BotonCancelar
                  pedido={pedido}
                  getDetalle={getDetalle}
                  carga={setLoading}
                />
              )}
            </Grid>
          </Grid>
        </div>
        <div style={{ margin: '10px auto' }}>
          <Divider />
        </div>
        <Grid container style={{ marginTop: '30px' }}>
          <Grid item xs={8} md={10}>
            {pedido?.pedidoFlete?.urlRastreo && (
              <Typography variant={'subtitle2'}>
                <a
                  href={`${
                    pedido?.pedidoFlete?.urlRastreo.startsWith('http')
                      ? ''
                      : '//'
                  }${pedido?.pedidoFlete?.urlRastreo}`}
                  target='_blank'
                >
                  Rastrear mi pedido.
                </a>
              </Typography>
            )}
          </Grid>
          <Grid item xs={4} md={2}>
            <Button
              onClick={() => {
                const carritoId = window.localStorage.getItem('carritoID')
                const data = {
                  carritoId,
                  folio: noPedido,
                }
                pedidoActions
                  .buyAgain(data)
                  .then(() => {
                    const carritoId = window.localStorage.getItem('carritoID')
                    return getCarrito(carritoId)
                  })
                  .then(response => {
                    var carrito = response.data
                    carrito.items.forEach(i => {
                      context.addCartFromRedis({
                        quantity: i.cantidad,
                        _id: i.id,
                        price: i.precio,
                        images: i.imagen,
                        title: i.producto,
                        peso: i.peso,
                        familiaId: i.categoriaId,
                      })
                    })
                    history.push('/tienda/articulos/carrito/pago')
                  })
                  .catch(e => {
                    console.log(e)
                  })
              }}
              size='small'
              variant='contained'
              color='primary'
            >
              Volver a comprar
            </Button>
          </Grid>
        </Grid>
        {pedido && (
          <Fragment>
            <Grid
              container
              spacing={0}
              style={{ display: isMobile ? 'block' : 'flex' }}
            >
              <Grid item sm={12} md={isMobile ? 12 : 4}>
                {historial &&
                  historial.map(h => (
                    <div>
                      <h3 style={{ marginBottom: 5 }}>{h.id}</h3>
                      {h.detalle.map(d => (
                        <div style={{ marginBottom: 15 }}>
                          <p style={{ lineHeight: '1.1' }}>
                            {moment(new Date(d.fecha)).format('HH:mm')} |{' '}
                            {d.descripcion}
                          </p>
                        </div>
                      ))}
                    </div>
                  ))}
              </Grid>
              <Grid item sm={12} md={isMobile ? 12 : 8}>
                <Stepper
                  className='pedido-detalle-stepper'
                  alternativeLabel
                  activeStep={activeStep}
                  connector={isMobile ? <></> : <ColorlibConnector />}
                  orientation={isMobile ? 'vertical' : 'horizontal'}
                >
                  {steps.map(label => (
                    <Step key={label}>
                      <StepLabel
                        StepIconComponent={props =>
                          ColorlibStepIcon(props, icons)
                        }
                      >
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
            </Grid>
            <TableContainer
              style={{ boxShadow: 'none', overflowX: 'unset' }}
              component={Paper}
            >
              <Table className={classes.table} aria-label='simple table'>
                <TableHead>
                  {moneda === 'USD' && (
                    <TableRow>
                      <TableCell
                        className='table-no-padding'
                        align='right'
                        colSpan={5}
                      >
                        Tipo de cambio:{' '}
                        <strong style={{ color: 'red' }}>
                          ${pedido.tipoCambioStr}
                        </strong>{' '}
                        MXN
                      </TableCell>
                    </TableRow>
                  )}
                  {}
                  {pedido.costoEnvio +
                    pedido.costoEmplayado +
                    pedido.costoPesoCero >
                  0 ? (
                    <TableRow>
                      {moneda === 'MXN' && (
                        <TableCell
                          className='table-no-padding'
                          align='right'
                          colSpan={5}
                        >
                          Costo de envío:{' '}
                          <strong style={{ color: 'red' }}>
                            $
                            {(
                              pedido.costoEnvio +
                              pedido.costoEmplayado +
                              pedido.costoPesoCero
                            ).toFixed(2)}
                          </strong>{' '}
                          MXN
                        </TableCell>
                      )}
                      {moneda === 'USD' && (
                        <TableCell
                          className='table-no-padding'
                          align='right'
                          colSpan={5}
                        >
                          Costo de envío:{' '}
                          <strong style={{ color: 'red' }}>
                            $
                            {(
                              pedido.costoEnvio +
                              pedido.costoEmplayado +
                              pedido.costoPesoCero
                            ).toFixed(2) / pedido.tipoCambio.toFixed(2)}
                          </strong>{' '}
                          USD
                        </TableCell>
                      )}
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell
                        className='table-no-padding'
                        align='right'
                        colSpan={5}
                      >
                        Sin costo de envío.
                      </TableCell>
                    </TableRow>
                  )}
                  {pedido.tarimas > 0 ? (
                    <TableRow>
                      {moneda === 'MXN' && (
                        <TableCell
                          className='table-no-padding'
                          align='right'
                          colSpan={5}
                        >
                          Tarimas ({pedido.tarimas}):{' '}
                          <strong style={{ color: 'red' }}>
                            ${pedido.costoTarimasStr}
                          </strong>{' '}
                          MXN
                        </TableCell>
                      )}
                      {moneda === 'USD' && (
                        <TableCell
                          className='table-no-padding'
                          align='right'
                          colSpan={5}
                        >
                          Tarimas ({pedido.tarimas}):{' '}
                          <strong style={{ color: 'red' }}>
                            $
                            {(
                              pedido.costoTarimasStr / pedido.tipoCambio
                            ).toFixed(2)}
                          </strong>{' '}
                          USD
                        </TableCell>
                      )}
                    </TableRow>
                  ) : (
                    <div />
                  )}
                  {pedido.costoSeguro > 0 ? (
                    <TableRow>
                      {moneda === 'MXN' && (
                        <TableCell
                          className='table-no-padding'
                          align='right'
                          colSpan={5}
                        >
                          Seguro de carga ({pedido.ptjeSeguro}):{' '}
                          <strong style={{ color: 'red' }}>
                            ${pedido.costoSeguro.toFixed(2)}
                          </strong>{' '}
                          MXN
                        </TableCell>
                      )}
                      {moneda === 'MXN' && (
                        <TableCell
                          className='table-no-padding'
                          align='right'
                          colSpan={5}
                        >
                          Seguro de carga ({pedido.ptjeSeguro}):{' '}
                          <strong style={{ color: 'red' }}>
                            $
                            {pedido.costoSeguro.toFixed(2) /
                              pedido.tipoCambio.toFixed(2)}
                          </strong>{' '}
                          USD
                        </TableCell>
                      )}
                    </TableRow>
                  ) : (
                    <div />
                  )}
                  <TableRow>
                    {moneda === 'MXN' && (
                      <TableCell
                        className='table-bottom'
                        align='right'
                        colSpan={5}
                      >
                        Total del pedido:{' '}
                        <strong style={{ color: 'red' }}>
                          ${pedido.totalStr}
                        </strong>{' '}
                        MXN
                      </TableCell>
                    )}

                    {moneda === 'USD' && (
                      <TableCell
                        className='table-bottom'
                        align='right'
                        colSpan={5}
                      >
                        Total del pedido:{' '}
                        <strong style={{ color: 'red' }}>
                          ${(pedido.total / pedido.tipoCambio).toFixed(2)}
                        </strong>{' '}
                        USD
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow
                    style={{
                      borderLeft: '1px solid #e0e0e0',
                      borderRight: '1px solid #e0e0e0',
                    }}
                  >
                    <TableCell></TableCell>
                    <TableCell align='left' style={{ pagging: '8px 16px' }}>
                      Producto
                    </TableCell>
                    <TableCell align='right' style={{ pagging: '8px 16px' }}>
                      Precio
                    </TableCell>
                    <TableCell align='right' style={{ pagging: '8px 16px' }}>
                      Cantidad
                    </TableCell>
                    <TableCell align='right' style={{ pagging: '8px 16px' }}>
                      Total
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pedido &&
                    pedido.productos &&
                    pedido.productos.map(p => (
                      <TableRow
                        key={p.name}
                        style={{
                          borderLeft: '1px solid #e0e0e0',
                          borderRight: '1px solid #e0e0e0',
                        }}
                      >
                        <TableCell align='left'>
                          <Image
                            width={60}
                            height={60}
                            preview={false}
                            style={{ objectFit: 'cover' }}
                            src={Base.baseFiles.imgProduct + p.imagen}
                            fallback={'/img/IMAGEN_NO_DISPONIBLE.png'}
                          />
                        </TableCell>
                        {getInfoAdicional(p)}
                        {moneda === 'MXN' && (
                          <TableCell align='right'>
                            {(p.precio * pedido.tipoCambio)
                              .toFixed(2)
                              .toLocaleString('es-MX', {
                                style: 'currency',
                                currency: 'MXN',
                              })}
                          </TableCell>
                        )}
                        {moneda === 'USD' && (
                          <TableCell align='right'>
                            {p.precio.toLocaleString('es-MX', {
                              style: 'currency',
                              currency: 'MXN',
                            })}
                          </TableCell>
                        )}
                        <TableCell align='right'>{p.cantidad}</TableCell>
                        {moneda === 'MXN' && (
                          <TableCell align='right'>
                            {(
                              p.cantidad *
                              (p.precio * pedido.tipoCambio).toFixed(2)
                            ).toLocaleString('es-MX', {
                              style: 'currency',
                              currency: 'MXN',
                            })}
                          </TableCell>
                        )}
                        {moneda === 'USD' && (
                          <TableCell align='right'>
                            {(p.cantidad * p.precio.toFixed(2)).toLocaleString(
                              'es-MX',
                              {
                                style: 'currency',
                                currency: 'MXN',
                              }
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ marginTop: '20px' }}>
              <Typography
                variant={'body'}
                style={{ color: '#003462', fontWeight: 'bold' }}
              >
                ¿Necesitas ayuda?
              </Typography>
              <Typography variant={'body2'}>
                <a
                  href={`https://wa.me/+528180287617?text=Hola! Quiero informes sobre el pedido ${noPedido}`}
                  target='_blank'
                >
                  No olvides contactarnos si tienes algún detalle con tu pedido.
                </a>
              </Typography>
            </div>
          </Fragment>
        )}
      </Segment>
    </div>
  )
}
