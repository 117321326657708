import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2'

import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
// import pedidoActions from '../../../actions/pedidoActions'
// import { alerts } from '../../../services/alerts'
import ApiGeneral from '../../../services/ApiGeneral'
import { useFormik } from 'formik'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import errores from '../../../services/errores'
import Select from '@material-ui/core/Select'

// import AuthCliente from '../../../services/AuthCliente'

import * as yup from 'yup'
const instance = axios.create({
  baseURL: 'https://api.paypal.com',
})
const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(../img/logointegrinox.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  grid: {
    background: '#eeeeee',
    height: '100%',
  },
  paper: {
    margin: '3%',
    marginTop: '14%',
    textAlign: 'center',
    background: '#ffff',
    alignItems: 'center',
  },
  formulario: {
    margin: '6%',
    textAlign: 'center',
  },
  link: {
    marginTop: '5%',
  },
  titulo: {
    color: '#003462!important',
  },
  btncancelar: {
    background: '#eeeeee',
    color: '#003462',
    backgroundColor: '#eeeeee!important',
  },
  btnsubnmit: {
    background: '#003462',
    color: '#ffff',
    backgroundColor: '#003462 !important',
  },
  DiAction: {
    paddingRight: '2rem',
  },
  error: {
    color: '#ff0500',
  },
  formContro: {
    minWidth: '531px',
  },
}))
const BotonFacturar = ({ pedido, getDetalle }) => {
  const [open, setOpen] = React.useState(false)
  const [openConfirmacion, setOpenConfirmacion] = React.useState(false)
  const [RFCVal, setRFCVal] = React.useState('')
  const [rfcVerified, setRfcVerified] = React.useState(false)
  const [btnEnabled, setBtnEnabled] = React.useState(true)
  const classes = useStyles()

  const [CP, setCP] = React.useState('')
  const [tipoCfdi, setTipoCfdi] = React.useState([])
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClickOpenConf = () => {
    setOpenConfirmacion(true)
  }
  const validationSchema = yup.object({
    razonSocial: yup.string().required('La razón social es requerida'),
    rfc: yup
      .string()
      .min(12, 'El RFC debe tener un mínimo de 12 caracteres.')
      .max(13, 'El RFC debe tener un máximo de 13 caracteres.')
      .required('El RFC es requerido.'),

    domicilioFiscal: yup.string().required('El domicilio fiscal es requerido.'),
    usoCFDIId: yup.string().required('El campo CDFI es requerido.'),

    codigoPostal: yup
      .string()
      .max(5, 'el codigo postal debe tener un maximo de 5 caracteres')
      .min(5, '')
      .required('El codigo postal es requerido.'),
  })
  useEffect(() => {
    getCfdi()
  }, [])
  const validate = values => {
    const errors = {}

    return errors
  }
  const formik = useFormik({
    initialValues: {
      rfc: '',
      usoCFDIId: '',
      codigoPostal: '',
      razonSocial: '',
      domicilioFiscal: '',
      PedidoId: pedido.id,
      UsuarioCreoId: pedido.cliente.idCliente,
    },

    validate,

    validationSchema: validationSchema,
    onSubmit: values => {
      setOpenConfirmacion(true)
    },
  })
  const handleClose = () => {
    setBtnEnabled(true)
    setCP('')

    formik.resetForm()
    setOpen(false)
  }
  const handleCloseConf = () => {
    setOpenConfirmacion(false)
    setBtnEnabled(true)
  }
  const getCfdi = () => {
    ApiGeneral.getCFDI(3)
      .then(response => {
        setTipoCfdi(response)

        console.info('éxito data cfdi:' + response)
      })
      .catch(error => {
        console.error('error data:' + error)
      })
  }
  return (
    <div style={{ display: 'inline-block', float: 'center' }}>
      <Button
        size='small'
        style={{ float: 'center', backgroundColor: 'green', color: 'white' }}
        variant='outlined'
        color='primary'
        onClick={handleClickOpen}
      >
        Solicitar factura
      </Button>
      <Dialog open={open} onClose={handleClose}>
        {/* <DialogTitle id="alert-dialog-title">
            {"Use Google's location service?"}
          </DialogTitle> */}
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <DialogContent>
              <DialogContentText>
                <TextField
                  label='Razón Social*'
                  margin='dense'
                  id='razonSocial'
                  name='razonSocial'
                  placeholder='Razón Social'
                  type='text'
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.razonSocial}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.razonSocial &&
                    Boolean(formik.errors.razonSocial)
                  }
                  helperText={
                    formik.touched.razonSocial && formik.errors.razonSocial
                  }
                />
                <TextField
                  margin='dense'
                  id='rfc'
                  name='rfc'
                  label='RFC*'
                  placeholder='RFC'
                  type='text'
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.rfc}
                  onBlur={formik.handleBlur}
                  error={formik.touched.rfc && Boolean(formik.errors.rfc)}
                  helperText={formik.touched.rfc && formik.errors.rfc}
                />

                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel htmlFor='usoCFDIId'> CFDI*</InputLabel>
                  <Select
                    native
                    label='CFDI'
                    name='CFDI'
                    inputProps={{
                      name: 'usoCFDIId',
                      id: 'usoCFDIId',
                    }}
                    onChange={formik.handleChange}
                    value={formik.values.usoCFDIId}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.usoCFDIId &&
                      Boolean(formik.errors.usoCFDIId)
                    }
                    helperText={
                      formik.touched.usoCFDIId && formik.errors.usoCFDIId
                    }
                  >
                    <option aria-label='None' value='' />
                    {tipoCfdi.map((object, index) =>
                      object.activo ? (
                        <option value={object.idGeneral}>
                          {object.descripcionLarga}
                        </option>
                      ) : (
                        <div></div>
                      ),
                    )}
                  </Select>
                </FormControl>
                <TextField
                  margin='dense'
                  id='codigoPostal'
                  name='codigoPostal'
                  label='Codigo postal*'
                  placeholder='Codigo postal'
                  type='text'
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.codigoPostal}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.codigoPostal &&
                    Boolean(formik.errors.codigoPostal)
                  }
                  helperText={
                    formik.touched.codigoPostal && formik.errors.codigoPostal
                  }
                />
                <TextField
                  margin='dense'
                  id='domicilioFiscal'
                  name='domicilioFiscal'
                  label='Domicilio fiscal*'
                  placeholder='Domicilio fiscal'
                  type='text'
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.domicilioFiscal}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.domicilioFiscal &&
                    Boolean(formik.errors.domicilioFiscal)
                  }
                  helperText={
                    formik.touched.domicilioFiscal &&
                    formik.errors.domicilioFiscal
                  }
                />
              </DialogContentText>
            </DialogContent>
            <Button onClick={handleClose} color='primary' autoFocus>
              Cerrar
            </Button>
            <div style={{ marginLeft: '30px', float: 'right' }}>
              <Button
                variant='outlined'
                type='submit'
                className={classes.btnsubnmit}
                color='primary'
              >
                Guardar
              </Button>
              <Dialog open={openConfirmacion} onClose={handleCloseConf}>
                {/* <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle> */}
                <DialogContent>
                  <DialogContentText>
                    Los datos serán enviados
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant='outlined'
                    disabled={!btnEnabled}
                    className={classes.btnsubnmit}
                    value='Confirmar'
                    color='primary'
                    onClick={() => {
                      setBtnEnabled(false)
                      var createFactura = {
                        rfc: formik.values.rfc,
                        usoCFDIId: formik.values.usoCFDIId,
                        codigoPostal: formik.values.codigoPostal,
                        razonSocial: formik.values.razonSocial,
                        domicilioFiscal: formik.values.domicilioFiscal,
                        PedidoId: pedido.id,
                        UsuarioCreoId: pedido.cliente.idCliente,
                      }

                      ApiGeneral.enviarFacturacion(createFactura)
                        .then(r => {
                          handleClose()
                          handleCloseConf()

                          Swal.fire(
                            'Los datos se han guardado con éxito.',
                            '',
                            'success',
                          )
                          getDetalle()
                          console.info('Los datos se han guardado con éxito', r)
                        })
                        .catch(e => {
                          handleClose()
                          handleCloseConf()
                          Swal.fire(errores.handleError('error'), '', 'error')
                        })
                    }}
                  >
                    Confirmar
                  </Button>
                  <Button onClick={handleCloseConf} color='primary' autoFocus>
                    Cerrar
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default BotonFacturar
