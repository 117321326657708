import Grid from '@material-ui/core/Grid'
import Ofertas from '../../components/Ofertas/Ofertas'
import Cvendido from '../../components/Vendido/Cvendido'
import BannerExample from '../Banner/BannerExample'
import General from '../../Views/General/General'
import ProcederPago from '../../components/Cart/procederPago'
import { useEffect } from 'react'

export default function ArticulosCarrito() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <Grid container>
        <Grid item lg={12} md={12} xs={12}>
          <ProcederPago></ProcederPago>
        </Grid>
      </Grid>
    </div>
  )
}
