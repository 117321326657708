import React, { useReducer, useEffect } from 'react'

import UserContext from './UserContext'
import UserReducer from './UserReducer'
import { setUser, clearUser } from './UserActions'
import { login as identityLogin } from '../../services/identidadService'

const initialState = {
  authenticated: false,
  token: null,
  tokenExp: null,
  idCliente: null,
  email: null,
  name: null,
  preferredUsername: null,
  locale: null,
  loading: false,
  error: null,
}

const getInitialState = () => {
  const savedUser = localStorage.getItem('userData')
  if (savedUser) {
    const userData = JSON.parse(savedUser)
    if (userData.tokenExp > Date.now()) {
      return { ...userData, authenticated: true }
    }
    localStorage.removeItem('userData')
  }
  return initialState
}

const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(UserReducer, getInitialState())

  useEffect(() => {
    const savedUser = localStorage.getItem('userData')
    if (savedUser && !state.authenticated) {
      const userData = JSON.parse(savedUser)
      if (userData.tokenExp > Date.now()) {
        dispatch(setUser(userData))
      }
    }
  }, [state.authenticated])

  const doLogin = async (email, password) => {
    const controller = new AbortController()
    const signal = controller.signal

    dispatch({ type: 'LOADING_START' })

    try {
      const response = await identityLogin(signal, { email, password })

      if (response.Success) {
        const tokenData = parseJwt(response.Token)
        const tokenExp = tokenData.exp * 1000

        const userData = {
          authenticated: true,
          token: response.Token,
          tokenExp: tokenExp,
          // userData: {
          // authenticated: true,
          idCliente: tokenData.sub,
          email: tokenData.email,
          name:
            tokenData.name ||
            `${tokenData.given_name} ${tokenData.family_name}`,
          preferredUsername: tokenData.preferred_username,
          // },
          locale: tokenData.locale,
        }

        // Guardar en el estado
        dispatch(setUser(userData))

        // Guardar en localStorage
        localStorage.setItem('userData', JSON.stringify(userData))
        localStorage.setItem('tokenCustom', response.Token)

        // Configurar el temporizador para la expiración del token
        startTokenExpiryTimer(tokenExp)
      } else {
        throw new Error('Credenciales inválidas.')
      }
    } catch (error) {
      // console.log('error')
      if (error.name !== 'AbortError') {
        const errorMessage =
          error?.response?.data?.message || 'Error al iniciar sesión'
        dispatch({ type: 'LOGIN_ERROR', payload: errorMessage })
      }
    } finally {
      dispatch({ type: 'LOADING_END' })
    }

    // Cancelar la solicitud si es necesario (por ejemplo, si se navega a otra página)
    return () => {
      controller.abort()
    }
  }

  const doLogout = () => {
    dispatch(clearUser())
    localStorage.removeItem('userData')
    clearTimeout(tokenExpiryTimeout)
  }

  // Temporizador para manejar la expiración del token
  let tokenExpiryTimeout
  const startTokenExpiryTimer = expiryTime => {
    const timeLeft = expiryTime - Date.now()
    if (timeLeft > 0) {
      tokenExpiryTimeout = setTimeout(() => {
        doLogout()
        alert('Tu sesión ha expirado. Por favor, inicia sesión nuevamente.')
      }, timeLeft)
    }
  }

  return (
    <UserContext.Provider
      value={{
        UserState: state,
        doLogin,
        doLogout,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider

// Función para decodificar el token JWT
function parseJwt(token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  )

  return JSON.parse(jsonPayload)
}
