import React, { useEffect, useState, useRef, useContext } from 'react'

import Card from './CardProductMobile'
// import Mask from './integrinoxoriginal.css'
import Context from '../../../store/Context'
import { getUrlForProductImg } from '../../../utils/ima'
// import NumberFormat from 'react-number-format'
// import { COLORS } from '../../../Views/Colores'
// import { getProductosConPromo, getMoneda } from './catalogorequest'
// import CardPromo from './CardPromo'
// import Header from '../../Header/HeaderResponsive'
// import {
//   getProductDetail,
//   getProductDetailName,
//   postComentario,
//   getComentarios,
//   getProductPrice,
//   getRespuesta,
//   getPreguntas,
//   getPromo,
//   getProductVariant,
//   postPregunta,
//   getVariant,
//   getWishList,
//   searchgetWishList,
//   getWishExist,
//   editWishList,
//   getElement,
//   getLogo,
// } from '../../../actions/resultActions'
// import TableRang from './TableRank'
import GlobalContext from '../../../contexts/GlobalContext'

// const Footer = React.lazy(() => import('../../../Views/Footer/Footer'))

const ProductCard = ({ items, nombres }) => {
  const context = useContext(Context)
  const { monedaState } = useContext(GlobalContext)

  // const [promos, setPromos] = useState([])
  const [moneda, setMoneda] = useState(() => monedaState)

  // const getPromos = async () => {
  //   const promociones = await getProductosConPromo()
  //   console.log(promociones.data)
  //   setPromos(promociones.data)
  // }

  // const getMonedas = async () => {
  //   const moned = await getMoneda()
  //   window.localStorage.setItem('monedasup', moned.data.abreviatura)

  //   setMoneda(moned.data)
  //   console.log(moned)
  // }

  // useEffect(() => {
  //  getPromos();
  // getMonedas()
  // getL()
  // }, [])

  // const [logop, setLogop] = useState('')

  // document.documentElement.style.setProperty('--color-principal', COLORS.black)
  // const getL = async () => {
  //   const response2 = await getLogo()
  //   setLogop(response2.data.url)
  // }

  const nodata = (
    <div style={{ textAlign: 'center' }}>
      <br></br>

      <div>
        <h2 className='title-NotFound'>No se encontraron resultados</h2>
      </div>

      <div>
        <img style={{ textAlign: 'center' }} src='/img/DEFAULT1.png' alt='' />
      </div>
    </div>
  )
  const datos = (
    <div>
      <div className='product-items'>
        {items.map(p => (
          <div>
            {moneda.abreviatura === 'USD' && (
              <Card
                key={p.idProducto}
                idProducto={p.idProducto}
                price={p.precio}
                imagenPereview={
                  p.imagenPereview === null
                    ? '/img/IMAGEN_NO_DISPONIBLE.png'
                    : getUrlForProductImg(p.imagenPereview)
                }
                sku={p.sku}
                nombre={p.nombre}
                sprice2={p.precio}
                sprice={p.preciod === undefined ? p.precio : p.preciod}
                abrevia={moneda.abreviatura}
                calificacion={p.calificacion}
                activoDescuento={p.activoDescuento}
                descuentoPrecio={p.descuentoPrecio}
                rebaja={p.rebaja}
                descuento={p.descuento}
                icon={p.wish}
                peso={p.peso}
                inventario={p.inventario}
                categoriaId={p.categoriaId}
                lists={p.lists}
                addProductToCart={context.addProductToCart}
              />
            )}
            {moneda.abreviatura === 'MXN' && (
              <Card
                key={p.idProducto}
                idProducto={p.idProducto}
                price={p.precio * context.getTipoCambio}
                imagenPereview={
                  p.imagenPereview === null
                    ? '/img/IMAGEN_NO_DISPONIBLE.png'
                    : getUrlForProductImg(p.imagenPereview)
                }
                sku={p.sku}
                nombre={p.nombre}
                sprice2={p.precio * context.getTipoCambio}
                sprice={
                  p.preciod === undefined
                    ? p.precio * context.getTipoCambio
                    : p.preciod * context.getTipoCambio
                }
                abrevia={moneda.abreviatura}
                calificacion={p.calificacion}
                activoDescuento={p.activoDescuento}
                descuentoPrecio={p.descuentoPrecio * context.getTipoCambio}
                rebaja={p.rebaja}
                descuento={p.descuento}
                icon={p.wish}
                peso={p.peso}
                inventario={p.inventario}
                categoriaId={p.categoriaId}
                lists={p.lists}
                addProductToCart={context.addProductToCart}
              />
            )}
            <br></br> <br></br>
          </div>
        ))}
      </div>
    </div>
  )

  const result = items.length == 0 ? nodata : datos

  return result
}

export default ProductCard
