import axios from 'axios'
import Swal from 'sweetalert2'

import Base from '../services/Base'
import { nombre } from '../services/Url'

const axiosInstance = axios.create({
  baseURL: Base.baseUrls.almacen,
  headers: {
    'Content-Type': 'application/json',
    username: 'cliente',
    nombres: nombre.tienda,
    //id: id,
  },
  //timeout: 1000,
})

// Función para manejar peticiones con cancelación y manejo centralizado de errores
const fetchWithCancel = async (url, signal) => {
  try {
    const response = await axiosInstance.get(url, { signal })
    return response.data
  } catch (e) {
    if (axios.isCancel(e)) {
      console.warn('Request canceled', e.message)
    } else {
      Swal.fire(
        e.response?.data || 'Almacen actions',
        'Error desconocido',
        'error'
      )
      throw e
    }
  }
}

export const getProductosById = async (signal, id) => {
  return fetchWithCancel('Producto/GetFCollectionId/' + id, signal)
}
