import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import styled from 'styled-components'
import {
  EmptyCart,
  SideBarHeader,
  Card,
  CardBody,
  CardTitle,
  CardRow,
  CardSubtitle,
  Card2,
  CardSubtitle2,
} from '../../components/Cart/Styles'
import { Image } from 'antd'
import Base from '../../services/Base'
import CountDown from '../CountDown/CountDown'
import { withStyles } from '@material-ui/core/styles'
import { Button, TextField, Tooltip, Typography, Zoom } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import NumberFormat from 'react-number-format'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard'
import StylesContext from '../../contexts/styles/stylesContext'

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip)

// const getInfoAdicional = (id, producto, infoAdicional) => (
//   <HtmlTooltip
//     placement='top'
//     interactive
//     TransitionComponent={Zoom}
//     leaveDelay={300}
//     title={
//       <>
//         <Typography style={{ display: 'flex' }}>
//           <Typography variant='caption' gutterBottom align='center'>
//             {infoAdicional}
//           </Typography>
//         </Typography>
//       </>
//     }
//   >
//     <CardTitle
//       style={{ cursor: 'pointer' }}
//       onClick={() => history.push(`/products/${id}/p`)}
//     >
//       {producto}
//       <InfoIcon
//         style={{ marginLeft: '5px', verticalAlign: 'top' }}
//         fontSize={'small'}
//       />
//     </CardTitle>
//   </HtmlTooltip>
// )

const Text = styled.div`
  box-sizing: border-box;
  display: ${props => (props.display ? props.display : 'inline-block')};
  font-size: ${props => (props.size ? props.size : '14')};
  font-weight: ${props => (props.weight ? props.weight : '400')};
  color: ${props => (props.color ? props.color : 'black')};
  text-align: ${props => (props.align ? props.align : 'left')};
  width: ${props => (props.width ? props.width : '100%')};
  height: ${props => (props.height ? props.height : 'auto')};
  margin: ${props => (props.margin ? props.margin : '0')};
  padding: ${props => (props.padding ? props.padding : 'padding')};
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
`

export const ContentRow = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: ${({ fullWidth }) => (fullWidth ? '1fr' : '1fr 1fr')};
  grid-auto-rows: auto;
  gap: 10px;
  border: 2px #f2f2f2 solid;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
`

export const ContentRowTitle = styled(ContentRow)`
  border-radius: 0;
  border-left: none;
  border-right: none;
`
// export const SideBarHeader = styled.div`
//   font-weight: 300;
//   font-size: 24px;
//   text-transform: none;
//   position: relative;
//   padding: 15px 0;
//   color: black;
//   font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;

//   &:after {
//     content: '';
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     height: 1px;
//     width: 100%;
//     background: #f2f2f2;
//   }
// `

export const ContentRowCard = styled(ContentRow)`
  display: flex;
  justify-content: center;
  transition: background-color 0.3s ease, border-color 0.3s ease;

  &:hover {
    background-color: #e2e2e2;
    border-color: #cccccc;
  }
`

export const Column = styled.div`
  grid-column: ${({ fullWidth }) => (fullWidth ? 'span 2' : 'auto')};
  padding: 0.2rem;
  box-sizing: border-box;
`

const formatCurrency = value => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value)
}

const OrdenDetalle = ({
  isInteractive = false,
  productos = [],
  abreviatura = 'MXN',
  tipoCambio = 19,
  promos = null,

  totales = null,
  // resumen = null,
  peso = 1,
  pesoDeTarima = 1,
  tarimas = 1,
  tarjetaPay = false,
  tarjetasVal = false,
  tarjetas = null,
  cupones = null,
  totalA = 123.456,
  Antes = 0,
  desTarjeta = 0,
  precioClienteDescuento = 0, // PrecioCliente.descuento
  envios = 0,
  enviosTarjeta = 0,
  envioStep = false,
  descuento = 0,
  useTarjeta = false,
  prod = [],
  resumenCompra = null,
}) => {
  const history = useHistory()
  const stylesContext = useContext(StylesContext)

  const [cuponText, setCuponText] = useState('')

  const validateCupon = async () => {
    // fix: hacer funcionar
    // if (cupones.filter(x => x.aplicaOtrosCupones === false).length > 0) {
    //   var cuponActual = cupones[0]
    //   alerts.warning(
    //     'El cupón ' + cuponActual.codigo + ' no es aplicable con otros cupones'
    //   )
    // } else {
    //   pedidoActions
    //     .getCuponValidacion(cuponText)
    //     .then(response => {
    //       setCuponText('')
    //       let precio = 0
    //       precio = (response.descuento / 100) * totalA
    //       let aux = totalA - precio
    //       setTotalA(aux)
    //       setUseCupon(true)
    //       setDescuentoC(precio)
    //       if (cupones.length > 0 && response.aplicaOtrosCupones === false) {
    //         alerts.warning(
    //           'El cupón ' +
    //             response.codigo +
    //             ' no es aplicable con otros cupones'
    //         )
    //       } else {
    //         setCupones([...cupones, response])
    //       }
    //     })
    //     .catch(error => {
    //       setCuponText('')
    //       alerts.warning(error.data.errors)
    //     })
    // }
  }

  const getTarimasInfo = () => {
    // FIX: hacer funcionar
    // <div>
    //   <div style={{ textAlign: 'center' }}>
    //     Tarimas de transporte
    //     <HtmlTooltip
    //       placement='top'
    //       interactive
    //       TransitionComponent={Zoom}
    //       leaveDelay={300}
    //       title={
    //         <>
    //           <Typography style={{ display: 'flex' }}>
    //             <Typography variant='body2' gutterBottom align='center'>
    //               {`Los productos de las siguientes familias requieren ser transportados en tarimas para evitar daños: `}
    //               {totales?.familiasConTarima.map(x => (
    //                 <Typography variant='body2' align='center'>
    //                   {`-${x}`}
    //                 </Typography>
    //               ))}
    //               <Typography variant='caption' gutterBottom align='center'>
    //                 Para más información, favor de contactarnos.
    //               </Typography>
    //             </Typography>
    //           </Typography>
    //         </>
    //       }
    //     >
    //       <HelpIcon fontSize={'small'} />
    //     </HtmlTooltip>
    //   </div>
    //   <div style={{ textAlign: 'center' }}>
    //     Cantidad de tarimas: {totales?.tarimas}
    //   </div>
    //   <div style={{ textAlign: 'center' }}>
    //     Peso en tarimas: {totales?.pesoEnTarimas?.toFixed(2)} Kg
    //   </div>
    // </div>
  }

  const TarjetaPay = async (
    monto,
    idCompra,
    nombre,
    id,
    cantidad,
    urls,
    activo,
    mensaje,
    destinatario,
    remitente,
    fecha
  ) => {
    // if (totalA > monto) {
    //   getTarjeta()
    //   setDisable(true)
    //   setIsLoading(true)
    //   _Producto.id = id
    //   _Producto.idCompra = idCompra
    //   _Producto.activo = activo
    //   _Producto.destinatario = destinatario
    //   _Producto.remitente = remitente
    //   _Producto.mensaje = mensaje
    //   _Producto.fecha = fecha
    //   _Producto.cantidad = cantidad
    //   _Producto.urls = urls
    //   _Producto.nombre = nombre
    //   if (totales?.total + envios >= monto) {
    //     //400, 200= 200
    //     if (useCupon === false) {
    //       let variable = totales?.total + envios - (monto + descuento)
    //       setTotalA(variable)
    //       setUseTarjeta(true)
    //       setdesTarjeta(monto)
    //       _Producto.montoActual = monto
    //       setIsLoading(false)
    //     } else {
    //       let variable = totalA - monto
    //       setTotalA(variable)
    //       setUseTarjeta(true)
    //       setdesTarjeta(monto)
    //       _Producto.montoActual = monto
    //       setIsLoading(false)
    //     }
    //   }
    //   if (monto > totales?.total + envios) {
    //     //400, 1000= 1000-400= 600
    //     let variable = monto + descuento - (totales?.total + envios)
    //     setTotalA(0)
    //     setEnviosTarjeta(envios)
    //     setEnvios(0)
    //     setUseTarjeta(true)
    //     setdesTarjeta(variable)
    //     _Producto.montoActual = variable
    //     var response = await updateTarjeta(_Producto)
    //     const directionId = window.localStorage.getItem('direccion')
    //     // const idCarrito = carritoId // window.localStorage.getItem('carritoID')
    //     const envio = JSON.parse(window.localStorage.getItem('envio'))
    //     // const deliveryType99m = JSON.parse(
    //     //   window.localStorage.getItem('deliveryType99m')
    //     // )
    //     const costoEnvio = Number(costoEnvio)
    //     envio.deliveryType99m = deliveryType99m
    //     const _totales = await pedidoActions.getCarritototales(
    //       carritoId,
    //       deliveryMethod === 'Sucursal'
    //         ? null
    //         : currentFlete?.idFleteConfiguracion,
    //       cupones.length <= 0 ? [] : cupones.map(x => x.id),
    //       deliveryMethod
    //     )
    //     let data2
    //     if (deliveryMethod === 'Sucursal') {
    //       data2 = {
    //         carritoId: carritoId,
    //         sucursalId: sucursalId,
    //         referencia: _totales.referencia,
    //         clienteDireccionId: null,
    //         cuponesIds: cupones?.length <= 0 ? [] : cupones.map(x => x.id),
    //         fleteId: null,
    //         idFleteConfiguracion: null,
    //         payPalId: '1443849397',
    //         payPalCaptureId: '14948527',
    //         intent: 'CAPTUREMP',
    //         status: 'COMPLETED',
    //         monto: desTarjeta,
    //       }
    //     } else {
    //       data2 = {
    //         carritoId: carritoId,
    //         sucursalId: null,
    //         costoEnvio,
    //         envio,
    //         referencia: _totales.referencia,
    //         clienteDireccionId: directionId,
    //         cuponesIds: cupones?.length <= 0 ? [] : cupones.map(x => x.id),
    //         fleteId: 1,
    //         idFleteConfiguracion: 1,
    //         payPalId: '1443849397',
    //         payPalCaptureId: '14948527',
    //         intent: 'CAPTUREMP',
    //         status: 'COMPLETED',
    //         monto: desTarjeta,
    //       }
    //     }
    //     pedidoActions
    //       .createPayPal(data2)
    //       .then(folio => {
    //         setTarjetaPay(false)
    //         setIsLoading(false)
    //         if (monto < totales?.total + envios) {
    //           deletes()
    //         }
    //         history.push(`/orden/${folio}`)
    //         clearCart()
    //       })
    //       .catch(error => {
    //         console.log(error)
    //       })
    //       .finally(() => {
    //         setLoading(false)
    //       })
    //   }
    // } else {
    //   Swal.fire(
    //     'La tarjeta de regalo solo puede usarse con pedidos mayores a su valor',
    //     'Agrega más cosas a tu carrito',
    //     'error'
    //   )
    // }
  }

  const getSeguroCargaInfo = () => {
    // <div>
    //   {totales?.costoSeguro > 0 && (
    //     <div className='nrow'>
    //       <CardSubtitle>
    //         {`Seguro de carga`}
    //         <HtmlTooltip
    //           placement='top'
    //           interactive
    //           TransitionComponent={Zoom}
    //           leaveDelay={300}
    //           title={
    //             <>
    //               <Typography style={{ display: 'flex' }}>
    //                 <Typography variant='body2' gutterBottom align='center'>
    //                   {`*Por disposición de tu pedido, se estará aplicando un costo adicional del ${totales?.ptjeSeguro} de seguro de carga en el total de tu compra.`}
    //                 </Typography>
    //               </Typography>
    //             </>
    //           }
    //         >
    //           <HelpIcon fontSize={'small'} style={{ marginTop: '-10px' }} />
    //         </HtmlTooltip>
    //       </CardSubtitle>
    //       <CardSubtitle2>
    //         <NumberFormat
    //           value={totales?.costoSeguro.toFixed(2)}
    //           displayType={'text'}
    //           thousandSeparator={true}
    //           prefix={'$'}
    //         />{' '}
    //         MXN
    //       </CardSubtitle2>
    //     </div>
    //   )}
    // </div>
  }

  const getEmplayadoInfo = () => {
    // <div>
    //   {totales?.costoEmplayado > 0 ? (
    //     <div className='nrow'>
    //       <CardSubtitle>
    //         {`Emplayado (${totales?.ptjeEmplayado})`}
    //         <HtmlTooltip
    //           placement='top'
    //           interactive
    //           TransitionComponent={Zoom}
    //           leaveDelay={300}
    //           title={
    //             <>
    //               <Typography style={{ display: 'flex' }}>
    //                 <Typography variant='body2' gutterBottom align='center'>
    //                   {`Los productos de las siguientes familias requieren ser emplayados para evitar daños: `}
    //                   {totales?.familiasConEmplayado &&
    //                     totales?.familiasConEmplayado.map(x => (
    //                       <Typography variant='body2' align='center'>
    //                         {`-${x}`}
    //                       </Typography>
    //                     ))}
    //                   <Typography variant='caption' gutterBottom align='center'>
    //                     Para más información, favor de contactarnos.
    //                   </Typography>
    //                 </Typography>
    //               </Typography>
    //             </>
    //           }
    //         >
    //           <HelpIcon fontSize={'small'} style={{ marginTop: '-10px' }} />
    //         </HtmlTooltip>
    //       </CardSubtitle>
    //       <CardSubtitle2>
    //         <NumberFormat
    //           value={totales?.costoEmplayado.toFixed(2)}
    //           displayType={'text'}
    //           thousandSeparator={true}
    //           prefix={'$'}
    //         />{' '}
    //         MXN
    //       </CardSubtitle2>
    //     </div>
    //   ) : (
    //     <div />
    //   )}
    // </div>
  }

  if (productos?.length === 0) {
    return (
      <ContentRow>
        <Column fullWidth>
          <Text weight='600' size='18' color={stylesContext.primaryColor}>
            Carrito Vacio
          </Text>
        </Column>
      </ContentRow>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        padding: '0.5rem',
        borderLeft: '2px solid #f2f2f2',
      }}
    >
      {isInteractive && (
        <SideBarHeader>
          <CountDown isActive={isInteractive} />
        </SideBarHeader>
      )}

      <ContentRowTitle>
        <Column fullWidth>
          <Text size='24px' color={stylesContext.primaryColor}>
            Resumen de compras
          </Text>
        </Column>
      </ContentRowTitle>

      {productos?.map(item => (
        <ContentRowCard>
          <Column>
            <Image
              width={130}
              preview={false}
              src={Base.baseFiles.imgProduct + item.imagen}
              fallback={'/img/IMAGEN_NO_DISPONIBLE.png'}
            />
          </Column>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Column>
              <Text weight='600' color={stylesContext.primaryColor}>
                {!item.infoAdicional ? (
                  <CardTitle
                    style={{ cursor: 'pointer' }}
                    onClick={() => history.push(`/products/${item.id}/p`)}
                  >
                    {decodeURIComponent(item.producto)}
                  </CardTitle>
                ) : (
                  // getInfoAdicional(item.id, item.producto, item.infoAdicional)
                  <HtmlTooltip
                    placement='top'
                    interactive
                    TransitionComponent={Zoom}
                    leaveDelay={300}
                    title={
                      <>
                        <Typography style={{ display: 'flex' }}>
                          <Typography
                            variant='caption'
                            gutterBottom
                            align='center'
                          >
                            {item.infoAdicional}
                          </Typography>
                        </Typography>
                      </>
                    }
                  >
                    <CardTitle
                      style={{ cursor: 'pointer' }}
                      onClick={() => history.push(`/products/${item.id}/p`)}
                    >
                      {item.producto}
                      <InfoIcon
                        style={{ marginLeft: '5px', verticalAlign: 'top' }}
                        fontSize={'small'}
                      />
                    </CardTitle>
                  </HtmlTooltip>
                )}
              </Text>
            </Column>

            <Column>
              <CardRow
                style={{ marginBottom: 0 }}
                className='card-cart-counter'
              >
                <input
                  type='text'
                  className='number'
                  value={item.cantidad}
                  readOnly
                  style={{
                    width: 'auto',
                    display: 'inline-block',
                  }}
                />
              </CardRow>
            </Column>

            <Column>
              <Text>
                {/* {formatCurrency(item.peso)} */}
                {`P/U ${item.peso.toFixed(2)} Kg`}
              </Text>
            </Column>

            <Column>
              <Text color={stylesContext.primaryColor}>{`${formatCurrency(
                item.precio * 1
              )} ${abreviatura}`}</Text>
            </Column>
          </div>
        </ContentRowCard>
      ))}

      {/* <div
        className='pedido-details'
        style={{ padding: '1rem', display: 'hiden' }}
      >
        {productos.map(item => (
          <>
            <Card key={item.id} style={{ alignItems: 'center' }}>
              <Image
                width={130}
                preview={false}
                src={Base.baseFiles.imgProduct + item.imagen}
                fallback={'/img/IMAGEN_NO_DISPONIBLE.png'}
              />
              <CardBody>
                <CardRow>
                  {!item.infoAdicional ? (
                    <CardTitle
                      style={{ cursor: 'pointer' }}
                      onClick={() => history.push(`/products/${item.id}/p`)}
                    >
                      {decodeURIComponent(item.producto)}
                    </CardTitle>
                  ) : (
                    // getInfoAdicional(item.id, item.producto, item.infoAdicional)
                    <HtmlTooltip
                      placement='top'
                      interactive
                      TransitionComponent={Zoom}
                      leaveDelay={300}
                      title={
                        <>
                          <Typography style={{ display: 'flex' }}>
                            <Typography
                              variant='caption'
                              gutterBottom
                              align='center'
                            >
                              {item.infoAdicional}
                            </Typography>
                          </Typography>
                        </>
                      }
                    >
                      <CardTitle
                        style={{ cursor: 'pointer' }}
                        onClick={() => history.push(`/products/${item.id}/p`)}
                      >
                        {item.producto}
                        <InfoIcon
                          style={{ marginLeft: '5px', verticalAlign: 'top' }}
                          fontSize={'small'}
                        />
                      </CardTitle>
                    </HtmlTooltip>
                  )}
                  {isInteractive && (
                    <Button
                      size='small'
                      style={{
                        marginTop: -10,
                        textTransform: 'capitalize',
                      }}
                      onClick={() => {
                        history.push('/tienda/articulos/carrito/pago')
                      }}
                    >
                      Editar
                    </Button>
                  )}
                </CardRow>
                <CardRow
                  style={{ marginBottom: 0 }}
                  className='card-cart-counter'
                >
                  <input
                    type='text'
                    className='number'
                    value={item.cantidad}
                    readOnly
                    style={{
                      width: 'auto',
                      display: 'inline-block',
                    }}
                  />
                </CardRow>
                <CardRow style={{ marginTop: 0 }}>
                  <CardTitle
                    style={{
                      color: 'black',
                      fontWeight: 400,
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '0.5rem',
                    }}
                  >
                    <Typography>P/U</Typography>
                    <Typography>
                      <NumberFormat
                        value={item.peso.toFixed(2)}
                        displayType={'text'}
                        thousandSeparator={true}
                      />
                    </Typography>
                    <Typography>Kg</Typography>
                  </CardTitle>
                </CardRow>

                <CardRow>
                  <CardTitle>
                    {tipoCambio && (
                      <div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '0.5rem',
                            fontWeight: 'bold',
                          }}
                        >
                          <Typography>
                            <NumberFormat
                              value={parseFloat(item.precio * 1).toFixed(2)}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'$'}
                            />
                          </Typography>
                          <Typography>{abreviatura}</Typography>
                        </div>
                        <div>
                          {promos != null && (
                            <div>
                              {promos.map(promo => (
                                <div>
                                  {promo.idProducto === item.id && (
                                    <CardTitle>
                                      <del>
                                        <NumberFormat
                                          value={parseFloat(
                                            item.precio
                                          ).toFixed(2)}
                                          displayType={'text'}
                                          thousandSeparator={true}
                                          prefix={'$'}
                                        />
                                        USD
                                      </del>
                                      <div>
                                        <Typography
                                          variant='h2'
                                          className='etiqueta'
                                        >
                                          Ahorra
                                          <NumberFormat
                                            decimalSeparator={'.'}
                                            value={parseFloat(
                                              (promo.descuento / 100) *
                                                item.precio
                                            ).toFixed(2)}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            prefix={'$'}
                                          />
                                        </Typography>
                                        <NumberFormat
                                          value={parseFloat(
                                            item.precio -
                                              (promo.descuento / 100) *
                                                item.precio
                                          ).toFixed(2)}
                                          displayType={'text'}
                                          thousandSeparator={true}
                                          prefix={'$'}
                                        />
                                        USD
                                      </div>
                                    </CardTitle>
                                  )}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </CardTitle>
                </CardRow>
              </CardBody>
            </Card>
          </>
        ))}
      </div> */}

      {isInteractive && (
        <Card2>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <CardSubtitle
              style={{
                display: 'flex',
                alignItems: 'center',
                width: 'auto',
              }}
            >
              Aplicar código <LocalOfferIcon />
            </CardSubtitle>

            <TextField
              style={{ margin: '0px 5px' }}
              size='small'
              id='outlined-basic'
              label='Código'
              variant='outlined'
              inputProps={{ maxLength: 10 }}
              value={cuponText}
              onChange={e => {
                setCuponText(e.target.value)
              }}
            />
            <Button onClick={validateCupon} variant='contained' color='primary'>
              Aplicar
            </Button>
          </div>
        </Card2>
      )}

      <ContentRow>
        {tarimas > 0 ?? <Column fullWidth> {getTarimasInfo()}</Column>}
        <Column fullWidth>
          <Text weight='600' align='center'>
            <Tooltip
              title={
                tarimas > 0
                  ? `Peso adicional por ${tarimas} tarimas: ${(
                      pesoDeTarima * tarimas
                    ).toFixed(2)} Kg`
                  : 'Peso total del pedido'
              }
              aria-label='add'
              placement='bottom'
            >
              <span>
                {/* {`Peso total de pedido: ${peso.toFixed(2)} Kg`} */}
                {`Peso total de pedido: ${(
                  peso +
                  pesoDeTarima * tarimas
                ).toFixed(2)} Kg`}
              </span>
            </Tooltip>
          </Text>
        </Column>
      </ContentRow>

      {isInteractive && tarjetaPay === true && (
        <Card2>
          {tarjetasVal === true && (
            <div>
              <CardTitle>Tarjetas de regalo disponibles</CardTitle>{' '}
              <CardGiftcardIcon />
            </div>
          )}
          {tarjetas !== null &&
            tarjetas.map(tarjeta => (
              <div
                style={{
                  display: 'flex',
                }}
              >
                <Card2
                  style={{
                    width: '100%',
                  }}
                >
                  <CardSubtitle2>Tarjeta {tarjeta.nombre}</CardSubtitle2>
                  <CardSubtitle2
                  // style={{
                  //   color: stylesContext.black,
                  // }}
                  >
                    <Typography>Monto</Typography>
                    {abreviatura === 'MXN' && (
                      <Typography>
                        <NumberFormat
                          value={tarjeta.montoActual.toFixed(2)}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'$'}
                        />{' '}
                        MXN
                      </Typography>
                    )}
                    {abreviatura === 'USD' && (
                      <div>
                        <NumberFormat
                          value={(tarjeta?.montoActual / 1).toFixed(2)}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'$'}
                        />{' '}
                        USD
                      </div>
                    )}
                  </CardSubtitle2>
                  <Button
                    disabled={isInteractive}
                    onClick={() =>
                      TarjetaPay(
                        tarjeta.montoActual,
                        tarjeta.idCompra,
                        tarjeta.nombre,
                        tarjeta.id,
                        tarjeta.cantidad,
                        tarjeta.urls,
                        tarjeta.activo,
                        tarjeta.mensaje,
                        tarjeta.destinatario,
                        tarjeta.remitente,
                        tarjeta.fecha
                      )
                    }
                    variant='contained'
                    color='primary'
                    style={{
                      left: '50%',
                      top: '1%',
                    }}
                  >
                    Aplicar
                  </Button>
                  <Image
                    preview={false}
                    style={{
                      height: '40%',
                      width: '40%',
                      left: '70%',
                      top: '-40%',
                    }}
                    src={tarjeta.urls}
                    fallback={'/img/IMAGEN_NO_DISPONIBLE.png'}
                  />
                </Card2>
              </div>
            ))}
        </Card2>
      )}

      {/* {monedaState.abreviatura === 'USD' && (
        <div>
          <Card2>
            <CardTitle>Resumen de Costos</CardTitle>
            <div className='nrow'>
              <CardSubtitle>Descripción</CardSubtitle>
              <CardSubtitle2>Precio</CardSubtitle2>
            </div>
            <div className='nrow'>
              <CardSubtitle>Subtotal</CardSubtitle>

              <CardSubtitle2>
                <NumberFormat
                  value={totales?.subtotal.toFixed(2)}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                />{' '}
                USD
              </CardSubtitle2>
            </div>
            <div className='nrow'>
              <CardSubtitle>Tipo cambio​</CardSubtitle>
              <CardSubtitle2>
                ${totales?.tipoCambio.toFixed(4)} MXN
              </CardSubtitle2>
            </div>
            {envioStep === true && (
              <div className='nrow'>
                <CardSubtitle>Envío</CardSubtitle>

                <CardSubtitle2>
                  <p>
                    <NumberFormat
                      value={((envios + enviosTarjeta) / 1).toFixed(2)}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />{' '}
                    USD
                  </p>
                </CardSubtitle2>
              </div>
            )}

            {totales?.costoTarimas > 0 ? (
              <div className='nrow'>
                <CardSubtitle>Tarimas</CardSubtitle>
                <CardSubtitle2>
                  <NumberFormat
                    value={(totales?.costoTarimas / 1).toFixed(2)}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                  />{' '}
                  USD
                </CardSubtitle2>
              </div>
            ) : (
              <div />
            )}
            {getEmplayadoInfo()}
            {cupones &&
              cupones.length > 0 &&
              cupones.map(cupon => (
                <div className='nrow' key={cupon?.codigo}>
                  <CardSubtitle
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {cupon?.codigo} ({cupon?.descuento}%)
                    <LocalOfferIcon />
                  </CardSubtitle>
                  <CardSubtitle2>
                    - $
                    <Typography>
                      <NumberFormat
                        value={descuento.toFixed(2)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      />{' '}
                      USD
                    </Typography>
                  </CardSubtitle2>
                </div>
              ))}
            <div className='nrow'>
              <CardSubtitle>IVA</CardSubtitle>
              <CardSubtitle2>
                <NumberFormat
                  value={(totales?.iva / 1).toFixed(2)}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                />{' '}
                USD
              </CardSubtitle2>
            </div>
            {useTarjeta == true && (
              <div>
                <CardSubtitle2
                  style={{
                    color: stylesContext.black,
                  }}
                >
                  Tarjeta de regalo-
                  <NumberFormat
                    value={(desTarjeta / 1).toFixed(2)}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                  />{' '}
                </CardSubtitle2>
                <Card2
                  style={{
                    display: 'flex',
                  }}
                >
                  <CardSubtitle className='text-bold'>
                    Precio anterior
                  </CardSubtitle>

                  <CardSubtitle2>
                    <del>
                      <NumberFormat
                        value={((totales?.total + envios) / 1).toFixed(2)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      />{' '}
                      USD
                    </del>
                  </CardSubtitle2>
                </Card2>
              </div>
            )}
          </Card2>
          <Card2>
            {getSeguroCargaInfo()}
            {precioClienteDescuento !== 0 && (
              <div className='nrow'>
                <CardSubtitle className='text-bold'>
                  {' '}
                  Descuento aplicado: {precioClienteDescuento} %
                </CardSubtitle>
                <CardSubtitle2>
                  <del>
                    <NumberFormat
                      value={Antes.toFixed(2)}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />{' '}
                    USD
                  </del>
                </CardSubtitle2>
              </div>
            )}
            <div className='nrow'>
              <CardSubtitle className='text-bold'>Total</CardSubtitle>
              <CardSubtitle2>
                <NumberFormat
                  value={(
                    (totales?.total +
                      totales?.costoSeguro +
                      envios -
                      (desTarjeta + descuento)) /
                    1
                  ).toFixed(2)}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                />{' '}
                USD
              </CardSubtitle2>
            </div>
          </Card2>
        </div>
      )} */}

      <ContentRow>
        <Column fullWidth>
          <Text weight='600' color={stylesContext.primaryColor}>
            Resumen de Costos
          </Text>
        </Column>

        <Column>
          <Text weight='600'>Descripción</Text>
        </Column>
        <Column>
          <Text weight='600' align='center'>
            Precio
          </Text>
        </Column>

        <Column>
          <Text weight='600'>Subtotal</Text>
        </Column>
        <Column>
          <Text weight='600' align='center'>
            {formatCurrency(resumenCompra?.subtotal)} MXN
          </Text>
        </Column>

        <Column>
          <Text weight='600'>Envío</Text>
        </Column>
        <Column>
          <Text weight='600' align='center'>
            {/* FIX: no viene en el objeto */}
            {`${formatCurrency(resumenCompra?.costoEnvio)} ${abreviatura}`}
          </Text>
        </Column>

        {/* <Column>
          <Text weight='600'>Tarimas</Text>
        </Column>
        <Column>
          <Text weight='600' align='center'>
            {formatCurrency(totales?.costoTarimas)} MXN FIX: activeStep
          </Text>
        </Column> */}

        {/* FIX: que es esto */}
        {/* {getEmplayadoInfo()} */}

        {cupones &&
          cupones?.length > 0 &&
          cupones?.map(cupon => (
            <div className='nrow' key={cupon?.codigo}>
              <CardSubtitle
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {cupon?.codigo} ({cupon?.descuento}%)
                <LocalOfferIcon />
              </CardSubtitle>
              <CardSubtitle2>
                - $
                <Typography>
                  <NumberFormat
                    value={descuento.toFixed(2)}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                  />{' '}
                  MXN
                </Typography>
              </CardSubtitle2>
            </div>
          ))}

        <Column>
          <Text weight='600'>IVA</Text>
        </Column>
        <Column>
          <Text weight='600' align='center'>
            {formatCurrency(resumenCompra?.iva)} MXN
          </Text>
        </Column>

        {useTarjeta == true && (
          <div>
            <CardSubtitle2
            // style={{
            //   color: stylesContext.black,
            // }}
            >
              <Typography>
                Tarjeta de regalo-
                <NumberFormat
                  value={desTarjeta.toFixed(2)}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                />
              </Typography>
            </CardSubtitle2>
            <Card2
              style={{
                display: 'flex',
              }}
            >
              <CardSubtitle className='text-bold'>Precio anterior</CardSubtitle>

              <CardSubtitle2>
                <del>
                  <NumberFormat
                    value={(totalA + desTarjeta + descuento).toFixed(2)}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                  />{' '}
                  MXN
                </del>
              </CardSubtitle2>
            </Card2>
          </div>
        )}
      </ContentRow>

      {/* <Card2>
        {getSeguroCargaInfo()}
        {precioClienteDescuento !== 0 && (
          <div className='nrow'>
            <CardSubtitle className='text-bold'>
              {' '}
              Descuento aplicado: {precioClienteDescuento} %
            </CardSubtitle>
            <CardSubtitle2>
              <del>
                <NumberFormat
                  value={Antes.toFixed(2)}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                />{' '}
                MXN
              </del>
            </CardSubtitle2>
          </div>
        )}

        {prod.length > 0 && (
          <div>
            <CardTitle>Productos en descuento para ti:</CardTitle> <br></br>
            {prod.map((object, index) => (
              <div className='nrow'>
                <CardSubtitle className='text-bold'>
                  {' '}
                  {object.infoAdicional}
                </CardSubtitle>{' '}
                <br></br>
                <CardSubtitle className='text-bold'>
                  {' '}
                  {object.producto} %
                </CardSubtitle>{' '}
                <br></br>
                <br></br>
                <CardSubtitle2>
                  <del>
                    <NumberFormat
                      value={object.precio.toFixed(2)}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />{' '}
                    MXN
                  </del>
                </CardSubtitle2>{' '}
                <br></br>
                <CardSubtitle2>
                  <NumberFormat
                    value={object.precioMXN.toFixed(2)}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                  />{' '}
                  MXN
                </CardSubtitle2>
              </div>
            ))}
          </div>
        )}
      </Card2> */}

      <ContentRow>
        <Column>
          <Text weight='600' color={stylesContext.primaryColor}>
            Total
          </Text>
        </Column>
        <Column>
          <Text weight='600' align='center' color={stylesContext.primaryColor}>
            {/* FIX: No viene el total calculado */}
            {formatCurrency(resumenCompra?.total)} MXN
          </Text>
        </Column>
      </ContentRow>
    </div>
  )
}

export default OrdenDetalle
