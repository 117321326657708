import ReactGA from 'react-ga'
import { accion, renderMetaPixel } from './Pixel'
import ReactPixel from 'react-facebook-pixel'
import { getKey } from '../../actions/resultActions'
import ReactGA4 from 'react-ga4'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
export const useAnalyticsEventTracker = (category = 'Blog category') => {
  const [pixelId, setPixelId] = useState('')

  const history = useHistory()
  const eventTracker = async (
    action = 'test action',
    label = 'test label',
    id,
  ) => {
    const responses = await getKey('9d7e554f-32c5-40c6-bdcf-a558f7a28f9c')
    const TRACKING_ID = responses.data.valor
    ReactGA.initialize(TRACKING_ID)
    ReactGA4.initialize(TRACKING_ID)

    ReactGA.event({ category, action, label })

    ReactGA4.event({ category, action, label })

    renderMetaPixel(action)
    if (
      label === 1 ||
      label === 2 ||
      label === 3 ||
      label === 4 ||
      label === 5 ||
      label === 6 ||
      label === 7 ||
      label === 8 ||
      label === 9
    ) {
      history.push('/products/' + label + '/cat/ls')
      // window.fbq('track', 'Purchase', {currency: "USD", value: 30.00});
    }
  }
  return eventTracker
}
