import { Button, Tooltip, Zoom, Typography, Grid } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import HelpIcon from '@material-ui/icons/Help'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import LoadingScreen from '../RegistroCliente/LoadingScreen'
import { useHistory } from 'react-router'

import Reaptcha from 'reaptcha'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import ApiGeneral from '../../services/ApiGeneral'
import AuthCliente from '../../services/AuthCliente'
import errores from '../../services/errores'
import MySnackBar from './../MySnackBar/SnackBar'
import CircularProgress from '@material-ui/core/CircularProgress'

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    marginTop: '-10px',
  },
}))(Tooltip)
const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: 9999,
    color: '#fff',
  },
  circle: {
    marginLeft: '270px',
    marginTop: '250px',
    position: 'fixed',
  },
  letra: {
    marginLeft: '-15px',
  },
  btncancelar: {
    background: '#eeeeee',
    color: '#003462',
    backgroundColor: '#eeeeee!important',
  },
  btnsubnmit: {
    background: '#003462',
    color: '#ffffff!important',
    backgroundColor: '#003462!important',
  },
  DiAction: {
    paddingRight: '2rem',
  },
  error: {
    color: '#ff0500',
  },
  margin: {
    margin: theme.spacing(1),
  },
}))

const Cliente = ({ setToggle, fun, close }) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [verified, setVerified] = React.useState(false)
  const [emailVerified, setEmailVerified] = React.useState(false)
  const [tipoCliente, setTipoCliente] = React.useState([])
  const [bar, setBar] = React.useState(false)

  const [usuario, setUser] = React.useState({
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    descripcion: '',
    email: '',
    activo: true,
  })

  const [existEmail, setExistEmail] = React.useState({
    emailexist: 'hola@gmail.com',
  })
  function Greeting() {
    return <CircularProgress color='primary' />
  }
  const validate = values => {
    const errors = {}
    if (!values.email) {
      errors.email = 'El campo correo electrónico es requerido'
      setIsLoading(false)
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = 'Introduzca un correo electrónico válido'
      setIsLoading(false)
    }
    AuthCliente.existsEmail(values.email).then(r => {
      setEmailVerified(r.data.helperData)
    })
    if (emailVerified) {
      errors.email = 'El correo ingresado ya existe, favor de ingresar otro'
      setIsLoading(false)
    }

    return errors
  }
  const history = useHistory()

  const formik = useFormik({
    initialValues: {
      descripcion: '',
      email: '',
      activo: true,
    },

    onSubmit: values => {
      setBar(true)
      setToggle(0)
      AuthCliente.createProductoMedida(values)
        .then(r => {
          handleClose()
          Swal.fire('El registro se ha realizado con éxito', '', 'success')
        })
        .catch(e => {
          console.log(e)

          // handleClose();
          setToggle(0)
          Swal.fire(errores.handleError(e.response.data.message), '', 'error')
        })
    },
  })
  const getTipoUsuario = () => {
    ApiGeneral.getCFDI(4) //tipoUsuario
      .then(response => {
        setTipoCliente(response)

        console.info('éxito data tipousuario:' + response)
      })
      .catch(error => {
        console.error('error data:' + error)
      })
  }

  const onVerify = () => {
    setVerified(true)
  }
  useEffect(() => {
    handleClickOpen()
  }, [])
  const handleClickOpen = () => {
    getTipoUsuario()
    formik.handleReset()
    setOpen(true)
  }

  const handleClose = () => {
    fun()
    close()
    AsignarDatos(null)
    setOpen(false)
    setIsLoading(false)
  }
  const [isLoading, setIsLoading] = useState(false)

  const BarS = () => {
    console.log('soy')
    setIsLoading(true)
  }
  const Progress = () => {
    return (
      <React.Fragment>
        <CircularProgress variant='determinate' />
      </React.Fragment>
    )
  }
  const AsignarDatos = objeto => {
    setUser({
      ...usuario,
      id: objeto === null ? '' : objeto.id,
      descripcion: objeto === null ? '' : objeto.descripcion,
      email: objeto === null ? '' : objeto.email,
      activo: objeto === null ? '' : objeto.activo,
    })
  }

  const [hasFletes, setHasFletes] = React.useState('')
  const blurCP = evt => {
    //GetByCP
    AuthCliente.validCP(evt.target.value)
      .then(r => {
        // setHasFletes(r.data ? "" : "Actualmente no contamos con servicio de envio a tu zona.")
        if (!r.data) {
          setSBOpen(true)
          setMessage('Actualmente no contamos con servicio de envío a tu zona.')
          setType('warning')
        }
      })
      .catch(e => {
        console.log(e)
      })
  }
  const [SBopen, setSBOpen] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const [type, setType] = React.useState('')
  const handleCloseSB = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSBOpen(false)
  }
  const KeyPress = event => {
    const keyCode = event.keyCode || event.which
    const keyValue = String.fromCharCode(keyCode)
    if (!/^\d*$/.test(keyValue)) event.preventDefault()
  }
  return (
    <>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby='draggable-dialog-title'
      >
        <div>
          <LoadingScreen open={isLoading} />
          <DialogTitle style={{ cursor: 'move' }} id='draggable-dialog-title'>
            <li
              className='close-icon'
              style={{ marginBottom: '20px', textAlign: 'right' }}
              onClick={handleClose}
            >
              ✕
            </li>
            Registrar Producto Medida
          </DialogTitle>

          <form onSubmit={formik.handleSubmit} autoComplete='off'>
            <DialogContent>
              <DialogContentText>
                <TextField
                  label='Correo Electrónico*'
                  margin='dense'
                  id='email'
                  name='email'
                  type='email'
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && formik.errors.email}
                  helperText={formik.touched.email && formik.errors.email}
                />
                {/* <span style={{color: "red"}}>{errores.error["email"]}</span> */}
                <TextField
                  margin='dense'
                  id='descripcion'
                  name='descripcion'
                  label='Descripcion*'
                  placeholder='Descripcion'
                  type='text'
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.descripcion}
                  onBlur={formik.handleBlur}
                />
                {/* <span style={{color: "red"}}>{errores.error["nombre"]}</span> */}
              </DialogContentText>
              {/*qa*/
              /*6LcPtoMaAAAAAGsptRHqVLqSbVFucHWBBd9oOMeE*/
              /*pp*/
              /*6LcYOdIaAAAAAG09grfCXalmQny9-od32Bs1Opd-*/}
            </DialogContent>
            <DialogActions className={classes.DiAction}>
              <Button
                autoFocus
                onClick={handleClose}
                variant='outlined'
                className={classes.btncancelar}
              >
                Cancelar
              </Button>
              <Button
                type='submit'
                variant='contained'
                className={classes.btnsubnmit}
                value='Registrar'
                color='primary'
                onClick={BarS}
              >
                Guardar
              </Button>
            </DialogActions>
          </form>
        </div>
        <MySnackBar
          Open={SBopen}
          Type={type}
          Message={message}
          HandleClose={handleCloseSB}
        />
      </Dialog>
    </>
  )
}

export default Cliente
