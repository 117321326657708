import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import OrdenDetalle from '../../components/OrdenDetalle/OrdenDetalle'
import OrdenCompleta from '../../components/Orden/OrdenCompleta'

// import { getPedidoResumenByIdCarrito } from '../../services/almacenService'
import { getPedidoResumenById } from '../../services/pedidoService'

const PedidoResumen = () => {
  const { idPedido, bbva } = useParams()

  const [isLoading, setIsLoading] = useState(false)
  const [resumen, setResumen] = useState()
  const [carritoId, setCarritoId] = useState(
    () => window.localStorage.getItem('carritoID') || null
  )

  // const getResumenFromAlmacen = async signal => {
  //   try {
  //     const result = await getPedidoResumenByIdCarrito(signal, carritoId)
  //     if (result) {
  //       setResumen(result)
  //       console.log('PedidoResumen - getResumenFromAlmacen', result)
  //     }
  //     setIsLoading(false)
  //   } catch (error) {
  //     console.error('Error fetching getPedidoResumenByIdCarrito()', carritoId)
  //     setIsLoading(false)
  //   }
  // }

  // useEffect(() => {
  //   if (!carritoId) return

  //   let isMounted = true
  //   const controller = new AbortController()
  //   const signal = controller.signal

  //   setIsLoading(true)
  //   getResumenFromAlmacen(signal)

  //   return () => {
  //     isMounted = false
  //     controller.abort()
  //   }
  // }, [carritoId])

  const getResumenFromPedido = async signal => {
    try {
      const result = await getPedidoResumenById(signal, idPedido)
      setResumen(result)
      console.log('PedidoResumen - getResumenFromPedido', result)
      setIsLoading(false)
    } catch (error) {
      console.error('Error fetching getResumenFromPedido()', idPedido)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!idPedido) return

    let isMounted = true
    const controller = new AbortController()
    const signal = controller.signal

    setIsLoading(true)
    getResumenFromPedido(signal)

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [idPedido])

  return (
    <div
      className='pedido'
      style={{
        display: 'grid',
        gridTemplateColumns: '2fr 1fr',
        gap: '1rem',
        width: '100%',
      }}
    >
      {idPedido && (
        <div className='pedido-tracking' style={{ padding: '1rem' }}>
          <OrdenCompleta idPedido={idPedido} />
        </div>
      )}

      {resumen && (
        // <div className='pedido-details' style={{ padding: '1rem' }}>
        <OrdenDetalle
          isInteractive={false}
          productos={resumen.productos}
          abreviatura='MXN'
          tipoCambio={resumen.tipoCambio}
          // promos
          // totales
          peso={resumen.peso}
          // pesoDeTarima
          tarimas={resumen.tarimas}
          // tarjetaPay
          // tarjetasVal
          // tarjetas
          cupones={resumen.cupones}
          // totalA
          // Antes
          // desTarjeta
          // precioClienteDescuento = 0, // PrecioCliente.descuento
          // envios={}
          // enviosTarjeta
          // envioStep
          // descuento
          // useTarjeta
          // prod
          resumenCompra={resumen}
        />
        // </div>
      )}
    </div>
  )
}

export default PedidoResumen
