/* eslint-disable no-unused-vars */
import axios from 'axios'
import Base from './Base'
import jwt_decode from 'jwt-decode'
import { setUserSession, getUser } from '../components/SecuredUser'
// import KeycloakService from './KeycloakService'
import { nombre, realm } from './Url'
import jsons from './realm.json'
const tokenName = 'user_token'
const userData = {
  islogeado: false,
  data: [],
}

const getLocalToken = () => {
  return JSON.parse(localStorage.getItem(tokenName))
}
const logIn = user => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.identity,
      headers: {
        'Content-Type': 'application/json',
        rsid: 'cliente',
        nombres: nombre.tienda,
        realm: realm.tienda,
      },
    })
    instance
      .post('PublicUsers/login', user)
      .then(r => {
        var token = r.data.helperData.access_token
        var user = jwt_decode(JSON.stringify(token))
        var myuser = {
          scope: user.scope,
          email_verified: user.email_verified,
          sub: user.sub,
          name: user.name,
          preferred_username: user.preferred_username,
          given_name: user.given_name,
        }

        setUserSession(token, myuser, true, user.given_name)
        resolve(r.data)
      })
      .catch(e => {
        //console.log(e);
        reject(e)
      })
  })
}

const userInfo = tk => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.identity,
      headers: {
        'Content-Type': 'application/json',
        rsid: 'cliente',
        nombres: nombre.tienda,
        realm: realm.tienda,
      },
    })
    instance
      .post('PublicUsers/userInfo', tk)
      .then(r => {
        userData.data = r.data
        resolve(r.data)
      })
      .catch(e => {
        //console.log(e);
        reject(e.response)
      })
  })
}
const createUser = user => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        realm: realm.tienda,
      },
    })
    instance
      .post('ClienteForm/CreateB2C', user)
      .then(r => {
        resolve(r.data)
        console.log(user)
        console.log(r.data)
      })
      .catch(e => {
        console.log(e)
        reject(e)
      })
  })
}
const BannerSuscribe = objeto => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        nombres: nombre.tienda,
      },
    })
    instance
      .post('Suscribe/Create', objeto)
      .then(response => {
        resolve(response)
        console.log(objeto)
      })
      .catch(e => {
        reject(e)
      })
  })
}
const createProductoMedida = user => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
      },
    })
    instance
      .post('ProductoMedida/Create', user)
      .then(r => {
        resolve(r.data)
        console.log(user)
        console.log(r.data)
      })
      .catch(e => {
        console.log(e)
        reject(e)
      })
  })
}

const createUserGoogle = user => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        realm: realm.tienda,
      },
    })
    instance
      .post('Clientes/checkAndSave', user)
      .then(r => {
        console.log(r.data)
        resolve(r.data)
      })
      .catch(e => {
        console.log(e)
        reject(e)
      })
  })
}
const logOut = () => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        nombres: nombre.tienda,
        realm: realm.tienda,
      },
    })

    instance
      .post('logout/', {})
      .then(r => {
        localStorage.removeItem(tokenName)
        resolve(r.data)
      })
      .catch(e => {
        //console.log(e);
        reject(e.response)
      })
  })
}

const existsEmail = email => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        realm: realm.tienda,
      },
    })
    instance
      .get('Clientes/exitsEmail/' + email)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        reject(e)
      })
  })
}
const existsRFC = rfc => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        realm: realm.tienda,
      },
    })
    instance
      .get('Clientes/existsRFC/' + rfc)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        reject(e)
      })
  })
}
const existsRFCv2 = rfc => {
  var id = null //KeycloakService.getUserData().id
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        realm: realm.tienda,
      },
    })
    instance
      .get(`Clientes/existsRFCv2/${id}/${rfc}`)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        reject(e)
      })
  })
}
const getUserData = () => {
  return {
    NombreCompleto: userData.name,
    Nombre: userData.given_name,
    Apellido: userData.family_name,
    email: userData.email,
    userName: userData.preferred_username,
    id: userData.sub,
  }
}

const validCP = cp => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.logistica,
      headers: {
        'Content-Type': 'application/json',
        nombres: nombre.tienda,
      },
    })
    instance
      .get('FleteConfiguracion/GetByCP/' + cp)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        reject(e)
      })
  })
}

const AuthCliente = {
  createProductoMedida,
  BannerSuscribe,
  logIn,
  createUser,
  createUserGoogle,

  logOut,
  userInfo,
  existsEmail,
  existsRFC,
  existsRFCv2,
  validCP,
}

export default AuthCliente
