import React, { useState, useEffect } from 'react'
import { getTableById } from '../../../actions/catalogos'
import { Typography } from '@material-ui/core'

export default function DetalleCatalogo({ id, name, idCat }) {
  const [resultResponse, setResultResponse] = useState(<></>)
  let valor = ''
  useEffect(() => {
    const detalleProducto = async () => {
      const response = await getTableById(idCat)
      if (response?.status === 200) {
        // response.data.filter(x => x.idGeneral == idCat).map(item => (
        valor =
          id === 9
            ? response?.data?.descripcionCorta
            : response?.data?.descripcionLarga
        setResultResponse(
          <div className='key-val'>
            <Typography variant='h2' className='keytext'>
              {name}:
            </Typography>
            <Typography variant='h2' className='valtext'>
              {valor}
            </Typography>
          </div>,
        )
        // ));
      }
    }

    detalleProducto()
  }, '')

  return <div> {resultResponse}</div>
}
