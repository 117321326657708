import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-bootstrap'

import useWindowSize from '../../utils/useWindowSize'

import './index.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-alice-carousel/lib/alice-carousel.css'

import { getBannersById } from '../../actions/catalogoActions'

function BannerResponsive({ tipo, idElementos, cl }) {
  const { responsiveSize } = useWindowSize()

  console.log(idElementos)

  const [banners, setBanners] = useState([])

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const signal = controller.signal

    getBannersById(signal, idElementos)
      .then(data => {
        if (isMounted) {
          setBanners(data)
        }
      })
      .catch(e => {
        if (isMounted) {
          console.error('Error fetching banners ', e)
        }
      })

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [idElementos])

  return (
    <Carousel className='desk-banner'>
      {banners.map(banner => (
        <Carousel.Item key={banner.id}>
          <img
            src={responsiveSize === 'sm' ? banner.vertical : banner.horizontal}
          />
        </Carousel.Item>
      ))}
    </Carousel>
  )
}

export default BannerResponsive
