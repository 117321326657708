import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { Menu } from 'antd'

import '../Header/testmenu.css'
import ApiGeneral from '../../services/ApiGeneral'
import { useAnalyticsEventTracker } from '../GoogleAnalitycs/useAnalyticsEventTracker'

import ProductoMedida from './ProductoMedida'
import Contactanos from './Contactanos'
import { getPlantillas } from '../../actions/resultActions'
import Cliente from '../RegistroCliente/Cliente'

const { SubMenu } = Menu

export default function TestMenu(props) {
  const [url, setUrl] = useState('')

  useEffect(() => {
    getIFrame()
    getPlanti()
  }, [])
  const [Plantillas, setPlantillas] = useState([])

  const getPlanti = async () => {
    const plant = await getPlantillas()
    setPlantillas(plant.data)
  }

  const getIFrame = () => {
    ApiGeneral.getLeadForm()
      .then(response => {
        const data = response.data

        setUrl(data.src)
      })
      .catch(e => {
        console.error('error data:' + e)
      })
  }

  const history = useHistory()
  const location = useLocation()

  const menuprincipal = props.Categorias.filter(x => x.idCategoria >= 1)
  const agregados = props.Categorias.filter(x => x.idCategoria <= 0)
  const [isToggle, setToggle] = useState(0)

  const handleClick = e => {
    if (e.key === 'Ver todos') history.push('/products/search/all/ls')

    if (e.key === 'Producto Medida') {
      setToggle(-2)
    }
    if (e.key === 'Tarjetas de regalo') {
      history.push('/tienda/tarjetas')
    }

    if (e.key === 'Contáctanos') {
      setToggle(-4)
    }
  }
  const go = (nombre, id) => {
    console.log(nombre)
    console.log(id)
  }
  const getsublevels = x =>
    x.activo && (
      <SubMenu onTitleClick={handleClick} key={x.idCategoria} title={x.nombre}>
        {x.categoriasHijas
          .sort((a, b) =>
            a.nombre > b.nombre ? 1 : b.nombre > a.nombre ? -1 : 0
          )
          .map(k =>
            k.activo && k.categoriasHijas.filter(x => x.activo).length > 0
              ? getsublevels(k)
              : k.activo && (
                  <Menu.Item
                    style={{ marginLeft: '15px' }}
                    key={k.idCategoria}
                    onClick={() => go(x.nombre, x.idCategoria)}
                  >
                    {k.nombre}
                  </Menu.Item>
                )
          )}
      </SubMenu>
    )
  const getModal = x =>
    x.key === -2 && (
      <Grid container direction='column' justify='center' alignItems='center'>
        <Box display='flex' p={1}>
          <Cliente />
        </Box>
      </Grid>
    )
  const gaEventTracker = useAnalyticsEventTracker('Menu Click')
  return (
    <div>
      <Menu
        className='principalMenu'
        selectedKeys={
          location.pathname.includes('cat')
            ? [parseInt(location.pathname.split('/')[4])]
            : []
        }
        onClick={handleClick}
        mode='horizontal'
      >
        {menuprincipal
          .sort((a, b) =>
            a.nombre > b.nombre ? 1 : b.nombre > a.nombre ? -1 : 0
          )
          .map(x =>
            x.activo && x.categoriasHijas.filter(x => x.activo).length > 0
              ? getsublevels(x)
              : x.activo && (
                  <Menu.Item
                    key={x.idCategoria}
                    onClick={() => gaEventTracker(x.nombre, x.idCategoria)}
                  >
                    {x.nombre}
                  </Menu.Item>
                )
          )}
        {agregados.map(x => (
          <Menu.Item
            key={x.nombre}
            onClick={() => {
              gaEventTracker(x.nombre, x.idCategoria)
              // let id ="";
              if (x.idCategoria === -200) {
                Plantillas.forEach(element => {
                  if (element.nombre === x.nombre) {
                    history.push('/' + element.nombre)
                  }
                })
              }
              //history.push("/products/" + x.idCategoria + "/cat/ls");
            }}
          >
            {x.nombre}
          </Menu.Item>
        ))}
      </Menu>

      {isToggle === -2 && (
        <ProductoMedida
          fun={setToggle}
          close={setToggle}
          setToggle={setToggle}
        />
      )}
      {isToggle === -4 && (
        <Contactanos
          fun={setToggle}
          close={setToggle}
          setToggle={setToggle}
          urls={url}
        />
      )}
    </div>
  )
}
