// import { COLORS } from '../../Views/Colores'
// import useWindowSize from './../../utils/useWindowSize'

import {
  // getKeyPay,
  // getEle,
  // getMercadoPK,
  // getLogo,
  // getDetalle,
  // getBanerT,
  getColeccionBanner,
} from '../../actions/resultActions'
import React, { useContext, useEffect, useState, Suspense } from 'react'
import './plantillas.css'
// import Carousel from 'react-multi-carousel'
// import Card from 'react-bootstrap/Card'
import { Link } from 'react-router-dom'
export default function HomeTest(props) {
  const [banners, setBanners] = useState([])

  useEffect(() => {
    //  getPromos();
    BannersGet()
  }, [])
  const BannersGet = async () => {
    const carrusels = await getColeccionBanner(props.id)
    setBanners(carrusels.data)
  }
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1336 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }
  return (
    <div>
      <section className='collections'>
        <div className='container'>
          <div className='row'>
            <div className='col-4'>
              <div>
                <h3>{banners.titulo}</h3>

                <p>{banners.subtitulo}</p>
                <Link to={`/products/ran/${banners.coleccion}/ls`}>
                  <button className='btn btn-main'>Conozca la colección</button>
                </Link>
              </div>
            </div>

            <div className='col-8'>
              <div className='d-flex'>
                <img src={banners.imagen} alt='' />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
