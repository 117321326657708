import Keycloak from 'keycloak-js'

const _kc = new Keycloak(
  process.env.NODE_ENV === 'production'
    ? '/keycloakPublic.json'
    : process.env.REACT_APP_BASE_URL === 'prd'
    ? '/keycloakPublic.json'
    : '/keycloak.json'
)

const initKeycloak = onAuthenticatedCallback => {
  _kc
    .init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri:
        window.location.origin + '/silent-check-sso.html',
      pkceMethod: 'S256',
      flow: 'standard',
      loginInframe: { enable: false },
      enableLogging: true,
    })
    .then(authenticated => {
      if (authenticated) {
        // Llama el callback de autenticación exitosa
        onAuthenticatedCallback()
      }
    })
    .catch(error => {
      console.error('Error al iniciar Keycloak:', error)
    })
}

// Iniciar sesion
const doLogin = _kc.login

// Cerrar sesion y limpiar el token y la expiración del localStorage
const doLogout = () => {
  localStorage.clear()

  _kc.logout()
}

// Obtener el token del localStorage si está presente y no ha expirado
const getToken = () => {
  // if (!_kc.authenticated) return null

  // Intentar obtener el token del localStorage
  let token = localStorage.getItem('tokenKc')
  let tokenExpiration = localStorage.getItem('tokenExpKc')

  // Verificar si el token en localStorage es válido comprobando la expiración
  const currentTime = new Date().getTime()
  if (token && tokenExpiration && currentTime < tokenExpiration) {
    // console.warn('Usando el token desde localstorage')
    return token
  }

  // Si el token ha expirado, eliminarlo del localStorage
  if (token && tokenExpiration && currentTime >= tokenExpiration) {
    localStorage.removeItem('tokenKc')
    localStorage.removeItem('tokenExpKc')
    // console.warn('El token en localStorage ha expirado')
  }

  // Si no hay token válido en localStorage, intenta usar el token de _kc
  if (_kc.authenticated && _kc.token) {
    // console.warn('Usando el token desde Keycloak (_kc)')

    const token = _kc.token
    const expirationTime = _kc.tokenParsed.exp * 1000 // Convertir exp a milisegundos

    localStorage.setItem('tokenKc', token)
    localStorage.setItem('tokenExpKc', expirationTime)

    return _kc.token
  }

  return null
}

// Obtener instancia completa de Keycloak
const getAll = () => _kc

// Verificar si el usuario está autenticado
const isLoggedIn = () => {
  // Intentar obtener el token
  const token = getToken()

  // Verificar si el token es válido
  return token !== null
}

// Obtener datos del usuario
const getUserData = () => {
  if (!_kc.tokenParsed || !_kc.token) {
    return {
      authenticated: false,
    }
  }

  return {
    authenticated: true,
    NombreCompleto: _kc.tokenParsed?.name,
    Nombre: _kc.tokenParsed?.given_name,
    Apellido: _kc.tokenParsed?.family_name,
    email: _kc.tokenParsed?.email,
    userName: _kc.tokenParsed?.preferred_username,
    token: _kc.token,
    refresh_token: _kc.refreshToken,
    roles: _kc.realmAccess,
    roles2: _kc.resourceAccess,
    id: _kc.tokenParsed?.sub,
  }
}

// Exportar el servicio de usuario
const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getUserData,
  getAll,
}

export default UserService
