import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Swal from 'sweetalert2'

import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Button,
  TextField,
  DialogContent,
  DialogActions,
  DialogContentText,
  InputAdornment,
  IconButton,
} from '@material-ui/core' // TODO: from '@mui/material'

import { RequestResetPassword } from '../../services/catalogoService'

const Container = styled.div`
  width: 100%;
  min-heigth: 360px;
  margin: 0 auto;

  /* Pantallas grandes (Desktop) */
  @media (min-width: 1024px) {
    max-width: 480px;
  }

  /* Tablets */
  @media (min-width: 768px) and (max-width: 1023px) {
    max-width: 50vw;
  }

  /* Tamaño intermedio entre móvil y tablet */
  @media (min-width: 600px) and (max-width: 767px) {
    max-width: 70vw;
  }

  /* Móviles */
  @media (max-width: 599px) {
    max-width: 100%;
  }
`

const Form = styled.form`
  width: 100%;
  padding: 20px;
  border-radius: 0.6rem;
  background-color: #f5f5f5;

  /* Pantallas grandes (Desktop) */
  @media (min-width: 1024px) {
    border: 2px solid #f2f2f2;
  }

  /* Tablets */
  @media (min-width: 768px) and (max-width: 1023px) {
    border: 2px solid #f2f2f2;
  }

  /* Tamaño intermedio entre móvil y tablet */
  @media (min-width: 600px) and (max-width: 767px) {
    border: 1px solid #e0e0e0;
  }

  /* Móviles */
  @media (max-width: 599px) {
    border: none;
    background-color: #ffffff;
  }
`

const Title = styled.div`
  text-align: center;
  font-size: 32px;
  font-weight: 600;
`

const StyledDialogActions = styled(DialogActions)`
  padding-right: 2rem;
`

const ResetPassword = () => {
  const history = useHistory()

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const controller = new AbortController()

  // Capturar el token desde los parámetros de la URL
  const urlParams = new URLSearchParams(window.location.search)
  const token = urlParams.get('token')

  useEffect(() => {
    return () => {
      controller.abort()
    }
  }, [])

  // Configuración de Yup para validación
  const validationSchema = Yup.object({
    password: Yup.string()
      .min(8, 'La contraseña debe tener al menos 8 caracteres.')
      .matches(
        /[A-Z]/,
        'La contraseña debe tener al menos una letra mayúscula.'
      )
      .matches(
        /[a-z]/,
        'La contraseña debe tener al menos una letra minúscula.'
      )
      .matches(/\d/, 'La contraseña debe tener al menos un número.')
      .matches(
        /[\W_]/,
        'La contraseña debe tener al menos un carácter especial.'
      )
      .required('El campo contraseña es requerido'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
      .required('El campo confirmación de contraseña es requerido'),
  })

  // Configuración del formulario con Formik
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: async values => {
      try {
        const response = await RequestResetPassword(controller.signal, {
          token,
          newPassword: values.password,
        })

        if (response.success) {
          Swal.fire({
            title: 'Contraseña restablecida',
            text: 'Tu contraseña ha sido actualizada exitosamente.',
            icon: 'success',
            confirmButtonText: 'Iniciar sesión',
          }).then(() => {
            history.push('/signin')
          })
        } else {
          Swal.fire({
            title: 'Error',
            text:
              response.message ||
              'Hubo un problema al actualizar la contraseña.',
            icon: 'error',
            confirmButtonText: 'Aceptar',
          })
        }
      } catch (error) {
        if (error?.response?.data) {
          const { success, message, statusCode, errorCode, additionalInfo } =
            error?.response?.data

          switch (errorCode) {
            case 'INVALID_TOKEN':
              Swal.fire({
                title: 'Token inválido o expirado',
                text: 'El enlace de restablecimiento es inválido o ha expirado.',
                icon: 'error',
                confirmButtonText: 'Aceptar',
              })
              break
            case 'USER_NOT_FOUND':
              Swal.fire({
                title: 'Usuario no encontrado',
                text: 'No se encontró un usuario asociado a este token.',
                icon: 'error',
                confirmButtonText: 'Aceptar',
              })
              break
            case 'INTERNAL_SERVER_ERROR':
              Swal.fire({
                title: 'Error del servidor',
                text: 'Ocurrió un error al procesar la solicitud. Por favor, inténtelo de nuevo más tarde.',
                icon: 'error',
                confirmButtonText: 'Aceptar',
              })
              break
            default:
              Swal.fire({
                title: 'Error',
                text: 'No se pudo procesar la solicitud. Inténtelo más tarde.',
                icon: 'error',
                confirmButtonText: 'Aceptar',
              })
              break
          }
        } else {
          Swal.fire({
            title: 'Error',
            text: 'No se pudo procesar la solicitud. Inténtelo más tarde.',
            icon: 'error',
            confirmButtonText: 'Aceptar',
          })
        }
      }
    },
  })

  // Funciones para alternar visibilidad de contraseña
  const togglePasswordVisibility = () => setShowPassword(prev => !prev)
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(prev => !prev)

  return (
    <Container>
      <Form
        onSubmit={formik.handleSubmit}
        style={{ maxWidth: '400px', margin: '0 auto' }}
      >
        <DialogContent>
          <Title>Restablecer Contraseña</Title>
        </DialogContent>

        <DialogContent>
          <DialogContentText>
            <TextField
              fullWidth
              margin='dense'
              type={showPassword ? 'text' : 'password'}
              id='password'
              name='password'
              label='Nueva Contraseña*'
              placeholder='Ingresa tu nueva contraseña'
              onChange={formik.handleChange}
              value={formik.values.password}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={togglePasswordVisibility} edge='end'>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              margin='dense'
              type={showConfirmPassword ? 'text' : 'password'}
              id='confirmPassword'
              name='confirmPassword'
              label='Confirmar Contraseña*'
              placeholder='Confirma tu nueva contraseña'
              onChange={formik.handleChange}
              value={formik.values.confirmPassword}
              onBlur={formik.handleBlur}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={toggleConfirmPasswordVisibility}
                      edge='end'
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </DialogContentText>
        </DialogContent>

        <StyledDialogActions>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            fullWidth
            style={{ marginTop: '20px' }}
          >
            Restablecer Contraseña
          </Button>
        </StyledDialogActions>
      </Form>
    </Container>
  )
}

export default ResetPassword
