import React, { useEffect, useState, useContext } from 'react'
import { Skeleton } from '@material-ui/lab'
import StylesContext from '../../contexts/styles/stylesContext'
import { getItemCatGeneralById } from '../../services/catalogoService'

const BannerResponsive = ({ identificador }) => {
  const { primaryColor } = useContext(StylesContext)
  const [dataBar, setDataBar] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [retryCount, setRetryCount] = useState(0)

  const fetchData = async signal => {
    try {
      const result = await getItemCatGeneralById(signal, identificador)
      setDataBar(result)
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error('Error fetching notification bar:', error)
        setError(true)
        setLoading(false)
        if (retryCount < 3) {
          setTimeout(() => {
            setRetryCount(prev => prev + 1)
            setLoading(true)
            setError(false)
          }, 3000)
        }
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const signal = controller.signal

    fetchData(signal)

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [retryCount, identificador])

  return (
    <div
      className='NotificacionBar'
      style={{
        backgroundColor: primaryColor || '#000',
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '30px',
      }}
    >
      {loading ? (
        <Skeleton variant='text' width={300} height={40} />
      ) : error && retryCount >= 3 ? (
        <div>No se pudo cargar la información. Intenta más tarde.</div>
      ) : error ? (
        <div>Intentando cargar de nuevo...</div>
      ) : (
        dataBar?.descripcionLarga
      )}
    </div>
  )
}

export default BannerResponsive
