import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { IconContext } from 'react-icons'
import { Link } from 'react-router-dom'
import FilterIcon from '../icon/filterIcon'
import TreeViewFamilias from './filtersV2/TreeViewFamilias'
import './Navbar.css'
import { Helmet } from 'react-helmet'
import PixelR from '../GoogleAnalitycs/PixelR'
import ApiGeneral from './../../services/ApiGeneral'
// import { Scrollbars } from 'react-custom-scrollbars'
import { Scrollbar } from 'react-scrollbars-custom'
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  Typography,
  makeStyles,
  MenuItem,
  Toolbar,
} from '@material-ui/core'
// import { COLORS } from '../../Views/Colores'
import StylesContext from '../../contexts/styles/stylesContext'

const { forwardRef, useRef, useImperativeHandle } = React

const Navbar = forwardRef((props, ref) => {
  const stylesContext = useContext(StylesContext)

  // document.documentElement.style.setProperty('--color-principal', COLORS.black)
  const childRef = useRef()
  const childRefPrice = useRef()

  useImperativeHandle(ref, () => ({
    getAlert() {
      setSidebar(false)
    },
  }))

  const [sidebar, setSidebar] = useState(false)

  const showSidebar = () => setSidebar(!sidebar)

  const showResult = () => {
    setSidebar(!sidebar)
    props.handleFilters(newFilters)
  }

  const limpiarFiltros = () => {
    childRef.current.resetCats()
    childRefPrice.current.resetPrice()
    setSidebar(!sidebar)
    props.handleFilters(newFilters)
  }

  const [Filters, setFilters] = useState({
    continents: [0],
    price: 1,
  })

  const [Checked, setChecked] = useState([])

  const newFilters = { ...Filters }

  const handleFilters = (filters, category) => {
    newFilters[category] = filters
    setFilters(newFilters)
  }
  useEffect(() => {
    getCategorias()
  }, [])

  const [categorias, setCategorias] = React.useState([])
  const [medida, setMedida] = React.useState([])
  const [espesor, setEspesor] = React.useState([])
  const [tipoAcero, setTipoAcero] = React.useState([])

  const getCategorias = () => {
    ApiGeneral.getCategorias()
      .then(response => {
        setCategorias(response.data)
        getCatalogos()
      })
      .catch(e => {
        console.error('error data:' + e)
      })
  }

  const getCatalogos = () => {
    ApiGeneral.getCFDI(7) //Espesor
      .then(response => {
        setEspesor(response)
        ApiGeneral.getCFDI(9) //Tipo de acero
          .then(response => {
            setTipoAcero(response)
            ApiGeneral.getCFDI(13) //Espesor
              .then(response => {
                setMedida(response)
              })
              .catch(e => {
                console.error('error data:' + e)
              })
          })
          .catch(e => {
            console.error('error data:' + e)
          })
      })
      .catch(e => {
        console.error('error data:' + e)
      })
  }

  return (
    <>
      <PixelR nombres={props.nombres} />
      <IconContext.Provider value={{ color: '#fff' }}>
        <br></br>
        <div className='coleccion'>
          &nbsp;
          <h3 style={{ fontWeight: 'bolder', color: stylesContext.black }}>
            {props.nombres}
          </h3>
        </div>

        <div className='navbar'>
          <Link onClick={showSidebar} to='#' className='menu-bars'>
            <FilterIcon />

            <li className='filtertext'>Filtro</li>
          </Link>
        </div>
        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
          <Scrollbar style={{ width: '100%', height: '100%' }}>
            <ul className='nav-menu-items'>
              <li className='navbar-toggle'>
                <Link to='#' className='menu-barsclose'>
                  <li onClick={showSidebar}>✕</li>
                </Link>
              </li>

              <TreeViewFamilias
                name={'Familia'}
                nombre={props.nombres}
                categorias={categorias}
                tipoAcero={tipoAcero}
                espesor={espesor}
                medida={medida}
                getCategorias={getCategorias}
                setSidebar={setSidebar}
                filterPrice={props.filterPrice}
                filterFicha={props.filterFicha}
                filterMarca={props.filterMarca}
                filterUnidad={props.filterUnidad}
                categoria={props.categoria}
                prods={props.prods}
                setMain={props.setMain}
                set={props.set}
              />
            </ul>
          </Scrollbar>
        </nav>
      </IconContext.Provider>
    </>
  )
})

export default Navbar
