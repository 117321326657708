import React from 'react'
import Container from '@material-ui/core/Container'
import { Box, Grid, Typography } from '@material-ui/core'
import { getBanner } from '../../actions/bannerActions'

import { makeStyles } from '@material-ui/core/styles'
import BannerExample from '../../components/Banner/BannerResponsive'
const useStyles = makeStyles(theme => ({
  root: {
    background: '#ececec ',
    width: '100%',
    padding: '3%',
  },
  gridConten: {
    width: '100%',
    textAlign: 'center',
  },
  titulo: {
    width: '100%',
    textAlign: 'center',
    color: '#003462',
    fontWeight: 'bolder',
  },
}))

function General() {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3} className={classes.gridConten}>
            <Box>
              <Typography variant='h5' className={classes.titulo}>
                Forma de pago
              </Typography>
              <Typography variant='subtitle1' color='initial'>
                Contamos con distintas formas de pago.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.gridConten}>
            <Box>
              <Typography variant='h5' className={classes.titulo}>
                Envíos
              </Typography>
              <Typography variant='subtitle1' color='initial'>
                Envíos rápidos y seguros a todo México
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.gridConten}>
            <Box>
              <Typography variant='h5' className={classes.titulo}>
                Seguridad
              </Typography>
              <Typography variant='subtitle1'>
                Todas tus transacciones son segura
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.gridConten}>
            <Box>
              <Typography variant='h5' className={classes.titulo}>
                Garantía y Servicio
              </Typography>
              <Typography variant='subtitle1'>
                Seguimiento de tus pedidos en cada momento
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <BannerExample cl={'banner'} tipo={2}></BannerExample>
      </Container>
    </Box>
  )
}

export default General
