import React, { useContext, useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Swal from 'sweetalert2'

import { Visibility, VisibilityOff } from '@material-ui/icons'
import {
  Button,
  TextField,
  DialogContent,
  DialogActions,
  DialogContentText,
  InputAdornment,
  IconButton,
} from '@material-ui/core'

import LoadingScreen from '../../components/RegistroCliente/LoadingScreen'

import UserContext from '../../contexts/user/UserContext'
import StylesContext from '../../contexts/styles/stylesContext'

const Container = styled.div`
  width: 100%;
  min-heigth: 360px;
  margin: 0 auto;

  /* Pantallas grandes (Desktop) */
  @media (min-width: 1024px) {
    max-width: 480px;
  }

  /* Tablets */
  @media (min-width: 768px) and (max-width: 1023px) {
    max-width: 50vw;
  }

  /* Tamaño intermedio entre móvil y tablet */
  @media (min-width: 600px) and (max-width: 767px) {
    max-width: 70vw;
  }

  /* Móviles */
  @media (max-width: 599px) {
    max-width: 100%;
  }
`

const Form = styled.form`
  width: 100%;
  padding: 20px;
  border-radius: 0.6rem;
  background-color: #f5f5f5;

  /* Pantallas grandes (Desktop) */
  @media (min-width: 1024px) {
    border: 2px solid #f2f2f2;
  }

  /* Tablets */
  @media (min-width: 768px) and (max-width: 1023px) {
    border: 2px solid #f2f2f2;
  }

  /* Tamaño intermedio entre móvil y tablet */
  @media (min-width: 600px) and (max-width: 767px) {
    border: 1px solid #e0e0e0;
  }

  /* Móviles */
  @media (max-width: 599px) {
    border: none;
    background-color: #ffffff;
  }
`

const Title = styled.div`
  text-align: center;
  font-size: 32px;
  font-weight: 600;
`

const SubTitle = styled.div`
  text-align: center;
  font-size: 16px;
  font-weight: 400;
`

const TextLink = styled.div`
  display: inline-block;
  font-weight: 600;
  cursor: pointer;
`

const StyledDialogActions = styled(DialogActions)`
  padding-right: 2rem;
`

const Signin = () => {
  const { UserState, doLogin } = useContext(UserContext)
  const history = useHistory()
  const location = useLocation()
  const stylesContext = useContext(StylesContext)

  const [isLoading, setIsLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  // Controlador para manejar la cancelación
  const [controller, setController] = useState(null)

  // Ruta a la que redirigir después de iniciar sesión
  const redirectTo = location.state?.from || '/'

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Correo electrónico inválido')
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          'Introduzca un correo electrónico válido'
        )
        .required('Correo electrónico requerido'),
      password: Yup.string()
        .min(8, 'La contraseña debe tener al menos 8 caracteres.')
        .required('Contraseña requerida'),
    }),
    onSubmit: async values => {
      const abortController = new AbortController() // Crear un nuevo AbortController
      setController(abortController) // Guardar el controlador en el estado

      setIsLoading(true)
      try {
        // Pasar el signal para permitir la cancelación
        await doLogin(values.email, values.password, abortController.signal)
      } catch (error) {
        console.error('Error durante el inicio de sesión:', error)
      } finally {
        setIsLoading(false)
      }
    },
  })

  useEffect(() => {
    if (!UserState.loading) {
      if (UserState.authenticated) {
        // Redirige al usuario a la ruta original o a la página principal
        history.push(redirectTo)
      } else if (!UserState.authenticated && UserState.error) {
        // Mostrar mensaje de error si el inicio de sesión falló
        Swal.fire({
          icon: 'error',
          title: 'Inicio de sesión fallido',
          text:
            UserState.error ||
            'Las credenciales proporcionadas son incorrectas. Por favor, inténtalo de nuevo.',
          confirmButtonText: 'Aceptar',
          allowOutsideClick: false,
        })
      }
    }
  }, [UserState.loading, UserState.authenticated, UserState.error])

  // Limpiar controlador cuando el componente se desmonte
  useEffect(() => {
    return () => {
      if (controller) {
        controller.abort() // Cancelar cualquier solicitud en curso si el componente se desmonta
      }
    }
  }, [controller])

  return (
    <Container>
      <LoadingScreen open={isLoading} />
      <Form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Title>Inicia sesión</Title>
          <SubTitle>
            <span>¿No tienes una cuenta?</span>
          </SubTitle>
          <SubTitle>
            <TextLink
              style={{ color: stylesContext.primaryColor }}
              onClick={() => history.push('/signup')}
            >
              Regístrate
            </TextLink>
            <span>&nbsp;ó&nbsp;</span>
            <TextLink
              style={{ color: stylesContext.primaryColor }}
              onClick={() =>
                Swal.fire({
                  title: 'Recuperación de contraseña',
                  text: 'Se enviará un enlace de recuperación de contraseña al correo electrónico con el que intentas iniciar sesión. Este correo electrónico contiene un enlace con el que podrás restablecer tu contraseña.',
                  icon: 'info',
                  showCancelButton: true,
                  confirmButtonText: 'Aceptar',
                  cancelButtonText: 'Cancelar',
                }).then(result => {
                  if (result.isConfirmed) {
                    history.push(
                      `/request-recovery-password?email=${formik.values.email}`
                    )
                  }
                })
              }
            >
              recuperar contraseña
            </TextLink>
          </SubTitle>
        </DialogContent>

        <DialogContent>
          <DialogContentText>
            <TextField
              label='Correo electrónico*'
              margin='dense'
              id='email'
              name='email'
              type='email'
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.email}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />

            {/* <TextField
              margin='dense'
              id='password'
              name='password'
              label='Contraseña*'
              type='password'
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.password}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            /> */}

            <TextField
              fullWidth
              margin='dense'
              type={showPassword ? 'text' : 'password'}
              id='password'
              name='password'
              label='Contraseña*'
              placeholder='Contraseña'
              onChange={formik.handleChange}
              value={formik.values.password}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={togglePasswordVisibility} edge='end'>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </DialogContentText>
        </DialogContent>

        <StyledDialogActions>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            disabled={isLoading}
          >
            Iniciar sesión
          </Button>
        </StyledDialogActions>
      </Form>
    </Container>
  )
}

export default Signin
