import React from 'react'
import AuthCliente from '../../services/AuthCliente'
import ApiGeneral from '../../services/ApiGeneral'
import {
  Button,
  Tooltip,
  Zoom,
  Typography,
  Grid,
  MenuItem,
  FormHelperText,
} from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Swal from 'sweetalert2'
import errores from '../../services/errores'
import Reaptcha from 'reaptcha'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { useFormik } from 'formik'
import * as yup from 'yup'
import MySnackBar from './../MySnackBar/SnackBar'
import HelpIcon from '@material-ui/icons/Help'
import { useAnalyticsEventTracker } from '../GoogleAnalitycs/useAnalyticsEventTracker'

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    marginTop: '-10px',
  },
}))(Tooltip)
const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(../img/logointegrinox.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  grid: {
    background: '#eeeeee',
    height: '100%',
  },
  paper: {
    margin: '3%',
    marginTop: '14%',
    textAlign: 'center',
    background: '#ffff',
    alignItems: 'center',
  },
  formulario: {
    margin: '6%',
    textAlign: 'center',
  },
  link: {
    marginTop: '5%',
  },
  titulo: {
    color: '#003462!important',
  },
  btncancelar: {
    background: '#eeeeee',
    color: '#003462',
    backgroundColor: '#eeeeee!important',
  },
  btnsubnmit: {
    background: '#003462',
    color: '#ffff!important',
    backgroundColor: '#003462!important',
  },
  DiAction: {
    paddingRight: '2rem',
  },
  error: {
    color: '#ff0500',
  },
  formContro: {
    minWidth: '531px',
  },
}))

const Empresa = props => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [verified, setVerified] = React.useState(false)
  const [emailVerified, setEmailVerified] = React.useState(false)
  const [tipoCfdi, setTipoCfdi] = React.useState([])
  const [Pais, setPais] = React.useState([])
  const [tipoCliente, setTipoCliente] = React.useState([])
  const [estado, setEstado] = React.useState([])
  const [municipio, setMunicipio] = React.useState([])
  const [colonia, setColonia] = React.useState([])
  const [RFCVal, setRFCVal] = React.useState('')
  const [rfcVerified, setRfcVerified] = React.useState(false)
  const [coloniaVerified, setColoniaVerified] = React.useState(false)
  const [CP, setCP] = React.useState('')

  const [emailVal, setEmailVal] = React.useState('')

  const gaEventLogin = useAnalyticsEventTracker('Registro Empresa')
  const [selecteds, setSelecteds] = React.useState({
    Pais: '',
    Estado: '',
    Municipio: '',
    Colonia: '',
  })
  const [usuario, setUser] = React.useState({
    tipoClienteId: null,
    razonSocial: '',
    email: '',
    telefono: '',
    formaPagoId: 4,
    metodoPagoId: 5,
    origenWeb: true,
  })

  const validationSchema = yup.object({
    razonSocial: yup.string().required('El campo razón social es requerido'),
    email: yup
      .string()
      .email('Introduzca un correo electrónico válido')
      .required('El campo correo electrónico es requerido'),
    rfc: yup
      .string()
      .min(12, 'El RFC social debe tener un mínimo de 12 caracteres.')
      .max(13, 'El RFC social debe tener un máximo de 13 caracteres.')
      .required('El campo RFC es requerido.'),
    calle: yup.string().required('El campo calle es requerido.'),
    telefono: yup
      .string()
      .min(10, 'El telefono debe tener un mínimo de 10 caracteres.')
      .max(10, 'El telefono debe tener un máximo de 10 caracteres.')
      .required('El campo teléfono es requerido.'),
    cp: yup
      .string()
      .min(5, 'El C.P. debe tener un mínimo de 5 caracteres.')
      .max(5, 'El C.P. debe tener un máximo de 5 caracteres.')
      .required('El campo C.P. es requerido.'),
    usoCFDIId: yup.string().required('El campo CDFI es requerido.'),
    noExt: yup.string().required('El campo número exterior es requerido.'),

    // Estado:yup
    // .string().required('El campo Pais es requerido.'),
    // Municipio:yup
    // .string().required('El campo Pais es requerido.'),
    Colonia: yup.string().required('El campo colonia es requerido.'),
    tandc: yup
      .boolean()
      .oneOf([true], 'You must accept the terms and conditions'),
  })
  const validate = values => {
    //  validateExistEmail();
    const errors = {}
    var count = CP.length
    if (count < 5) {
      errors.cp = 'El C.P. debe tener un mínimo de 5 caracteres.'
    }
    if (count > 5) {
      errors.cp = 'El C.P. debe tener un máximo de 5 caracteres.'
    }
    if (emailVerified) {
      errors.email = 'El correo ingresado ya existe, favor de ingresar otro'
    }
    if (rfcVerified) {
      errors.rfc = 'El RFC ingresado ya existe, favor de ingresar otro'
    }

    if (selecteds.Pais == '') {
      errors.Pais = 'El campo país es requerido.'
    }
    if (selecteds.Municipio == '') {
      errors.Municipio = 'El campo municipio es requerido.'
    }
    if (selecteds.Estado == '') {
      errors.Estado = 'El campo estado es requerido.'
    }
    if (coloniaVerified) {
      errors.Colonia = 'El campo colonia es requerido.'
    }
    if (emailVal == '') {
      errors.email = 'El campo correo es requerido.'
    }
    if (RFCVal == '') {
      errors.rfc = 'El campo RFC es requerido.'
    }
    // if (!values.rfc) {
    //   errors.rfc = 'El campo RFC es requerido.';
    // } else if (/^[a-zA-Z]{3,4}(\d{6})((\D|\d){2,3})?$/.test(values.rfc)) {
    //   errors.email = 'Invalid email address';
    // }

    return errors
  }

  const formik = useFormik({
    initialValues: {
      tipoClienteId: null,
      razonSocial: usuario.razonSocial,
      email: '',
      telefono: '',
      formaPagoId: null,
      metodoPagoId: null,
      rfc: '',
      usoCFDIId: '',
      calle: '',
      tandc: true,
      Pais: selecteds.Pais,
      Estado: selecteds.Estado,
      Municipio: selecteds.Municipio,
      Colonia: '',
      noInt: '',
      noExt: '',
      cp: '',
    },
    validate,
    validationSchema: validationSchema,
    onSubmit: values => {
      //validateExistEmail()

      var createEmpresa = {
        origenWeb: true,
        tipoClienteId: tipoCliente.find(obj => {
          return obj.descripcionCorta.includes('moral')
        }).idGeneral,
        razonSocial: values.razonSocial,
        email: emailVal,
        telefono: values.telefono,
        activo: true,
        formaPagoId: values.formaPagoId,
        metodoPagoId: values.metodoPagoId,
        datosFacturacion: {
          rfc: RFCVal,
          usoCFDIId: parseInt(values.usoCFDIId),
          calle: values.calle,
          noInt: values.noInt,
          NoExt: values.noExt,
          coloniaId: parseInt(selecteds.Colonia),
        },
      }
      AuthCliente.createUser(createEmpresa)
        .then(r => {
          handleClose()
          Swal.fire(
            'El registro se ha realizado con éxito, verifique su correo.',
            '',
            'success',
          )
        })
        .catch(e => {
          Swal.fire(errores.handleError(e.response.data.message), '', 'error')
        })
    },
  })

  const onVerify = () => {
    setVerified(true)
  }
  const handleClickOpen = () => {
    getTipoUsuario()
    getCfdi()
    getPais()
    gaEventLogin('Empresa')
    setOpen(true)
  }
  const handleClose = () => {
    AsignarDatos(null)
    setSelecteds([])
    setCP('')
    setRFCVal('')
    setEmailVal('')
    setSelecteds({
      Pais: '',
      Estado: '',
      Municipio: '',
      Colonia: '',
    })
    formik.resetForm()
    setOpen(false)
  }

  const AsignarDatos = objeto => {
    setUser({
      ...usuario,
      nombre: objeto === null ? '' : objeto.nombre,
      correo: objeto === null ? '' : objeto.correo,
      telefono: objeto === null ? '' : objeto.telefono,
      rfc: objeto === null ? '' : objeto.rfc,
      cfdi: objeto === null ? '' : objeto.cfdi,
      calle: objeto === null ? '' : objeto.calle,
    })
  }
  const getDataByCP = event => {
    setCP(event.currentTarget.value)
    if (event.currentTarget.value.length === 5)
      blurCP(event.currentTarget.value)

    // validateExistEmail();
    if (event.currentTarget.value !== selecteds.CP) {
      selecteds.CP = event.currentTarget.value
      formik.values.cp = event.currentTarget.value
      ApiGeneral.getCP(event.currentTarget.value)
        .then(response => {
          setPais(response.data.paises)
          setEstado(response.data.estados)
          setMunicipio(response.data.municipios)
          setColonia(response.data.colonias)
          setSelecteds({
            Pais: response.data.idPais,
            Estado: response.data.idEstado,
            Municipio: response.data.idMunicipio,
          })
          // setDatosFacturacion({ ...props.datosFacturacion, 'coloniaId': response.idColonia });
        })
        .catch(error => {})
    }
  }
  const handleEmail = event => {
    formik.values.email = event.currentTarget.value
    setEmailVal(event.currentTarget.value)
    AuthCliente.existsEmail(event.currentTarget.value)
      .then(r => {
        if (r.data.helperData == null) {
          setEmailVerified(false)
        } else {
          setEmailVerified(true)
        }
        validate()
        console.info(
          'El correo ingresado ya existe, favor de ingresar otro.',
          r,
        )
      })
      .catch(e => {
        setEmailVerified(false)
        validate()

        console.info('error catch correoo', e)
      })
  }
  const handleRFC = event => {
    formik.values.rfc = event.currentTarget.value
    setRFCVal(event.currentTarget.value)
    AuthCliente.existsRFC(event.currentTarget.value)
      .then(r => {
        if (r.data.helperData == null) {
          setRfcVerified(false)
          formik.errors.rfc = ''
        } else {
          setRfcVerified(true)
          formik.errors.rfc =
            'El RFC ingresado ya existe, favor de ingresar otro.'
        }
        // validate();
        console.info('El RFC ingresado ya existe, favor de ingresar otro.', r)
      })
      .catch(e => {
        setRfcVerified(false)
        validate()

        console.info('error catch correoo', e)
      })
  }
  const changeCP = event => {
    setCP(event.currentTarget.value)
  }

  const getCfdi = () => {
    ApiGeneral.getCFDI(3)
      .then(response => {
        setTipoCfdi(response)

        console.info('éxito data cfdi:' + response)
      })
      .catch(error => {
        console.error('error data:' + error)
      })
  }

  const getTipoUsuario = () => {
    ApiGeneral.getCFDI(4) //tipoUsuario
      .then(response => {
        setTipoCliente(response)

        console.info('éxito data tipousuario:' + response)
      })
      .catch(error => {
        console.error('error data:' + error)
      })
  }

  const getPais = () => {
    ApiGeneral.getPais()
      .then(response => {
        console.info('data pais:' + response.data.paises)
        setPais(response.data.paises)
      })
      .catch(error => {
        console.error('error data pais:' + error)
      })
  }

  const handlePais = event => {
    selecteds.Pais = event.currentTarget.value
    setSelecteds({ ...selecteds, Pais: event.target.value })
    ApiGeneral.getEstadoPais(selecteds.Pais)
      .then(response => {
        setEstado(response.data.estados)
      })
      .catch(error => {
        console.error('error data estado:' + error)
      })
  }
  const handleEstado = event => {
    selecteds.Estado = event.currentTarget.value
    setSelecteds({ ...selecteds, Estado: event.target.value })
    ApiGeneral.getMunicipoEstado(event.currentTarget.value)
      .then(response => {
        setMunicipio(response.data.municipios)
      })
      .catch(error => {
        console.error('error data  municipio:' + error)
      })
  }
  const handleMunicipio = event => {
    selecteds.Municipio = event.currentTarget.value
    setSelecteds({ ...selecteds, Municipio: event.target.value })
    ApiGeneral.getColoniasByMunicipios(selecteds.Municipio)
      .then(response => {
        setColonia(response.data.colonias)
      })
      .catch(error => {
        //console.log("error data colonia"+ error);
      })
  }
  const handleColonia = event => {
    if (!event.target.value) {
      setColoniaVerified(true)
      formik.errors.Colonia = 'El campo colonia es requerido'
      formik.touched.Colonia = 'El campo colonia es requerido'
      return
    }
    setColoniaVerified(false)
    formik.errors.Colonia = ''
    formik.touched.Colonia = ''

    selecteds.Colonia = event.target.value
    setSelecteds({ ...selecteds, Colonia: event.target.value })
    ApiGeneral.getColonias(event.target.value)
      .then(response => {
        setCP(response.data.cp)
        selecteds.CP = response.data.cp
      })
      .catch(error => {
        console.error('error data colonias')
      })
  }
  const [hasFletes, setHasFletes] = React.useState('')
  const blurCP = cp => {
    AuthCliente.validCP(cp)
      .then(r => {
        if (!r.data) {
          setSBOpen(true)
          setMessage('Actualmente no contamos con servicio de envío a tu zona.')
          setType('warning')
        }
      })
      .catch(e => {
        console.log(e)
      })
  }
  const [SBopen, setSBOpen] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const [type, setType] = React.useState('')
  const handleCloseSB = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSBOpen(false)
  }
  const KeyPress = event => {
    const keyCode = event.keyCode || event.which
    const keyValue = String.fromCharCode(keyCode)
    if (!/^\d*$/.test(keyValue)) event.preventDefault()
  }
  return (
    <>
      <Button variant='contained' color='primary' onClick={handleClickOpen}>
        Registrar Empresa
      </Button>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        scroll='body'
        aria-labelledby='draggable-dialog-title'
      >
        <DialogTitle
          className={classes.titulo}
          style={{ cursor: 'move' }}
          id='draggable-dialog-title'
        >
          <li
            className='close-icon'
            style={{ marginBottom: '20px', textAlign: 'right' }}
            onClick={handleClose}
          >
            ✕
          </li>
          Registrar Empresa
        </DialogTitle>
        <form onSubmit={formik.handleSubmit} autoComplete='off'>
          <DialogContent>
            <DialogContentText>
              <TextField
                label='Razón Social*'
                margin='dense'
                id='razonSocial'
                name='razonSocial'
                placeholder='Razón Social'
                type='text'
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.razonSocial}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.razonSocial &&
                  Boolean(formik.errors.razonSocial)
                }
                helperText={
                  formik.touched.razonSocial && formik.errors.razonSocial
                }
              />
              <TextField
                margin='dense'
                id='rfc'
                name='rfc'
                label='RFC*'
                placeholder='RFC'
                type='text'
                fullWidth
                onChange={handleRFC}
                value={RFCVal}
                onBlur={handleRFC && formik.handleBlur}
                error={formik.touched.rfc && Boolean(formik.errors.rfc)}
                helperText={formik.touched.rfc && formik.errors.rfc}
              />

              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor='usoCFDIId'> CFDI*</InputLabel>
                <Select
                  native
                  label='CFDI'
                  name='usoCFDIId'
                  inputProps={{
                    name: 'usoCFDIId',
                    id: 'usoCFDIId',
                  }}
                  onChange={formik.handleChange}
                  value={formik.values.usoCFDIId}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.usoCFDIId && Boolean(formik.errors.usoCFDIId)
                  }
                  helperText={
                    formik.touched.usoCFDIId && formik.errors.usoCFDIId
                  }
                >
                  <option aria-label='None' value='' />
                  {tipoCfdi.map((object, index) =>
                    object.activo ? (
                      <option value={object.idGeneral}>
                        {object.descripcionLarga}
                      </option>
                    ) : (
                      <div></div>
                    ),
                  )}
                </Select>
                <FormHelperText style={{ color: 'red' }}>
                  {formik.touched.usoCFDIId && formik.errors.usoCFDIId}
                </FormHelperText>
              </FormControl>

              <div>
                <Grid container spacing={1} alignItems='center'>
                  <Grid item xs={11}>
                    <TextField
                      margin='dense'
                      id='telefono'
                      name='telefono'
                      label='Teléfono*'
                      placeholder='Teléfono'
                      type='text'
                      fullWidth
                      onKeyPress={KeyPress}
                      onChange={formik.handleChange}
                      value={formik.values.telefono}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.telefono &&
                        Boolean(formik.errors.telefono)
                      }
                      helperText={
                        formik.touched.telefono && formik.errors.telefono
                      }
                    />
                  </Grid>
                  <Grid item>
                    <HtmlTooltip
                      placement='bottom'
                      interactive
                      TransitionComponent={Zoom}
                      leaveDelay={100}
                      title={
                        <React.Fragment>
                          <Typography style={{ display: 'flex' }}>
                            <Typography
                              variant='body2'
                              gutterBottom
                              align='center'
                            >
                              {`Se estarán enviando actualizaciones de  estatus de pedidos a través de Whatsapp.`}
                            </Typography>
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <HelpIcon fontSize={'small'} />
                    </HtmlTooltip>
                  </Grid>
                </Grid>
              </div>

              <TextField
                margin='dense'
                id='email'
                name='email'
                label='Correo*'
                placeholder='Correo'
                type='email'
                fullWidth
                onChange={handleEmail}
                value={emailVal}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                style={{ marginTop: '10px' }}
                label='C.P.*'
                fullWidth
                name='cp'
                id='cp'
                type='text'
                onKeyPress={KeyPress}
                value={CP}
                onChange={getDataByCP}
                onBlur={formik.handleBlur}
                error={formik.touched.cp && Boolean(formik.errors.cp)}
                helperText={formik.touched.cp && formik.errors.cp}
              />
              <FormControl
                style={{ marginTop: '10px' }}
                fullWidth
                className={classes.formControl}
              >
                <InputLabel htmlFor='Pais'>País*</InputLabel>
                <Select
                  native
                  label='Pais'
                  inputProps={{
                    name: 'Pais',
                    id: 'Pais',
                  }}
                  onChange={handlePais}
                  value={selecteds.Pais}
                  onBlur={formik.handleBlur}
                  error={formik.touched.Pais && Boolean(formik.errors.Pais)}
                  helperText={formik.touched.Pais && formik.errors.Pais}
                >
                  <option aria-label='None' value='' />
                  {Pais.map((object, index) => (
                    <option value={object.idPais}>
                      {object.nombre} - {object.clavePais}
                    </option>
                  ))}
                </Select>
                <FormHelperText style={{ color: 'red' }}>
                  {formik.touched.Pais && formik.errors.Pais}
                </FormHelperText>
              </FormControl>
              <FormControl
                style={{ marginTop: '10px' }}
                fullWidth
                className={classes.formControl}
              >
                <InputLabel htmlFor='Estado'>Estado*</InputLabel>
                <Select
                  native
                  label='Estado'
                  inputProps={{
                    name: 'Estado',
                    id: 'Estado',
                  }}
                  value={selecteds.Estado}
                  onChange={handleEstado}
                  onBlur={formik.handleBlur}
                  error={formik.touched.Estado && Boolean(formik.errors.Estado)}
                  helperText={formik.touched.Estado && formik.errors.Estado}
                >
                  <option aria-label='None' value='' />
                  {estado.map((object, index) => (
                    <option value={object.idEstado}>
                      {object.nombre} - {object.claveEstado}
                    </option>
                  ))}
                </Select>
                <FormHelperText style={{ color: 'red' }}>
                  {formik.touched.Estado && formik.errors.Estado}
                </FormHelperText>
              </FormControl>

              <FormControl
                fullWidth
                style={{ marginTop: '10px' }}
                className={classes.formControl}
              >
                <InputLabel htmlFor='Municpio'>Municipio*</InputLabel>
                <Select
                  native
                  label=''
                  inputProps={{
                    name: 'Municpio',
                    id: 'Municipio',
                  }}
                  value={selecteds.Municipio}
                  onChange={handleMunicipio}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.Municipio && Boolean(formik.errors.Municipio)
                  }
                  helperText={
                    formik.touched.Municipio && formik.errors.Municipio
                  }
                >
                  <option aria-label='None' value='' />
                  {municipio.map((object, index) => (
                    <option value={object.idMunicipio}>{object.nombre}</option>
                  ))}
                </Select>
                <FormHelperText style={{ color: 'red' }}>
                  {formik.touched.Municipio && formik.errors.Municipio}
                </FormHelperText>
              </FormControl>

              <FormControl
                fullWidth
                style={{ marginTop: '10px' }}
                className={classes.formControl}
              >
                <InputLabel htmlFor='Colonia'>Colonia*</InputLabel>
                <Select
                  native
                  label=''
                  inputProps={{
                    name: 'Colonia',
                    id: 'Colonia',
                  }}
                  value={selecteds.Colonia}
                  onChange={evt => {
                    handleColonia(evt)
                    formik.handleChange(evt)
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.Colonia && Boolean(formik.errors.Colonia)
                  }
                  helperText={formik.touched.Colonia && formik.errors.Colonia}
                >
                  <option aria-label='None' value='' />
                  {colonia.map((object, index) => (
                    <option value={object.idColonia}>
                      {object.nombre} - {object.cp}
                    </option>
                  ))}
                </Select>
                <FormHelperText style={{ color: 'red' }}>
                  {formik.touched.Colonia && formik.errors.Colonia}
                </FormHelperText>
              </FormControl>

              <TextField
                margin='dense'
                id='calle'
                name='calle'
                label='Calle*'
                placeholder='Calle'
                type='text'
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.calle}
                onBlur={formik.handleBlur}
                error={formik.touched.calle && Boolean(formik.errors.calle)}
                helperText={formik.touched.calle && formik.errors.calle}
              />
              <TextField
                margin='dense'
                id='noExt'
                name='noExt'
                label='No. Ext*'
                placeholder='No. Ext'
                type='text'
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.noExt}
                onBlur={formik.handleBlur}
                error={formik.touched.noExt && Boolean(formik.errors.noExt)}
                helperText={formik.touched.noExt && formik.errors.noExt}
              />
              <TextField
                margin='dense'
                id='noInt'
                name='noInt'
                label='No. Int'
                placeholder='No. Int'
                type='text'
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.noInt}
                onBlur={formik.handleBlur}
                error={formik.touched.noInt && Boolean(formik.errors.noInt)}
                helperText={formik.touched.noInt && formik.errors.noInt}
              />

              <label>
                <input
                  type='checkbox'
                  id='tandc'
                  name='tandc'
                  checked={true}
                  value={formik.values.tandc}
                  error={formik.touched.tandc && Boolean(formik.errors.tandc)}
                  helperText={formik.touched.tandc && formik.errors.tandc}
                />{' '}
                Aceptas términos y condiciones
              </label>
            </DialogContentText>
            <Reaptcha
              sitekey={
                process.env.NODE_ENV === 'production'
                  ? '6LcYOdIaAAAAAG09grfCXalmQny9-od32Bs1Opd-'
                  : process.env.REACT_APP_BASE_URL === 'prd'
                    ? '6LfXVq8cAAAAANirUaFiAIfwU43isKIro4cTEZH8'
                    : '6LcPtoMaAAAAAGsptRHqVLqSbVFucHWBBd9oOMeE'
              }
              onVerify={onVerify}
            />
          </DialogContent>
          <DialogActions className={classes.DiAction}>
            <Button
              onClick={handleClose}
              variant='outlined'
              className={classes.btncancelar}
            >
              Cancelar
            </Button>
            <Button
              type='submit'
              variant='outlined'
              className={classes.btnsubnmit}
              value='Registrar'
              color='primary'
            >
              Guardar
            </Button>
          </DialogActions>
        </form>
        <MySnackBar
          Open={SBopen}
          Type={type}
          Message={message}
          HandleClose={handleCloseSB}
        />
      </Dialog>
    </>
  )
}
export default Empresa
