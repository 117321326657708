import { SET_PRIMARY_COLOR, SET_BLACK_COLOR, SET_LOGO } from './stylesTypes'

export const setPrimaryColor = (dispatch, color) => {
  dispatch({ type: SET_PRIMARY_COLOR, payload: color })
}

export const setBlackColor = (dispatch, color) => {
  dispatch({ type: SET_BLACK_COLOR, payload: color })
}

export const setLogo = (dispatch, logoUrl) => {
  dispatch({ type: SET_LOGO, payload: logoUrl })
}
