import {
  // ButtonGroup,
  // Box,
  Button,
  Dialog,
  DialogActions,
  Tooltip,
  // DialogContentText,
  // Typography,
  DialogContent,
  InputLabel,
  // MenuItem,
  Select,
  FormControl,
  DialogTitle,
  // FormControlLabel,
  // GridList,
  // GridListTile,
  // IconButton,
  // Switch,
  // CardMedia,
  // CardActionArea,
  // TextField,
  // Grid,
} from '@material-ui/core'

import { makeStyles, withStyles } from '@material-ui/core/styles'

// import HelpIcon from '@material-ui/icons/Help'
import { useFormik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
// import Backdrop from '@material-ui/core/Backdrop'
import LoadingScreen from '../../RegistroCliente/LoadingScreen'
import { useHistory } from 'react-router'
import UserContext from '../../../contexts/user/UserContext'
// import Reaptcha from 'reaptcha'
import Swal from 'sweetalert2'
// import * as yup from 'yup'
import ApiGeneral from '../../../services/ApiGeneral'
import AuthCliente from '../../../services/AuthCliente'
import errores from '../../../services/errores'
// import MySnackBar from './../../MySnackBar/SnackBar'
// import CircularProgress from '@material-ui/core/CircularProgress'
import pedidoActions from '../../../actions/pedidoActions'

import {
  // getMoneda,
  // postEstatus,
  Devolver,
} from '../../Result/Mask/catalogorequest'

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    marginTop: '-10px',
  },
}))(Tooltip)
const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: 9999,
    color: '#fff',
  },
  circle: {
    marginLeft: '270px',
    marginTop: '250px',
    position: 'fixed',
  },
  letra: {
    marginLeft: '-15px',
  },
  btncancelar: {
    background: '#eeeeee',
    color: '#003462',
    backgroundColor: '#eeeeee!important',
  },
  btnsubnmit: {
    background: '#003462',
    color: '#ffffff!important',
    backgroundColor: '#003462!important',
  },
  DiAction: {
    paddingRight: '2rem',
  },
  error: {
    color: '#ff0500',
  },
  margin: {
    margin: theme.spacing(1),
  },
}))

const Cliente = props => {
  const { UserState } = useContext(UserContext)

  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [verified, setVerified] = React.useState(false)
  const [emailVerified, setEmailVerified] = React.useState(false)
  const [tipoCliente, setTipoCliente] = React.useState([])
  const [bar, setBar] = React.useState(false)

  const [usuario, setUser] = React.useState({
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    descripcion: '',
    email: '',
    activo: true,
  })

  const history = useHistory()

  const formik = useFormik({
    initialValues: {
      descripcion: '',
      email: '',
      activo: true,
    },

    onSubmit: values => {
      setBar(true)

      AuthCliente.createProductoMedida(values)
        .then(r => {
          handleClose()
          Swal.fire('El registro se ha realizado con éxito', '', 'success')
        })
        .catch(e => {
          console.log(e)

          // handleClose();

          Swal.fire(errores.handleError(e.response.data.message), '', 'error')
        })
    },
  })
  useEffect(() => {
    getDireccionPrincipal()
    getMisDatos()
  }, [])

  const [currentDireccion, setCurrentDireccion] = React.useState(null)
  const [misDatos, getClienteDatos] = useState([])
  const [misDatosId, getClienteDatosId] = useState('')
  const [misDatosEmail, getClienteDatosEmail] = useState('')

  const getDireccionPrincipal = async () => {
    const direccion = await pedidoActions.getDireccionPrincial()

    setCurrentDireccion(direccion)
  }
  const onVerify = () => {
    setVerified(true)
  }
  const getMisDatos = () => {
    getClienteDatosId(UserState.idCliente)
    getClienteDatosEmail(UserState.email)
    ApiGeneral.getMisDatos(UserState.idCliente)
      .then(response => {
        getClienteDatos(response.data)
        console.log(response.data)
      })
      .catch(e => {
        console.error('error data:' + e)
      })
  }

  const [cotizacion, setCotizacion] = React.useState([])
  const [almacen, setAlmacen] = React.useState({
    id: '',
    pedidoId: '',
    correo: '',
    monto: '',
    tipoDevolucion: false,
    idCliente: '',
    idDevolucionEnvio: '',

    envio: { address_from: {}, carriers: [] },
  })
  const [address_to, setAddress_to] = React.useState({
    province: '',
    city: '',
    name: '',
    country: '',
    zip: false,
    address1: '',
    address2: '',
    company: '',
    phone: '',
    email: '',
  })
  const envioPost = async pedido => {
    setIsLoading(true)

    almacen.id = '00000000-0000-0000-0000-000000000000'
    almacen.pedidoId = pedido.pedido
    almacen.monto = 0
    almacen.idCliente = misDatosId
    almacen.correo = misDatosEmail
    almacen.tipoDevolucion = tipoDevolucion

    almacen.idDevolucionEnvio = pedido.idEnvio
    almacen.envio.address_from.province = 'Nuevo Leon'
    almacen.envio.address_from.city = currentDireccion.municipio
    almacen.envio.address_from.name = misDatos.nombre
    almacen.envio.address_from.zip = currentDireccion.cp
    almacen.envio.address_from.country = 'MX'
    almacen.envio.address_from.address1 =
      currentDireccion.colonia + ' ' + currentDireccion.noExt
    almacen.envio.address_from.company = 'Cliente'
    almacen.envio.address_from.address2 =
      currentDireccion.colonia + ' ' + currentDireccion.noExt
    almacen.envio.address_from.phone = misDatos.telefono
    almacen.envio.address_from.email = misDatos.email
    let carriers = []
    let name = {
      name: 'DHL',
    }
    let name2 = {
      name: 'Fedex',
    }
    carriers.push(name, name2)
    almacen.envio.carriers = carriers

    setAlmacen(almacen)
    console.log(almacen)
  }
  const postDevolver = async () => {
    envioPost(props.pedido)
    const moned = await Devolver(almacen)
    setIsLoading(false)
    props.onClose()
  }
  const handleClose = () => {
    AsignarDatos(null)
    setOpen(false)
    setIsLoading(false)
  }
  const [isLoading, setIsLoading] = useState(false)

  const BarS = () => {
    console.log('soy')
    setIsLoading(true)
  }

  const AsignarDatos = objeto => {
    setUser({
      ...usuario,
      id: objeto === null ? '' : objeto.id,
      descripcion: objeto === null ? '' : objeto.descripcion,
      email: objeto === null ? '' : objeto.email,
      activo: objeto === null ? '' : objeto.activo,
    })
  }

  const [SBopen, setSBOpen] = React.useState(false)
  const [tipoDevolucion, settipoDevolucion] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const [type, setType] = React.useState('')
  const handleCloseSB = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSBOpen(false)
  }
  const handleChange = event => {
    settipoDevolucion(event.target.value)
  }
  return (
    <>
      <Dialog
        fullWidth
        open={props.open}
        onClose={props.onClose}
        aria-labelledby='draggable-dialog-title'
      >
        <div>
          <LoadingScreen open={isLoading} />
          <DialogTitle style={{ cursor: 'move' }} id='draggable-dialog-title'>
            <li
              className='close-icon'
              style={{ marginBottom: '20px', textAlign: 'right' }}
              onClick={props.onClose}
            >
              ✕
            </li>
            Solicitar devolucion
          </DialogTitle>

          <form autoComplete='off'>
            <DialogContent>
              <FormControl
                required
                fullWidth
                variant='outlined'
                className={classes.formControl}
              >
                <InputLabel
                  style={{ width: '60%', marginLeft: '20%' }}
                  htmlFor='cmbcoleccion'
                >
                  Selecciona{' '}
                </InputLabel>
                <Select
                  native
                  value={tipoDevolucion}
                  defaultValue={'tipoDevolucion'}
                  onChange={handleChange}
                  style={{ width: '60%', marginLeft: '20%' }}
                  label='Selecciona '
                  inputProps={{
                    name: 'tipoDevolucion',
                    id: 'cmbcoleccion',
                  }}
                >
                  <option aria-label='None' value='' />
                  <option title={'Tienda'} value={false}>
                    Devolucion en tienda
                  </option>
                  <option title={'Paqueteria'} value={true}>
                    Devolucion por paqueteria
                  </option>
                </Select>
              </FormControl>
              {/*qa*/
              /*6LcPtoMaAAAAAGsptRHqVLqSbVFucHWBBd9oOMeE*/
              /*pp*/
              /*6LcYOdIaAAAAAG09grfCXalmQny9-od32Bs1Opd-*/}
            </DialogContent>
            <DialogActions className={classes.DiAction}>
              <Button
                autoFocus
                onClick={props.onClose}
                variant='outlined'
                className={classes.btncancelar}
              >
                Cancelar
              </Button>
              <Button
                variant='contained'
                className={classes.btnsubnmit}
                color='primary'
                onClick={postDevolver}
              >
                Guardar
              </Button>
            </DialogActions>
          </form>
        </div>
      </Dialog>
    </>
  )
}

export default Cliente
