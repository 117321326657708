import React, { useReducer, useEffect } from 'react'

import { COLORS } from '../../Views/Colores'
import StylesContext from './stylesContext'
import stylesReducer from './stylesReducer'
import { setPrimaryColor, setBlackColor, setLogo } from './stylesActions'

import { getColor, getLogo } from '../../actions/catalogoActions'

const initialState = {
  primaryColor: '',
  black: '',
  logo: '',
  backgroundImage: '/path/to/background.jpg',
}

const StylesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(stylesReducer, initialState)

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const signal = controller.signal

    getColor(signal)
      .then(data => {
        if (isMounted) {
          setPrimaryColor(dispatch, data.nombre)
          setBlackColor(dispatch, data.nombre)

          document.documentElement.style.setProperty(
            '--color-principal',
            data.nombre
          )

          // FIX: eliminar referencia
          COLORS.black = data.nombre
        }
      })
      .catch(e => {
        if (isMounted) {
          console.log('Error fetching colors:', e)
        }
      })

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [dispatch])

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const signal = controller.signal

    getLogo(signal)
      .then(data => {
        if (isMounted) {
          setLogo(dispatch, data.url)
        }
      })
      .catch(e => {
        if (isMounted) {
          console.error('Error fetching logo:', e)
        }
      })

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [dispatch])

  return (
    <StylesContext.Provider
      value={{
        ...state,
      }}
    >
      {children}
    </StylesContext.Provider>
  )
}

export default StylesProvider
