import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CreateIcon from '@material-ui/icons/Create'
import StarIcon from '@material-ui/icons/Star'
import { Grid } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import ApiGeneral from '../../services/ApiGeneral'

import EditarDireccion from './EditarDireccion'

const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'relative',
    marginTop: 10,
  },
  principal: {
    width: '100%',
    textAlign: 'end',
    padding: 10,
    position: 'absolute',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  star: {
    fill: 'gold !important',
  },
})

const DireccionCard = ({
  direccion,
  currentDireccion,
  setCurrentDireccion,
  getDirecciones,
  editable = true,
  setType,
  setMessage,
  setSBOpen,
  fromOrden = false,
  getDireccionPrincipal,
  handleSelectDireccion,
}) => {
  const classes = useStyles()
  const [abrir, setOpen] = React.useState(false)

  const {
    alias,
    idClienteDireccion,
    cp,
    calle,
    colonia,
    municipio,
    noExt,
    noInt,
    referencia,
    principal,
    open = true,
  } = direccion

  const handleCloseDirecciones = () => {
    if (fromOrden) {
      ApiGeneral.getDirecciones(direccion.clienteId)
        .then(response => {
          let _direcciones = response.data.helperData
          let _direccion = _direcciones.filter(
            k => k.idClienteDireccion == direccion.idClienteDireccion
          )
          setCurrentDireccion(_direccion[0])
        })
        .catch(e => {
          console.error('error data:' + e)
        })
    }

    setOpen(false)
  }

  return (
    <Card
      style={{
        cursor:
          currentDireccion?.idClienteDireccion !== direccion?.idClienteDireccion
            ? 'pointer'
            : 'default',
        backgroundColor:
          idClienteDireccion === currentDireccion?.idClienteDireccion
            ? '#e2e2e2'
            : 'white',
      }}
      className={classes.root}
      variant='outlined'
      onClick={() => {
        if (
          currentDireccion?.idClienteDireccion !== direccion?.idClienteDireccion
        ) {
          setCurrentDireccion(direccion)
          handleSelectDireccion()
        }
      }}
    >
      {principal && (
        <div className={classes.principal}>
          <StarIcon className={classes.star} />
        </div>
      )}
      <CardContent style={{ paddingBottom: 0 }}>
        <Typography variant='body' component='p'>
          {`${calle} ${noExt ? `#${noExt}` : ''}${
            noInt ? ` Int: ${noInt}` : ''
          }`}
        </Typography>
        <Typography variant='body' component='p'>
          {colonia}
        </Typography>
        <Typography variant='body' component='p'>
          {municipio}
        </Typography>
        <Typography variant='body' component='p'>
          {cp}
        </Typography>
      </CardContent>
      <CardActions
        style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 0 }}
      >
        <div>
          <Button
            onClick={() => {
              setOpen(true)
            }}
            size='small'
            style={{ textTransform: 'capitalize' }}
            endIcon={<CreateIcon></CreateIcon>}
          >
            Editar
          </Button>
          <Dialog
            onClose={handleCloseDirecciones}
            aria-labelledby='simple-dialog-title'
            open={abrir}
          >
            <DialogTitle style={{ cursor: 'move' }} id='draggable-dialog-title'>
              <li
                className='close-icon'
                style={{ marginBottom: '20px', textAlign: 'right' }}
                onClick={handleCloseDirecciones}
              >
                ✕
              </li>
              Editar dirección
            </DialogTitle>
            <DialogContent dividers style={{ padding: 8 }}>
              <Grid
                container
                direction='column'
                justify='center'
                alignItems='center'
              >
                <Box display='flex' p={1}>
                  <EditarDireccion
                    direccion={direccion}
                    getDirecciones={getDirecciones}
                    handleCloseDirecciones={handleCloseDirecciones}
                    setSBOpen={setSBOpen}
                    setMessage={setMessage}
                    setType={setType}
                  />
                </Box>
              </Grid>
            </DialogContent>
          </Dialog>
        </div>
      </CardActions>
    </Card>
  )
}

export default DireccionCard
