// import { getColor } from '../actions/resultActions'
// import React, { useContext, useEffect, useState, Suspense } from 'react'

// export const getColors = async () => {
// 	const response = await getColor()

// 	document.documentElement.style.setProperty(
// 		'--color-principal',
// 		response.data.nombre
// 	)
// 	const nombre = response.data.nombre
// 	return nombre
// }

export const COLORS = {
  black: '',
}

export const COLORS2 = {
  black: '#54A59E',
}
