import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import {
  GridList,
  GridListTile,
  Select,
  InputLabel,
  FormControl,
  InputAdornment,
  Tooltip,
  IconButton,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import { Button } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Colaboradores from './Colaboradores'
import DesactivarColaborador from './DesactivarColaborador'

import AgregarColaborador from './AgregarColaborador'

import { useFormik } from 'formik'

//MIS DATOS
const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'relative',
    marginTop: 10,
  },
  principal: {
    width: '100%',
    textAlign: 'end',
    padding: 10,
    position: 'absolute',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  star: {
    fill: 'gold !important',
  },
})

const ColaboradoresList = ({
  misDatos,
  getMisDatos,
  c,
  setType,
  setMessage,
  setSBOpen,
}) => {
  const classes = useStyles()

  const {
    idCliente,
    tipoClienteId,
    clienteColaboradores,
    nombre,
    razonSocial,
    nombreRazonSocial,
    aPaterno,
    aMaterno,
    email,
    telefono,
    celular,
    datosFacturacion,
  } = misDatos

  const [open, setOpen] = React.useState(false)
  const [openAdd, setOpenAdd] = React.useState(false)
  const [openActivo, setOpenActivo] = React.useState(false)

  const [active, setActive] = React.useState(c.activo)
  const formik = useFormik({
    initialValues: {
      activo: c.activo,
    },

    onSubmit: values => {
      var editDir = {
        activo: values.activo,
      }
    },
  })
  //b3b3f7d5-dab8-42c7-b5c8-759d63ef01bd
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleCloseDirecciones = () => {
    setOpen(false)
  }
  const handleCloseActivo = () => {
    getMisDatos()
    setActive(!c.activo)

    setOpenActivo(false)
  }
  const handleCloseActiveCancel = () => {
    formik.values.activo = c.activo
    setActive(c.activo)
    getMisDatos()
    setOpenActivo(false)
  }
  const handleChangeActive = event => {
    formik.values.activo = event.target.checked
    setActive(event.target.checked)
    setOpenActivo(true)
  }
  return (
    <Card
      className={classes.root}
      variant='outlined'
      onClick={() => {
        // if (!!setCurrentDireccion) setCurrentDireccion(direccion);
      }}
    >
      <CardContent style={{ paddingBottom: 0 }}>
        <Card
          className={classes.root}
          variant='outlined'
          onClick={() => {
            // if (!!setCurrentDireccion) setCurrentDireccion(direccion);
          }}
        >
          <CardContent style={{ padding: '10px' }}>
            <div>
              <Typography variant='body' component='p'>
                {c.nombre} {c.aPaterno} {c.aMaterno}
              </Typography>
              <Typography variant='body' component='p'>
                {c.email}
              </Typography>
              <Typography variant='body' component='p'>
                {c.telefono}
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    color='primary'
                    checked={active}
                    onChange={handleChangeActive}
                    onBlur={formik.handleBlur}
                    name='activo'
                  />
                }
                label='Activo'
                labelPlacement='top'
              />
              <Dialog
                onClose={handleCloseActivo}
                aria-labelledby='simple-dialog-title'
                open={openActivo}
              >
                <DialogTitle
                  style={{ cursor: 'move' }}
                  id='draggable-dialog-title'
                >
                  <li
                    className='close-icon'
                    style={{ textAlign: 'right' }}
                    onClick={handleCloseActiveCancel}
                  >
                    ✕
                  </li>
                  {active ? 'Activar Cliente' : 'Desactivar Cliente'}
                </DialogTitle>
                <DialogContent dividers style={{ padding: 8 }}>
                  <Grid
                    container
                    direction='column'
                    justify='center'
                    alignItems='center'
                  >
                    <Box display='flex' p={1}>
                      <DesactivarColaborador
                        handleCloseActiveCancel={handleCloseActiveCancel}
                        getMisDatos={getMisDatos}
                        activoC={active}
                        colaborador={c}
                        handleCloseActivo={handleCloseActivo}
                      />
                    </Box>
                  </Grid>
                </DialogContent>
              </Dialog>
              <Button
                onClick={() => {
                  setOpen(true)
                }}
                variant='outlined'
                color='primary'
              >
                Editar
              </Button>
              <Dialog
                onClose={handleCloseDirecciones}
                aria-labelledby='simple-dialog-title'
                open={open}
              >
                <DialogTitle
                  style={{ cursor: 'move' }}
                  id='draggable-dialog-title'
                >
                  <li
                    className='close-icon'
                    style={{ textAlign: 'right' }}
                    onClick={handleCloseDirecciones}
                  >
                    ✕
                  </li>
                  Editar Datos
                </DialogTitle>
                <DialogContent dividers style={{ padding: 8 }}>
                  <Grid
                    container
                    direction='column'
                    justify='center'
                    alignItems='center'
                  >
                    <Box display='flex' p={1}>
                      <Colaboradores
                        key={c.clienteId}
                        getMisDatos={getMisDatos}
                        colaborador={c}
                        setActive={setActive}
                        handleCloseDirecciones={handleCloseDirecciones}
                        setSBOpen={setSBOpen}
                        setMessage={setMessage}
                        setType={setType}
                      />
                    </Box>
                  </Grid>
                </DialogContent>
              </Dialog>
            </div>
          </CardContent>
        </Card>
      </CardContent>
      <CardActions
        style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 0 }}
      ></CardActions>
    </Card>
  )
}

export default ColaboradoresList
