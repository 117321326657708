import React, { useContext, useState, useEffect } from 'react'
import { Button } from '@material-ui/core'
import ApiGeneral from '../../services/ApiGeneral'
import UserContext from '../../contexts/user/UserContext'
import MisDatosCard from './DatosCard'
import EditarMisDatos from './EditarMisDatos'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import MySnackBar from './../MySnackBar/SnackBar'
import PixelR from '../GoogleAnalitycs/PixelR'

const MisDatosPersonales = () => {
  const { UserState } = useContext(UserContext)

  const [open, setOpen] = useState(false)
  const [misDatos, getClienteDatos] = useState([])
  const [SBopen, setSBOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [type, setType] = useState('')

  const getMisDatos = () => {
    
    ApiGeneral.getMisDatos(UserState.idCliente)
      .then(response => {
        getClienteDatos(response.data)
        console.log(response.data)
      })
      .catch(e => {
        console.error('error data:' + e)
      })
  }

  const handleCloseDirecciones = () => {
    setOpen(false)
  }

  const handleCloseSB = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSBOpen(false)
  }

  useEffect(() => {
    getMisDatos()
  }, [])

  return (
    <div style={{ width: '100%' }}>
      <PixelR nombres={'Mis datos'} />

      <MySnackBar
        Open={SBopen}
        Type={type}
        Message={message}
        HandleClose={handleCloseSB}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 5,
        }}
      >
        <label>Mis datos</label>

        <>
          <div>
            <Button
              onClick={() => {
                setOpen(true)
              }}
              variant='outlined'
              color='primary'
            >
              Editar Datos
            </Button>
            <Dialog
              fullWidth
              onClose={handleCloseDirecciones}
              aria-labelledby='simple-dialog-title'
              open={open}
            >
              <DialogTitle id='draggable-dialog-title'>
                <div
                  style={{
                    marginTop: '1rem',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <span style={{ display: 'inline-block' }}>Editar Datos</span>
                  <span
                    style={{ display: 'inline-block', cursor: 'pointer' }}
                    onClick={handleCloseDirecciones}
                  >
                    ✕
                  </span>
                </div>
              </DialogTitle>
              <DialogContent dividers>
                <EditarMisDatos
                  setSBOpen={setSBOpen}
                  setMessage={setMessage}
                  setType={setType}
                  misDatos={misDatos}
                  getMisDatos={getMisDatos}
                  handleCloseDirecciones={handleCloseDirecciones}
                />
              </DialogContent>
            </Dialog>
          </div>
        </>
      </div>

      {
        <MisDatosCard
          key={misDatos.idCliente}
          misDatos={misDatos}
          getMisDatos={getMisDatos}
          setSBOpen={setSBOpen}
          setMessage={setMessage}
          setType={setType}
        />
      }
    </div>
  )
}

export default MisDatosPersonales
