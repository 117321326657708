import {
  // Grid,
  // CardMedia,
  // CardContent,
  // CardActions,
  Typography,
  // Button,
  // Box,
} from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
// import { makeStyles } from '@material-ui/core/styles'
// import Container from '@material-ui/core/Container'
import { getBlogs } from '../../actions/resultActions'
// import MainStyle from '../../components/Result/Search_ResultStyle.css'
// import Cards from '../../components/Result/Mask/CardIntegrinox'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
// import Card from '../../components/Result/Mask/CardOriginal'
import { Link, NavLink } from 'react-router-dom'
import { Image } from 'antd'
// import { LazyLoadImage } from 'react-lazy-load-image-component'

import Context from '../../store/Context'
// import { getUrlForProductImg } from '../../utils/ima'
// import NumberFormat from 'react-number-format'
// import { COLORS } from '../../Views/Colores'
// import {
//   getProductosConPromo,
//   getMoneda,
// } from '../../components/Result/Mask/catalogorequest'
import { useHistory, useLocation } from 'react-router'

function Vvendido(props) {
  const [nombre, setNombre] = useState('')
  const [fill, setFill] = useState(<h1 className='buscando'></h1>)
  const history = useHistory()
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1336 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  }
  const arr = []

  const context = useContext(Context)

  const [responses, setReponse] = useState([])

  useEffect(() => {
    const buscarProductos = async () => {
      const response = await getBlogs()

      setReponse(response.data)
    }
    buscarProductos()
  }, [])

  const formatDate = d => {
    const f = new Date(d)

    return f.getDate() + '/' + (f.getMonth() + 1) + '/' + f.getFullYear()
  }

  return (
    <div>
      <div className='content'>
        <br></br>
        <div className='titleColeccion1'>
          <span>{props.tittle}</span>
        </div>
        <br></br>

        <Carousel responsive={responsive}>
          {responses.map(p => (
            <div key={p.id} className='carus'>
              <br></br>
              <div className='myimg'>
                <Link to={`/blog/${p.id}/p`}>
                  <Image
                    preview={false}
                    style={{ paddingLeft: '5%', paddingRight: '5%' }}
                    src={p.imagen}
                    fallback={'/img/IMAGEN_NO_DISPONIBLE.png'}
                  />
                </Link>
              </div>
              <br></br>
              <Typography variant={'body2'}>
                <span className='myfecha'>{formatDate(p.fecha)}</span>
              </Typography>
              <br></br>

              <div
                style={{
                  width: '96%',
                  height: '96%',
                  marginLeft: '1%',
                  paddingLeft: '5%',
                  paddingRigth: '5%',
                }}
              >
                <span className='mytitulo'> {p.titulo}</span>
              </div>
              <br></br>
            </div>
          ))}
        </Carousel>
        <br></br>
      </div>
      <br></br>
    </div>
  )
}

export default Vvendido
