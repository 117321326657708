import axios from 'axios'
import Swal from 'sweetalert2'

import Base from '../services/Base'
import { nombre } from '../services/Url'

const axiosInstance = axios.create({
  baseURL: Base.baseUrls.pedido,
  headers: {
    'Content-Type': 'application/json',
    username: 'cliente',
    nombres: nombre.tienda,
  },
  //timeout: 1000,
})

// Interceptor para agregar el token a la cabecera de autorización
axiosInstance.interceptors.request.use(
  async config => {
    const tokenCustom = localStorage.getItem('tokenCustom')
    if (tokenCustom) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${tokenCustom}`,
      }
    }

    return config
  },
  error => {
    // Manejar errores antes de enviar la solicitud
    return Promise.reject(error)
  }
)

// Función para manejar peticiones con cancelación y manejo centralizado de errores
const fetchWithCancel = async ({
  url,
  method = 'GET',
  data = null,
  params = null,
  signal,
}) => {
  try {
    const config = {
      method,
      url,
      data,
      params,
      signal,
    }

    const response = await axiosInstance(config)
    return response.data
  } catch (e) {
    if (axios.isCancel(e)) {
      console.warn('Request canceled', e.message)
    } else {
      Swal.fire(
        e.response?.data || 'Pedido service',
        'Ha ocurrido un error desconocido',
        'error'
      )
      throw e
    }
  }
}

// Función para crear la preferencia de MercadoPago utilizando fetchWithCancel
export const createPreferencesMercadoPago = async (signal, data) => {
  return fetchWithCancel({
    url: 'pedido/mercado-pago/create-preferences',
    method: 'POST',
    data,
    signal,
  })
}

export const CreateOrder = async (signal, orderData) => {
  return fetchWithCancel({
    url: 'pedido/createOrder',
    method: 'POST',
    data: orderData,
    signal,
  })
}

export const getPedidoResumenById = async (signal, id) => {
  return fetchWithCancel({
    url: 'pedido/summary/' + id,
    method: 'GET',
    signal,
  })
}
