export const getUser = () => {
  const user = sessionStorage.getItem('user')
  if (user) return JSON.parse(user)
  else return null
}

export const getToken = () => {
  return sessionStorage.getItem('token') || null
}
export const getLogeado = () => {
  return sessionStorage.getItem('logeado')
}
export const getusername = () => {
  return sessionStorage.getItem('username')
}

export const removeUserSession = () => {
  sessionStorage.removeItem('token')
  sessionStorage.removeItem('user')
  sessionStorage.removeItem('logeado')
  sessionStorage.removeItem('username')
}

export const setUserSession = (token, userState, logeado, name) => {
  sessionStorage.setItem('token', token)
  sessionStorage.setItem('user', JSON.stringify(userState))
  sessionStorage.setItem('username', name)
  sessionStorage.setItem('logeado', logeado)
}
