import React, { useContext, useEffect, useState } from 'react'
// import ReactDOM from 'react-dom'
import { useHistory } from 'react-router'
// import pedidoActions from '../../actions/pedidoActions'
// import { alerts } from '../../services/alerts'
// import Context from '../../store/Context'
// import {
//   getKeysPago,
//   getKeyTipoName,
// getColor,
// } from '../../actions/resultActions'
// import { loadScript } from '@paypal/paypal-js'
import { getTarjetasId, PostTarjetas } from '../Result/Mask/catalogorequest'
import PaypalCheckoutButton from './PayPal'
// import { COLORS } from '../../Views/Colores'
// import { getMoneda } from '../../components/Result/Mask/catalogorequest'
//    src="https://www.paypal.com/sdk/js?client-id=AQzhpgJIYoh598V5JpxJHKEiX_AfdG880_UFSm4M4aN59c__sHjHqPh1i61lhtQzPWD8QaryRimIZhhe&currency=MXN&debug=true&locale=es_MX&disable-funding=credit,card"></script>
//loadScript({ "client-id": "AQzhpgJIYoh598V5JpxJHKEiX_AfdG880_UFSm4M4aN59c__sHjHqPh1i61lhtQzPWD8QaryRimIZhhe", currency: "MXN",debug:"true",locale:"es_MX","disable-funding":"credit,card" });

import GlobalContext from '../../contexts/GlobalContext'

const Client_IDPaypal = window.localStorage.getItem('IdClien')
const Moneda = window.localStorage.getItem('monedas')

// eslint-disable-next-line no-undef

const OrdenPayPal = ({
  setLoading,
  idClient,
  id,
  activo,
  destinatario,
  fecha,
  remitente,
  mensaje,
  cantidad,
  monto,
  enviar,
  nombre,
  urls,
  mone,
  paypalID,
}) => {
  const { monedaState } = useContext(GlobalContext)

  const [moneda, setMoneda] = useState(() => monedaState)

  // const getMonedas = async () => {
  // const moned = await getMoneda()
  // setMoneda(moned.data)
  // window.localStorage.setItem('monedasup', moned.data.abreviatura)

  // window.localStorage.setItem('monedas', moned.data.abreviatura)

  // console.log(moned)
  // }

  // const getColors = async () => {
  //   const response = await getColor()
  //   COLORS.black = response.data.nombre
  // }
  // useEffect(() => {
  //  getPromos();
  // getColors()
  // getMonedas()
  // }, [])

  const history = useHistory()
  const [_Producto, setProducto] = React.useState({
    id: id,
    activo: activo,
    destinatario: destinatario,
    remitente: remitente,
    mensaje: mensaje,
    fecha: fecha,
    cantidad: cantidad,
    urls: urls,
    nombre: nombre,
    montoActual: monto,
  })
  const order = {
    customer: '123456',
    total: cantidad * monto,
    currency: moneda.abreviatura,
    id: Client_IDPaypal,
  }

  const TarjetaPost = async () => {
    const response = await PostTarjetas(_Producto)
    console.log(response)

    if (response.status === 200) {
      history.push(`/enviado/${id}/${response.data.idCompra}`)
      setLoading(false)
    }
    ///tarjetas/enviado
  }
  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: cantidad * monto,
          },
        },
      ],
      application_context: { shipping_preference: 'NO_SHIPPING' },
    })
  }
  useEffect(() => {
    /* loadScript({ "client-id": "test" })
    .then((paypal) => {
  
      paypal=  loadScript({ "client-id": Client_IDPaypal, currency: "USD",debug:"true",locale:"es_MX" });


    })
    .catch((error) => {
        console.error("failed to load the PayPal JS SDK script", error);
    });*/
  })
  const paypalConf = {
    currency: Moneda,
    env: 'sandbox',
    client: {
      sandbox: Client_IDPaypal,
      production: '--',
    },
    style: {
      label: 'pay',
      size: 'medium', // small | medium | large | responsive
      shape: 'rect', // pill | rect
      color: 'gold', // gold | blue | silver | black
    },
  }
  const onApprove = (data, actions) => {
    if (cantidad < 1) {
      window.alert(
        'Requiere seleccionarse almenos 1 cantidad de tarjeta de regalo'
      )
    } else {
      setLoading(true)
      const pago = actions.order.capture()

      enviar()
      return pago
    }
  }

  return (
    <div>
      <PaypalCheckoutButton
        order={order}
        envio={enviar}
        setLoading={setLoading}
        fecha={fecha}
        paypalID={paypalID}
      />
    </div>
  )
}

export default OrdenPayPal
