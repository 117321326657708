import React from 'react'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Popover from '@material-ui/core/Popover'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import Grid from '@material-ui/core/Grid'
import Cliente from '../../components/RegistroCliente/Clienteb2c'
import Empresa from '../../components/RegistroCliente/Empresa'
import { Link } from 'react-router-dom'

function Ingresar() {
  return (
    <>
      <PopupState variant='popover' popupId='demo-popup-popover'>
        {popupState => (
          <>
            <Button
              variant='contained'
              color='primary'
              {...bindTrigger(popupState)}
            >
              Registrar
            </Button>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Grid
                container
                direction='column'
                justify='center'
                alignItems='center'
              >
                <Box display='flex' p={1}>
                  <Cliente openModal={popupState.isOpen} />
                </Box>
              </Grid>
              <Grid
                container
                direction='row'
                justify='flex-end'
                alignItems='center'
              ></Grid>
              {/* <Grid container direction="row" justify="center" alignItems="center">
                                <Link to="/integrinox/web/secured">
                                   
                                </Link>

                            </Grid> */}
              {/* <Button variant="contained" color="primary" onClick={h} >
                                        Ingresar con Google
                               </Button> */}
            </Popover>{' '}
          </>
        )}
      </PopupState>
    </>
  )
}
export default Ingresar
