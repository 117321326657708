import { useContext, useState, useEffect } from 'react'
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react'
import Swal from 'sweetalert2'

import GlobalContext from '../../contexts/GlobalContext'

import { createPreferencesMercadoPago } from '../../services/pedidoService'
import { getPaymentMethodKeysById } from '../../services/catalogoService'

const MercadoPagoButton = ({
  items,
  totalAmount,
  resumen,
  sucursalId,
  costoEnvio,
  envio,
  proveedo,
  totales,
  cuponesId,
  descuentoT,
  envios,
  deliveryMethod,
  deliveryType99m,
  carritoId,
  direccionId,
}) => {
  const { paymentMethods } = useContext(GlobalContext)

  const [mercadoPagoData, setMercadoPagoData] = useState(null)
  const [publicKey, setPublicKey] = useState(null)

  useEffect(() => {
    if (!paymentMethods && !Array.isArray(paymentMethods)) return

    const payWithMercadoPago = paymentMethods.find(
      item => item.nombreTipo === 'Mercado Pago'
    )

    if (payWithMercadoPago) {
      setMercadoPagoData(payWithMercadoPago)
    }
  }, [paymentMethods])

  const getMercadoPagoKeys = async signal => {
    try {
      const result = await getPaymentMethodKeysById(
        signal,
        mercadoPagoData.idTipo
      )
      const public_Key = result
        .find(item => item.nombreClave.trim() === 'Public_key')
        .clave.trim()

      setPublicKey(public_Key)
    } catch (error) {
      console.warn('Error fetching getPaymentMethodKeysById()')
    } finally {
      console.info('Finally fetching getPaymentMethodKeysById()')
    }
  }

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const signal = controller.signal

    getMercadoPagoKeys(signal)

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [mercadoPagoData])

  const [loading, setLoading] = useState(false)
  const [preferenceId, setPreferenceId] = useState(null)
  const [abortController, setAbortController] = useState(null)

  const baseUrl = window.location.origin

  initMercadoPago(publicKey, {
    locale: 'es-MX',
  })

  if (deliveryMethod !== 'Sucursal') {
    envios.deliveryType99m = deliveryType99m

    const dataOrder = {
      carritoId: carritoId,
      envio: envios,
      costoEnvio: costoEnvio,
      sucursalId: sucursalId,
      referencia: totales.referencia,
      clienteDireccionId: direccionId,
      cuponesIds: cuponesId,
      fleteId: 1,
      idFleteConfiguracion: 1,
      // montoTotal: descuentoT,
      monto: descuentoT,

      proveedor: 'MercadoPago',
      paymentProviderId: null,
      captureId: null,
      intent: 'CAPTURE',
      status: 'COMPLETED',
    }

    window.localStorage.setItem('dataOrder', JSON.stringify(dataOrder))
  }

  const convertToMercadoPagoItems = productos => {
    return productos.map(producto => ({
      title: decodeURIComponent(producto.producto),
      quantity: producto.cantidad,
      currencyId: 'MXN',
      unitPrice: producto.precio,
    }))
  }

  const handleCreatePreference = async () => {
    if (!items || items.length === 0) {
      Swal.fire('Error', 'No hay productos para procesar el pago', 'error')
      return
    }

    setLoading(true)
    const controller = new AbortController()
    setAbortController(controller)

    const data = {
      items: convertToMercadoPagoItems(items),
      shipments: { cost: Number(costoEnvio), mode: 'not_specified' },
      taxes: [{ type: 'IVA', value: 16 }],
      successUrl: `${baseUrl}/pago/success/mercadopago`,
      failureUrl: `${baseUrl}/pago/failure/mercadopago`,
      pendingUrl: `${baseUrl}/pago/pending/mercadopago`,
      auto_return: 'approved',
    }

    try {
      const result = await createPreferencesMercadoPago(controller.signal, data)
      setPreferenceId(result.preferenceId)
    } catch (e) {
      console.error('Error al crear la preferencia:', e)
    } finally {
      setLoading(false)
      console.info('Finalizo la creacion de la preferencia')
    }
  }

  useEffect(() => {
    if (totalAmount > 0) {
      handleCreatePreference()
    }
  }, [totalAmount, costoEnvio])

  useEffect(() => {
    return () => {
      if (abortController) {
        abortController.abort()
      }
    }
  }, [abortController])

  return (
    <>
      {loading && <p>Cargando...</p>}
      {preferenceId && !loading && (
        <Wallet initialization={{ preferenceId: preferenceId }} />
      )}
    </>
  )
}

export default MercadoPagoButton
