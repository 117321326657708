import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import SearchField from 'react-search-field'
import index from './index.css'
// import { COLORS } from '../../../Views/Colores'
import StylesContext from '../../../contexts/styles/stylesContext'

const SearchBar = ({ keyword, setKeyword }) => {
  const stylesContext = useContext(StylesContext)
  //   const [query,setQuery] = useState("");
  const history = useHistory()
  // document.documentElement.style.setProperty('--color-principal', COLORS.black)

  const BarStyling = {
    width: '40%',
    background: 'white',
    borderRadius: '5px',
    border: stylesContext.black + '1px solid',
    padding: '0.5rem',
  }

  const searchResult = value => {
    if (value.length >= 2) {
      var jsonF = value.replace('/', '').replace('#', '_NUM_')
      history.push(`/products/${jsonF}/sb/ls`)
    }
  }

  const searchResultKey = (value, event) => {
    if (value.length >= 2) {
      var jsonF = value.replace('/', '').replace('#', '_NUM_')
      history.push(`/products/${jsonF}/sb/ls`)
    }
  }

  return (
    <SearchField
      placeholder='Buscar'
      onEnter={searchResultKey}
      onSearchClick={searchResult}
    />
  )
}

export default SearchBar
