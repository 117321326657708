import React from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: 9999,
    color: '#fff',
  },
}))

export default function SimpleBackdrop(props) {
  const classes = useStyles()
  return (
    <div>
      <Backdrop className={classes.backdrop} open={props.open}>
        <CircularProgress color='primary' />
        <Typography>Cargando...</Typography>
      </Backdrop>
    </div>
  )
}
