import React from 'react'
import ReactDOM from 'react-dom'
import paypal from 'paypal-checkout'
import { engineVersion } from 'react-device-detect'
import { FaMoneyCheckAlt } from 'react-icons/fa'
import LoadingScreen from '../RegistroCliente/LoadingScreen'
import { useContext, useEffect, useState } from 'react'
/*
 * By AFelipe MX  @afelipelc
 */

const PaypalCheckoutButton = ({
  order,
  envio,
  setLoading,
  fecha,
  paypalID,
}) => {
  const paypalConf = {
    currency: order.currency,
    env: 'sandbox',
    client: {
      sandbox: order.id,
      production: '--',
    },
    style: {
      label: 'pay',
      size: 'medium', // small | medium | large | responsive
      shape: 'rect', // pill | rect
      color: 'gold', // gold | blue | silver | black
    },
  }
  const PayPalButton = paypal.Button.driver('react', { React, ReactDOM })
  const [isLoading, setIsLoading] = useState(false)
  const payment = (data, actions) => {
    const payment = {
      transactions: [
        {
          amount: {
            total: order.total,
            currency: paypalConf.currency,
          },
          description: 'Tarjera de regalo',
          custom: order.customer || '',
        },
      ],
      note_to_payer: 'Contáctanos para cualquier aclaración sobre tu compra.',
    }

    // console.log(payment);
    return actions.payment.create({
      payment,
    })
  }

  const onAuthorize = (data, actions) => {
    setIsLoading(true)
    setLoading(true)
    return actions.payment
      .execute()
      .then(response => {
        console.log(response)
        envio()
        setLoading(false)
        setIsLoading(false)
      })
      .catch(error => {
        console.log(error)
        alert('Ocurrió un error al procesar el pago con Paypal')
        setIsLoading(false)
        setLoading(false)
      })
  }

  const onError = error => {
    alert('El pago con PayPal no fue realizado, vuelva a intentarlo.')
    setIsLoading(false)
  }

  const onCancel = (data, actions) => {
    alert('El pago con PayPal no fue realizado, el usuario canceló el proceso.')
    setIsLoading(false)
  }

  return (
    <div
      style={{
        marginLeft: '15%',
      }}
    >
      <br></br>

      <PayPalButton
        env={paypalConf.env}
        client={paypalConf.client}
        payment={(data, actions) => payment(data, actions)}
        onAuthorize={(data, actions) => onAuthorize(data, actions)}
        onCancel={(data, actions) => onCancel(data, actions)}
        onError={error => onError(error)}
        style={paypalConf.style}
        commit
        locale='es_MX'
      />
      <div>
        <LoadingScreen open={isLoading} />
      </div>
    </div>
  )
}

export default PaypalCheckoutButton
