// import { COLORS } from '../../Views/Colores'
// import useWindowSize from './../../utils/useWindowSize'
import { Link } from 'react-router-dom'
import {
  // getKeyPay,
  // getEle,
  // getMercadoPK,
  // getLogo,
  // getDetalle,
  // getBanerT,
  getBannercompra,
} from '../../actions/resultActions'
import React, { useContext, useEffect, useState, Suspense } from 'react'
import './plantillas.css'

export default function HomeTest(props) {
  const [banners, setBanners] = useState([])

  // document.documentElement.style.setProperty('--color-principal', COLORS.black)
  document.documentElement.style.setProperty(
    '--background',
    'url(' + banners.imagen + ')',
  )
  useEffect(() => {
    //  getPromos();
    BannersGet()
  }, [])
  const BannersGet = async () => {
    const carrusels = await getBannercompra(props.id)
    setBanners(carrusels.data)
    document.documentElement.style.setProperty(
      '--background',
      'url(' + carrusels.data.imagen + ')',
    )
  }
  return (
    <div>
      <section className='main-section'>
        <div className='container'>
          <div className='col-lg-6 offset-lg-6'>
            <h1>{banners.titulo}</h1>
            <h4>{banners.subtitulo}</h4>
            <br></br>
            <div className='row'>
              <div className='col-auto'>
                <Link to={`/products${banners.producto}`}>
                  <button className='btn btn-main'>Compra ahora</button>
                </Link>
              </div>
              <div className='col-auto'>
                <Link to={banners.link}>
                  <button className='btn btn-secondary'>Descubre más</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
