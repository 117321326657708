import React, { useEffect, useState } from 'react'
import { Card, CardMedia, Grid } from '@material-ui/core'
import './ima.css'
// import { COLORS2 } from '../../Views/Colores'
import BannerExample from '../Banner/BannerResponsive'
import NotificacionBar from '../NotificacionBar/NotificacionBar'
import Cvendido from '../../components/Vendido/Cvendido'
const Header = React.lazy(
  () => import('../../components/Header/HeaderResponsive'),
)

function Elemento(props) {
  var color
  if (props.ind === 0) {
    color = 'opacity(0.4) drop-shadow(0 0 0 ' + props.colorImage + ')'
    document.documentElement.style.setProperty('--colorima0', color)
  }
  if (props.ind === 1) {
    color = 'opacity(0.4) drop-shadow(0 0 0 ' + props.colorImage + ')'
    document.documentElement.style.setProperty('--colorima1', color)
  }
  if (props.ind === 2) {
    color = 'opacity(0.4) drop-shadow(0 0 0 ' + props.colorImage + ')'
    document.documentElement.style.setProperty('--colorima2', color)
  }
  if (props.ind === 3) {
    color = 'opacity(0.4) drop-shadow(0 0 0 ' + props.colorImage + ')'
    document.documentElement.style.setProperty('--colorima3', color)
  }
  if (props.ind === 4) {
    color = 'opacity(0.4) drop-shadow(0 0 0 ' + props.colorImage + ')'
    document.documentElement.style.setProperty('--colorima4', color)
  }
  if (props.ind === 5) {
    color = 'opacity(0.4) drop-shadow(0 0 0 ' + props.colorImage + ')'
    document.documentElement.style.setProperty('--colorima5', color)
  }
  if (props.ind === 6) {
    color = 'opacity(0.4) drop-shadow(0 0 0 ' + props.colorImage + ')'
    document.documentElement.style.setProperty('--colorima6', color)
  }
  if (props.ind === 7) {
    color = 'opacity(0.4) drop-shadow(0 0 0 ' + props.colorImage + ')'
    document.documentElement.style.setProperty('--colorima7', color)
  }

  return (
    <div>
      {props.ind === 0 && (
        <img
          className='img0'
          src={props.icono}
          style={{ width: '40%', height: '40%', marginLeft: '30%' }}
        />
      )}
      {props.ind === 1 && (
        <img
          className='img1'
          src={props.icono}
          style={{ width: '40%', height: '40%', marginLeft: '30%' }}
        />
      )}
      {props.ind === 2 && (
        <img
          className='img2'
          src={props.icono}
          style={{ width: '40%', height: '40%', marginLeft: '30%' }}
        />
      )}
      {props.ind === 3 && (
        <img
          className='img3'
          src={props.icono}
          style={{ width: '40%', height: '40%', marginLeft: '30%' }}
        />
      )}
      {props.ind === 4 && (
        <img
          className='img4'
          src={props.icono}
          style={{ width: '40%', height: '40%', marginLeft: '30%' }}
        />
      )}
      {props.ind === 5 && (
        <img
          className='img5'
          src={props.icono}
          style={{ width: '40%', height: '40%', marginLeft: '30%' }}
        />
      )}
      {props.ind === 6 && (
        <img
          className='img6'
          src={props.icono}
          style={{ width: '40%', height: '40%', marginLeft: '30%' }}
        />
      )}
      {props.ind === 7 && (
        <img
          className='img7'
          src={props.icono}
          style={{ width: '40%', height: '40%', marginLeft: '30%' }}
        />
      )}
    </div>
  )
}

export default Elemento
