// import { COLORS } from '../../Views/Colores'
// import useWindowSize from './../../utils/useWindowSize'
import { Link } from 'react-router-dom'
import {
  // getKeyPay,
  // getEle,
  // getMercadoPK,
  // getLogo,
  // getDetalle,
  // getBanerT,
  getCat,
} from '../../actions/resultActions'
import React, { useContext, useEffect, useState, Suspense } from 'react'
import './plantillas.css'
// import Card from 'react-bootstrap/Card'
export default function HomeTest(props) {
  const [banners, setBanners] = useState([])

  // document.documentElement.style.setProperty('--color-principal', COLORS.black)

  useEffect(() => {
    //  getPromos();
    BannersGet()
  }, [])
  const BannersGet = async () => {
    const carrusels = await getCat(props.id)
    setBanners(carrusels.data)
  }
  return (
    <div>
      <section className='ourCategories'>
        <div className='container'>
          <div className='row justify-content-center mb-5'>
            {banners.length > 0 && (
              <h2 className='text-center'>{banners[0].nombre}</h2>
            )}
          </div>

          <div className='row row-cols-1 row-cols-md-4 g-4'>
            {banners.map((object, index) => (
              <div className='col'>
                <div className='card h-100'>
                  <img src={object.imagen} />
                  <div className='card-img-overlay'>
                    <b>
                      {' '}
                      <span className='card-title'>{object.titulo}</span>
                    </b>
                    <b>
                      {' '}
                      <span className='card-text'>{object.subtitulo}</span>
                    </b>
                    <br></br>
                    <Link to={`/products/${object.idCategoria}/cat/ls`}>
                      <button className='btn btn-main'>Comprar ahora</button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  )
}
