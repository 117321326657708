import React, { useContext, useEffect, useState } from 'react'
import { Chip, Step, StepLabel, Stepper, Link } from '@material-ui/core'
import { MailOutlineSharp } from '@material-ui/icons'
import { Helmet } from 'react-helmet'

import pedidoActions from '../../actions/pedidoActions'
import StylesContext from '../../contexts/styles/stylesContext'

function getSteps() {
  return [
    'Pedido realizado',
    'Confirmación de pago',
    'En proceso',
    'Confirmación de pedido',
    'En camino / Listo para recolección',
    'Entrega confirmada',
  ]
}

const OrdenCompleta = ({ idPedido }) => {
  const stylesContext = useContext(StylesContext)

  const [idFolioPedido, setIdFolioPedido] = useState(idPedido ?? '')
  const [activeStep, setActiveStep] = useState(1)
  const [resumens, setResumen] = useState(null)

  const steps = getSteps()

  const getPedidoResumen = async () => {
    const resumens = await pedidoActions.getPedidoResumen(idFolioPedido)

    setResumen(resumens)
  }

  useEffect(() => {
    if (!idPedido) return

    setIdFolioPedido(idPedido)
    getPedidoResumen()
  }, [idPedido])

  return (
    <div>
      <div
        style={{
          margin: 30,
          padding: 20,
          marginTop: 5,
          paddingTop: 5,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Helmet
          script={[
            {
              type: 'text/javascript',
              innerHTML:
                '<script>' +
                "gtag('event', 'conversion', {'send_to': 'AW-1070359895/UEXNCLTA3fUCENfKsf4D'});" +
                '</script>',
            },
          ]}
        />

        <div style={{ textAlign: 'center', width: '100%', marginBottom: 20 }}>
          <br></br>
          <h2 style={{ color: stylesContext.primaryColor }}>
            ¡Gracias por realizar tu pedido!
          </h2>
        </div>
        <div style={{ textAlign: 'center', width: '100%', marginBottom: 15 }}>
          <Chip
            style={{ fontSize: '1.3rem', margin: 5 }}
            variant='default'
            size='medium'
            label={`Pedido No. ${idFolioPedido}`}
          />
        </div>
        <div
          style={{
            width: '100%',
            padding: '5px 90px',
            marginBottom: 15,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <MailOutlineSharp style={{ fontSize: '2.5rem', marginRight: 20 }} />
          <label>
            Te enviaremos un email de seguimiento para que puedas saber en todo
            momento dónde se encuentra cada uno de ellos.
          </label>
        </div>
        <div
          style={{
            width: '100%',
            padding: '5px',
            paddingLeft: '30%',
            marginBottom: 15,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Stepper orientation='vertical'>
            {steps.map((label, index) => (
              <Step
                active={index === activeStep || index <= activeStep}
                key={label}
              >
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <div style={{ textAlign: 'center', width: '100%', marginBottom: 15 }}>
          <label
            style={{
              padding: '10px',
              backgroundColor: '#fade98',
              width: '390px',
              marginLeft: '30px',
              borderRadius: '10px',
              fontSize: '.9rem',
            }}
          >
            {`Si olvido realizar su factura, puede realizarla en `}
            <Link href='/MiCuenta/pedidos'>{`"Mis pedidos"`}</Link>.
          </label>
        </div>
      </div>
    </div>
  )
}

export default OrdenCompleta
