import axios from 'axios'
import Base from '../../../services/Base'
import Swal from 'sweetalert2'
import { nombre } from '../../../services/Url'

export const getProductosConPromo = () => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('PromocionesDescuentoProducto/GetSinPromocion')
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        console.log(e)
        reject(e)
      })
  })
}
export const getTarjetas = () => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('Tarjeta/GetAll')
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        console.log(e)
        reject(e)
      })
  })
}
export const Devolver = data => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.skydropx,
      headers: {
        'Content-Type': 'application/json',

        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
      },
    })
    instance
      .post('Devolucion/Create', data)
      .then(response => {
        resolve(response)
        Swal.fire('Devolucion solicitada con exito', response.data, 'success')
      })
      .catch(e => {
        console.log(e)
        reject(e)
        Swal.fire('Hubo un error', '', 'error')
      })
  })
}
export const postEstatus = (id, estado) => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.pedido,
      headers: {
        'Content-Type': 'application/json',

        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
      },
    })
    instance
      .post('Pedido/enviado/' + id + '/' + estado)
      .then(response => {
        resolve(response)
        Swal.fire('El paquete fue recibido', '', 'success')
      })
      .catch(e => {
        console.log(e)
        reject(e)
        Swal.fire('Hubo un error', '', 'error')
      })
  })
}
export const getPed = () => {
  return new Promise((resolve, reject) => {
    const tokenCustom = localStorage.getItem('tokenCustom')
    const instance = axios.create({
      baseURL: Base.baseUrls.pedido,
      headers: {
        'Content-Type': 'application/json',

        nombres: nombre.tienda,
        Authorization: `Bearer ${tokenCustom}`,
      },
    })
    instance
      .get('pedido/client')
      .then(response => {
        console.log(response)
        resolve(response)
      })
      .catch(e => {
        console.log(e)

        reject(e)
      })
  })
}
// export const getMoneda = () => {
//   return new Promise((resolve, reject) => {
//     const instance = axios.create({
//       baseURL: Base.baseUrls.catalogo,
//       headers: {
//         'Content-Type': 'application/json',
//         username: 'cliente',
//         nombres: nombre.tienda,
//         'Access-Control-Allow-Origin': '*',
//         'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
//         'Access-Control-Allow-Headers':
//           'Content-Type, Authorization, X-Requested-With',
//       },
//     })
//     instance
//       .get('Moneda/750f3976-da97-466f-8a76-c6bca1be9c1b')
//       .then(response => {
//         resolve(response)
//       })
//       .catch(e => {
//         console.log(e)

//         reject(e)
//       })
//   })
// }
export const getProductos = (idCarrito, idCliente) => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('ProductoCliente/GetPedido/' + idCarrito + '/' + idCliente)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        console.log(e)

        reject(e)
      })
  })
}
export const getPreciosCliente = id => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('PrecioCliente/' + id)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        console.log(e)

        reject(e)
      })
  })
}
export const getTarjetasId = id => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('Tarjeta/' + id)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        console.log(e)
        reject(e)
      })
  })
}
export const getTarjetasRegaloId = id => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('TarjetaRegalo/' + id)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        console.log(e)
        reject(e)
      })
  })
}
export const getTarjetasRegalo = correo => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('TarjetaRegalo/GetByEmail/' + correo)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        console.log(e)
        reject(e)
      })
  })
}

export const PostTarjetas = data => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .post('TarjetaRegalo/Create', data)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        console.log(e)

        reject(e)
      })
  })
}
export const UpdateTarjetas = data => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .post('TarjetaRegalo/Update', data)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        console.log(e)

        reject(e)
      })
  })
}
export const GetDetalle = () => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
      },
    })
    instance
      .get('Detalle/b8ddb7d8-66f5-401c-b3e8-95f087b19d48')
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        console.log(e)

        reject(e)
      })
  })
}
