import { Button, Tooltip, Zoom, Typography, Grid } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import HelpIcon from '@material-ui/icons/Help'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import LoadingScreen from './LoadingScreen'
import { useAnalyticsEventTracker } from '../GoogleAnalitycs/useAnalyticsEventTracker'
import Reaptcha from 'reaptcha'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import { useHistory } from 'react-router'

import ApiGeneral from '../../services/ApiGeneral'
import AuthCliente from '../../services/AuthCliente'
import errores from '../../services/errores'
import MySnackBar from './../MySnackBar/SnackBar'
import CircularProgress from '@material-ui/core/CircularProgress'

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    marginTop: '-10px',
  },
}))(Tooltip)
const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: 9999,
    color: '#fff',
  },
  circle: {
    marginLeft: '270px',
    marginTop: '250px',
    position: 'fixed',
  },
  letra: {
    marginLeft: '-15px',
  },
  btncancelar: {
    background: '#eeeeee',
    color: '#003462',
    backgroundColor: '#eeeeee!important',
  },
  btnsubnmit: {
    background: '#003462',
    color: '#ffffff!important',
    backgroundColor: '#003462!important',
  },
  DiAction: {
    paddingRight: '2rem',
  },
  error: {
    color: '#ff0500',
  },
  margin: {
    margin: theme.spacing(1),
  },
}))

const Cliente = () => {
  const classes = useStyles()
  const history = useHistory()

  const handleClickOpen = () => {
    history.push('/registro/clikalo/tienda')
  }

  return (
    <>
      <Button variant='contained' color='primary' onClick={handleClickOpen}>
        Registrar
      </Button>
    </>
  )
}

export default Cliente
