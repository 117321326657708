import React, { useContext, useEffect, useState } from 'react'
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  Link,
  makeStyles,
  Toolbar,
} from '@material-ui/core'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import MenuIcon from '@material-ui/icons/Menu'
// import { useKeycloak } from '@react-keycloak/web'
import { useHistory } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'
import { Segment } from 'semantic-ui-react'
import { GiHamburgerMenu } from 'react-icons/gi'
import { TiDeleteOutline } from 'react-icons/ti'

import './index.css'

import { useAnalyticsEventTracker } from '../GoogleAnalitycs/useAnalyticsEventTracker'
import Context from '../../store/Context'
import GlobalContext from '../../contexts/GlobalContext'
import StylesContext from '../../contexts/styles/stylesContext'
import ApiGeneral from '../../services/ApiGeneral'
import UserContext from '../../contexts/user/UserContext'

import {
  getProductoMedida,
  getForm,
  getPlantillas,
} from '../../actions/resultActions'

import Cart from '../../components/Cart/index'
import MenuRegistroB2C from '../../components/Login/MenuRegistrob2c'
import Clienteb2c from '../../components/RegistroCliente/Clienteb2c'
import MenuDetalle from '../Login/MenuDetalle'
import TestMenu from './TestMenu'
import SearchBar from './SearchBar/SearchBar'
import SubMenu from './SubMenu'

import { getItemConfiguracionById } from '../../services/catalogoService'

const useStyles = makeStyles(() => ({
  searbarMobiile: {
    width: '80% !important',
    marginLeft: '10%',
  },

  header: {
    backgroundColor: '#FFFFFF',
    paddingLeft: '70px',
    zIndex: 999,
    position: 'sticky',
    '@media (max-width: 900px)': {
      paddingLeft: 1,
    },
  },
  logo: {
    maxWidth: 180,
  },
  logoMobile: {
    maxWidth: 180,
    marginLeft: '10px',
  },
  menuButton: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 250,
    size: '14px',
    marginLeft: '5px',
    color: '#003462',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  drawerContainer: {
    padding: '20px 30px',
  },
  button: {},
  textfildMargin: {
    width: '17rem',
    height: '9px !important',
    paddingbottom: '2.5px',
  },
  btnRegis: {
    width: '19rem',
  },
  gridAlings: {
    textAlign: 'center',
    padding: '1rem',
  },
  btnSocial: {
    textAlign: 'center',
    padding: '1rem',
  },
}))

const HeaderResponsive = () => {
  const { UserState, doLogin, doLogout } = useContext(UserContext)
  const context = useContext(Context)
  const { monedaState } = useContext(GlobalContext)
  const stylesContext = useContext(StylesContext)

  const [moneda, setMoneda] = useState(() => monedaState)
  const [isToggle, setToggle] = useState(false)
  const [Categorias, headersData] = useState([])
  const [menus, setMenus] = useState(false)
  const [isActiveFullMenu, setIsActiveFullMenu] = useState(false)
  const [productoMedida, setProductoMedida] = useState(true)
  const [b2b, setB2b] = useState(false)
  const [isOpenRegistro, setIsOpenRegistro] = useState(() => false)
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
    use: [],
  })

  const gaEventLogin = useAnalyticsEventTracker('Login')

  // const { keycloak, initialized } = useKeycloak()
  const history = useHistory()
  const { searbarMobiile, header, logo, logoMobile, toolbar, drawerContainer } =
    useStyles()

  const abrir = () => {
    setMenus(!menus)
  }

  const { mobileView, drawerOpen } = state

  const getConfiguracionMenu = async signal => {
    try {
      const res = await getItemConfiguracionById(
        signal,
        '94c77ff4-d55f-4a23-a188-bbb9032f7bd1'
      )
      setIsActiveFullMenu(res?.activo)
    } catch (error) {
      console.error('Error fetching ... in header')
    } finally {
      console.info('Finally fetching ... in header')
    }
  }

  const getForms = async () => {
    const responses = await getForm()
    setB2b(responses.data.activo)
  }

  const getProductosMedida = async () => {
    const responses = await getProductoMedida()
    setProductoMedida(responses.data.activo)
    window.localStorage.setItem('PM', responses.data.activo)
  }

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const signal = controller.signal

    getConfiguracionMenu(signal)

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])

  useEffect(() => {
    // console.log('initialized', initialized)
    console.log('b2b', b2b)
    getForms()

    setTimeout(() => {
      if (b2b === true) {
        // if (initialized === true) {
        var estado = UserState.authenticated

        if (estado === false) {
          history.push('/registro/clikalo/tienda')
        }
        // }
      }
    }, 1000)

    getCategorias()
  }, [b2b])

  const getCategorias = async () => {
    const plant = await getPlantillas()
    ApiGeneral.getCategorias()
      .then(response => {
        const data = response.data
        getProductosMedida()

        data.push({
          categoriasHijas: [],
          activo: true,
          idCategoria: -1,
          nombre: 'Ver todos',
        })
        var P = window.localStorage.getItem('PM')

        if (P === 'true') {
          data.push({
            categoriasHijas: [],
            activo: true,
            idCategoria: -2,
            nombre: 'Producto Medida',
          })
        }

        data.push({
          categoriasHijas: [],
          activo: true,
          idCategoria: -3,
          nombre: 'Tarjetas de regalo',
        })
        data.push({
          categoriasHijas: [],
          activo: true,
          idCategoria: -4,
          nombre: 'Contáctanos',
        })

        plant.data.forEach(element => {
          data.push({
            categoriasHijas: [],
            activo: element.activo,
            idCategoria: -200,
            nombre: element.nombre,
          })
        })
        headersData(data)
      })
      .catch(e => {
        console.error('error data:' + e)
      })
  }

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState(prevState => ({ ...prevState, mobileView: true }))
        : setState(prevState => ({ ...prevState, mobileView: false }))
    }
    setResponsiveness()

    window.addEventListener('resize', () => setResponsiveness())
  }, [])

  const handleClick = event => {
    gaEventLogin('Registrar')
    doLogin()
  }

  const displayDesktop = () => {
    return (
      <div className='all'>
        <AppBar className={header}>
          <Toolbar className={toolbar}>
            <Link
              to='/web/'
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              {LogoHeader}
            </Link>
            <SearchBar />

            <Box display='flex'>
              <Segment
                basic
                style={{ margin: 0, padding: 0 }}
                // loading={!initialized}
              >
                <ButtonGroup className='login-desk'>
                  {!!UserState.authenticated ? (
                    <MenuDetalle />
                  ) : (
                    <div className='lo'>
                      <div className='ingre'>
                        <Box display='inline' m={0.2}>
                          <Button
                            variant='contained'
                            color='primary'
                            // onClick={doLogin}
                            onClick={() => history.push('/signin')}
                          >
                            Ingresar
                          </Button>
                        </Box>
                      </div>
                      <div>
                        <Box display='inline' m={0.2}>
                          {b2b === false && (
                            //   <MenuRegistroB2C />
                            <div>
                              <Button
                                variant='contained'
                                color='primary'
                                // onClick={() => setIsOpenRegistro(true)}
                                onClick={() => history.push('/signup')}
                              >
                                Registrar
                              </Button>
                            </div>
                          )}
                        </Box>
                      </div>
                      {/* {isOpenRegistro ? (
                        <div
                          style={{
                            position: 'fixed',
                            top: '0',
                            bottom: '0',
                            left: '0',
                            right: '0',
                            visibility: isOpenRegistro ? 'visible' : 'hidden',
                          }}
                        >
                          <Clienteb2c
                            openModal={isOpenRegistro}
                            onClose={() => setIsOpenRegistro(false)}
                          />
                        </div>
                      ) : null} */}
                    </div>
                  )}
                </ButtonGroup>
              </Segment>

              <Box mr={4} className='car'>
                <Cart
                  total={context.total}
                  isToggle={isToggle}
                  setToggle={setToggle}
                  iva={context.iva}
                  carts={context.carts}
                  subtotal={context.subtotal}
                  removeProductFromCart={context.removeProductFromCart}
                  tipoCambio={context.getTipoCambio}
                  mone={moneda.abreviatura}
                />
              </Box>
            </Box>
          </Toolbar>
          {!isActiveFullMenu && (
            <div style={{ marginLeft: '-19px', marginTop: '-45px' }}>
              {menus == true && (
                <Button>
                  <TiDeleteOutline
                    onClick={() => abrir()}
                    size={35}
                    color={stylesContext.primaryColor}
                  />
                </Button>
              )}
              {menus == false && (
                <Button>
                  <GiHamburgerMenu
                    onClick={() => abrir()}
                    style={{ color: stylesContext.primaryColor }}
                    size={30}
                  />
                </Button>
              )}

              {menus == true && (
                <TestMenu
                  Categorias={Categorias}
                  getCategorias={getCategorias}
                />
              )}
            </div>
          )}
          {isActiveFullMenu && (
            <TestMenu Categorias={Categorias} getCategorias={getCategorias} />
          )}
        </AppBar>
      </div>
    )
  }

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState(prevState => ({ ...prevState, drawerOpen: true }))
    const handleDrawerClose = () =>
      setState(prevState => ({ ...prevState, drawerOpen: false }))
    const go = item => {}
    const getDrawerChoices = () => {
      return (
        <>
          {Categorias.map((item, index) => {
            if (item.activo) {
              return (
                <SubMenu
                  handleDrawerClose={handleDrawerClose}
                  item={item}
                  key={index}
                />
              )
            }
          })}
        </>
      )
    }
    return (
      <div className='all'>
        <AppBar className={header}>
          <Toolbar>
            <IconButton
              {...{
                edge: 'end',
                color: 'inherit',
                'aria-label': 'menu',
                'aria-haspopup': 'true',
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon />
            </IconButton>

            <Drawer
              {...{
                anchor: 'top',
                open: drawerOpen,
                onClose: handleDrawerClose,
              }}
            >
              <div>
                <li
                  className='close-icon'
                  style={{
                    marginRight: '20px',
                    marginTop: '10px',
                    textAlign: 'right',
                  }}
                  onClick={handleDrawerClose}
                >
                  ✕
                </li>

                <div className={drawerContainer}>{getDrawerChoices()}</div>
              </div>

              <Box mr={10}>
                <Box display='inline' m={0.3}></Box>
                {/* <Box display="inline" m={0.3} >
                             <Button variant="contained" color="primary" href="Registrar">Registrar</Button>
                         </Box> */}
                {/* <Box display="inline" m={0.3} >
                             <Button variant="outlined" color="primary" size="small">
                                 <IconButton color="primary" size="small">
                                     <ShoppingCartIcon />
                                 </IconButton>
                             </Button>
                         </Box> */}
              </Box>
            </Drawer>

            <div>{LogoHeaderM}</div>

            <Box className='cart-mobile' display='flex'>
              <Box mr={4}>
                <Cart
                  total={context.total}
                  isToggle={isToggle}
                  setToggle={setToggle}
                  iva={context.iva}
                  carts={context.carts}
                  subtotal={context.subtotal}
                  removeProductFromCart={context.removeProductFromCart}
                  tipoCambio={context.getTipoCambio}
                  mone={moneda.abreviatura}
                />
              </Box>
            </Box>
          </Toolbar>

          <Box display='flex'>
            <Box p={1} flexGrow={1}>
              <SearchBar className={searbarMobiile} />
            </Box>
          </Box>

          <Box display='flex'>
            <ButtonGroup className='login-mobile' display='flex'>
              {UserState.authenticated ? (
                <MenuDetalle />
              ) : (
                <div style={{ display: 'flex' }}>
                  <Box display='inline' m={0.2}>
                    <Button
                      variant='contained'
                      color='primary'
                      // onClick={doLogin}
                      onClick={() => history.push('/signin')}
                    >
                      Ingresar
                    </Button>
                  </Box>
                  <Box display='inline' m={0.2}>
                    {b2b === false && (
                      <Button
                        variant='contained'
                        color='primary'
                        // onClick={() => setIsOpenRegistro(true)}
                        onClick={() => history.push('/signup')}
                      >
                        Registrar
                      </Button>
                    )}
                  </Box>
                </div>
              )}
            </ButtonGroup>
          </Box>
        </AppBar>
      </div>
    )
  }

  const LogoHeader = (
    <RouterLink to='/'>
      {stylesContext !== '' && (
        <img src={stylesContext.logo} alt='logo' className={logo} />
      )}
    </RouterLink>
  )

  const LogoHeaderM = (
    <RouterLink to='/'>
      {stylesContext !== '' && (
        <img src={stylesContext.logo} alt='logo' className={logoMobile} />
      )}
    </RouterLink>
  )

  return (
    <header
      style={{
        zIndex: 1000,
        position: 'sticky',
        top: 0,
      }}
    >
      <div>{mobileView ? displayMobile() : displayDesktop()}</div>
    </header>
  )
}
export default HeaderResponsive
