import React from 'react'
import ReactDOM from 'react-dom'
import paypal from 'paypal-checkout'
import LoadingScreen from '../RegistroCliente/LoadingScreen'
import pedidoActions from '../../actions/pedidoActions'
/*
 * By AFelipe MX  @afelipelc
 */
import Context from '../../store/Context'
import { useContext, useState } from 'react'
import { useHistory } from 'react-router'
import GlobalContext from '../../contexts/GlobalContext'

const PaypalCheckoutButton = ({
  envios,
  order,
  costoenvio,
  flete,
  sucursalId,
  direccionId,
  resumen,
  setLoading,
  cuponesId,
  totales,
  descuentoT,
  cambiar,
  deletes,
  tarjeta,
  getPedidoResumen,
  carga,
  usd,
  use,
  envio,
  method,
}) => {
  const { monedaState } = useContext(GlobalContext)
  const [moneda, setMoneda] = useState(() => monedaState)

  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const context = useContext(Context)
  const { clearCart } = context
  const paypalConf = {
    currency: moneda.abreviatura,
    env: 'sandbox',
    client: {
      sandbox: order.id,
      production: '--',
    },
    style: {
      label: 'pay',
      size: 'medium', // small | medium | large | responsive
      shape: 'rect', // pill | rect
      color: 'gold', // gold | blue | silver | black
    },
  }

  const PayPalButton = paypal.Button.driver('react', { React, ReactDOM })

  const payment = (data, actions) => {
    console.log('Tarjeta:', tarjeta, 'Currency:', paypalConf.currency)
    if (tarjeta > 0 && use === true) {
      if (paypalConf.currency === 'MXN') {
        const payment = {
          transactions: [
            {
              amount: {
                total: tarjeta.toFixed(2),
                currency: paypalConf.currency,
              },
              description: 'Orden',
              custom: order.customer || '',
            },
          ],
          note_to_payer:
            'Contáctanos para cualquier aclaración sobre tu compra.',
        }
        return actions.payment.create({
          payment,
        })
      } else {
        const payment = {
          transactions: [
            {
              amount: {
                total: (tarjeta / context.getTipoCambio).toFixed(2),
                currency: paypalConf.currency,
              },
              description: 'Orden',
              custom: order.customer || '',
            },
          ],
          note_to_payer:
            'Contáctanos para cualquier aclaración sobre tu compra.',
        }
        return actions.payment.create({
          payment,
        })
      }
    } else {
      if (paypalConf.currency === 'MXN') {
        const payment = {
          transactions: [
            {
              amount: {
                total: tarjeta.toFixed(2),
                currency: paypalConf.currency,
              },
              description: 'Orden',
              custom: order.customer || '',
            },
          ],
          note_to_payer:
            'Contáctanos para cualquier aclaración sobre tu compra.',
        }
        return actions.payment.create({
          payment,
        })
      } else {
        const payment = {
          transactions: [
            {
              amount: {
                total: usd,
                currency: paypalConf.currency,
              },
              description: 'Orden',
              custom: order.customer || '',
            },
          ],
          note_to_payer:
            'Contáctanos para cualquier aclaración sobre tu compra.',
        }
        return actions.payment.create({
          payment,
        })
      }
    }
    // console.log(payment);
  }
  const clearCacheData = () => {
    caches.keys().then(names => {
      names.forEach(name => {
        caches.delete(name)
      })
    })
  }
  const onAuthorize = (data, actions) => {
    setLoading(true)
    setIsLoading(true)

    return actions.payment
      .execute()
      .then(response => {
        const idCarrito = window.localStorage.getItem('carritoID')
        const deliveryType99m = window.localStorage.getItem('deliveryType99m')
        envios.deliveryType99m = deliveryType99m
        // const { id, intent, status } = details;
        // const captureId =
        //   details.purchase_units[0]?.payments?.captures[0]?.id ?? "";
        console.log(response)

        let data
        if (method === 'Sucursal') {
          data = {
            carritoId: idCarrito,

            sucursalId: sucursalId,
            referencia: totales.referencia,
            clienteDireccionId: direccionId,
            cuponesIds: cuponesId,
            fleteId: null,
            idFleteConfiguracion: null,
            payPalId: response.id,
            payPalCaptureId:
              response.transactions[0].related_resources[0].sale.id,
            intent: 'CAPTURE',
            status: 'COMPLETED',
            monto: descuentoT,
          }
        } else {
          data = {
            carritoId: idCarrito,
            envio: envios,
            costoEnvio: costoenvio,
            sucursalId: sucursalId,
            referencia: totales.referencia,
            clienteDireccionId: direccionId,
            cuponesIds: cuponesId,
            fleteId: 1,
            idFleteConfiguracion: 1,
            payPalId: response.id,
            payPalCaptureId:
              response.transactions[0].related_resources[0].sale.id,
            intent: 'CAPTURE',
            status: 'COMPLETED',
            monto: descuentoT,
          }
        }
        console.log(data)

        pedidoActions
          .createPayPal99M(data)
          .then(folio => {
            setIsLoading(false)
            carga(false)
            //  window.location.href = "http://localhost:3001/orden/"+folio;

            deletes()

            history.push(`/orden/${folio}`)

            clearCart()
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            setLoading(false)
            setIsLoading(false)
          })
        console.log(response)
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
        alert('Ocurrió un error al procesar el pago con Paypal')
      })
  }

  const onError = error => {
    alert('El pago con PayPal no fue realizado, vuelva a intentarlo.')
  }

  const onCancel = (data, actions) => {
    alert('El pago con PayPal no fue realizado, el usuario canceló el proceso.')
  }

  return (
    <div>
      <LoadingScreen open={isLoading} />
      <PayPalButton
        env={paypalConf.env}
        client={paypalConf.client}
        payment={(data, actions) => payment(data, actions)}
        onAuthorize={(data, actions) => onAuthorize(data, actions)}
        onCancel={(data, actions) => onCancel(data, actions)}
        onError={error => onError(error)}
        style={paypalConf.style}
        commit
        locale='es_MX'
      />
    </div>
  )
}

export default PaypalCheckoutButton
