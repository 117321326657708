import { Box, Dialog, DialogContent, Grid, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { isMobile } from 'react-device-detect'

import Direccion from '../Direccion/Direccion'

const ModalDirecciones = ({
  currentDireccion,
  setCurrentDireccion,
  openDirecciones,
  handleCloseDirecciones,
  handleSelectDireccion,
  classes,
}) => {
  return (
    <Dialog
      onClose={handleCloseDirecciones}
      aria-labelledby='simple-dialog-title'
      open={openDirecciones}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <label style={{ paddingLeft: 16 }}>Mis direcciones de envío</label>
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={handleCloseDirecciones}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent dividers style={{ padding: 8 }}>
        <Grid container direction='column' justify='center' alignItems='center'>
          <Box display='flex' p={1} style={{ minWidth: isMobile ? 300 : 500 }}>
            <Direccion
              currentDireccion={currentDireccion}
              setCurrentDireccion={setCurrentDireccion}
              showTitle={false}
              fromOrden={true}
              handleSelectDireccion={handleSelectDireccion}
            />
          </Box>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default ModalDirecciones
