import React from 'react'
import { CirclePicker } from 'react-color'

function Colors(props) {
  const [state, setState] = React.useState('#0a0808')

  const handleChangeComplete = (color, event) => {
    setState(color.hex)
    props.filterFicha(color.hex)
  }

  return (
    <CirclePicker
      colors={[
        'red',
        'brown',
        'orange',
        'purple',
        'blue',
        'green',
        'yellow',
        'black',
        'white',
        'grey',
      ]}
      color={state}
      onChange={handleChangeComplete}
      onChangeComplete={handleChangeComplete}
    />
  )
}

export default Colors
